export function lowerCase(val) {
  return val.toString().toLowerCase();
}

export function upperCase(val) {
  return val.toString().toUpperCase();
}

export function numberFormat(val) {
  if (typeof val == "string") {
    val = parseInt(val);
  }
  return val.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}
