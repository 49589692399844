import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { firestore, FieldValue, } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import { useDocument } from 'react-firebase-hooks/firestore';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import { useData } from '../../../components/DataProvider';

function EditUpline({ dialog: { open, userId }, handleClose }) {

    const classes = useStyles();
    const { company_id } = useData();

    const usersDoc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers/${userId?.id || 'userId?.id'}`);

    const [userSnapshot, userLoading] = useDocument(usersDoc);


    const [form, setForm] = useState();

    // console.log(company_id, userId)

    useEffect(() => {
        if (userSnapshot) {

            const data = userSnapshot.data()
            setForm({
                ...data,
            });
        }

    }, [userSnapshot]);

    const [error, setError] = useState({
        path: ''
    });

    // console.log(form)

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        // console.log(e.target.type)


        if (userId?.level === 4) {
            setForm({
                ...form,
                [e.target.name]: value,
                ref_3: { path: value }
            })
        } else if (userId?.level === 3) {
            setForm({
                ...form,
                [e.target.name]: value,
                ref_2: { path: value }
            })
        } else if (userId?.level === 2) {
            setForm({
                ...form,
                [e.target.name]: value,
                ref_1: { path: value }
            })
        }

        setError({
            ...error,
            [e.target.name]: '',
            path: ''
        })
    }


    const subdomDoc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers/${form?.ref_3?.path?.user_id || form?.ref_2?.path?.user_id || form?.ref_1?.path?.user_id}`)

    const [subdomSnapshot, subdomLoading] = useDocument(subdomDoc);

    const [subForm, setSubForm] = useState();

    useEffect(() => {
        if (subdomSnapshot) {

            const data = subdomSnapshot.data()
            setSubForm({
                ...data,
            });
        }

    }, [subdomSnapshot]);
    // console.log(subForm)

    const levelDoc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers_level/${userId?.ref_3?.user_id || userId?.ref_2?.user_id || userId?.ref_1?.user_id || 'company_id'}`)

    const [levelSnapshot, levelLoading] = useDocument(levelDoc);

    const [levelForm, setLevelForm] = useState();

    useEffect(() => {
        if (levelSnapshot) {

            const data = levelSnapshot.data()
            setLevelForm({
                ...data,
            });
        }

    }, [levelSnapshot]);
    // console.log(levelForm)
    const domDoc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers_webstore_path/${form?.ref_3?.path || form?.ref_2?.path || form?.ref_1?.path || 'company_id'}`)

    const [domSnapshot, domLoading] = useDocument(domDoc);

    const [domForm, setDomForm] = useState();

    useEffect(() => {
        if (domSnapshot) {

            const data = domSnapshot.data()
            setDomForm({
                ...data,
            });
        }

    }, [domSnapshot]);
    // console.log(domForm)
    const user2Doc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers/${domForm?.user_id || 'company_id'}`)

    const [user2Snapshot, user2Loading] = useDocument(user2Doc);

    const [userForm, setUserForm] = useState();

    useEffect(() => {
        if (user2Snapshot) {

            const data = user2Snapshot.data()
            setUserForm({
                ...data,
            });
        }

    }, [user2Snapshot]);
    // console.log(userForm)
    const levelsDoc = firestore.doc(`mitra/${company_id || 'company_id'}/sellers_level/${domForm?.user_id || 'company_id'}`)

    const [levelsSnapshot, levelsLoading] = useDocument(levelsDoc);

    const [levelsForm, setLevelsForm] = useState();

    useEffect(() => {
        if (levelsSnapshot) {

            const data = levelsSnapshot.data()
            setLevelsForm({
                ...data,
            });
        }

    }, [levelsSnapshot]);
    // console.log(form)
    // console.log(userForm)
    // console.log(levelsForm)
    // console.log(
    //     levelsForm?.sellerId,
    //     form?.ref_3?.path,
    //     domForm?.user_id,
    //     userForm?.ref_2?.user_id,
    //     userForm?.ref_1?.sellerId,
    //     userForm?.ref_1?.path)
    const validate = async () => {
        const newError = { ...error };

        if (levelForm?.level !== userForm?.level) {
            newError.path = 'Upline harus sesuai';
        }
        // if (!form.sellerId) {
        //     newError.sellerId = 'Seller Id harus diisi';
        // }
        // if (!form.path) {
        //     newError.path = 'Subdomain harus diisi';
        // }
        // if (!form.namaToko) {
        //     newError.namaToko = 'Nama Toko harus diisi';
        // }
        // if (!form.wa) {
        //     newError.wa = 'Wa harus diisi';
        // }
        // if (!form.level) {
        //     newError.level = 'Level harus diisi';
        // }
        // if (!form.password) {
        //     newError.password = 'Password harus diisi';
        // }

        return newError;
    }

    // console.log('here', userId?.tipe_akun);
    // const handleSubmit = async e => {
    //     e.preventDefault();
    //     const findError = await validate();

    //     console.log(findError)
    //     if (Object.values(findError).some(m => m !== "")) {
    //         setError(findError)
    //     } else {
    //         setSubmitting(true)
    //         try {
    //             if (form?.tipe_akun === 'reseller') {
    //                 console.log('here', userId.tipe_akun);
    //                 const updProd = {
    //                     ...form,
    //                     ref_3: { sellerId: subForm?.ref_3?.sellerId, path: subForm?.ref_3?.path, user_id: subForm?.ref_3?.user_id },
    //                     ref_2: { sellerId: subForm?.ref_2?.sellerId, path: subForm?.ref_2?.path, user_id: subForm?.ref_2?.user_id },
    //                     ref_1: { sellerId: subForm?.ref_1?.sellerId, path: subForm?.ref_1?.path, user_id: subForm?.ref_1?.user_id },
    //                     kode_referral: subForm?.ref_3?.path,
    //                     updated_at: FieldValue.serverTimestamp(),


    //                 };

    //                 await usersDoc.set(updProd, { merge: true });

    //                 enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

    //                 handleClose();
    //             } else if (form?.tipe_akun === '3') {
    //                 console.log('here', userId.tipe_akun);
    //                 const updProd = {
    //                     ...form,
    //                     ref_2: { sellerId: subForm?.ref_2?.sellerId, path: subForm?.ref_2?.path, user_id: subForm?.ref_2?.user_id },
    //                     ref_1: { sellerId: subForm?.ref_1?.sellerId, path: subForm?.ref_1?.path, user_id: subForm?.ref_1?.user_id },
    //                     kode_referral: subForm?.ref_2?.path,
    //                     updated_at: FieldValue.serverTimestamp(),


    //                 };

    //                 await usersDoc.set(updProd, { merge: true });

    //                 enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

    //                 handleClose();
    //             } else if (form?.tipe_akun === '2') {
    //                 console.log('here', userId.tipe_akun);
    //                 const updProd = {
    //                     ...form,
    //                     ref_1: { sellerId: subForm?.ref_1?.sellerId, path: subForm?.ref_1?.path, user_id: subForm?.ref_1?.user_id },
    //                     kode_referral: subForm?.ref_1?.path,
    //                     updated_at: FieldValue.serverTimestamp(),


    //                 };

    //                 await usersDoc.set(updProd, { merge: true });

    //                 enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

    //                 handleClose();
    //             }


    //         } catch (e) {
    //             const newError = {};

    //             newError.title = e.message;

    //             setError(newError);
    //         }

    //         setSubmitting(false)
    //     }
    // }
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const updProd = {
                    ...form,
                    ref_3: { path: form?.ref_3?.path, user_id: domForm?.user_id },
                    ref_2: { path: userForm?.ref_2?.path, user_id: userForm?.ref_2?.user_id },
                    ref_1: { path: userForm?.ref_1?.path, user_id: userForm?.ref_1?.user_id },
                    kode_referral: form?.ref_3?.path,
                    updated_at: FieldValue.serverTimestamp(),


                };

                await usersDoc.set(updProd, { merge: true });

                enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

                handleClose();



            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }
    const handleSubmitAgen = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const updProd = {
                    ref_2: { sellerId: levelsForm?.sellerId, path: form?.ref_2?.path, user_id: domForm?.user_id },
                    ref_1: { sellerId: userForm?.ref_1?.sellerId, path: userForm?.ref_1?.path, user_id: userForm?.ref_1?.user_id },
                    kode_referral: form?.ref_2?.path,
                    updated_at: FieldValue.serverTimestamp(),


                };

                await usersDoc.set(updProd, { merge: true });

                enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

                handleClose();



            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }
    const handleSubmitDistri = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {

                const updProd = {
                    ...form,
                    ref_1: { sellerId: levelsForm?.sellerId, path: form?.ref_1?.path, user_id: domForm?.user_id },
                    kode_referral: form?.ref_1?.path,
                    updated_at: FieldValue.serverTimestamp(),


                };

                await usersDoc.set(updProd, { merge: true });

                enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

                handleClose();



            } catch (e) {
                const newError = {};

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }
    // const subdom = levelList?.filter((sub) => {
    //     if (sub?.id === subForm?.id) {
    //         return sub
    //     }
    // })
    // console.log(subdom)









    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Edit Upline</DialogTitle>
            <DialogContent dividers>
                {
                    userLoading || subdomLoading || levelLoading || levelsLoading || domLoading || user2Loading ?
                        <CircularProgress size={20} className={classes.loading} /> : null
                }
                <Grid container spacing={2}>
                    {form?.level === 4 &&
                        <Grid item>
                            <TextField
                                // className={classes.margin}
                                id="ref_3"
                                name="ref_3"

                                margin="normal"

                                required
                                value={form?.ref_3?.path}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                variant="outlined"
                                helperText={error?.path}
                                error={error?.path ? true : false}
                            />
                        </Grid>
                    }
                    {form?.level === 3 &&
                        <Grid item>
                            <TextField
                                // className={classes.margin}
                                id="ref_2"
                                name="ref_2"

                                margin="normal"

                                required
                                value={form?.ref_2?.path}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                variant="outlined"
                                helperText={error?.path}
                                error={error?.path ? true : false}
                            />
                        </Grid>
                    }
                    {form?.level === 2 &&
                        <Grid item>
                            <TextField
                                // className={classes.margin}
                                id="ref_1"
                                name="ref_1"

                                margin="normal"

                                required
                                value={form?.ref_1?.path}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                variant="outlined"
                                helperText={error?.path}
                                error={error?.path ? true : false}
                            />
                        </Grid>
                    }
                    {/* {
                        !form?.level &&
                        <Grid item>
                            <Typography style={{textAlign: 'center', width: 250}}>Kosong</Typography>
                        </Grid>
                    } */}

                </Grid>
            </DialogContent>
            <DialogActions>
                {
                    form?.level === 4 &&
                    <><Button
                        disabled={isSubmitting}
                        onClick={handleClose}
                    >Batal</Button>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            color="primary"
                        >
                            Simpan
                        </Button></>
                }
                {
                    form?.level === 3 &&
                    <><Button
                        disabled={isSubmitting}
                        onClick={handleClose}
                    >Batal</Button>
                        <Button
                            onClick={handleSubmitAgen}
                            disabled={isSubmitting}
                            color="primary"
                        >
                            Simpan
                        </Button></>
                }
                {
                    form?.level === 2 &&
                    <><Button
                        disabled={isSubmitting}
                        onClick={handleClose}
                    >Batal</Button>
                        <Button
                            onClick={handleSubmitDistri}
                            disabled={isSubmitting}
                            color="primary"
                        >
                            Simpan
                        </Button></>
                }
                {
                    !form?.level &&
                    <Button
                        fullWidth
                        disabled={isSubmitting}
                        onClick={handleClose}
                    >Kembali</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

EditUpline.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default EditUpline;
