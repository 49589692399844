import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

import qs from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { firestore, functions } from "../../../components/FirebaseProvider";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import useStyles from "./styles";
import PageLoading from "../../../components/loading/pageLoading";
// import { useData } from '../../../components/DataProvider';
import ProductList from "./listProduct";
// import { IconButton, Toolbar, Typography, AppBar } from '@material-ui/core';
// icon
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import WidgetsIcon from "@material-ui/icons/Widgets";
import ListPo from "./listPO";
import SmartPO from "./smartPO";
import { useData } from "../../../components/DataProvider";
import { useSnackbar } from "notistack";
import MinimumBelanja from "./minimumBelanja/listProduct";

function TabPO() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { hideSetting, company_id } = useData();
  const docFitur = firestore.doc(`mitra/${company_id}/settings/fitur`);
  const [fitur, loadFitur] = useDocumentData(docFitur);
  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "listProduct"
  );
  useEffect(() => {
    if (fitur?.is_aktif === false || !fitur?.is_aktif) {
      setTabValue("minimumBelanja");
    } else if (
      hideSetting.data().hideSmartPO === true &&
      hideSetting.data().hideManualPO === false
    ) {
      setTabValue("listProduct");
    } else if (
      hideSetting.data().hideSmartPO === false &&
      hideSetting.data().hideManualPO === true
    ) {
      setTabValue("smartPO");
    } else if (
      hideSetting.data().hideSmartPO === true &&
      hideSetting.data().hideManualPO === true
    ) {
      setTabValue("listPO");
    } else {
      setTabValue("listProduct");
    }
    //   hideSetting.data().hideSmartPO && hideSetting.data().hideManualPO
    //     ? qs.parse(location.search)?.tab ?? "listPO"
    //     : hideSetting.data().hideManualPO
    //     ? qs.parse(location.search)?.tab ?? "smartPO"
    //     : qs.parse(location.search)?.tab ?? "listProduct"
    // );
  }, [fitur]);
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);
  const [tabs, setTabs] = useState();
  useEffect(() => {
    setTabs(
      fitur?.is_aktif === false || !fitur?.is_aktif
        ? [
            {
              label: "Minimal Belanja",
              value: "minimumBelanja",
              icon: <WidgetsIcon />,
            },
            { label: "List PO", value: "listPO", icon: <WidgetsIcon /> },
          ]
        : hideSetting.data().hideSmartPO && hideSetting.data().hideManualPO
        ? [{ label: "List PO", value: "listPO", icon: <WidgetsIcon /> }]
        : hideSetting.data().hideSmartPO
        ? [
            {
              label: "Manual PO",
              value: "listProduct",
              icon: <LocalOfferIcon />,
            },
            { label: "List PO", value: "listPO", icon: <WidgetsIcon /> },
          ]
        : hideSetting.data().hideManualPO
        ? [
            { label: "Smart PO", value: "smartPO", icon: <WidgetsIcon /> },
            { label: "List PO", value: "listPO", icon: <WidgetsIcon /> },
          ]
        : [
            {
              label: "Manual PO",
              value: "listProduct",
              icon: <LocalOfferIcon />,
            },
            { label: "Smart PO", value: "smartPO", icon: <WidgetsIcon /> },

            { label: "List PO", value: "listPO", icon: <WidgetsIcon /> },
          ]
    );
  }, [fitur]);

  // const { company_id } = useData();
  const prodDoc = firestore.doc(`products/${params.productId}`);
  const [prodSnapshot, prodLoading] = useDocument(prodDoc);
  const [prodForm, setProdForm] = useState();
  useEffect(() => {
    if (prodSnapshot) {
      const data = prodSnapshot.data();
      setProdForm({
        ...data,
      });
    }
  }, [prodSnapshot]);

  const { enqueueSnackbar } = useSnackbar();

  // response
  const [response, setResponse] = useState();
  // loading
  const [loading, setLoading] = useState(false);
  //ongkir
  const [ongkir, setOngkir] = useState();

  // finde toko
  const handleFind = async () => {
    setLoading(true);

    try {
      const find = functions.httpsCallable("smartPO-findStock");

      const response = await find();

      const data = response.data;
      console.log(data);

      setResponse(data);
      setLoading(false);
      // history.push('/smartPO/searchToko')
    } catch (e) {
      console.log(e.message);

      enqueueSnackbar(e.message, { variant: "error" });
      setLoading(false);
    }
  };

  if (prodLoading) {
    return <PageLoading />;
  }
  return (
    <>
      <Paper square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={tabValue || "listProduct"}
          onChange={handleChangeTab}
          className={classes.tab}
          style={{ marginTop: 7 }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              icon={tab.icon}
            />
          ))}
        </Tabs>
      </Paper>
      <div>
        <ProductList value={tabValue} />
        <ListPo value={tabValue} />
        <MinimumBelanja value={tabValue} />
        <SmartPO
          value={tabValue}
          loading={loading}
          setLoading={setLoading}
          response={response}
          setResponse={setResponse}
          handleFind={handleFind}
          ongkir={ongkir}
          setOngkir={setOngkir}
        />
      </div>
    </>
  );
}

export default TabPO;
