import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import {
//   firestore,
//   functions,
// } from "../../../components/FirebaseProvider";
// import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import {
  List,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { currency } from "../../../../utils/formatter";
import { firestore, functions } from "../../../../components/FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { DataJumlah } from "./dataVariant";
// import { useDocument } from "react-firebase-hooks/firestore";
function PilihKurir({
  editDialog: {
    open,
    formKurir,
    prod,
    mitraAsalId,
    prodId,
    varId,
    jumlah,
    company_id,
    handleEditKurir,
  },
  handleClose,
}) {
  // const classes = useStyles();
  // const location = useLocation();
  // const { enqueueSnackbar } = useSnackbar();

  //   cf getOngkir\
  // mitra_asal
  const mitraAsalDoc = firestore.doc(
    `mitra/${mitraAsalId || "mitra_asal"}/settings/alamat`
  );
  const [mitraAsal, loadMitrAs] = useDocumentData(mitraAsalDoc);

  //   mitra dest
  const destinationDoc = firestore.doc(`mitra/${company_id}/settings/alamat`);
  const [formDest, loadDest] = useDocumentData(destinationDoc);

  //   variants
  const lengthProdId = prodId?.length;
  let index = [];
  // let variantDoc
  for (var j = 0; j < lengthProdId; j++) {
    index.push(
      <DataJumlah
        prodId={prodId?.[j]}
        varId={varId?.[j]}
        jumlah={jumlah?.[j]}
      />
    );
  }
  const variantDoc = firestore.doc(
    `products/${prodId?.[j]}/variants/${varId?.[j]}`
  );

  const [variantData, loadVar] = useDocumentData(variantDoc);
  // console.log(prod);

  const [berat, setBerat] = useState();
  useEffect(() => {
    async function getProductDetail() {
      const beratProducts = await Promise.all(
        prod?.map?.(async (p) => {
          const variantDoc = await firestore
            .doc(`products/${p?.productId}/variants/${p?.variantId}`)
            .get();
          if (variantDoc.exists) {
            const variantData = variantDoc.data();

            return variantData.berat_paket * p?.jumlah;
          }
        }) ?? []
      );
      const jumlahBerat = beratProducts.reduce((a, b) => a + b, 0);
      setBerat(jumlahBerat);
    }
    getProductDetail();
  }, [JSON.stringify(prod)]);

  // console.log(berat);

  const [isSubmitting, setSubmitting] = useState(false);
  const provider_detail = formDest?.[formDest?.provider] ?? {};
  const [kurir_regular, setKurir_regular] = useState({});

  useEffect(() => {
    if (
      berat &&
      mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id &&
      formDest?.[formDest?.provider] &&
      provider_detail
    ) {
      setSubmitting(true);
      async function cekOngkir() {
        try {
          const getOngkir = functions.httpsCallable("shipping-ongkir");
          let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;

          let destination = provider_detail?.kecamatan?.id;

          if (mitraAsal?.provider === "directapi") {
            origin = {};
            destination = {};
            let origin_sicepat = mitraAsal?.[
              mitraAsal?.provider
            ]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
            let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

            if (
              origin_sicepat &&
              destination_sicepat &&
              mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat
            ) {
              origin.sicepat = origin_sicepat;
              destination.sicepat = destination_sicepat;
            }
            let origin_jne =
              mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
            let destination_jne =
              provider_detail?.kelurahan?.kode_destination_jne;
            if (
              origin_jne &&
              destination_jne &&
              mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne
            ) {
              origin.jne = origin_jne;
              destination.jne = destination_jne;
            }
            let origin_kode_ninja_lt1 =
              mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_ninja_lt1;
            let origin_kode_ninja_lt2 =
              mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_ninja_lt2;
            let destination_kode_ninja_lt1 =
              provider_detail?.kelurahan?.kode_ninja_lt1;
            let destination_kode_ninja_lt2 =
              provider_detail?.kelurahan?.kode_ninja_lt2;
            if (
              origin_kode_ninja_lt1 &&
              origin_kode_ninja_lt2 &&
              destination_kode_ninja_lt1 &&
              destination_kode_ninja_lt2
            ) {
              origin.ninja_l1 = origin_kode_ninja_lt1;
              origin.ninja_l2 = origin_kode_ninja_lt2;
              destination.ninja_l1 = destination_kode_ninja_lt1;
              destination.ninja_l2 = destination_kode_ninja_lt2;
            }
            let origin_jnt =
              mitraAsal?.[mitraAsal?.provider]?.kelurahan?.nama_sendSite_jnt;
            let destination_jnt =
              provider_detail?.kelurahan?.nama_destination_jnt;
            if (origin_jnt && destination_jnt) {
              origin.jnt_send = origin_jnt;
              destination.jnt_dest = destination_jnt;
            }
          }

          // const destination = alamat?.kecamatan?.id
          // const origin = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
          // const destinationCoord = alamat?.koordinat ? `${alamat.koordinat?.latitude}, ${alamat.koordinat?.longitude}` : null;
          // const weight = variantData?.berat_paket * jumlah;
          // const price = hargaPricing?.data?.priceslocal?.[0] * jumlah;
          // const length = variantData?.panjang_paket;
          // const width = variantData?.lebar_paket;
          // const height = variantData?.tinggi_paket;
          // const mitra_tujuan = form?.[form?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)

          let sub = {
            id_asal: origin,
            id_tujuan: destination,
            berat: berat,
            // panjang: length,
            // lebar: width,
            // tinggi: height,
            // harga: price,
            cod: 0,
            type: 2,
          };

          const response = await getOngkir(sub);

          let data = {};
          switch (response.data.provider) {
            case "directapi":
              data = Object.entries(response.data.items)
                .map(([key, value]) => {
                  return {
                    title: key,
                    data: value
                      ?.filter(
                        (item, index) =>
                          item?.service !== "KEPO" && item?.service !== "REG"
                      )
                      .map?.((item) => {
                        if (key === "sicepat") {
                          return {
                            provider: "sicepat",
                            ...item,
                            name: "SiCepat",
                            rate_name: item?.service,
                            rate_id: item?.service,
                            finalRate: item?.tariff,
                            kurir: "sicepat",
                            tipe: item?.service,
                            berat_total: berat,
                          };
                        } else if (key === "jne") {
                          return {
                            provider: "jne",
                            ...item,
                            name: "JNE",
                            service: item.service_display,
                            rate_id: item.service_code,
                            rate_name: item.service_display,
                            finalRate: parseInt(item.price),
                            kurir: "jne",
                            tipe: item.service_code,
                            berat_total: berat,
                          };
                        } else if (key === "ninja") {
                          return {
                            provider: "ninja",
                            ...item,
                            name: "Ninja",
                            rate_id: item?.serviceType,
                            rate_name: item?.serviceName,
                            finalRate: item?.price,
                            kurir: "ninja",
                            tipe: item?.serviceName,
                            berat_total: berat,
                          };
                        } else if (key === "jnt") {
                          return {
                            provider: "jnt",
                            ...item,
                            name: "JNT",
                            rate_id: item?.productType,
                            rate_name: item?.productType,
                            finalRate: parseInt(item?.cost),
                            kurir: "jnt",
                            tipe: item?.nameService,
                            berat_total: berat,
                          };
                        }
                      }),
                  };
                })
                .filter((item) => item.data.length > 0);
              // data = response.data.items.map(item => {

              //   return {
              //     title: item.description,
              //     data: [
              //       {
              //         ...item,
              //         name: item.description,
              //         rate_name: item.service,
              //         rate_id: item.service,
              //         finalRate: item.tariff
              //       }
              //     ]
              //   }
              // })
              break;
            case "rajaongkir":
              data = response.data.items
                .map((item) => {
                  return {
                    title: item.name,
                    data: item.costs.map((cost) => {
                      return {
                        ...cost,
                        rate_id: `${item.code}-${cost.service}`,
                        finalRate: cost?.cost?.[0]?.value ?? 0,
                        name: item.name,
                        rate_name: cost.service,
                        berat_total: berat,
                      };
                    }),
                  };
                })
                .filter((item) => item.data.length > 0);

              break;
            case "shipper":
              if (kurir_regular) {
                data = Object.entries(kurir_regular)
                  .map(([key, value]) => {
                    return {
                      title: key,
                      data: value,
                    };
                  })
                  .filter((item) => item.data.length > 0);
              }
              break;
            default:
          }
          console.log("respo", response?.data);
          setKurir_regular(data);
          // setOngkir(data)

          setSubmitting(false);
        } catch (e) {
          console.log(e.message);
        }
      }
      cekOngkir();
    }
  }, [berat, mitraAsal, provider_detail]);
  // console.log((hargaTagihan + ongkir?.[0]?.data?.[0]?.finalRate))

  // console.log(kurir_regular);

  const lengthKurir = kurir_regular?.length;
  var fieldsArray = [];

  for (var i = 0; i < lengthKurir; i++) {
    fieldsArray.push(
      kurir_regular?.[i]?.data ? (
        <>
          <Typography>{kurir_regular?.[i]?.title.toUpperCase()}</Typography>
          {kurir_regular?.[i]?.data
            ?.filter((value, index) => {
              return (
                value?.service !== "KEPO" &&
                !value?.service?.includes("JTR") &&
                !value?.service?.includes("CTC")
              );
            })
            ?.map?.((item, i) => {
              return (
                <>
                  {item?.price !== 0 && item?.serviceName !== "Cargo" && (
                    <List key={i}>
                      <ListItem
                        key={i}
                        button
                        // disabled={isSubmitting}
                        // selected={selectedIndex === 0}
                        value={formKurir?.pengiriman}
                        // disabled={isSubmitting}
                        // selected={selectedIndex === 0}
                        onClick={(event, value) => {
                          handleEditKurir({
                            target: { name: "pengiriman", value: item },
                          });
                          handleClose();
                        }}
                      >
                        <ListItemText
                          primary={`${item?.provider?.toUpperCase()} ${
                            item?.provider === "jne" ||
                            item?.provider === "ninja" ||
                            item?.provider === "jnt"
                              ? ""
                              : item?.description
                          } - ${
                            item?.provider === "ninja"
                              ? item?.serviceName
                              : item?.provider === "jnt"
                              ? item?.nameService
                              : item?.service
                          }`}
                          secondary={`${currency(item?.finalRate)} 
                            - ( ${
                              item?.provider === "jne"
                                ? item?.etd_from !== null
                                  ? `${item?.etd_from} - ${item.etd_thru} hari`
                                  : `0 hari`
                                : `${item?.etd}`
                            } 
                            )
                        `}
                        />
                      </ListItem>
                    </List>
                  )}
                </>
              );
            })}
        </>
      ) : (
        <ListItemText primary="Belum Tersedia" />
      )
    );
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Pilih Jasa Pengiriman </DialogTitle>
      <DialogContent dividers>
        {isSubmitting || loadMitrAs || loadDest || loadVar
          ? "Loading..."
          : fieldsArray}
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={handleClose}>
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PilihKurir.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PilihKurir;
