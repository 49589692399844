import React, { useState, useEffect, forwardRef } from "react";
import Container from "@material-ui/core/Container";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Fab from "@material-ui/core/Fab";
import useStyles from "./styles";
import AddProduct from "./add";
import { useCollection } from "react-firebase-hooks/firestore";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import { useSnackbar } from "notistack";
import tableIcons from "../../../config/table-icons";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
// import ExcelRead from './addFileExcel';
import { useData } from "../../../components/DataProvider";
import Edit from "@material-ui/icons/Edit";
import TabPanel from "../../../components/TabPanel";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import { Delete, EditSharp } from "@material-ui/icons";

function SetAdmin({ value }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddProduct, setOpenAddProduct] = useState({
    mode: "Tambah",
    open: false,
  });
  // const komisiDoc = firestore.doc(`settings/komisi`);
  // const [komisiSnapshot, komisiLoading] = useDocument(komisiDoc);
  // const [komisiForm, setKomisiForm] = useState();
  // console.log(komisiForm)
  // useEffect(() => {
  // 	if (komisiSnapshot) {
  // 		const data = komisiSnapshot.data()
  // 		setKomisiForm({
  // 			...data,
  // 		});
  // 	}
  // }, [komisiSnapshot]);
  const { company_id } = useData();
  const col = firestore.collection(`mitra/${company_id}/admin`);
  const [snapshot, loading] = useCollection(col);
  const [table, setTable] = useState({
    columns: [
    {
        label: "Actions",
        name: "uid",
        options: {
            sort : false,
            customBodyRender: (value, tableMeta, updateValue) => {
            let data = value
            // console.log('==>',)
            return (
                <>
                  <IconButton
                    aria-label="delete"
                    onClick={async () => {
                      if (window.confirm(`apakah anda yakin menghapus admin ini?`)){
                        await firestore.doc(`mitra/${company_id}/admin/${data}`).delete();
                        await firestore.doc(`mitra/${company_id}/access_control_list/${data}`).delete();
                        enqueueSnackbar(`Berhasil Menghapus Admin ${data}`, {
                          variant: "success",
                        });
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                  {tableMeta?.tableData.length > 4 ? 
                    null
                  : <>
                    <IconButton
                        aria-label="edit"
                        onClick={async () => {
                          let data = await firestore
                            .doc(`mitra/${company_id}/admin/${value}`)
                            .get();
                          let result = data.data();
                          setOpenAddProduct((dialog) => ({
                            ...dialog,
                            mode: "Edit",
                            data: result,
                            id: value,
                            open: true,
                          }));
                        }}
                      >
                        <EditSharp />
                      </IconButton>
                    </> 
                  }
                </>
              );
            },
        },
    },
    {
      label: "Email",
      name: "email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = value
          if (value) {
            return data
          } else {
            return <p>-</p>;
          }
        },
      },
    },
    {
        label: "Password",
        name: "password_tmp",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value
            if (value) {
              return `**********`
            } else {
              return <p>-</p>;
            }
          },
        },
    },
    {
        label: "Status",
        name: "access_role",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
            let data = value
            if (value) {
                return data
            } else {
                return <p>-</p>;
            }
            },
        },
    },
    {
      label: "Tanggal Dibuat",
      name: "created_at",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = value;
          return (
            <>
              {rowData && rowData !== null ? (
                <span>{unixToDate(rowData.toMillis())}</span>
              ) : (
                <span>-</span>
              )}
            </>
          );
        },
      },
    },
    {
      label: "Tanggal Diubah",
      name: "updated_at",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = value;
          return (
            <>
              {rowData && rowData !== null ? (
                <span>{unixToDate(rowData?.toMillis())}</span>
              ) : (
                <span>Belum Di Perbarui</span>
              )}
            </>
          );
        },
      },
    },
    ],
    
    data: [],
  });

  const options = {
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 35, 50],
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "600px",
    download: false,
    print: false,
    empty: true,
    selectableRows: "none",
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: true,
    textLabels: {
        body: {
        noMatch: "Maaf Data Admin Tidak Di Temukan",
        toolTip: "Sort",
            columnHeaderTooltip: (column) => `Filter Berdasarkan ${column.label}`,
        },
        pagination: {
            next: "Next Page",
            previous: "Previous Page",
            rowsPerPage: "Baris per Halaman:",
            displayRows: "Dari",
        },
        toolbar: {
            search: "Cari Produk",
            downloadCsv: "Download CSV",
            print: "Print Data Product",
            viewColumns: "View Columns",
            filterTable: "Filter Table",
        },
        selectedRows: {
            text: "Baris Di Tandai",
            delete: "Delete",
            deleteAria: "Delete Selected Rows",
        },
    },
  };

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  if (
    loading
    // || komisiLoading
  ) {
    return <PageLoading />;
  }
  // console.log(table?.data)

  return (
    <>
      <TabPanel value={value} index="admin">
        <div className={classes.root}>
          {loading ? (
            <PageLoading />
          ) : (
            <Container className={classes.tableCon} maxWidth="lg">
              <div className={classes.tableWrap}>
                <MUIDataTable
                    title={"Daftar Admin"}
                    columns={table?.columns}
                    data={table?.data}
                    options={options}
                />
              </div>
            </Container>
          )}
        </div>
        {table?.data?.length > 4 ?
          null
        :
        <div className={classes.fab1}>
          <Fab
            color="secondary"
            onClick={() => {
              setOpenAddProduct({
                mode: "Tambah",
                open: true,
                data: {},
                id: "",
              });
            }}
            tooltip
          >
            <PostAddIcon />
          </Fab>
          <AddProduct
            dialog={openAddProduct}
            handleClose={() => {
              setOpenAddProduct({ mode: "Tambah", open: false });
            }}
          />
          {/* <Button color="primary" variant="contained" className={classes.fab}
					onClick={(e) => {
						setOpenAddDialog({
							open: true,
							// komisi: komisiForm
						});
					}}
				>
					<UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
				</Button>
				<ExcelRead
					dialog={openAddDialog}
					handleClose={() => {
						setOpenAddDialog({
							open: false,
							// komisi: null
						});
					}}
				/> */}
        </div>
      }
      </TabPanel>
    </>
  );
}

export default SetAdmin;
