import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({



    noOrderMsg: {
        // width: 150,
        height: 40,
        marginTop: '7%',
        // marginBottom: '7%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 30,
        // border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noOrderMsg2: {
        // width: 150,
        height: 40,
        // marginTop: '7%',
        marginBottom: '7%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 20,
        // border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

}))

export default useStyles;