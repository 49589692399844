import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { firestore, FieldValue } from '../../../../components/FirebaseProvider';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useData } from '../../../../components/DataProvider';
import SeacrhProduk from './searchProduk';
import ProductSmartPO from '../../../../components/produkSmartPO';
import { useCollectionData } from 'react-firebase-hooks/firestore';




function AddProductSmartPO({ open, handleClose }) {
    const history = useHistory();

    const { treeDataKategori, company_id, peoductList } = useData();

    const [form, setForm] = useState({
        product: '',
        jumlah: '',
        variant: '',

    });
    // console.log(form)

    const [error, setError] = useState({
        product: '',
        jumlah: '',
        variant: '',

    });

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        // console.log(e.target.type);

        setForm({
            ...form,
            [e.target.name]: value,
        });


        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    // variant
    const varCol = firestore.collection(`products/${form?.product?.id}/variants`);
    const [varData, loadingVar] = useCollectionData(varCol, {
        idField: "id",
    });

    const validate = async () => {
        const newError = { ...error };

        if (!form.variant) {
            newError.variant = 'variant harus diisi';
        }
        if (!form.jumlah) {
            newError.jumlah = 'jumlah harus diisi';
        }

        return newError;
    }

    const handleSimpan = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const purchaseDoc = firestore.collection(`smart_purchase_orders`).doc(company_id);


            // if (state === false) {
            await purchaseDoc.set({
                // seller_mitra: { ...mitraDoc?.[0], mitra_unique_id: subdomainSeller },
                subcarts:
                {
                    alamat:
                    {
                        berat_total: (form?.variant?.berat_paket * form?.jumlah), height: form?.variant?.tinggi_paket, length: form?.variant?.panjang_paket, width: form?.variant?.lebar_paket,
                        //  total_pembayaran: (hargaTagihan + ongkir?.pengiriman?.finalRate) || (hargaTagihan + ongkir?.[0]?.data?.[0]?.finalRate), total_tagihan: hargaTagihan,
                        products:
                        {
                            [`${form?.product?.product_id}_${form?.variant?.variant_id}`]: { created_at: FieldValue.serverTimestamp(), jumlah: form?.jumlah, nama: form?.product?.nama, product: form?.product?.product_id, variant: `${form?.variant?.variant1?.label} ${form?.variant?.variant1?.nilai},${form?.variant?.variant2?.label} ${form?.variant?.variant2?.nilai}`, promo: false, berat: (form?.variant?.berat_paket * form?.jumlah) }
                        },
                        // pengiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
                        // biaya_pengiriman: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate,
                        // biaya_pengiriman_before: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate

                    }
                },
                // kode_po: `p_${subdomainSeller}_${subdomain?.[0]}_${Date.now()}`,
                created_at: FieldValue.serverTimestamp(),
                updated_at: FieldValue.serverTimestamp(),
            }, { merge: true });
            // } else if (state === true) {
            //     await purchaseDoc.set({
            //         seller_mitra: { ...mitraDoc?.[0], mitra_unique_id: subdomainSeller },
            //         subcarts:
            //         {
            //             [alamat?.alamat?.id ? alamat?.alamat?.id : 'alamat']:
            //             {
            //                 berat_total: (form?.variant2?.berat_paket * jumlah), height: form?.variant2?.tinggi_paket, length: form?.variant2?.panjang_paket, width: form?.variant2?.lebar_paket, total_pembayaran: hargaTagihan, total_tagihan: hargaTagihan,
            //                 products:
            //                 {
            //                     [`${params?.productId}_${varId?.[0]}`]: { created_at: FieldValue.serverTimestamp(), jumlah: jumlah, nama: prodForm?.nama, product: prodForm?.product_id, variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`, promo: false, harga: hargaTagihan, berat: (form?.variant2?.berat_paket * jumlah) }
            //                 },

            //                 biaya_pengiriman: 0,
            //                 biaya_pengiriman_before: 0,
            //                 resi_otomatis_mp: true,
            //                 resi_otomatis_mp_kurir: form?.kurir,
            //                 resi_otomatis_mp_resi: form?.resi

            //             }
            //         },
            //         kode_po: `p_${subdomainSeller}_${subdomain?.[0]}_${Date.now()}`,
            //         created_at: FieldValue.serverTimestamp(),
            //         updated_at: FieldValue.serverTimestamp(),
            //     });
            // }
            enqueueSnackbar("Produk Berhasil Ditambahkan", {
                variant: "success",
            });
            handleClose()
            // history.replace("/cart");


        } catch (e) {
            setError(e.message);
        }
        setSubmitting(false);

    };


    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Tambah Produk</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>


                    <Grid item xs={12}>
                        <ProductSmartPO
                            // value={peoductList}
                            data={form?.product}
                            handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            id="variant"
                            name="variant"
                            margin="normal"
                            required
                            value={form?.variant || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "variant", value } });
                            }}
                            disabled={isSubmitting || loadingVar}
                            options={varData || ''}
                            getOptionLabel={(option) => `${option?.variant1?.nilai ?

                                `${option?.variant1?.nilai}_${option?.variant2?.nilai}`

                                : ''}`}
                            renderOption={(option) => (
                                <div>
                                    {option?.id} {","} {option?.variant1?.label}: {option?.variant1?.nilai} {","}  {option?.variant2?.label}: {option?.variant2.nilai}
                                </div>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.variantId}
                                    error={error?.variantId ? true : false}
                                    {...params}
                                    label="Variant"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingVar ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="jumlah"
                            name="jumlah"
                            label="Jumlah Produk"
                            onChange={handleChange}
                            error={error.jumlah ? true : false}
                            helperText={error.jumlah}
                            fullWidth
                            type='number'
                            variant="outlined"
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button
                            onClick={handleSimpan}
                            disabled={isSubmitting}
                            fullWidth
                            color="primary"
                            variant='contained'
                        >
                            Tambah Produk
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>
            {/* <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    fullWidth
                >Batal</Button>
               
            </DialogActions> */}
        </Dialog>
    )
}

AddProductSmartPO.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddProductSmartPO;
