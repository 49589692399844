import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import PageLoading from "../../../../components/loading/pageLoading";
import Typography from '@material-ui/core/Typography';
import { currency } from "../../../../utils/formatter";

export default function HargaVar({ prodId, file }) {
    const prodCol = firestore.collection(`products/${prodId}/variants`);

    const [dataProd, loadingDataProd] = useCollectionData(prodCol, { idField: 'id' })
    // console.log(dataProd[0]?.harga_normal)
    const minHarga = dataProd?.[0]?.harga_normal * (1 - 0.4)
    const maxHarga = dataProd?.[0]?.harga_normal
    if (loadingDataProd) {
        return <PageLoading />
    }
    return <>

        <Typography variant={file === 'detailProduct' ? 'h6' : 'body1'} color="error">{
            currency(minHarga)} - {
                currency(maxHarga)}</Typography>
    </>
}