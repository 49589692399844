import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({


    fab1: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    noOrderMsg: {
        // width: 150,
        height: 40,
        marginTop: '7%',
        // marginBottom: '7%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 20,
        // border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noOrderMsg2: {
        // width: 150,
        height: 40,
        // marginTop: '7%',
        marginBottom: '7%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        // border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconBck: {
        // color: 'white',
        marginRight: 10,
        // marginTop: '7%',

    },
    nextBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2),
        marginTop: theme.spacing(12)
    },
    alamat: {
        marginLeft: theme.spacing(4),
        border: 'solid 1px ' + theme.palette.primary.main,
        borderRadius: '5px',
        padding: 4,
        fontSize: 13,
        color: theme.palette.primary.main
    },
    ongkir: {

    }
}))

export default useStyles;