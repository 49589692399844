import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { firestore, storage , FieldValue, functions} from "../../../components/FirebaseProvider";
import DialogActions from '@material-ui/core/DialogActions';
import { Checkbox, DialogContentText, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { Clear, PhotoCamera } from "@material-ui/icons";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import dataBank from '../pencairanRekber/bank.json'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";


export default function CancelOrdersPO({ CancelOrders: { id , open, data , status }, handleClose,order,dataSubOrder }) {
    // console.log('STATUS',status)
    const { enqueueSnackbar } = useSnackbar();
    const [Data, setData] = useState(data)
    const [isSubmitting, setSubmitting] = useState();
    const [hideAktif, setHideAktif] = useState(false)
    const { company_id } = useData();
    const [errorProd, setErrorProd] = useState({});
    const [prodForm, setProdForm] = useState();
    const CompDoc = firestore.doc(`mitra/${order.mitra_id}`);
    const [compSnapshot, compLoading] = useDocument(CompDoc);
    const [compForm, setCompForm] = useState({})
    // console.log('==< PO', )
    const [form, setForm] = useState({
        nama_penjual : "",
        nama_pembeli : "",
        no_rekening : "",
        bank_tujuan: '',
        nama_rek: '',
        kode_order : "",
        alasan : "",
        biaya : {
            total_biaya_asuransi_pengiriman: order?.total_biaya_asuransi_pengiriman > 0 ? order?.total_biaya_asuransi_pengiriman : 0,
            total_biaya_pengiriman: order?.total_biaya_pengiriman > 0 ? order?.total_biaya_pengiriman : 0,
            total_pembayaran: order?.total_pembayaran > 0 ? order?.total_pembayaran : 0,
            // total_pembayaran_cod: order?.total_pembayaran_cod > 0? order?.total_pembayaran_cod : 0,
            total_promo: order?.total_promo > 0 ? order?.total_promo : 0,
            total_promo_ongkir: order?.total_promo_ongkir > 0 ? order?.total_promo_ongkir : 0,
            total_promo_produk: order?.total_promo_produk > 0 ? order?.total_promo_produk : 0,
            total_tagihan: order?.total_tagihan > 0 ? order?.total_tagihan : 0,
            total_tagihan_harga_normal: order?.total_tagihan_harga_normal > 0 ? order?.total_tagihan_harga_normal : 0,
        }
    })
    const [error, setError] = useState({
        nama_penjual : "",
        nama_pembeli : "",
        no_rekening : "",
        bank_tujuan: '',
        nama_rek: '',
        kode_order : "",
        alasan : "",
    });
    const [Image, setImage] = useState({imageLabel : "" , value : ""})
    const [filter, setFilter] = useState('');
    const prodDoc = firestore.collection('order_refund').doc()
    useEffect(() => {
        if (compSnapshot) {
            const data = compSnapshot.data();
            setCompForm({
                ...data,
                });
            }
        }, [compSnapshot]);
    const handleUploadProduct = async (e) => {
        const file = e.target.files[0];
        if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: `Tipe file tidak didukung: ${file.type}`,
            }));
        } else if (file.size >= 512000) {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: `Ukuran file terlalu besar > 500KB`,
            }));
        } else {
            const reader = new FileReader();
            reader.onabort = () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: `Proses pembacaan file dibatalkan`,
                }));
            };
            reader.onerror = () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: "File tidak bisa dibaca",
                }));
            };
            reader.onload = async () => {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: "",
                }));
                // setSubmitting(true);
                try {
                    const productsStorageRef = storage.ref(`order_refund`);
                    const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));
                    const thumbnailRef = productsStorageRef.child(
                        `bukti_pembayaran/bukti_${Date.now()}${thumbnailExt}`
                    );
                    const thumbnailSnapshot = await thumbnailRef.putString(
                        reader.result,
                        "data_url"
                    );

                    const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

                    setProdForm((currentForm) => ({
                        ...currentForm,
                        thumbnail: thumbnailUrl,
                    }));
                    setImage({imageLabel : file.name , value : thumbnailUrl})
                    // await prodDoc.set({
                    //     proof_picture: thumbnailUrl,
                    //     created_at: FieldValue.serverTimestamp(),
                    // }, { merge: true });
                } catch (e) {
                    setErrorProd((errorProd) => ({
                        ...errorProd,
                        thumbnail: e.message,
                    }));
                }
                // setSubmitting(false);
            };
            reader.readAsDataURL(file);
        }
    };

    

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            nama_penjual: compForm?.nama,
            kode_order : order?.kode_order,
            nama_pembeli : dataSubOrder[0]?.alamat_tujuan?.nama_penerima
        })
        // console.log('==>',[e.target.name] , e.target.value)
        // console.log(form)
        setError({
            ...error,
            [e.target.name]: ''
        })
    }
    
    const validate = async () => {
        const newError = { ...error };
        // if (!form.nama_penjual) {
        //     newError.nama_penjual = 'Nama Penjual Harus Di isi';
        // }
        if (!form.nama_pembeli) {
            newError.nama_pembeli = 'Nama Pembeli Harus Di isi';
        }
        if (!form.no_rekening) {
            newError.no_rekening = 'No rekening Penjual Harus Di isi';
        }
        if (!form.bank_tujuan) {
            newError.bank_tujuan = 'Pilih Bank Tujuan';
        }
        if (!form.nama_rek) {
            newError.nama_rek = 'Nama rekening harus diisi';
        }
        // if (!form.kode_order){
        //     newError.kode_order = "Kode Orderan Harus Di isi"
        // }
        if (!form.alasan){
            newError.alasan = "Silahkan masukan Alasan Yang kuat Untuk Membatalkan Transaksi ini"
        }
        return newError;
    }

    const validatePending = async () => {
        const newError = { ...error };
        // if (!form.nama_penjual) {
        //     newError.nama_penjual = 'Nama Penjual Harus Di isi';
        // }
        // if (!form.nama_pembeli) {
        //     newError.nama_pembeli = 'Nama Pembeli Harus Di isi';
        // }
        // if (!form.no_rekening) {
        //     newError.no_rekening = 'No rekening Penjual Harus Di isi';
        // }
        // if (!form.bank_tujuan) {
        //     newError.bank_tujuan = 'Pilih Bank Tujuan';
        // }
        // if (!form.nama_rek) {
        //     newError.nama_rek = 'Nama rekening harus diisi';
        // }
        // if (!form.kode_order){
        //     newError.kode_order = "Kode Orderan Harus Di isi"
        // }
        if (!form.alasan){
            newError.alasan = "Silahkan masukan Alasan Yang kuat Untuk Membatalkan Transaksi ini"
        }
        return newError;
    }

    const handleChangeAktif = () => {
        setHideAktif(!hideAktif);
    };

    const HandleClick =  async e => {
        // e.preventDefault();
        const findError = await validate();
        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const Canceled = firestore.collection(`order_refund`)
                await Canceled.add({
                    nama_pembeli: form?.nama_pembeli,
                    nama_penjual: form?.nama_penjual,
                    no_rekening: form?.no_rekening,
                    kode_order : form?.kode_order,
                    nama_rek: form?.nama_rek,
                    alasan : form?.alasan,
                    created_at: FieldValue.serverTimestamp(),
                    mitra_id: order?.mitra_id,
                    bukti : Image?.value,
                    status : 'pending',
                    check : false,
                    biaya : form?.biaya,
                    status_transaksion : status ? status : 'paid'
                })
                const cancleOrder = functions.httpsCallable("order-setCanceled");

                const response = await cancleOrder({ mitra_id: order.mitra_id, order_id: order.id });
    
                const data = response?.data;

                console.log('data==>', data)
                HandleClosegrid()
                enqueueSnackbar('Order Canceled', { variant: "success" })


            } catch (e) {
                const newError = {};
                enqueueSnackbar('Cancel terCancel', { variant: "error" })
                // console.log('data',data)

                newError.title = e.message;

                setError(newError);
                HandleClosegrid()
            }
            setSubmitting(false)
        }
    }

    const HandlePending = async e => {
        
        const findError = await validatePending();
        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                console.log("Handle Pending Di jalan kan")

                // const cancelOrder = functions.httpsCallable("order-setCanceled");

                // const response = await cancelOrder({ 
                //     mitra_id: order?.mitra_id,
                //     order_id: order?.id 
                // });

                // const urlOrder = firestore.collection(`mitra/${order.mitra_id}/orders`).where("kode_order", "==", `${order?.kode_order}`)
                // await urlOrder.set({
                //     status: "canceled",
                //     updated_at: FieldValue.serverTimestamp()
                // }, {
                //     merge: true
                // })
                if(window.confirm(`apakah ingin cancel orderan ini ?`)){
                const mitraDoc = firestore.doc(`mitra/${order?.mitra_id}`);
                const orderDoc = mitraDoc.collection("orders").doc(order?.id);
                await orderDoc.set({
                  status: "canceled",
                  status_pembayaran: "canceled",
                  status_pengiriman : "canceled",
                  canceled_at: FieldValue.serverTimestamp(),
                }, { merge: true });
            
                const suborderCol = orderDoc.collection("suborders");
                const findSuborders = await suborderCol.get();
            
                for (const doc of findSuborders.docs) {
                  await suborderCol.doc(doc.id).set({
                    status: "canceled",
                    status_pembayaran: "canceled",
                    canceled_at: FieldValue.serverTimestamp(),
                  }, { merge: true });
            
                  let dataSub = doc.data();
                  let count = 0;
                  await firestore.runTransaction(async (t) => {
                    for (const ids of dataSub.productIds) {
                      let id = ids.split("_");
                      let productId = id[0];
                      let variantId = id[1];
                      let qty = dataSub.products_qty[count];
                      let variantRef = mitraDoc.collection("products_stock").doc(`${productId}_${variantId}`);
                      t.update(variantRef, {
                        stok_available: FieldValue.increment(qty),
                        stok_booked: FieldValue.increment(-qty)
                      });
            
                      count++;
                    }
                  })
                }
                    const Canceled = firestore.collection(`order_canceled`)
                    await Canceled.add({
                        nama_pembeli: form?.nama_pembeli ? form?.nama_pembeli : "",
                        nama_penjual: form?.nama_penjual ? form?.nama_penjual : "" ,
                        no_rekening: form?.no_rekening ? form?.no_rekening : "",
                        kode_order : form?.kode_order ? form?.kode_order : "",
                        nama_rek: form?.nama_rek ? form?.nama_rek : "",
                        alasan : form?.alasan ?  form?.alasan : "",
                        created_at: FieldValue.serverTimestamp(),
                        mitra_id: order?.mitra_id ? order?.mitra_id : "",
                        bukti : Image?.value ? Image?.value : "",
                        status : 'pending',
                        check : false,
                        biaya : form?.biaya ? form?.biaya : {},
                        type_transaksi : 'PO',
                        status_transaksion : status !== "pending" ? status : status
                    })
                
                    
                    // const data = response?.data;
                    HandleClosegrid()
                    enqueueSnackbar(`Order Berhasil Di Batal Kan`, { variant: "success" })
                    window.location.reload(false)
                }


        } catch (error) {
                const newError = {};
                enqueueSnackbar('Erorr tidak bisa Cancel Data', { variant: "error" })
                console.log('data', error)

                // newError.title = e.message;

                // setError(newError);
                HandleClosegrid()
            }
            setSubmitting(false)
        }
     
    }

    const HandleClearImage = () => {
        setImage({imageLabel : "" , value : ""})
    }

    const HandleClosegrid = () => {
        setForm({
            nama_penjual : "",
            nama_pembeli : "",
            no_rekening : "",
            kode_order : "",
            alasan : "",
        })
        HandleClearImage()
        handleClose(false)
        setHideAktif(false)
    }

    const filteredBank = dataBank.filter(Bank => {
        return Bank.name.toLowerCase().includes(filter.toLowerCase());
    });
    
    return (
        <Dialog  
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting} 
            open={open} 
            onClose={() => HandleClosegrid()}
            aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Form Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ket : Form Untuk Membatalkan Transaksi 
          </DialogContentText>
          <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        autoComplete="off"
                        id="name"
                        name="nama_penjual"
                        label="Nama Penjual"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={compForm?.nama}
                        onChange={handleChange}
                        helperText={error.nama_penjual}
                        error={error.nama_penjual ? true : false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        autoComplete="off"
                        id="name"
                        label="Nama Pembeli"
                        fullWidth
                        name="nama_pembeli"
                        variant="outlined"
                        disabled={true}
                        value={dataSubOrder[0]?.alamat_tujuan?.nama_penerima}
                        onChange={handleChange}
                        helperText={error.nama_pembeli}
                        error={error.nama_pembeli ? true : false}
                    />
                </Grid>
                {status !== 'pending' ?
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="bank_tujuan"
                                name="bank_tujuan"
                                margin="normal"
                                required
                                value={form?.bank_tujuan || ''}
                                onChange={(event, value) => {
                                    handleChange({ target: { name: "bank_tujuan", value } });
                                }}
                                // disabled={loadingStok || loadingVariants || cekLoad}
                                options={filteredBank || ''}
                                getOptionLabel={(option) => option?.name || ''}
                                renderInput={(params) => (
                                    <TextField
                                        helperText={error?.bank_tujuan}
                                        error={error?.bank_tujuan ? true : false}
                                        {...params}
                                        label='Bank Tujuan'
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                disabled={isSubmitting}
                                id="no_rekening"
                                name="no_rekening"
                                label="No Rekening"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                helperText={error.no_rekening}
                                error={error.no_rekening ? true : false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                // disabled={loading}
                                id="nama_rek"
                                name="nama_rek"
                                value={form?.nama_rek}
                                label="Atas Nama"
                                onChange={handleChange}
                                error={error.nama_rek ? true : false}
                                helperText={error.nama_rek}
                                fullWidth
                                multiline
                                variant="outlined"
                            />
                        </Grid>
                    </>
                : null}
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        disabled={true}
                        id="kode_order"
                        label="Kode Orders"
                        name="kode_order"
                        fullWidth
                        variant="outlined"
                        value={order?.kode_order}
                        onChange={handleChange}
                        helperText={error.kode_order}
                        error={error.kode_order ? true : false}
                    />
                </Grid>
                    {dataSubOrder?.map((data)=> {
                    return (
                        <>
                        {data?.products?.map((prod) => {
                        return (
    
                <>
                    <Grid item xs={4}>
                        <TextField
                            autoComplete="off"
                            disabled={true}
                            label="Produk"
                            fullWidth
                            variant="outlined"
                            value={prod?.nama}
                            helperText={error.kode_order}
                            error={error.kode_order ? true : false}
                        />
                    </Grid>
                
                    <Grid item xs={4}>
                        <TextField
                            autoComplete="off"
                            disabled={true}
                            label="Ukuran"
                            fullWidth
                            variant="outlined"
                            value={prod?.variant?.variant1?.nilai}
                            helperText={error.kode_order}
                            error={error.kode_order ? true : false}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoComplete="off"
                            disabled={true}
                            label="Warna"
                            fullWidth
                            variant="outlined"
                            value={prod?.variant?.variant2?.nilai}
                            helperText={error.kode_order}
                            error={error.kode_order ? true : false}
                        />
                    </Grid>
                    </>
            
                        )
                })}
                </> )
                    })}
                <Grid item xs={12}>
                    <TextField
                        disabled={isSubmitting}
                        id="alasan"
                        label="Alasan"
                        name="alasan"
                        fullWidth
                        variant="outlined"
                        multiline
                        onChange={handleChange}
                        helperText={error.alasan}
                        error={error.alasan ? true : false}
                    />
                </Grid>
                {status !== 'pending' ? 
                    <>
                        <Grid item xs={6}>
                            {!Image.imageLabel ? 
                            <>
                                <input
                                    accept="image/jpeg,image/png,image/jpg"
                                    style={{display : "none"}}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleUploadProduct}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span"
                                        startIcon={<PhotoCamera />}
                                        >
                                        Upload Bukti Pembayaran
                                    </Button>
                                </label>
                            </>
                            : 
                            <>
                                <Typography color="primary">
                                    {Image.imageLabel}{"  "}
                                    <Button onClick={()=> HandleClearImage()} color="primary">
                                        <Clear/>
                                    </Button>
                                </Typography>
                            </>
                            }
                        </Grid>
                    </>
                : null}
                <Grid item xs={6}>
                    {errorProd.thumbnail && (
                        <Typography color="error">
                            {errorProd.thumbnail}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={hideAktif}
                                onChange={handleChangeAktif}
                                name="aktif"
                            />
                        }
                        label="Saya Menyetujui Membatalkan Transaksi Ini !!"
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() =>  HandleClosegrid()} color="primary" variant="contained">
                Batal
            </Button>
            <Button onClick={() => status === 'pending' ? HandlePending() : HandleClick() } color="primary" variant="contained" disabled={!hideAktif || isSubmitting}>
                Setuju
            </Button>
        </DialogActions>
      </Dialog>
    )
}