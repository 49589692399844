import { useEffect, useState } from "react";
import dataBank from "./bank.json";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { firestore, FieldValue , storage } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import { Checkbox, FormControlLabel, FormHelperText, IconButton, Typography } from "@material-ui/core";
import { Clear, Delete, PhotoCamera } from "@material-ui/icons";
import COPY from "../../../images/copy.svg";

export default function AddBank({ open, handleClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const { company_id } = useData();

  const [form, setForm] = useState({
    bank_tujuan: "",
    no_rek: "",
    nama_rek: "",
  });
  // console.log(totalOmset)

  const [error, setError] = useState({
    bank_tujuan: "",
    no_rek: "",
    nama_rek: "",
  });
  const handleChange = (e) => {
    let value = e.target.value;
    // if (e.target.type === "number") {
    //   value = parseInt(value);
    // }
    // console.log(typeof value, value)

    setForm({
      ...form,
      [e.target.name]: value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };
  // console.log(form)

  // data Bank
  const [Bank, setBank] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [Image, setImage] = useState({})
  const [errorProd, setErrorProd] = useState({});
  const [prodForm, setProdForm] = useState();
  const [hideAktif, setHideAktif] = useState(false)
  const [erorrCheck, seterorrCheck] = useState("")

  useEffect(() => {
    async function fetchBank() {
      setLoading(true);
      try {
        const getBank = dataBank.map((bank) => bank.name);

        const response = await getBank();

        setBank(response.data);
      } catch (e) {}
      setLoading(false);
    }

    fetchBank();
  }, []);

  const filteredBank = dataBank.filter((Bank) => {
    return Bank.name.toLowerCase().includes(filter.toLowerCase());
  });

  const validate = async () => {
    const newError = { ...error };

    if (!form.bank_tujuan) {
      newError.bank_tujuan = "Bank tujuan harus diisi";
    }

    if (!form.no_rek) {
      newError.no_rek = "No rekening harus diisi";
    }
    if (!form.nama_rek) {
      newError.nama_rek = "Nama rekening harus diisi";
    }
    // if (!Image.imageLabel && !Image.value){
    //   setErrorProd({ thumbnail : "Harap Masukan Data Diri Anda"})
    // }
    // if (Object.entries(Image).length < 0 || Image === null){
    //   setErrorProd({ thumbnail : "Harap Masukan Data Diri Anda"})
    // }
    if (!hideAktif){
      seterorrCheck("Anda belum menyetujui penyerahan data diri")
    }
    return newError;
  };

  // handle submit 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    // } else if (!modal) {
    //   setModal(!modal);
    } else {
      setLoading(true);
      try {
        const MitraBank = firestore.collection(`mitra_bank`);
        await MitraBank.add({
          ...Image,
          // ...form?.bank_tujuan,
          no_rek: form?.no_rek,
          // code: form?.bank_tujuan?.code,
          swift_code: form?.bank_tujuan?.swiftcode,
          // id: addBank?.id,
          name: form?.bank_tujuan?.name,
          created_at: FieldValue.serverTimestamp(),
          mitra_id: company_id,
          nama_rek: form?.nama_rek,
          verifikasi : Object.entries(Image).length === 0 ? false : true,
          check: false,
          check_admin : false,
        });
        setModal(false)
        setForm({  
          bank_tujuan: "",
          no_rek: "",
          nama_rek: "",
        })
        setImage({})
        handleClose();
        enqueueSnackbar("Pencairan ditambahkan", { variant: "success" });
        setHideAktif(false)
      } catch (e) {
        const newError = {};
        enqueueSnackbar("Pencairan gagal ditambahkan", { variant: "error" });

        newError.title = e.message;

        setError(newError);
      }

      setLoading(false);
    }
  };

  // handel upload photo
  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    // console.log('==>', file)
    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        setErrorProd((errorProd) => ({
            ...errorProd,
            thumbnail: `Tipe file tidak didukung: ${file.type}`,
        }));
    } else if (file.size >= 512000) {
        setErrorProd((errorProd) => ({
            ...errorProd,
            thumbnail: `Ukuran file terlalu besar > 500 KB`,
        }));
    } else {
        const reader = new FileReader();
        reader.onabort = () => {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: `Proses pembacaan file dibatalkan`,
            }));
        };
        reader.onerror = () => {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: "File tidak bisa dibaca",
            }));
        };
        reader.onload = async () => {
            setErrorProd((errorProd) => ({
                ...errorProd,
                thumbnail: "",
            }));
            // setSubmitting(true);
            try {
                const productsStorageRef = storage.ref(`data_pribadi_mitra`);
                const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));
                const thumbnailRef = productsStorageRef.child(
                    `${company_id}/data_pribadi_${company_id}_${Date.now()}${thumbnailExt}`
                );
                const thumbnailSnapshot = await thumbnailRef.putString(
                    reader.result,
                    "data_url"
                );

                const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

                setProdForm((currentForm) => ({
                    ...currentForm,
                    thumbnail: thumbnailUrl,
                }));
                setImage({imageLabel : file.name , value : thumbnailUrl})
                // await prodDoc.set({
                //     proof_picture: thumbnailUrl,
                //     created_at: FieldValue.serverTimestamp(),
                // }, { merge: true });
            } catch (e) {
                setErrorProd((errorProd) => ({
                    ...errorProd,
                    thumbnail: e.message,
                }));
            }
            // setSubmitting(false);
        };
        reader.readAsDataURL(file);
    }
  };

  // handle clear image
  const HandleClearImage = async () => {
    let thumbnailURL = Image.value 
    if (Image.value){
      await storage.refFromURL(thumbnailURL).delete();
    }
    setImage({})

  }

  // handleClose Modal
  const HandleCloseModal = () => {
    setForm({  
      bank_tujuan: "",
      no_rek: "",
      nama_rek: "",
    })
    handleClose(false)
    setModal(false)
    setImage({})
    setHideAktif(false)
  }

  const handleChangeAktif = () => {
    setHideAktif(!hideAktif);
  };
  return (
    <Dialog
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      open={open}
      onClose={HandleCloseModal}
    >
      <DialogTitle>Tambah Rek. Tujuan</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
              {!Image.imageLabel ? 
              <>
                <Grid item xs={6}>
                  <input
                    accept="image/jpeg,image/png,image/jpg"
                    style={{display : "none"}}
                    id="contained-button-file"
                    type="file"
                    onChange={handleUploadProduct}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span"
                        startIcon={
                          <PhotoCamera />
                        }>
                        Verifikasi Data Diri Anda
                    </Button>
                    {errorProd.thumbnail && (
                      <Typography color="error">
                          {errorProd.thumbnail}
                      </Typography>
                    )}
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <p style={{"fontSize" : 13, color : 'red' }}>
                    {`> .`} Data Diri Berupa KTP
                    <br/>
                    {`> .`} Foto KTP di haruskan jelas
                  </p>
                </Grid>
              </>
              :
              <Grid container item xs={12}>
                {/* <Grid  spacing={2}> */}
                  <Grid item xs={2}>
                    <img src={COPY} alt="cod" width="60" height="auto" />
                  </Grid>
                  <Grid item xs={3}>
                    {/* <Typography color="primary"> */}
                      <p style={{"fontSize" : 13, color : 'red'}}>
                        {Image.imageLabel}
                      </p>
                    {/* </Typography> */}
                  </Grid>
                  <Grid item xs>
                    <Button onClick={()=> HandleClearImage()} color="primary">
                        <Delete/>
                    </Button>
                  </Grid>
              </Grid>
              }
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="bank_tujuan"
              name="bank_tujuan"
              margin="normal"
              required
              value={form?.bank_tujuan || ""}
              onChange={(event, value) => {
                handleChange({ target: { name: "bank_tujuan", value } });
              }}
              // disabled={loadingStok || loadingVariants || cekLoad}
              options={filteredBank || ""}
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  helperText={error?.bank_tujuan}
                  error={error?.bank_tujuan ? true : false}
                  {...params}
                  label="Bank Tujuan"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={loading}
              id="no_rek"
              name="no_rek"
              value={form?.no_rek}
              label="No Rekening"
              onChange={handleChange}
              error={error.no_rek ? true : false}
              helperText={error.no_rek}
              fullWidth
              type="number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={loading}
              id="nama_rek"
              name="nama_rek"
              value={form?.nama_rek}
              label="Atas Nama"
              onChange={handleChange}
              error={error.nama_rek ? true : false}
              helperText={error.nama_rek}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {form.no_rek && form.bank_tujuan && form.nama_rek ?
            <Grid item xs={12}>
              <p style={{"fontSize" : 16, color : 'red'}}>
                {`> .`} Pastika Check Kembali Data Diri Anda !<br/>
                {`> .`} Apabila Terjadi Kesalahan Di luar Tanggung Jawab Kami !!
              </p>
            </Grid>
          : null }
            <Grid item xs={12}>
              <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideAktif}
                      onChange={handleChangeAktif}
                      name="aktif"
                    />
                  }
                  label="Saya Menyatakan Data Di Atas Sudah Benar"
              />
            </Grid>          
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button disabled={loading} onClick={() => HandleCloseModal()} variant="outlined">
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={!hideAktif || !Image.imageLabel} color="primary" variant="outlined">
          Buat
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AddBank.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
