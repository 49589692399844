// React
import React from 'react';

// Material UI
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';



function SmartPOLoading() {

    const classes = useStyles()
    const loginContainer = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        marginTop: 210
    }

    return (
        <Container maxWidth="sm" style={loginContainer}>
            <CircularProgress size={60} style={loginContainer} />
            <Typography className={classes.noOrderMsg} variant="h5">Mencari Produk</Typography>
            <Typography className={classes.noOrderMsg2} variant="h5">Mohon Tunggu</Typography>

        </Container>
    )
}
export default SmartPOLoading;