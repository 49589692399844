import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Table from './table';
// import Edit from './edit';
import useStyles from './style';
import EditReward from './edit';

function Reward() {

  const classes = useStyles();
  return (
    <div className={classes.pageWrap}>

      <Switch>
        <Route path="/reward/edit/:productId" component={EditReward} />
        <Route path="/reward" component={Table} />
        <Redirect to="/reward" />
      </Switch>

    </div>

  );
}

export default Reward;
