import { useState } from "react";
import { functions } from "../../components/FirebaseProvider";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../components/Logo";
import { IconButton, InputAdornment } from "@material-ui/core";
import { useSnackbar } from "notistack";

function ResetPassword() {
  const history = useHistory();
  const classes = useStyles();
  const [change, setChange] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // resultData
  const [resultDoc, setResultDoc] = useState({});
  // resultDataPassword
  const [resultData, setResultData] = useState({});

  // State Data
  const [findEmail, setFindEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  // state eyes
  const [pass, setPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);

  // state loading
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmittingPass, setSubmittingPass] = useState(false);
  const [loadingCircle, setloadingCircle] = useState(false);
  const [loadingCirclePass, setloadingCirclePass] = useState(false);

  // State Error
  const [error, setError] = useState("");
  const [errorFind, setErrorFind] = useState("");

  // function validate email
  const validateEmail = async () => {
    let newError = errorFind;
    if (!findEmail) {
      newError = "Harap Masukan Email Anda";
    }
    return newError;
  };

  // function validate password
  const ValidatePassword = async () => {
    let newErorrPass = error;

    if (!password) {
      newErorrPass = "Harap Masukan Password Baru Anda";
    }
    if (!confirm) {
      newErorrPass = "Harap Masukan Konfirmasi Password Baru Anda";
    }
    if (password !== confirm) {
      newErorrPass = "Maaf Pasword Anda Tidak sama";
    }

    return newErorrPass;
  };

  //Handle Change password
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  // handle chane confirm password
  const handleChangeConfirm = (e) => {
    setConfirm(e.target.value);
    setError("");
  };

  // handle change state email
  const handleChangeEmail = (e) => {
    setFindEmail(e.target.value);
    setErrorFind("");
  };

  // clear State data
  const handleBack = async () => {
    setChange(false);
    setFindEmail("");
    setPassword("");
    setConfirm("");
  };

  // function find Email
  const findEmaildoc = async (e) => {
    e.preventDefault();
    const findError = await validateEmail();
    if (findError) {
      setErrorFind(findError);
    } else {
      setloadingCircle(true);
      setSubmitting(true);
      try {
        const addMitra = functions.httpsCallable("mitra-resetPasswordMitra");
        const respon = await addMitra({
          key: "find",
          email: findEmail,
        });
        let res = respon?.data;
        enqueueSnackbar(`${res?.message}`, { variant: "success" });
        setChange(res?.status);
        setResultDoc(res);
      } catch (error) {
        // setErrorFind(error.message)
        enqueueSnackbar(`Error : ${error.message}`, { variant: "error" });
      }
      setloadingCircle(false);
      setSubmitting(false);
    }
  };

  // function reset Password
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await ValidatePassword();
    if (findError) {
      setError(findError);
    } else {
      setSubmittingPass(true);
      setloadingCirclePass(true);
      // window.alert("maaf Fitur Ini Belum Tersedia")
      try {
        const addMitra = functions.httpsCallable("mitra-resetPasswordMitra");
        const respon = await addMitra({
          key: "reset",
          mitra_id: resultDoc?.data?.mitra_id,
          password: password,
        });
        let res = respon?.data;
        setResultData(res);
        handleBack();
        history.push("/login");
        enqueueSnackbar(`${res?.message}`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Error : ${error.message}`, { variant: "error" });
      }
      setloadingCirclePass(false);
      setSubmittingPass(false);
    }
  };

  return (
    <>
      <Container maxWidth="xs" className={classes.container}>
        <div className={classes.logo}>
          <Button onClick={() => history.push("/login")}>
            <Logo />
          </Button>
        </div>
        {!change ? (
          <Typography className={classes.pageTitle}>
            Silahkan Masukan Email Anda
          </Typography>
        ) : (
          <Typography className={classes.pageTitle}>
            Apakah Anda Yakin Akan Mengganti Password Untuk Account :{" "}
            {findEmail}
          </Typography>
        )}
        <Grid container spacing={3}>
          {!change ? (
            <>
              <Grid item xs={12}>
                <TextField
                  id="nibras_id"
                  label="Email"
                  value={findEmail}
                  variant="outlined"
                  fullWidth
                  disabled={isSubmitting}
                  onChange={handleChangeEmail}
                  helperText={errorFind ? errorFind : ""}
                  error={errorFind ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  onClick={findEmaildoc}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {loadingCircle ? (
                    <CircularProgress color="white" size={24} value={17} />
                  ) : (
                    "Cari"
                  )}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Password baru"
                  value={password}
                  disabled={isSubmittingPass}
                  variant="outlined"
                  fullWidth
                  type={pass !== false ? "password" : "text"}
                  onChange={handleChangePassword}
                  helperText={error}
                  error={error ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setPass(!pass)}>
                          {pass !== false ? (
                            <VisibilityOffIcon className={classes.iconColor} />
                          ) : (
                            <VisibilityIcon className={classes.iconColor} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Konrimasi password baru"
                  value={confirm}
                  variant="outlined"
                  disabled={isSubmittingPass}
                  fullWidth
                  type={confirmPass !== false ? "password" : "text"}
                  onChange={handleChangeConfirm}
                  helperText={error}
                  error={error ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setConfirmPass(!confirmPass)}
                        >
                          {confirmPass !== false ? (
                            <VisibilityOffIcon className={classes.iconColor} />
                          ) : (
                            <VisibilityIcon className={classes.iconColor} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmittingPass}
                  onClick={handleSubmit}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {loadingCirclePass ? (
                    <CircularProgress color="white" size={24} value={17} />
                  ) : (
                    "Kirim"
                  )}
                </Button>
              </Grid>
            </>
          )}
          {!change ? (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={() => history.push("/login")}
                  color="primary"
                >
                  Kembali
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send/?phone=628118336243&text&app_absent=0`
                    )
                  }
                  color="primary"
                >
                  Hubungi CS
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Button fullWidth onClick={() => handleBack()} color="primary">
                Atur Ulang Email
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default ResetPassword;
