import React, { useState } from "react";
import TabPanel from "../../../components/TabPanel";

// material ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
    FieldValue,
    firestore,
} from "../../../components/FirebaseProvider";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";

import { useSnackbar } from "notistack";
import { Card, CardContent } from "@material-ui/core";
import { unixToDate } from "../../../utils/dateFormatter";

// eslint-disable-next-line no-sequences


export default function Pending({ value, penukaran, dialog, setDialog }) {
    const classes = useStyles();
    // console.log(penukaran);
    const { enqueueSnackbar } = useSnackbar();


    const [isSubmiting, setIsSubmiting] = useState(false);

    const changeStatus = data => async () => {
        // console.log(data.product_reward.variants[0].harga_poin);
        setIsSubmiting(true)
        const kredit = firestore.collection(`kredit`);
        const products_reward = firestore.collection(`products_reward/${data.product_reward.id}/variants`);
        const penukaran_reward = firestore.collection(`penukaran_reward`);
        try {
            await penukaran_reward.doc(data.uid).set({
                status: 'approved',
                updated_at: FieldValue.serverTimestamp(),
                approved_at: FieldValue.serverTimestamp()
            }, {
                merge: true
            })
            await products_reward.doc(data.product_reward.default_variant.id).set({
                stok_booked: FieldValue.increment(- 1),
                updated_at: FieldValue.serverTimestamp(),
            }, {
                merge: true
            })
            await kredit.doc(data.user_id).set({
                total_penukaran_reward_pending: FieldValue.increment(- data.product_reward.variants[0].harga_poin),
                total_penukaran_reward_approved: FieldValue.increment(+ data.product_reward.variants[0].harga_poin),
                updated_at: FieldValue.serverTimestamp(),
            }, {
                merge: true
            })
            enqueueSnackbar("status Berhasil Diperbarui", { variant: "success" });
        } catch (e) {
            console.log(e.message)
        }
        setIsSubmiting(false);
    }

    const changeStatusReject = data => async () => {
        // console.log(data.product_reward.id);
        setIsSubmiting(true)
        const kredit = firestore.collection(`kredit`);
        const products_reward = firestore.collection(`products_reward/${data.product_reward.id}/variants`);
        const penukaran_reward = firestore.collection(`penukaran_reward`);
        try {
            await penukaran_reward.doc(data.uid).set({
                status: 'rejected',
                updated_at: FieldValue.serverTimestamp(),
            }, {
                merge: true
            })
            await products_reward.doc(data.product_reward.default_variant.id).set({
                stok_available: FieldValue.increment(+ 1),
                stok_booked: FieldValue.increment(- 1),
                updated_at: FieldValue.serverTimestamp(),
            }, {
                merge: true
            })
            await kredit.doc(data.user_id).set({
                total_penukaran_reward_pending: FieldValue.increment(- data.product_reward.variants[0].harga_poin),
                updated_at: FieldValue.serverTimestamp(),
            }, {
                merge: true
            })
            enqueueSnackbar("status Berhasil Diperbarui", { variant: "success" });
        } catch (e) {
            console.log(e.message)
        }
        setIsSubmiting(false)
    }

    // if (settLoading) {
    //   return <PageLoading />;
    // }
    // console.log(form);
    return (
        <TabPanel value={value} index="pending">
            <Grid container spacing={4}>
                {penukaran?.data?.filter(isi => isi.status === "pending").map((filterIsi, index) => {
                    return (
                        <Grid item container className={classes.liquidBlock} lg={4} md={6} xs={12} key={index}>
                            <Card className={classes.root}>
                                <div className={classes.imgWrap}>
                                    <img src={filterIsi?.product_reward?.thumbnail} alt="" />
                                </div>
                                <CardContent>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Created At
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {unixToDate(filterIsi?.created_at?.toMillis())}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            User Id
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.user_id}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Nama penerima
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.nama_penerima}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Nomer penerima
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.no_telepon}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Alamat
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.alamat_lengkap}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Kelurahan
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.kelurahan.name}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Kecamatan
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.kecamatan.name}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Kota
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.alamat_pengiriman?.kota.name}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Produk Reward
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.product_reward?.nama}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Total Penukaran
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.total_penukaran_reward} poin
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            Status
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.status}
                                        </Typography>
                                    </div>
                                    <div className={classes.liquidRow}>
                                        <Typography className={classes.liquidLabel}>
                                            catatan
                                        </Typography>
                                        <Typography className={classes.liquidValue}>
                                            : {filterIsi?.catatan}
                                        </Typography>
                                    </div>
                                    <Button
                                        disabled={isSubmiting}
                                        onClick={(event) => {
                                            setDialog((dialog) => ({
                                                ...dialog,
                                                data: filterIsi,
                                                uid: filterIsi.id,
                                                open: true,
                                            }));
                                        }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnSave}
                                    >
                                        Detail Varian Produk
                                    </Button>
                                    <Button
                                        disabled={isSubmiting}
                                        onClick={changeStatus(filterIsi)}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnSave}
                                    >
                                        Approved
                                    </Button>
                                    <Button
                                        disabled={isSubmiting}
                                        onClick={changeStatusReject(filterIsi)}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnSave}
                                    >
                                        Reject
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
                {/* <Grid
          item
          xs={12}
          justify="flex-end"
          alignItems="flex-end"
          style={{ display: "flex" }}
        ></Grid> */}
            </Grid>
            {/* <Pagination
        className={classes.pagination}
        count={noOfPages}
        page={page}
        onChange={handleChangePage}
        defaultPage={1}
        showFirstButton
        showLastButton
        color="primary"
      /> */}
        </TabPanel>
    );
}
