import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link, useParams } from "react-router-dom";
import Fab from "@material-ui/core/Fab";

// components
import PageLoading from "../../../components/loading/pageLoading";
import useStyles from "./style";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  firestore,
  storage,
} from "../../../components/FirebaseProvider";
import { unixToDate } from "../../../utils/dateFormatter";
import { useData } from "../../../components/DataProvider";
import TabPanel from "../../../components/TabPanel";

// material ui icons
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import AddVariant from "./addVariant";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


function EditVariant({ value, prodForm }) {
  const classes = useStyles();

  const { company_id } = useData();

  const params = useParams();


  const [isSubmitting, setSubmitting] = useState(false);

  const [dialog, setDialog] = useState({
    mode: "Tambah",
    open: false,
    data: {},
    uid: {},
  });

  // Variant
  const varCol = firestore.collection(
    `mitra/${company_id}/products_reward/${params.productId}/variants`
  );

  const [varSnapshot, varLoading] = useCollection(varCol);

  const [varCollection, setVarCollection] = useState();

  useEffect(() => {
    if (varSnapshot && !varSnapshot.empty) {
      setVarCollection(
        varSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    }
  }, [varSnapshot]);

  // Page Loading
  if (varLoading) {
    return <PageLoading />;
  }

  // No. Variant
  let no = 0;

  return (
    <>
      <TabPanel value={value} index="editVariant">
        <div className={classes.root}>
          <Grid container className={classes.gridCon}>
            {/* Edit Produk */}

            {/* Varian Mapping */}
            {varCollection &&
              varCollection.map((varItem) => {
                no++;

                return (
                  <>
                    <Grid item xs={12} className={classes.variantWrap}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div className={classes.pageTitle}>
                            <Button
                              className={classes.backBtn}
                              variant="outlined"
                              component={Link}
                              to={`/reward`}
                            >
                              <ArrowBackIcon />
                            </Button>
                            <Typography variant="h6"> Variant {no} </Typography>
                          </div>
                          <div className={classes.dateInfo}>
                            <Typography variant="subtitle2">
                              {varItem.created_at !== null ? (
                                <span>
                                  {" "}
                                  Dibuat pada :{" "}
                                  {unixToDate(
                                    varItem.created_at?.toMillis()
                                  )}{" "}
                                </span>
                              ) : (
                                <span> Dibuat pada : - </span>
                              )}
                            </Typography>
                            <Typography variant="subtitle2">
                              {varItem.updated_at !== null ? (
                                <span>
                                  {" "}
                                  Perubahan terakhir :{" "}
                                  {unixToDate(
                                    varItem.updated_at?.toMillis()
                                  )}{" "}
                                </span>
                              ) : (
                                <span> Perubahan terakhir : - </span>
                              )}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <InputLabel className={classes.thumbnailLabel}>
                            Thumbnail
                          </InputLabel>
                          <div className={classes.previewFotoVar}>
                            {varItem.thumbnail?.map((thumb) => {
                              return (
                                <img
                                  key={thumb}
                                  src={thumb}
                                  width="200"
                                  height="auto"
                                  alt=""
                                />
                              );
                            })}
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                autoComplete="off"
                                id="variant_id"
                                name="variant_id"
                                label="variant id Produk"
                                value={varItem.variant_id}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                autoComplete="off"
                                id="sku"
                                name="sku"
                                label="SKU Produk"
                                value={varItem.sku}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="variant1"
                                name="variant1"
                                label={varItem.variant1?.label}
                                value={varItem.variant1?.nilai}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="variant2"
                                name="variant2"
                                label={varItem.variant2?.label}
                                value={varItem.variant2?.nilai}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="stok_available"
                                name="stok_available"
                                label="Stok Tersedia"
                                value={varItem.stok_available}
                                variant="outlined"
                                fullWidth
                                type="number"
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="stok_booked"
                                name="stok_booked"
                                label="Stok Dipesan"
                                value={varItem.stok_booked}
                                variant="outlined"
                                type="number"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="harga_poin"
                                name="harga_poin"
                                label="Harga"
                                value={varItem.harga_poin}
                                variant="outlined"
                                type="number"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="berat_paket"
                                name="berat_paket"
                                label="Berat Paket"
                                value={varItem.berat_paket}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="panjang_paket"
                                name="panjang_paket"
                                label="Panjang Paket"
                                value={varItem.panjang_paket}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="lebar_paket"
                                name="lebar_paket"
                                label="Lebar Paket"
                                value={varItem.lebar_paket}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="tinggi_paket"
                                name="tinggi_paket"
                                label="Tinggi Paket"
                                value={varItem.tinggi_paket}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                              <TextField
                                autoComplete="off"
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="alamat_rak"
                                name="alamat_rak"
                                label="Alamat Rak"
                                value={varItem.alamat_rak}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid
                              className={classes.btnWrap}
                              container
                              item
                              xs={12}
                              spacing={2}
                            >
                              <Grid item xs={6} lg={1}>
                                <Button
                                  onClick={(event) => {
                                    setDialog((dialog) => ({
                                      ...dialog,
                                      mode: "Edit",
                                      data: varItem,
                                      id: varItem.id,
                                      open: true,
                                    }));
                                  }}
                                  size="large"
                                  color="primary"
                                  variant="contained"
                                  fullWidth
                                >
                                  <EditIcon className={classes.iconLeft} />
                                </Button>
                              </Grid>
                              {varCollection.length > 1 && (
                                <Grid item xs={6} lg={1}>
                                  <Button
                                    disabled={isSubmitting}
                                    onClick={async () => {
                                      if (window.confirm("Yakin?")) {
                                        await Promise.all(
                                          varItem.thumbnail?.map?.(
                                            (thumbna) => {
                                              return storage
                                                .refFromURL(thumbna)
                                                .delete();
                                            }
                                          ) ?? []
                                        );
                                        await firestore
                                          .doc(
                                            `mitra/${company_id}/products/${params.productId}/variants/${varItem.id}`
                                          )
                                          .delete();

                                        alert("Berhasil");
                                      } else {
                                        alert("Gagal");
                                      }
                                    }}
                                    size="large"
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                  >
                                    <DeleteIcon className={classes.iconLeft} />
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </div>

        <Fab
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          className={classes.fab1}
          onClick={(e) => {
            setDialog({ mode: "Tambah", open: true, data: {} });
          }}
        >
          <PlaylistAdd />
          Add Variant
        </Fab>
        <AddVariant
          // kategori={dataKategori}
          dialog={dialog}
          handleClose={() => {
            setDialog({ mode: "Tambah", open: false, data: {} });
          }}
          product={prodForm}
          variants={varCollection}
        />
      </TabPanel>
    </>
  );
}

export default EditVariant;
