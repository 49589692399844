import React, { useState } from "react";
// import Fab from '@material-ui/core/Fab';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
import { firestore } from "../../../../components/FirebaseProvider";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import PageLoading from "../../../../components/loading/pageLoading";
// import Button from '@material-ui/core/Button';
// import Edit from '@material-ui/icons/Edit';
// import { useData } from '../../../components/DataProvider';
// import AddPurchaseOrders from './add';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import HargaVar from "./dataVar";

// lab
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TabPanel from "../../../../components/TabPanel";
import { TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useData } from "../../../../components/DataProvider";

function MinimumBelanja({ value }) {
  const classes = useStyles();
  const history = useHistory();

  const { company_id, dataMitraCurrent, subdomaintList } = useData();
  const upline = dataMitraCurrent?.ref_3
    ? dataMitraCurrent?.ref_3?.mitra_id
    : dataMitraCurrent?.ref_2
    ? dataMitraCurrent?.ref_2?.mitra_id
    : dataMitraCurrent?.ref_1?.mitra_id;
  const tokoDoc = firestore
    .collection(`mitra/${upline}/sellers_webstore`)
    .doc(upline);

  const [userToko, loadingToko] = useDocumentData(tokoDoc);

  const sliderCol = firestore.collection(`purchase_orders`);
  const [searchTerm, setSearchTerm] = React.useState("");

  const [dataSlider, loadingDataSlider] = useCollectionData(sliderCol, {
    idField: "id",
  });

  const prodCol = firestore.collection(`products`);
  const [dataProd, loadingDataProd] = useCollectionData(prodCol, {
    idField: "id",
  });
  const filterProd =
    userToko?.products?.length > 0
      ? dataProd?.filter((a) => userToko?.products?.includes(a.product_id))
      : dataProd;
  // pagination
  const itemsPerPage = 12;
  const [page, setPage] = React.useState(1);
  const noOfPages = Math.ceil(
    filterProd?.filter((p) => p.sembunyikan_produk !== true).length /
      itemsPerPage
  );

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const dataSearch = filterProd?.filter((data) => {
    let dataSearch = searchTerm.toUpperCase();

    if (dataSearch == data?.nama) {
      return data;
    } else if (dataSearch.length === 5) {
      let v = dataSearch;
      let v1 = v.substring(0, 2);
      let v2 = v.substring(2, 5);
      let join = v1 + " " + v2;
      if (join === data?.nama) {
        return data;
      }
    } else if (dataSearch.length === 6) {
      let v = dataSearch;
      let v1 = v.substring(0, 3);
      let v2 = v.substring(3, 6);
      let join = v1 + v2;
      if (join === data?.nama) {
        return data;
      }
    }
  });
  if (loadingDataProd || loadingDataSlider) {
    return <PageLoading />;
  }

  return (
    <>
      <TabPanel value={value} index="minimumBelanja">
        {!subdomaintList?.[0]?.mitra_unique_id ? (
          <>
            <Grid item xs={12}>
              <Typography
                className={classes.pageTitle}
                color="error"
                component="h1"
                variant="h6"
                style={{textAlign: "center"}}
              >
                Belum mengisi domain !
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Typography
              className={classes.pageTitle}
              component="h1"
              variant="h6"
            >
              List Products
            </Typography>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Cari Produk"
                value={searchTerm}
                id="search"
                name="search"
                variant="outlined"
                size="small"
                helperText="Masukan Nama Produk Dengan Lengkap Dan Benar !!"
                onChange={(e) => {
                  setSearchTerm(e?.target?.value);
                }}
                InputProps={{
                  endAdornment: <Search color="secondary" />,
                }}
              />
            </Grid>
            {/* ?.slice((page - 1) * itemsPerPage, page * itemsPerPage) */}
            <Grid className={classes.sliderWrap} container>
              {searchTerm
                ? dataSearch?.map?.((prod) => {
                    const thumb = prod?.thumbnail?.[0];
                    const position = thumb?.lastIndexOf?.(".");
                    const thumbnail300 =
                      thumb?.substring(0, position) +
                      "_300x300" +
                      thumb?.substring(position, position?.length);
                    return (
                      <Grid key={prod?.id} item xs={12} sm={6} md={4} lg={3}>
                        <Card className={classes.cardProd}>
                          <CardActionArea
                            className={classes.cardAct}
                            onClick={() => {
                              history.push(
                                `/purchaseOrders/detailProdMin/${prod?.id}`
                              );
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt={prod?.nama}
                              width="200px"
                              height="220px"
                              image={thumbnail300}
                              title={prod?.nama}
                              className={classes.image}
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6">
                                {prod?.nama}
                              </Typography>
                              <HargaVar prodId={prod?.id} />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })
                : filterProd
                    ?.filter((p) => p.sembunyikan_produk !== true)
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    ?.map((prod) => {
                      const thumb = prod?.thumbnail?.[0];
                      const position = thumb?.lastIndexOf?.(".");
                      const thumbnail300 =
                        thumb?.substring(0, position) +
                        "_300x300" +
                        thumb?.substring(position, position?.length);
                      return (
                        <Grid key={prod?.id} item xs={12} sm={6} md={4} lg={3}>
                          <Card className={classes.cardProd}>
                            <CardActionArea
                              className={classes.cardAct}
                              onClick={() => {
                                history.push(
                                  `/purchaseOrders/detailProdMin/${prod?.id}`
                                );
                              }}
                            >
                              <CardMedia
                                component="img"
                                alt={prod?.nama}
                                width="200px"
                                height="220px"
                                image={thumbnail300}
                                title={prod?.nama}
                                className={classes.image}
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h6">
                                  {prod?.nama}
                                </Typography>
                                <HargaVar prodId={prod?.id} />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
            </Grid>
            <Pagination
              className={classes.pagination}
              count={noOfPages}
              page={page}
              onChange={handleChangePage}
              defaultPage={1}
              showFirstButton
              showLastButton
              color="primary"
              variant="outlined"
            />
          </>
        )}
      </TabPanel>
    </>
  );
}

export default MinimumBelanja;
