import React from "react";
import TabPanel from "../../../components/TabPanel";

// material ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import Button from "@material-ui/core/Button";

import { Card, CardContent } from "@material-ui/core";
import { unixToDate } from "../../../utils/dateFormatter";

// eslint-disable-next-line no-sequences


export default function Approved({ value, penukaran, dialog, setDialog, resiDialog, setResiDialog }) {
    const classes = useStyles();

    return (
        <TabPanel value={value} index="approved">
            <Grid container spacing={4}>
                {penukaran?.data
                    ?.filter((isi) => isi.status === "approved")
                    .map((filterIsi, index) => {
                        return (
                            <Grid item container lg={4} md={6} xs={12} key={index}>
                                <Card className={classes.root}>
                                    <div className={classes.imgWrap}>
                                        <img src={filterIsi?.product_reward?.thumbnail} alt="" />
                                    </div>
                                    <CardContent>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Approved At
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {unixToDate(filterIsi?.approved_at?.toMillis())}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                User Id
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.user_id}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Nama penerima
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.nama_penerima}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Nomer penerima
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.no_telepon}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Alamat
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.alamat_lengkap}
                                            </Typography>
                                        </div>{" "}
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Kelurahan
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.kelurahan.name}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Kecamatan
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.kecamatan.name}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Kota
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.alamat_pengiriman?.kota.name}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Produk Reward
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.product_reward?.nama}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Total Penukaran
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.total_penukaran_reward} poin
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                Status
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.status}
                                            </Typography>
                                        </div>
                                        <div className={classes.liquidRow}>
                                            <Typography className={classes.liquidLabel}>
                                                catatan
                                            </Typography>
                                            <Typography className={classes.liquidValue}>
                                                : {filterIsi?.catatan}
                                            </Typography>
                                        </div>
                                        {filterIsi?.resi_pengiriman_reward ? (
                                            <div className={classes.liquidRow}>
                                                <Typography className={classes.liquidLabel}>
                                                    Resi Pengiriman
                                                </Typography>
                                                <Typography className={classes.liquidValue}>
                                                    : {filterIsi?.resi_pengiriman_reward}
                                                </Typography>
                                            </div>
                                        ) : null}
                                        <Button
                                            onClick={(event) => {
                                                setDialog((dialog) => ({
                                                    ...dialog,
                                                    data: filterIsi,
                                                    uid: filterIsi.id,
                                                    open: true,
                                                }));
                                            }}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnSave}
                                        >
                                            Detail Varian Produk
                                        </Button>
                                        {filterIsi?.resi_pengiriman_reward ? (
                                            <Button
                                                onClick={(event) => {
                                                    setResiDialog((resiDialog) => ({
                                                        ...resiDialog,
                                                        data: filterIsi,
                                                        id: filterIsi.uid,
                                                        open: true,
                                                    }));
                                                }}
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                className={classes.btnSave}
                                            >
                                                Edit Resi Pengiriman
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={(event) => {
                                                    setResiDialog((resiDialog) => ({
                                                        ...resiDialog,
                                                        data: filterIsi,
                                                        id: filterIsi.uid,
                                                        open: true,
                                                    }));
                                                }}
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                className={classes.btnSave}
                                            >
                                                Input Resi Pengiriman
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                {/* <Grid
          item
          xs={12}
          justify="flex-end"
          alignItems="flex-end"
          style={{ display: "flex" }}
        ></Grid> */}
            </Grid>
            {/* 
      <Pagination
        className={classes.pagination}
        count={noOfPages}
        page={page}
        onChange={handleChangePage}
        defaultPage={1}
        showFirstButton
        showLastButton
        color="primary"
      /> */}
        </TabPanel>
    );
}
