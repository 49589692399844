import React, { useState, useEffect, Fragment } from "react";
import {
  FieldValue,
  functions,
  firestore,
} from "../../../components/FirebaseProvider";
import useStyles from "./styles";
import COD from "../../../images/cod.svg";

// material ui core
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import PageLoading from "../../../components/loading/pageLoading";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { set } from "date-fns";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
// react router
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// icons
import PostAddIcon from "@material-ui/icons/PostAdd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Search from "@material-ui/icons/Search";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// import LabelPreview from '../../../components/LabelPreview';
import TabPanel from "../../../components/TabPanel";
import {
  blue,
  green,
  grey,
  orange,
  purple,
  red,
} from "@material-ui/core/colors";
import { unixToDate } from "../../../utils/dateFormatter";
import OrderItem from "./orderItem";
import ShowOrder from "./order";
import Edit from "./edit";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import AddOrder from "./add";
import OrderExpired from "./setOrderExpired";

function ListOrders() {
  const classes = useStyles();
  const history = useHistory();

  const { company_id, dataMitraCurrent } = useData();
  const [startDate, handleStartDateChange] = useState(
    set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
  );
  const [endDate, handleEndDateChange] = useState(
    set(new Date(), {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    })
  );
  const [search, setSeacrh] = useState();
  const [filter, setFilter] = useState("pending");
  const [activeOrderId, setActiveOrderId] = useState();
  const [dataOrder, setDataOrder] = useState();
  const [dataSubOrder, setDataSubOrder] = useState();
  const [loadingDataorder, setLoadingDataOrder] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ordersCol = firestore.collection(`mitra/${company_id}/orders`);
  const subOrderCol = (orderId) =>
    firestore.collection(`mitra/${company_id}/orders/${orderId}/suborders`);
  useEffect(() => {
    getAllOrders();
  }, [filter]);

  useEffect(() => {
    if (search && search.length > 0) getOrdersWithSearch();
    else getOrders();
  }, [search]);

  const getAllOrders = () => {
    setLoadingDataOrder(true);
    getOrders();
  };

  const getOrders = () => {
    let query = ordersCol
      .where("created_at", ">=", startDate)
      .where("created_at", "<=", endDate);
    if (filter !== "paid") {
      query = query.where("status", "==", filter);
    }
    if (filter === "paid") {
      query = query.where("status", "in", ["paid", "cod"]);
    }
    query
      .get()
      .then((snapshot) => {
        setLoadingDataOrder(false);
        let dumpArr = [];
        snapshot.docs.forEach((doc) => {
          const id = doc.id;
          const data = { id, ...doc.data() };
          dumpArr.push(data);
        });
        // console.log(dumpArr)
        setDataOrder(dumpArr);
      })
      .catch((err) => {
        setLoadingDataOrder(false);
        console.log("Error : ", err);
      });
  };

  const getDataSubOrder = (orderId, update) => {
    if (orderId === activeOrderId && !update) {
      return setActiveOrderId("");
    }
    setActiveOrderId(orderId);
    subOrderCol(orderId)
      .get()
      .then((snapshot) => {
        let dumpArr = [];
        snapshot.docs.forEach((doc) => {
          const id = doc.id;
          const data = { id, ...doc.data() };
          dumpArr.push(data);
        });
        setDataSubOrder(dumpArr);
      })
      .catch((err) => {
        console.log("Get Data Suborder Error : ", err);
      });
  };
  // console.log(getDataSubOrder);
  const getOrdersWithSearch = () => {
    ordersCol
      .where("kode_order", "==", search)
      .get()
      .then((snapshot) => {
        setLoadingDataOrder(false);
        let dumpArr = [];
        snapshot.docs.forEach((doc) => {
          const id = doc.id;
          const data = { id, ...doc.data() };
          dumpArr.push(data);
        });
        setDataOrder(dumpArr);
      })
      .catch((err) => {
        setLoadingDataOrder(false);
        // console.log('Error : ', err)
      });
  };

  const changeStatus = (order) => async () => {
    try {
      setButtonLoading(true);

      const varCol = firestore.collection(
        `mitra/${company_id}/orders/${order.id}/suborders`
      );
      varCol
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            updateOrderStatus(order.id, doc.id); // add subOrderID
            updateSuborderStatus(order.id, doc.id);
            // console.log(order.id);
          });
        })
        .catch((err) => {
          console.log("Error : ", err);
        });
      setButtonLoading(false);
      enqueueSnackbar("Status berhasil dirubah", { variant: "success" });
    } catch (e) {
      console.log(e.message);
    }
  };
  const getLabel = async (orderId, suborderId) => {
    setButtonLoading(true);
    const startShipment = functions.httpsCallable("shipping-startShipment");
    await startShipment({
      useInsurance: "0",
      packageType: "2",
      order_id: `${orderId}`,
      suborder_id: `${suborderId}`,
    })
      .then(async (res) => {
        setButtonLoading(false);
        if (res.data.statusCode > 200) {
          setShowAlert(true);
          setIsError(true);
          setAlertText(`Error ${res.data.statusCode} : ${res.data.title}`);
        } else {
          setShowAlert(true);
          setIsError(false);
          setAlertText(`Sukses memproses order`);
          await updateOrderStatus(orderId);
          await updateSuborderStatus(orderId, suborderId);
          // console.log('Label Result : ', res.data)
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        setShowAlert(true);
        setIsError(true);
        setAlertText(`Error ${err.message} : Gagal mengubah status`);
      });
    //setShowAlert(true)
    //setIsError(false)
    //await updateOrderStatus(orderId)
    //await updateSuborderStatus(orderId, suborderId)
    ///setAlertText(`Sukses memproses order`)
    setButtonLoading(false);
    // console.log('Label Result : ', res.data)
  };
  const updateSuborderStatus = async (
    orderId,
    suborderId,
    status = "on-process"
  ) => {
    const varCol = firestore.collection(
      `mitra/${company_id}/orders/${orderId}/suborders`
    );
    await varCol
      .doc(suborderId)
      .set(
        {
          status: status,
          status_pengiriman: status,
          on_process_at: FieldValue.serverTimestamp(),
        },
        {
          merge: true,
        }
      )
      .then((res) => {
        console.log("Success update sub order status");
      })
      .catch((err) => {
        console.log("Failed to update sub order status");
      });
    getDataSubOrder(orderId, true);
  };
  const updateOrderStatus = async (orderId) => {
    const status = "on-process";
    let DocData = await ordersCol.doc(orderId).get();

    const configDoku = (
      await firestore.doc("settings/payment/availables/doku").get()
    ).data();

    let data = DocData.data();

    let channelIdBank =
      data?.notif_payment_order?.transactions?.channel?.id.toLowerCase();

    let paymentFee = configDoku.list_payment_fee.default_payment_fee;

    for (let [id, value] of Object.entries(configDoku.list_payment_fee)) {
      if (channelIdBank == id) {
        paymentFee = value;
      }
    }

    let ppn = configDoku.ppn;

    paymentFee = Math.round(paymentFee + paymentFee * ppn);

    // console.log(paymentFee)

    await ordersCol.doc(orderId).set(
      {
        status_pengiriman: status,
        status: status,
        updated_at: FieldValue.serverTimestamp(),
        biaya_transaksi: paymentFee,
      },
      {
        merge: true,
      }
    );

    getOrders();
  };
  const handleAlertClose = () => {
    setShowAlert(false);
    setIsError("");
  };
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {},
    uid: {},
  });

  const handleChangeTab = (event, value) => {
    setFilter(value);
  };
  const [showResults, setShowResults] = React.useState(false);
  const handleEditKurir = async (item, data) => {
    const rate = item?.rate_name;
    const rate_id = item?.rate_id;
    const provider = data?.alamat_tujuan?.provider;
    const nama = item?.name;
    // console.log("kurir = ", data?.alamat_tujuan?.provider);
    //   console.log("sub = ", data);
    try {
      const subOrder = firestore.collection(
        `mitra/${company_id}/orders/${data?.orderId}/suborders`
      );
      await subOrder.doc(data?.id).set(
        {
          kurir: {
            layanan: rate,
            nama: nama,
            rate_id: rate_id,
            provider: provider,
          },
        },
        { merge: true }
      );
      setEditDialog({ open: false, dataSub: {} });
      enqueueSnackbar("Kurir Berhasil Diperbarui", { variant: "success" });
      getDataSubOrder(data.orderId, true);
    } catch (e) {
      console.log(e.message);
    }
  };
  const handleReveral = async (data) => {
    try {
      const users = firestore.collection(`mitra/${company_id}/sellers`);
      const dataUser = (await users.doc(data.order_info.owner_id).get()).data();
      // console.log(data);
      const subOrder = firestore.collection(
        `mitra/${company_id}/orders/${data.orderId}/suborders`
      );
      const dataOrder = firestore.collection(`mitra/${company_id}/orders`);
      // console.log(dataLama);
      if (dataUser.tipe_akun === "reseller") {
        await subOrder.doc(data.id).set(
          {
            ref_agen: dataUser.ref_agen,
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
            ref_distributor: dataUser.ref_distributor,
          },
          { merge: true }
        );
        await dataOrder.doc(data.orderId).set(
          {
            ref_agen: dataUser.ref_agen,
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
            ref_distributor: dataUser.ref_distributor,
          },
          { merge: true }
        );
      } else if (dataUser.tipe_akun === "agen") {
        await subOrder.doc(data.id).set(
          {
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
            ref_distributor: dataUser.ref_distributor,
          },
          { merge: true }
        );
        await dataOrder.doc(data.orderId).set(
          {
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
            ref_distributor: dataUser.ref_distributor,
          },
          { merge: true }
        );
      } else if (dataUser.tipe_akun === "distributor") {
        await subOrder.doc(data.id).set(
          {
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
          },
          { merge: true }
        );
        await dataOrder.doc(data.orderId).set(
          {
            ref_mentorbisnis: dataUser.ref_mentorbisnis,
          },
          { merge: true }
        );
      }
      getDataSubOrder(data.orderId, true);
      enqueueSnackbar("kode reveral Berhasil Diperbarui", {
        variant: "success",
      });
      //   console.log("ubah berhasil");
    } catch (e) {
      //   console.log("ubah galal");
      console.log(e.message);
    }
  };
  // const subOrd = dataOrder?.map((ord) => {
  //     return ord?.id
  // })

  // console.log(dataOrder)

  // const subOrdCol = firestore.collection(`orders/${subOrd}/suborders`);

  // const [jumlahOrder, loadJumlahOrder] = useCollectionData(subOrdCol, { idField: 'id' });
  // const subOrder = jumlahOrder?.map((ord) => {
  //     return ord.id
  // })
  // console.log(dataOrder?.map((ord) => {
  //     return ord.id
  // }))
  // if (loadJumlahOrder) {
  //     return <PageLoading />
  // }
  // add order manual
  const [openAddOrder, setOpenAddOrder] = useState(false);
  return (
    <>
      <Container maxWidth="lg">
        <Paper className={classes.paperWrap}>
          <div className={classes.pageWrap}>
            <div className={classes.headerFilterContainer}>
              <Typography
                className={classes.pageTitle}
                component="h1"
                variant="h6"
              >
                Daftar Order
              </Typography>
              <Typography>Filter Berdasarkan Tanggal</Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <KeyboardDatePicker
                          maxDate={new Date()}
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Dari Tanggal"
                          value={startDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <KeyboardDatePicker
                          minDate={startDate}
                          maxDate={new Date()}
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Sampai Tanggal"
                          value={endDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getAllOrders()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <TextField
                    label="Cari Kode Order"
                    value={search}
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSeacrh(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Search />
                </Grid>
              </Grid>
              {loadingDataorder ? (
                <PageLoading size={50} color="inherit" />
              ) : (
                ""
              )}
            </div>
            <div className={classes.tabsWrap}>
              <div className={classes.tabLink}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={filter}
                  onChange={handleChangeTab}
                >
                  <Tab label="Ditunda" value="pending" />
                  <Tab label="Sedang Di Konfirmasi" value="paid" />
                  <Tab label="DiProses" value="on-process" />
                  <Tab label="Sedang DiKirim" value="on-delivery" />
                  <Tab label="Diterima" value="received" />
                  <Tab label="Kedaluwarsa" value="expired" />
                  <Tab label="Selesai" value="completed" />
                  <Tab label="Cancel" value="canceled" />
                </Tabs>
              </div>
              <TabPanel
                value={(e) => {
                  setFilter("pending");
                }}
                index="pending"
              />
              <TabPanel
                value={(e) => {
                  setFilter("paid");
                }}
                index="paid"
              />
              <TabPanel
                value={(e) => {
                  setFilter("on-process");
                }}
                index="on-process"
              />
              <TabPanel
                value={(e) => {
                  setFilter("on-delivery");
                }}
                index="on-delivery"
              />
              <TabPanel
                value={(e) => {
                  setFilter("received");
                }}
                index="received"
              />
              <TabPanel
                value={(e) => {
                  setFilter("expired");
                }}
                index="expired"
              />
              <TabPanel
                value={(e) => {
                  setFilter("completed");
                }}
                index="completed"
              />
              <TabPanel
                value={(e) => {
                  setFilter("canceled");
                }}
                index="canceled"
              />
              {showAlert && (
                <Snackbar
                  open={showAlert}
                  autoHideDuration={5000}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  onClose={handleAlertClose}
                >
                  <Alert
                    onClose={handleAlertClose}
                    severity={isError ? "error" : "success"}
                  >
                    {alertText}
                  </Alert>
                </Snackbar>
              )}
              {dataOrder?.length <= 0 && (
                <Typography className={classes.noOrderMsg} variant="h5">
                  Belum Ada Order
                </Typography>
              )}
            </div>
            {dataOrder?.map?.((order) => {
              return (
                <>
                  <Paper key={order.id} className={classes.paper}>
                    <div className={classes.orderBlock}>
                      <OrderExpired dataOrder={order} getOrders={getOrders} />

                      <div className={classes.orderStatus}>
                        {order.status === "pending" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: orange[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: orange[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon
                                style={{ color: orange[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order.status === "paid" ||
                        order.status === "cod" ||
                        order.status === "completed" ? (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        ) : null}
                        {order.status === "received" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>

                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon
                                style={{ color: green[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order.status === "on-delivery" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: purple[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>

                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: purple[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon
                                style={{ color: purple[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order.status === "on-process" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: blue[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>

                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: blue[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon style={{ color: blue[500] }} />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order.status === "expired" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: grey[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>

                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon
                                style={{ color: grey[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon style={{ color: grey[500] }} />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order.status === "canceled" && (
                          <>
                            <div className={classes.statusBarItem}>
                              <FiberManualRecordIcon
                                style={{ color: red[500] }}
                              />
                              <Typography className={classes.statusLabel}>
                                {order.status}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <MonetizationOnIcon style={{ color: red[500] }} />
                              <Typography className={classes.statusLabel}>
                                {order.status_pembayaran}
                              </Typography>
                            </div>
                            <div className={classes.statusBarItem}>
                              <LocalShippingIcon style={{ color: red[500] }} />
                              <Typography className={classes.statusLabel}>
                                {order.status_pengiriman}
                              </Typography>
                            </div>
                          </>
                        )}
                        {order?.status_cod === true && (
                          <img src={COD} alt="cod" width="50" height="auto" />
                        )}
                      </div>
                      <div className={classes.btnDetailWrap}>
                        <Button
                          variant="contained"
                          className={classes.btnDetail}
                          onClick={() => {
                            setShowResults((open) => ({
                              // ...open,
                              [order.id]: !open[order.id],
                            }));
                            getDataSubOrder(order.id);
                          }}
                        >
                          <Typography>Details</Typography>
                          <ExpandMoreIcon className={classes.detailIcon} />
                        </Button>
                      </div>
                    </div>
                    {showResults[order.id] ? (
                      <ShowOrder
                        order={order}
                        buttonLoading={buttonLoading}
                        changeStatus={changeStatus}
                        handleReveral={handleReveral}
                        activeOrderId={activeOrderId}
                        dataSubOrder={dataSubOrder}
                        getDataSubOrder={getDataSubOrder}
                        enqueueSnackbar={enqueueSnackbar}
                        getOrders={getOrders}
                      />
                    ) : null}
                    <OrderItem
                      order={order}
                      buttonLoading={buttonLoading}
                      changeStatus={changeStatus}
                      activeOrderId={activeOrderId}
                      dataSubOrder={dataSubOrder}
                      setEditDialog={setEditDialog}
                      editDialog={editDialog}
                      getDataSubOrder={getDataSubOrder}
                      enqueueSnackbar={enqueueSnackbar}
                      getOrders={getOrders}
                    />
                  </Paper>
                </>
              );
            })}
            <Edit
              editDialog={editDialog}
              handleClose={() => {
                setEditDialog({ open: false, dataSub: {} });
              }}
              handleEditKurir={handleEditKurir}
            />
          </div>
        </Paper>
      </Container>
      <div className={classes.fab1}>
        <Fab
          disabled={dataMitraCurrent?.isBlock === true}
          color="secondary"
          onClick={() => {
            setOpenAddOrder(true);
          }}
          tooltip
        >
          <PostAddIcon />
        </Fab>
        <AddOrder
          open={openAddOrder}
          handleClose={() => {
            setOpenAddOrder(false);
          }}
        />
      </div>
      <div className={classes.fab2}>
        <Fab
          color="primary"
          onClick={() => {
            history.push("/orders/cartOrderManual");
          }}
        >
          <ShoppingCartIcon className={classes.icw} />
        </Fab>
      </div>
    </>
  );
}

export default ListOrders;
