import React, { useEffect } from "react";
import { functions } from "../../../components/FirebaseProvider";
import { unixToDate } from "../../../utils/dateFormatter";
import useStyles from "./styles";
import Typography from '@material-ui/core/Typography';

export default function OrderExpired(props) {
    const callExpired = React.useRef(false)
    const classes = useStyles();
    // console.log(props)
    useEffect(() => {

        if (props?.dataOrder?.expiration_date <= Date.now() && !callExpired.current && props?.dataOrder?.status === 'pending') {
            callExpired.current = true;

            async function callSetOrderToExpired() {

                try {

                    const setOrderToExpired = functions.httpsCallable("order-setExpired")

                    await setOrderToExpired({ order_id: props?.dataOrder?.id, mitra_id: props?.dataOrder?.mitra_id })
                    props?.getOrders()
                } catch (e) {
                    console.log("failed to set order", e.message)
                }
            }
            callSetOrderToExpired();
        }
    }, [props?.dataOrder?.expiration_date, props?.dataOrder?.id, props?.dataOrder?.status]);

    return <>
        <div className={classes.orderDateWrap}>
            <Typography className={classes.orderDate}>{props?.dataOrder?.created_at && unixToDate(props?.dataOrder.created_at?.toMillis())}</Typography>
        </div>
        <div className={classes.orderCode}>
            <Typography className={classes.orderValueBold}>{props?.dataOrder?.kode_order}</Typography>
        </div>
    </>


}