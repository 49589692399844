import { Button, Dialog, DialogTitle, } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import ReactToPrint from 'react-to-print';

const url = 'https://cors-anywhere.herokuapp.com/'

const LabelPreview = ({
    onClose,
    visible,
    resiUrl
}) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [disabled, setDisabled] = useState(true);
    const componentRef = useRef();

    function onDocumentLoadSuccess({ numPages }) {
        setDisabled(false)
    }

    const onDialogClose = () => {
        setDisabled(true)
        onClose()
    }

    return (
        <Dialog onClose={onDialogClose} open={visible} aria-labelledby="simple-dialog-title">
            <DialogTitle id='simple-dialog-title'>Preview Label Order</DialogTitle>
            <Document
                ref={componentRef}
                file={url + resiUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={1}
                    width={500} />
            </Document>

            <ReactToPrint
                trigger={() => (
                    <Button disabled={disabled} variant='contained' color='primary'>Print</Button>
                )}
                content={() => componentRef.current}
            />
        </Dialog>
    )
}

export default LabelPreview
