import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import { FieldValue, firestore } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import useStyles from "./styles";

export default function UpdateStok(orderId, mitraId, getOrders) {
    const classes = useStyles()
    const { company_id } = useData();
    // stok
    const stokCol = firestore.collection(`mitra/${company_id}/products_stock`);


    const { enqueueSnackbar } = useSnackbar();

    const [dataProd, loadingDataProd] = useCollectionData(stokCol, { idField: 'id' })

    const [form, setForm] = useState({
        stok: '',
        product: ''
    });
    // console.log(data);
    const [error, setError] = useState({});

    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        // console.log(e.target.type);

        setForm({
            ...form,
            [e.target.name]: value,
        });

        setError({
            ...error,
            [e.target.name]: "",
        });
    };
    // save
    const [loading, setLoading] = useState(false);


    // 
    const subordCol = firestore.collection(`mitra/${orderId?.mitraId}/orders/${orderId?.orderId}/suborders`);

    const [dataSub, loadingDataSub] = useCollectionData(subordCol, { idField: 'id' })
    // console.log('data', orderId)
    const lengthData = dataSub?.map((length) => {
        return length?.products_name?.length
    });
    const prodId = dataSub?.map((length) => {
        return length?.productIds
    })
    const prodQty = dataSub?.map((length) => {
        return length?.products_qty
    })
    const prod = dataSub?.map((length) => {
        return length?.products
    })

    // sellers_webstore
    const webstoreDoc = firestore.doc(`mitra/${company_id}/sellers_webstore/${company_id}`);
    const [dataWebs,] = useDocumentData(webstoreDoc)
    const handleSave = async () => {


        setLoading(true);

        try {
            for (var j = 0; j < lengthData; j++) {

                const stokDoc = stokCol.doc(`${prodId?.[0]?.[j]}`);
                let keyJ = j;
                let findDataStok = dataProd?.find((stok) => {
                    return stok?.id === prodId?.[0]?.[keyJ]
                })
                const filterProd = dataWebs?.products.find((data) => {
                    return data === prod?.[0]?.[keyJ]?.product_id
                })
                if (!findDataStok) {
                    const stokNew = {
                        stok_available: form?.stok?.[j] || prodQty?.[0]?.[j],
                        updated_at: FieldValue.serverTimestamp(),
                        crested_at: FieldValue.serverTimestamp(),
                        mitraId: company_id,
                        stok_booked: 0,
                        productId: prod?.[0]?.[j]?.product_id,
                        variantId: prod?.[0]?.[j]?.variant?.variant_id


                    }
                    await stokCol.doc(`${prodId?.[0]?.[j]}`).set(stokNew, { merge: true })

                    if (!filterProd) {
                        await webstoreDoc.set({
                            products: [...(dataWebs?.products ?? []), prod?.[0]?.[j]?.product_id]
                        }, { merge: true })
                    }
                } else {
                    const stokNow = {
                        stok_available: FieldValue.increment(+form?.stok?.[j] || prodQty?.[0]?.[j]),
                        updated_at: FieldValue.serverTimestamp(),
                    }
                    await stokDoc.set(stokNow, { merge: true })

                    if (!filterProd) {
                        await webstoreDoc.set({
                            products: [...(dataWebs?.products ?? []), prod?.[0]?.[j]?.product_id]
                        }, { merge: true })
                    }
                }


            }

            const orderIssueDoc = firestore.doc(`mitra/${orderId?.mitraId}/orders/${orderId?.orderId}`);

            await orderIssueDoc.set({
                update_stok: true
            }, { merge: true })
            orderId.getOrders()
            enqueueSnackbar('stok product berhasil di update', { variant: "success" });
            setLoading(false);

        } catch (e) {
            console.log(e.message)

            enqueueSnackbar(e.message, { variant: "error" });
            setLoading(false);
        }
    };
    let datasuborder = [];
    for (var i = 0; i < lengthData; i++) {
        let key = i
        datasuborder.push(
            dataSub?.map((data) => {
                return <div key={data?.id}>
                    <Typography className={classes.transactionItems}>Produk Ke-{key + 1}</Typography>
                    <Typography className={classes.transactionItems}>Nama Produk : {data?.products_name?.[key]} - {data?.products_variant1?.[key]} - {data?.products_variant2?.[key]}</Typography>
                    <Typography className={classes.transactionItems}>Jumlah Produk : {data?.products_qty?.[key]}</Typography>
                    {/* <Typography>{data?.products?.[key]?.product_id}_{data?.products?.[key]?.variant?.variant_id}</Typography> */}
                    {
                        data?.status_pengiriman === 'received' &&
                        <> <TextField
                            style={{ marginTop: 8, marginBottom: 8 }}
                            id={"stok" + [key]}
                            name="stok"
                            label="New Stok"
                            type='number'
                            color='primary'
                            variant='outlined'
                            value={form?.stok?.[key] || data?.products_qty?.[key] || ''}
                            onChange={(e) => {
                                const value = {
                                    ...form?.stok,
                                    [key]: e.target.value
                                }
                                handleChange({ target: { name: 'stok', value } })
                            }} fullWidth
                        />
                        </>
                    }


                </div>

            })
        )
    }
    const status = dataSub?.map((length) => {
        return length?.status_pengiriman
    })
    // console.log('prod', prod?.[0]?.[0]?.product_id)
    if (loadingDataSub || loadingDataProd) {
        return <PageLoading />
    }
    return <>
        {
            loadingDataSub ? 'loading...' :

                <>{datasuborder}
                    {
                        status?.[0] === 'received' &&

                        <Button
                            style={{ marginTop: 16 }}
                            // className={classes.margin}
                            onClick={handleSave}
                            disabled={loading}
                            color='primary'
                            variant='contained'
                            fullWidth
                            size='large'
                        >
                            Simpan
                        </Button>}
                </>
        }
    </>
}