import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // pageWrap: {
  //   display: 'flex',
  // },
  variantWrap: {
    paddingBottom: 60,
    marginBottom: 60,
    borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
  },
  pageTitle: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  backBtn: {
    marginRight: 20,
  },
  dateInfo: {
    margin: '20px 0 40px 86px',
    color: theme.palette.textColor.gray8,
  },
  tableCon: {
    marginTop: 35,
    marginBottom: 25,
  },
  gridCon: {
    marginTop: 20,
  },
  fab: {
    marginLeft: theme.spacing(2)
  },
  fab1: {
    display: 'flex',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(6)
  },
  hideInputFile: {
    display: 'none'
  },
  uploadFotoKursus: {
    textAlign: 'center',
    padding: '24px 0'
  },
  previewFotoKursus: {
    width: '100%',
    height: 'auto'
  },
  previewFotoVar: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    '& img': {
      padding: 3
    }
  },
  uploadBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 125,
    margin: '20px 0'
  },
  iconLeft: {
    marginRight: theme.spacing(1)
  },
  iconRight: {
    marginLeft: theme.spacing(1)
  },
  grid: {
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  xIcon: {
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(-56)
  },
  xIconProd: {
    marginLeft: theme.spacing(-4),
    marginTop: theme.spacing(-61)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  uploadIcon: {
    marginRight: 10,
  },
  tableWrap: {
    borderRadius: 8,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    marginBottom: 20,
  },
  content: {
    padding: theme.spacing(2),

  },
  btnWrap: {
    // marginBottom: 60,
  },
  thumbWrap:{
    position: 'relative',
    border: 'solid 1px ' + theme.palette.textColor.gray2,
    margin: '0 20px 0 0',
    width: 100,
    height: 100,
    '& img':{
      width: '100%',
      height: '100%',
    },
  },
  closeBtn:{
      position: 'absolute',
      top: -15,
      right: -15,
      color: theme.palette.textColor.gray2,
      backgroundColor: '#fff',
      padding: 3,
      '& :hover':{
        color: '#aaa',
        backgroundColor: '#ddd',
        borderRadius: '50%',
        padding: 3,
      }
  },
  sort:{
    paddingBottom:15
  }
}));

export default useStyles;
