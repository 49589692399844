import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import {
  firestore,
  functions,
  FieldValue,
} from "../../../components/FirebaseProvider";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import PageLoading from "../../../components/loading/pageLoading";
import { currency , delimiters } from "../../../utils/formatter";
import useStyles from "../cart/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { colors, Container } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CekOngkir from "../cart/cekOngkir";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AddCircleRounded, MoreVert, RemoveCircleOutline } from "@material-ui/icons";
import AddOrderMore from "./addMore";
import { blue, green, grey, pink, red } from "@material-ui/core/colors";

export default function CartOrderManual({ value }) {
  const classes = useStyles();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const { company_id } = useData();

  const cartsDoc = firestore.doc(`mitra/${company_id}/carts/${company_id}`);

  const [POSnapshot, POLoading] = useDocument(cartsDoc);
  const [changeColors, setchangeColors] = useState(false)
  const [CartsForm, setCartsForm] = useState();
  useEffect(() => {
    if (POSnapshot) {
      const data = POSnapshot?.data();
      setCartsForm({
        ...data,
      });
    }
  }, [POSnapshot]);
  // console.log(CartsForm);
  // cek ongkir
  // mitra_asal
  const mitraAsalDoc = firestore.doc(
    `mitra/${company_id || "mitra_asal"}/settings/alamat`
  );
  const [mitraSnapshot] = useDocument(mitraAsalDoc);
  // addMoreOrder
  const [openAddOrder, setOpenAddOrder] = useState(false);

  let alamatKey = [];
  if (CartsForm?.subcarts) {
    const objAlamat = Object.keys(CartsForm?.subcarts);

    alamatKey.push(objAlamat);
  }
  // console.log(alamatKey)
  const [mitraAsal, setMitraAsal] = useState();

  // mitra pengirim
  // console.log(mitraAsal);

  useEffect(() => {
    if (mitraSnapshot) {
      const data = mitraSnapshot.data();
      setMitraAsal({
        ...data,
      });
    }
  }, [mitraSnapshot]);

  //    mitra destination
  const pathAlamat =
    alamatKey?.[0]?.[0] === "alamat"
      ? `mitra/${company_id}/settings/alamat`
      : `mitra/${company_id}/alamat/${alamatKey ? alamatKey?.[0] : "alamat"}`;
  
  // alamat penerima
  const destinationDoc = firestore.doc(
    `mitra/${company_id}/alamat_order_manual/${
      alamatKey ? alamatKey?.[0] : "alamat"
    }`
  );
  const [settSnapshotDest] = useDocument(destinationDoc);

  const [formDest, setFormDest] = useState();
  // console.log(pathAlamat)

  useEffect(() => {
    if (settSnapshotDest) {
      const data = settSnapshotDest?.data();
      setFormDest({
        ...data,
      });
    }
  }, [settSnapshotDest]);

  // console.log(CartsForm?.subcarts?.[alamatKey?.[0]])
  // const beratProdPO = formPO?.subcarts?.alamat?.berat_total;

  const [ongkir, setOngkir] = useState();
  const handleChangeOngkir = (event) => {
    setOngkir({
      ...ongkir,
      [event?.target?.name]: event?.target?.value,
    });
  };
  const [kurir_regular, setKurir_regular] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  // set Ongkir
  useEffect(() => {
    if (
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
        ?.berat_total &&
      mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id &&
      formDest?.[formDest?.provider] &&
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
        ?.total_pembayaran &&
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.length &&
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.width &&
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.height
      //  debouncedJumlah && formDest && form?.variant2 && form?.variant2?.berat_paket &&
      //  form?.variant2?.harga_normal &&
      //  form?.variant2?.panjang_paket &&
      //  form?.variant2?.lebar_paket &&
      //  form?.variant2?.tinggi_paket && mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id && formDest?.[formDest?.provider]
    ) {
      setSubmitting(true);
      async function cekOngkir() {
        const getOngkir = functions.httpsCallable("shipping-ongkir");
        let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;
        const provider_detail = formDest?.[formDest?.provider] ?? {};

        let destination = provider_detail?.kecamatan?.id;
        if (mitraAsal?.provider === "directapi") {
          origin = {};
          destination = {};
          let origin_sicepat = mitraAsal?.[
            mitraAsal?.provider
          ]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
          let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

          if (
            origin_sicepat &&
            destination_sicepat &&
            mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat
          ) {
            origin.sicepat = origin_sicepat;
            destination.sicepat = destination_sicepat;
          }
          let origin_jne =
            mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
          let destination_jne =
            provider_detail?.kelurahan?.kode_destination_jne;
            if (
              origin_jne &&
              destination_jne &&
              mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne
          ) {
            origin.jne = origin_jne;
            destination.jne = destination_jne;
          }
          let origin_kode_ninja_lt1 = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_ninja_lt1
          let origin_kode_ninja_lt2 = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_ninja_lt2
          let destination_kode_ninja_lt1 = provider_detail?.kelurahan?.kode_ninja_lt1
          let destination_kode_ninja_lt2 = provider_detail?.kelurahan?.kode_ninja_lt2
          if (
            origin_kode_ninja_lt1 &&
            origin_kode_ninja_lt2 &&
            destination_kode_ninja_lt1 &&
            destination_kode_ninja_lt2
          ) {
            origin.ninja_l1 = origin_kode_ninja_lt1;
            origin.ninja_l2 = origin_kode_ninja_lt2;
            destination.ninja_l1 = destination_kode_ninja_lt1
            destination.ninja_l2 = destination_kode_ninja_lt2

          }
          let origin_jnt =
          mitraAsal?.[mitraAsal?.provider]?.kelurahan?.nama_sendSite_jnt;
          let destination_jnt =
          provider_detail?.kelurahan?.nama_destination_jnt;
          if (
            origin_jnt &&
            destination_jnt
            ) {
              origin.jnt_send = origin_jnt;
              destination.jnt_dest = destination_jnt;
        }
        }

        // const destination = [alamatKey ? alamatKey?.[0] : 'alamat']?.kecamatan?.id
        // const origin = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
        // const destinationCoord = [alamatKey ? alamatKey?.[0] : 'alamat']?.koordinat ? `${[alamatKey ? alamatKey?.[0] : 'alamat'].koordinat?.latitude}, ${[alamatKey ? alamatKey?.[0] : 'alamat'].koordinat?.longitude}` : null;
        //  const weight = CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total;
        const weightTot =
          CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
            ?.berat_total;
        const price =
          CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
            ?.total_pembayaran;
        const length =
          CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.length;
        const width =
          CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.width;
        const height =
          CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.height;
        // const mitra_tujuan = form?.[form?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)

        let sub = {
          id_asal: origin,
          id_tujuan: destination,
          berat: weightTot,
          panjang: length,
          lebar: width,
          tinggi: height,
          harga: price,
          cod: 0,
          type: 2,
        };
        const response = await getOngkir(sub);
        let data = {};
        switch (response?.data?.provider) {
          case "directapi":
            data = Object.entries(response?.data?.items)
            .map(([key, value]) => {
                return {
                  title: key,
                  data: value?.map?.((item) => {
                    if (key === "sicepat") {
                      return {
                        provider: "sicepat",
                        ...item,
                        name: "SiCepat",
                        rate_name: item?.service,
                        rate_id: item?.service,
                        finalRate: item?.tariff,
                        kurir: "sicepat",
                        tipe: item?.service,
                      };
                    } else if (key === "jne") {
                      return {
                        provider: "jne",
                        ...item,
                        name: "JNE",
                        service: item.service_display,
                        rate_id: item.service_code,
                        rate_name: item.service_display,
                        finalRate: parseInt(item.price),
                        kurir: "jne",
                        tipe: item.service_code,
                      };
                    } else if (key === "ninja") {
                      return {
                        provider: "ninja",
                        ...item,
                        name: "Ninja",
                        rate_id: item?.serviceType,
                        rate_name: item?.serviceName,
                        finalRate: item?.price,
                        kurir: "ninja",
                        tipe: item?.serviceName,
                      };
                    } else if (key === 'jnt'){
                      return {
                        provider: "jnt",
                        ...item,
                        name : 'JNT',
                        rate_id : item?.productType,
                        rate_name : item?.productType,
                        finalRate :  parseInt(item?.cost),
                        kurir: "jnt",
                        tipe : item?.nameService
                      };
                    }
                  }),
                };
              })
              .filter((item) => item.data.length > 0);
            // data = response.data.items.map(item => {

            //   return {
            //     title: item.description,
            //     data: [
            //       {
            //         ...item,
            //         name: item.description,
            //         rate_name: item.service,
            //         rate_id: item.service,
            //         finalRate: item.tariff
            //       }
            //     ]
            //   }
            // })
            break;
          case "rajaongkir":
            data = response?.data?.items
              .map((item) => {
                return {
                  title: item.name,
                  data: item.costs.map((cost) => {
                    return {
                      ...cost,
                      rate_id: `${item.code}-${cost.service}`,
                      finalRate: cost?.cost?.[0]?.value ?? 0,
                      name: item.name,
                      rate_name: cost.service,
                    };
                  }),
                };
              })
              .filter((item) => item?.data?.length > 0);

            break;
          case "shipper":
            if (kurir_regular) {
              data = Object.entries(kurir_regular)
                .map(([key, value]) => {
                  return {
                    title: key,
                    data: value,
                  };
                })
                .filter((item) => item.data.length > 0);
            }
            break;
          default:
        }
        // console.log('respo', data);
        setKurir_regular(data);
        setOngkir(data);

        setSubmitting(false);
      }
      cekOngkir();
    }
  }, [
    CartsForm?.subcarts?.[alamatKey?.[0]]?.berat_total,
    formDest?.[formDest?.provider],
    mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id,
    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
      ?.total_pembayaran,
    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.length,
    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.width,
    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.height,
  ]);

  // dialogOngkir
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {},
    uid: {},
  });


  const [loading, setLoading] = useState(false);
  const [urlDoku, setUrl] = useState();
  // setOngkir
  const totag =
    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.total_tagihan;
  const ongkirNow = ongkir?.pengiriman
    ? ongkir?.pengiriman
    : ongkir?.[0]?.data?.[0];
  const handleCheckout = async () => {
    setLoading(true);
    try {
      await cartsDoc.set(
        {
          subcarts: {
            [alamatKey ? alamatKey?.[0] : "alamat"]: {
              total_pembayaran: ongkirNow
                ? totag + ongkirNow?.finalRate
                : totag,
              pengiriman: ongkirNow || {},
              biaya_pengiriman: ongkirNow?.finalRate || 0,
              biaya_pengiriman_before: ongkirNow?.finalRate || 0,
            },
          },
        },
        { merge: true }
      );
      const paymentOrder = functions.httpsCallable("payment-order");

      const response = await paymentOrder();

      const data = response.data;
      if (data?.provider === "doku") {
        window.open(data?.doku?.payment?.url);
      }

      setUrl(data);
      setLoading(false);
      enqueueSnackbar(`Order Berhasil di checkout`, { variant: "success" });
    } catch (e) {
      console.log(e.message);

      enqueueSnackbar(`Order gagal di checkout`, e.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };
  const handleDeleteProd = (prodKeys, id) => async () => {
    // console.log('del', prodKeys, id)
    try {
      await cartsDoc.set(
        {
          subcarts: {
            [alamatKey ? alamatKey?.[0] : "alamat"]: {
              total_tagihan: FieldValue.increment(-prodKeys?.harga),
              total_pembayaran: FieldValue.increment(-prodKeys?.harga),
              berat_total: FieldValue.increment(-prodKeys?.berat),
              // prngiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
              products: {
                [id]: FieldValue.delete(),
              },
            },
          },
        },
        { merge: true }
      );
      // await cartsDoc.update({
      //     subcarts: {
      //         alamat: {
      //             products: {
      //                 [id]: FieldValue.delete()
      //             }
      //         }
      //     }
      // }, { merge: true })
      enqueueSnackbar(`Produk dihapus`, { variant: "success" });
    } catch (e) {
      // console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };  

  const handlePlusPrice = async (data) => {
    setchangeColors(true)

    let productId = data?.keys
    let ProductKeys = data?.keys?.split("_")
    
    let respon = await firestore.collection(`products/${ProductKeys[0]}/variants`).doc(`${ProductKeys[1]}`).get()
    let dataDocRespon = respon?.data()

    const docChart = await cartsDoc.get()
    let resultDocChart = docChart.data()
    let getIdKeys = Object.keys(resultDocChart?.subcarts)
    // data Chart
    let dataChartOrder = resultDocChart?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
    // data Product Per Items Yang Akan Di Tambahkan
    let dataProductItems = resultDocChart?.subcarts?.[getIdKeys ? getIdKeys?.[0] : "alamat" ]?.products?.[productId ? productId : "alamat"]

    if (productId) {
      let idKeys = getIdKeys[0] // ID CHART KEYS
      let harga_satuan = dataProductItems?.harga / dataProductItems?.jumlah

      let harga_total_peritems =  dataProductItems?.harga + harga_satuan
      let increment = dataProductItems?.jumlah + 1

      let berat_satuan = dataDocRespon?.berat_paket
      let panjang = dataDocRespon?.panjang_paket
      let tinggi = dataDocRespon?.tinggi_paket
      let lebar = dataDocRespon?.lebar_paket

      const chartDocs = firestore.doc(`mitra/${company_id}/carts/${company_id}`);
      await chartDocs.set({
        subcarts:
        {
          [idKeys] : {
            products:
            {
              [productId] : {
                jumlah : Number(increment),
                harga :  Number(harga_total_peritems),
                berat : Number(dataProductItems?.berat + berat_satuan),
              }
            },
            total_tagihan : Number(dataChartOrder?.total_tagihan + harga_satuan),
            total_pembayaran : Number(dataChartOrder?.total_pembayaran + harga_satuan),
            berat_total : Number(dataChartOrder?.berat_total + berat_satuan),
            height : Number(dataChartOrder?.height + tinggi),
            length : Number(dataChartOrder?.length + panjang),
            width : Number(dataChartOrder?.width + lebar)
          }
        },
        updated_at: FieldValue.serverTimestamp(),
      },{merge : true})
    }
    
    setTimeout(() => {
      setchangeColors(false)
    }, 200);
  }

  const handleMinimPrice = async (data) => {
    setchangeColors(true)
    let productId = data?.keys
    let ProductKeys = data?.keys?.split("_")
    // doc product
    let respon = await firestore.collection(`products/${ProductKeys[0]}/variants`).doc(`${ProductKeys[1]}`).get()
    let dataDocRespon = respon?.data()

    const docChart = await cartsDoc.get()
    let resultDocChart = docChart.data()
    let getIdKeys = Object.keys(resultDocChart?.subcarts)
    // data Chart
    let dataChartOrder = resultDocChart?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
    // data Product Per Items Yang Akan Di Tambahkan
    let dataProductItems = resultDocChart?.subcarts?.[getIdKeys ? getIdKeys?.[0] : "alamat" ]?.products?.[productId ? productId : "alamat"]

    if (productId) {
      let idKeys = getIdKeys[0] // ID CHART KEYS
      let harga_satuan = dataProductItems?.harga / dataProductItems?.jumlah

      let harga_total_peritems =  dataProductItems?.harga - harga_satuan
      let increment = dataProductItems?.jumlah - 1

      let berat_satuan = dataDocRespon?.berat_paket
      let panjang = dataDocRespon?.panjang_paket
      let tinggi = dataDocRespon?.tinggi_paket
      let lebar = dataDocRespon?.lebar_paket
      
      if (increment >= 1){
        const chartDocs = firestore.doc(`mitra/${company_id}/carts/${company_id}`);
        await chartDocs.set({
          subcarts:
          {
            [idKeys] : {
              products:
              {
                [productId] : {
                  jumlah : Number(increment),
                  harga :  Number(harga_total_peritems),
                  berat : Number(dataProductItems?.berat - berat_satuan),
                }
              },
              total_tagihan : Number(dataChartOrder?.total_tagihan - harga_satuan),
              total_pembayaran : Number(dataChartOrder?.total_pembayaran - harga_satuan),
              berat_total : Number(dataChartOrder?.berat_total - berat_satuan),
              height : Number(dataChartOrder?.height - tinggi),
              length : Number(dataChartOrder?.length - panjang),
              width : Number(dataChartOrder?.width - lebar)
            }
          },
          updated_at: FieldValue.serverTimestamp(),
        },{merge : true})
      } else {
        enqueueSnackbar(`Maaf Product Sudah Mencapai Batas Minim Belanja`, { variant: "error" });
      }
    }

    setTimeout(() => {
      setchangeColors(false)
    }, 200);
  }
  // var checkoutButton = document?.getElementById('checkout-button');
  // // Example: the payment page will show when the button is clicked
  // checkoutButton?.addEventListener?.('click', function () {
  //     `handleCheckout(${urlDoku?.doku?.payment?.url})`; // Replace it with the response.payment.url you retrieved from the response
  // });
  // const checkOut=handleCheckout.addEventListener('click', function () {
  //     handleCheckout('https://jokul.doku.com/checkout/link/SU5WFDferd561dfasfasdfae123c20200510090550775'); // Replace it with the response.payment.url you retrieved from the response
  // });

  // console.log('SUBCART => ' , CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.harga)
  let datasuborder = [];
  let array = []
  if (CartsForm?.subcarts) {
    const objkey =
      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products;
    const DataProd = Object.keys(objkey);
    // console.log(CartsForm?.subcarts?.alamat?.products?.[DataProd?.[0]])
    const DataLength = DataProd?.length;
    if (DataLength < 1) {
      return (
        <Container maxWidth="xs" className={classes.isEmpty}>
          <Typography variant="h5">Keranjang Masih Kosong</Typography>
          <Button
            onClick={() => {
              history.push(`/orders`);
            }}
            color="primary"
            variant="contained"
            style={{ marginTop: 20 }}
          >
            Kembali
          </Button>
        </Container>
      );
    }
    // if (!CartsForm?.kode_order) {
    //     return <Container maxWidth="xs" className={classes.isEmpty}>
    //         <Typography variant="h5">Keranjang Masih Kosong</Typography>
    //         <Button
    //             onClick={() => {
    //                 history.push('/purchaseOrders');
    //             }}
    //             color="primary"
    //             variant="contained"
    //             style={{ marginTop: 20 }}
    //         >
    //             Ke List Product
    //         </Button>
    //     </Container>
    // }
    
    for (var i = 0; i < DataLength; i++) {
      let key = i;
      const prodKeys =
        CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
          ?.products?.[DataProd?.[i]];
      let keys_id =  DataProd?.[i]

      let harga_normal = CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.harga_potongan + (CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.harga / CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.jumlah)
      // console.log('==><<',prodKeys)
      datasuborder.push(
        <Grid container className={classes.transaction}>
          <Grid container item xs={10} spacing={2} className={classes.pathTransaktion}>
            {/* <Grid container spacing={3}>
                  <Grid item xs={6}>
                      <img src={CartsForm?.subcarts?.[alamatKey?alamatKey?.[0]:'alamat']?.products?.[DataProd?.[i]]?.thumbnail?.[0]} />
                  </Grid>
                  <Grid item xs={6}> */}
            {/* <Typography className={classes.transactionItems}>Produk Ke-{key + 1}</Typography> */}
            <Grid item xs={1.5}>
              <Typography className={classes.transactionItems}>
                Nama Produk :{" "}
                {
                  CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
                    ?.products?.[DataProd?.[i]]?.nama
                }{" "}
              </Typography>
            </Grid>
            
            <Grid item xs={1.5}>
              <Typography className={classes.transactionItems}>
                Harga Normal :{" "}
                {/* {currency(
                  CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.harga_normal
                )} */}
                {currency(harga_normal)}
              </Typography>
            </Grid>

            <Grid item xs={1.5}>
              <Typography className={classes.transactionItems}>
                Potongan Harga :{" "}
                {currency(CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.harga_potongan)}
              </Typography>
            </Grid>

            {/* <Grid item xs={2.5}>
              <Typography className={classes.transactionItems}>
                Total Harga Potongan :{" "}
                {currency(
                  CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.harga 
                  /
                  CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.products?.[DataProd?.[i]]?.jumlah
                )}
              </Typography>
            </Grid> */}
            <Grid item xs={1.5}>
              <Typography className={classes.transactionItems}>
                Jumlah :{" "}
                  <b>
                    {
                      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
                        ?.products?.[DataProd?.[i]]?.jumlah
                    }
                  </b>
              </Typography>
            </Grid>

            <Grid item xs={1.5}>
              <Typography className={classes.transactionItems}>
                Total Harga :{" "}
                <b>
                  {currency(
                    CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
                      ?.products?.[DataProd?.[i]]?.harga
                  )}
                </b>
              </Typography>
            </Grid>
            
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ color: grey[500] }}
              aria-label="minimize"
              onClick={() => handleMinimPrice({product_id : prodKeys?.product , keys : keys_id })} 
              >
              <RemoveCircleOutline />
            </IconButton>
            <IconButton
              style={{ color: green[500] }}
              aria-label="add"
              onClick={() => handlePlusPrice({product_id : prodKeys?.product , keys : keys_id })} 
              >
              <AddCircleRounded />
            </IconButton>
            <IconButton
              style={{ float: "right" , color: red[500]}}
              // color="secondary"
              onClick={handleDeleteProd(prodKeys, DataProd?.[i])}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  }

  if (POLoading) {
    return <PageLoading />;
  }

  return (
    <>
      {CartsForm?.kode_order ? 
        <>
          <Paper className={classes.container}>
            <div className={classes.kode}>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                className={classes.iconBck}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography>
                Kode Order : <b>{CartsForm?.kode_order}</b>
              </Typography>
            </div>
            <div className={classes.subContainer}>
              {datasuborder}
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setOpenAddOrder(true);
                  }}
                  disabled={loading || !CartsForm?.kode_order}
                  // color="secondary"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Tambah Produk
                </Button>
              </Grid>
            </div>
            <Grid container spacing={3} className={classes.subContainer}>
              <Grid item xs={6}>
                <Typography>Detail Pembelian</Typography>
                <br />
                <Grid container>
                  <Grid xs={6}>
                    <Typography>
                      Nama Pembeli
                    </Typography>
                    <Typography>
                      No Telepon
                    </Typography>
                    <Typography>
                      Metode Pengiriman
                    </Typography>
                    <Typography>
                      Metode Pembayaran
                    </Typography>
                    {CartsForm?.metode_pengiriman == 'ekspedisi' ? 
                      <>
                        <Typography>
                          Provinsi
                        </Typography>
                        <Typography>
                          Kota
                        </Typography>
                        <Typography>
                          Kecamatan
                        </Typography>
                        <Typography>
                          Kelurahan
                        </Typography>
                        <Typography>
                          Alamat Lengkap
                        </Typography>
                      </>
                      : null}
                  </Grid>
                  <Grid xs={6}>
                    <Typography>
                      <b className={classes.gt}>
                        : {CartsForm?.detail_buyer?.nama}
                      </b>
                    </Typography>
                    <Typography>
                      <b className={classes.gt}>
                       : {CartsForm?.detail_buyer?.no_hp ? CartsForm?.detail_buyer?.no_hp : CartsForm?.detail_buyer?.no_hp}
                      </b>
                    </Typography>
                    <Typography>
                      <b className={classes.gt}>
                       : {CartsForm?.metode_pengiriman ? CartsForm?.metode_pengiriman : null}
                      </b>
                    </Typography>
                    <Typography>
                      <b className={classes.gt}>
                       : {CartsForm?.metode_pembayaran ? CartsForm?.metode_pembayaran :  CartsForm?.metode_pembayaran }
                      </b>
                    </Typography>
                    {CartsForm?.metode_pengiriman == 'ekspedisi' ? 
                      <>
                        <Typography>
                          <b className={classes.gt}>
                          : {formDest?.directapi?.provinsi?.name}
                          </b>
                        </Typography>
                        <Typography>
                          <b className={classes.gt}>
                          : {formDest?.directapi?.kota?.name}
                          </b>
                        </Typography>
                        <Typography>
                          <b className={classes.gt}>
                          : {formDest?.directapi?.kecamatan ? formDest?.directapi?.kecamatan?.name : null}
                          </b>
                        </Typography>
                        <Typography>
                          <b className={classes.gt}>
                          : {formDest?.directapi?.kelurahan ? formDest?.directapi?.kelurahan?.name : null}
                          </b>
                        </Typography>
                        <Typography>
                          <b className={classes.gt}>
                          : {formDest?.directapi?.alamat_lengkap}
                          </b>
                        </Typography>
                      </>
                    :null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Typography>Detail Pembayaran</Typography>
                <br />
                <Typography style={{fontSize : 16 , fontWeight : 'bold'}}>
                  Total Tagihan :{" "}
                  <b style={{color : changeColors ? red[500] : null}}> 
                  {/* pink[700] */}
                    {currency(
                      CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]
                        ?.total_tagihan
                    )}
                  </b>
                </Typography>
                <br/>
                <Typography style={{fontSize : 16 , fontWeight : 'bold'}}>
                  Total Berat :{" "}
                  <b style={{color : changeColors ? red[500] : null}}> 
                    {
                      delimiters(CartsForm?.subcarts?.[alamatKey ? alamatKey?.[0] : "alamat"]?.berat_total)
                    }
                  </b>
                 {" "}gr
                </Typography>
                <br/>
                {/* <Typography>Biaya Pengiriman : {currency(CartsForm?.subcarts?.alamat?.pengiriman?.finalRate)}</Typography> */}
                {/* <Typography>Alamat : {CartsForm?.subcarts?.alamat?.pengiriman}</Typography> */}
                <Grid>
                  {ongkir ? (
                    <Grid
                      container
                      className={classes.jp1}
                      onClick={(event) => {
                        setEditDialog((editDialog) => ({
                          ...editDialog,
                          // dataProd: form?.variant2,
                          open: true,
                          mitra_id: company_id,
                          mitra_asal: mitraAsal,
                          // jumlah: jumlah,
                          formKurir: ongkir,
                          isSubmitting: { isSubmitting },
                        }));
                      }}
                    >
                      <Typography variant="body2" style={{ marginBottom: 10 }}>
                        Jasa Pengiriman
                      </Typography>
                      <Grid item xs={11}>
                        {ongkir?.pengiriman?.name ? (
                          <>
                            <Typography>
                              {ongkir?.pengiriman?.name || ""}-
                              {ongkir?.pengiriman?.description || ""}(
                              {ongkir?.pengiriman?.service || ""})
                            </Typography>
                            <Typography>
                              Biaya : {currency(ongkir?.pengiriman?.finalRate)}
                            </Typography>
                            {/* <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography> */}
                          </>
                        ) : loading ? (
                          <>
                            <Typography>Memproses ....</Typography>
                          </>
                        ) : (
                          <>
                            <Typography>
                              {ongkir?.[0]?.data?.[0]?.name || ""}-
                              {ongkir?.[0]?.data?.[0]?.description || ""}(
                              {ongkir?.[0]?.data?.[0]?.service || ""})
                            </Typography>
                            <Typography>
                              Biaya :{" "}
                              {currency(ongkir?.[0]?.data?.[0]?.finalRate)}
                            </Typography>
                            {/* <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography> */}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          variant="contained"
                          size="large"
                          color="primary"
                        >
                          <MoreVert />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : isSubmitting ? (
                    <div className={classes.jp2}>
                      <div className={classes.jp3}>
                        <CircularProgress size={15} />
                        <Typography className={classes.ltx}>
                          Loading...
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </Grid>
                <Divider style={{ margin: "10px 0px" }} />
                <Typography style={{fontSize : 16 , fontWeight : 'bold'}}>
                  Biaya Ongkir :{" "}
                  <b className={classes.gt}>
                    {currency(ongkirNow ? ongkirNow?.finalRate : 0)}
                  </b>
                </Typography>
                <Divider style={{ margin: "10px 0px" }} />
                <Typography style={{fontSize : 15 , fontWeight : 'bold'}}>
                  Total Pembayaran :{" "}
                  <b className={classes.gt}>
                    {currency(ongkirNow ? totag + ongkirNow?.finalRate : totag)}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.btn}>
              <Grid item xs={12}>
                <Button
                  onClick={handleCheckout}
                  disabled={loading || !CartsForm?.kode_order}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Check Out
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </>
      : 
      <>
        <Container maxWidth="xs" className={classes.isEmpty}>
          <Typography variant="h5">Keranjang Masih Kosong</Typography>
          <Button
            onClick={() => {
              history.push(`/orders`);
            }}
            color="primary"
            variant="contained"
            style={{ marginTop: 20 }}
          >
            Kembali
          </Button>
        </Container>
      </>
      }
      <AddOrderMore
        open={openAddOrder}
        handleClose={() => {
          setOpenAddOrder(false);
        }}
      />
      <CekOngkir
        editDialog={editDialog}
        handleClose={() => {
          setEditDialog({ open: false, dataSub: {} });
        }}
        handleEditKurir={handleChangeOngkir}
        kurir_regular={kurir_regular}
      />
    </>
  );
}
