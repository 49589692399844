import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";
import { Typography } from "@material-ui/core";

function Detail({ dialog: { open, data, uid }, handleClose }) {
    const classes = useStyles();

    // console.log(data.product_reward?.variants);

    const variants = data?.product_reward?.variants[0];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        // disableBackdropClick={loading}
        // disableEscapeKeyDown={loading}
        >
            <DialogTitle>Detail Variant Produk</DialogTitle>
            <DialogContent dividers>
                {/* {data?.product_reward?.variants.map((data?.product_reward?.variants[0]) => { */}
                <Grid container justify="center" spacing={2}>
                    <div >
                        <img src={variants?.thumbnail} alt="" />
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel}>Produk Reward</Typography>
                        <Typography className={classes.liquidValue}>
                            : {data?.product_reward?.nama}
                        </Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel}>{variants?.variant1?.label}</Typography>
                        <Typography className={classes.liquidValue}>
                            : {variants?.variant1?.nilai}
                        </Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel}>{variants?.variant2?.label}</Typography>
                        <Typography className={classes.liquidValue}>
                            : {variants?.variant2?.nilai}
                        </Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel}>SKU Produk</Typography>
                        <Typography className={classes.liquidValue}>
                            : {variants?.sku}
                        </Typography>
                    </div>
                    {/* <div className={classes.liquidRow}>
              <Typography className={classes.liquidLabel}>Stok Tersedia</Typography>
              <Typography className={classes.liquidValue}>
                : {variants?.stok_available}
              </Typography>
            </div>
            <div className={classes.liquidRow}>
              <Typography className={classes.liquidLabel}>Stok Dibooking</Typography>
              <Typography className={classes.liquidValue}>
                : {variants?.stok_booked}
              </Typography>
            </div> */}
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel}>Alamat Rak Produk</Typography>
                        <Typography className={classes.liquidValue}>
                            : {variants?.alamat_rak}
                        </Typography>
                    </div>
                </Grid>
                {/* })} */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Tutup</Button>
            </DialogActions>
        </Dialog>
    );
}

export default Detail;
