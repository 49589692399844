import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { firestore, } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import Typography from '@material-ui/core/Typography';
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { useData } from "../../../components/DataProvider";

export default function WebSeller(users_id) {
    const classes = useStyles();
    const { company_id } = useData();
    const pathCol = firestore.collection(`mitra/${company_id}/sellers_webstore_path`).where('user_id', '==', users_id?.users_id);

    const [path, loadPath] = useCollectionData(pathCol, { idField: 'id' })
    const webstoresCol = firestore.doc(`mitra/${company_id}/sellers_webstore/${users_id.users_id}`);

    const [dataWeb, loadingDataWeb] = useDocument(webstoresCol);

    const [web, setWeb] = useState();
    useEffect(() => {
        if (dataWeb) {
            const data = dataWeb.data()
            setWeb({
                ...data,
            });
        }
    }, [dataWeb]);
    // console.log(users_id?.users_id);

    const nowa = web?.wa
    const wa = nowa?.replace(/[-()\s]/g, '');

    if (loadingDataWeb || loadPath) {
        return <PageLoading />
    }
    return <>

        <Typography className={classes.boxTitle}>Info Toko</Typography>
        <div className={classes.sellerRow}>
            <Typography className={classes.rowLabel}>Nama Toko</Typography>
            <Typography className={classes.rowValue}>: {web?.nama || '-'}</Typography>
        </div>
        <div className={classes.sellerRow}>
            <Typography className={classes.rowLabel}>Dibuat Pada</Typography>
            <Typography className={classes.rowValue}>: {unixToDate(web?.created_at?.toMillis?.()) || '-'}</Typography>
        </div>
        <div className={classes.sellerRow}>
            <Typography className={classes.rowLabel}>Path</Typography>
            <Typography className={classes.rowValue}>: {path?.map((pat) => { return pat?.id }) || '-'}</Typography>
        </div>
        <div className={classes.sellerRow}>
            <Typography className={classes.rowLabel}>WA Toko</Typography>
            <Typography className={classes.rowValue}>: {wa || '-'}</Typography>
        </div>
        {/* <div>
        {
            web?.changesubdomain === 1 ?
                <Button
                    color="primary"
                    variant="contained"
                    onClick={changeStatusDomain(web)}
                >
                    Izinkan Edit Domain
                </Button> :
                <Button
                    disabled
                    color="primary"
                    variant="contained"
                    onClick={changeStatusDomain(web)}
                >
                    Izinkan Edit Domain
                </Button>
        }

    </div> */}
    </>
}