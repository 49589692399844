import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {
  firestore,
  FieldValue,
  functions,
} from "../../../components/FirebaseProvider";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { currency } from "../../../utils/formatter";
import Typography from "@material-ui/core/Typography";
// import dataBank from './bank.json'
import Autocomplete from "@material-ui/lab/Autocomplete";

import useStyles from "./styles";

import Card from "@material-ui/core/Card";
import { useCollectionData } from "react-firebase-hooks/firestore";

function AddPencairanRekber({ open, handleClose, totalOmset }) {
  const history = useHistory();
  const classes = useStyles();
  const [modal, setModal] = useState(false);

  const { company_id } = useData();

  const [form, setForm] = useState({
    bank_tujuan: "",
    // rekening_tujuan: '',
    total_pencairan: "",
    catatan: "",
  });
  // console.log(totalOmset)

  const [error, setError] = useState({
    bank_tujuan: "",
    // rekening_tujuan: '',
    total_pencairan: "",
    catatan: "",
  });

  // console.log(form)

  const { enqueueSnackbar } = useSnackbar();
  const close = () => {
    handleClose();
    setForm({ ...form, catatan: "" });
    setModal(false);
  };
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(e.target.type)
    setForm({
      ...form,
      [e.target.name]: value,
    });
    // console.log(form)

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = async () => {
    const newError = { ...error };

    if (!form.bank_tujuan) {
      newError.bank_tujuan = "Bank tujuan harus diisi";
    }

    // if (!form.rekening_tujuan) {
    //     newError.rekening_tujuan = 'No rekening harus diisi';
    // }

    if (!form.total_pencairan) {
      newError.total_pencairan = "Total pencairan harus diisi";
    }
    if (form.total_pencairan > totalOmset) {
      newError.total_pencairan = "Total pencairan melebihi total omset";
    }
    // if (!totalOmset) {
    //     newError.total_pencairan = 'Total pencairan harus diisi';
    // }

    return newError;
  };
  // dataBank
  const MitraBank = firestore
    .collection(`mitra_bank`)
    .where("mitra_id", "==", company_id);
  const [dataBank, loadDataBank] = useCollectionData(MitraBank, {
    idField: "id",
  });
  const resDataBank = dataBank?.filter((val) => val.check_admin); // filter bank check admin nibras

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else if (!modal) {
      setModal(!modal);
      console.log(2333);
    } else {
      setSubmitting(true);
      try {
        const addPencairan = functions.httpsCallable("payout-mitra");
        await addPencairan({
          bank_id: form?.bank_tujuan?.id,
          amount: form?.total_pencairan,
          notes: form?.catatan,
        });

        const kreditDoc = firestore.doc(`kredit_mitra/${company_id}`);

        await kreditDoc.set(
          {
            total_pencairan_pending: FieldValue.increment(
              +form?.total_pencairan
            ),
          },
          { merge: true }
        );
        setForm({ ...form, catatan: "" });
        handleClose();
        enqueueSnackbar("Pencairan ditambahkan", { variant: "success" });

        // history.push(`product/edit/${form.product_id}`)
      } catch (e) {
        const newError = {};
        enqueueSnackbar(`Pencairan gagal ditambahkan,${e.message}`, {
          variant: "error",
        });
        setForm({ ...form, catatan: "" });
        handleClose();
        newError.title = e.message;

        setError(newError);
      }

      setSubmitting(false);
    }
  };
  if (modal) {
    return (
      <Dialog
        disableBackdropClick={isSubmitting}
        disableEscapeKeyDown={isSubmitting}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cek Kembali</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Pastikan data anda sudah benar</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="Bank_Tujuan"
                name="Bank_Tujuan"
                label="Bank Tujuan"
                value={form?.bank_tujuan?.name}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="atas_nama"
                name="atas_nama"
                label="atas_nama"
                value={form?.bank_tujuan?.nama_rek}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="no_rek"
                name="no_rek"
                label="Nomor Rekening"
                value={form?.bank_tujuan?.no_rek}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="total_pencairan"
                name="total_pencairan"
                type="number"
                label="Nominal Lengkap"
                value={form?.total_pencairan}
                error={error.total_pencairan ? true : false}
                helperText={error.total_pencairan}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="catatan"
                name="catatan"
                label="Catatan"
                value={form?.catatan}
                error={error.catatan ? true : false}
                helperText={error.catatan}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: "red" }}>
                Kesalahan No Rekening Di luar tanggung jawab kami!!!
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={close}>
            Batal
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting || !totalOmset}
            color="primary"
          >
            Ajukan
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Pencairan</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.jp1}>
              <Typography>Batas Pencairan : {currency(totalOmset)}</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="bank_tujuan"
              name="bank_tujuan"
              margin="normal"
              required
              value={form?.bank_tujuan || ""}
              onChange={(event, value) => {
                handleChange({ target: { name: "bank_tujuan", value } });
              }}
              // disabled={loadingStok || loadingVariants || cekLoad}
              options={resDataBank || ""}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name},No Rek. : ${option?.no_rek}`
                  : null || ""
              }
              renderOption={(option) => {
                return (
                  <React.Fragment>
                    {`Bank : ${option?.name}, No Rek. : ${option?.no_rek} , Penerima : ${option?.nama_rek}`}
                    {/* <MitraData opt={true} stok={option?.stok_available} mitraId={option?.mitraId} level={dataMitraCurrent?.mitra_level} /> */}
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  helperText={error?.bank_tujuan}
                  error={error?.bank_tujuan ? true : false}
                  {...params}
                  label="Bank Tujuan"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="total_pencairan"
              name="total_pencairan"
              type="number"
              label="Nominal Lengkap"
              onChange={handleChange}
              error={error.total_pencairan ? true : false}
              helperText={error.total_pencairan}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              id="catatan"
              name="catatan"
              label="Catatan"
              value={form?.catatan}
              error={error.catatan ? true : false}
              onChange={handleChange}
              helperText={error.catatan}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !totalOmset}
          color="primary"
        >
          Buat
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddPencairanRekber.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddPencairanRekber;
