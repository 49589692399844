import "../../font/index.css";

export default (theme) => ({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "blue",
  },
  resetPass : {
    textDecoration : 'none'
  },
  text: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginTop: 80,
      textAlign: "center",
    },
  },
  textloop: {
    backgroundColor: "grey",
    color: "white",
    paddingLeft: 5,
    paddingRight: 5,
  },
  btnSignin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  googleLogo: {
    width: 15,
    marginRight: theme.spacing(1),
  },

  loginBlock: {
    padding: "200px 0vw 40px 3vw",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 3vw 50px",
    },
    margin: "0 0 0 3vw",
    width: "40%",
  },

  formRow: {
    margin: "0 auto 10px",
  },

  desc: {
    marginBottom: 10,
    color: "grey",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },

  desc2: {
    color: "grey",
    fontSize: 45,
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 35,
    },
    fontWeight: 700,
  },

  pageTitle: {
    marginTop: 20,
    color: "grey",
    fontSize: 32,
    textAlign: "flex-start",
    fontWeight: 500,
    marginBottom: 0,
    color: "#CB4179",
    letterSpacing: 1,
    fontFamily: "Montserrat",
  },

  pageSubtitle: {
    color: "grey",
    textAlign: "flex-start",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
    fontFamily: "Poppins",
  },

  pageSubtitle2: {
    color: "grey",
    textAlign: "start",
    fontSize: 16,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
    width: "80%",
    textAlign: "center",
    fontFamily: "Poppins",
  },

  a_href: {
    textDecoration: "none",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 30,
    color: "#CB4179",
  },

  textField: {
    width: "80%",
    // fontFamily: "Poppins",
  },
  font: {
    // fontFamily: "Poppins",
  },

  iconColor: {
    color: "grey",
  },
  lupakatasandi: {
    size: 16,
    color: "grey",
    textAlign: "end",
    fontSize: 16,
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 0,
    width: "80%",
    color: "#CB4179",
    cursor : 'pointer',
  },

  textKatasandi : {
    textDecoration : 'none',
    color: "#CB4179",
  },

  loginBtn: {
    marginTop: 30,
    marginBottom: 20,
    borderRadius: 6,
    width: "80%",
    height: 55,
    boxShadow: "5px 10px 45px rgb(203 65 121 / 15%)",
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: 600,
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  img: {
    display: "flex",
    width: "100%",
    maxWidth: "70%",
    height: "100%",
    backgroundColor: "#CB4179",
    justifyContent: "center",
    alignItem: "center",
  },

  belumpunyaakun: {
    bottom: 0,
    position: "fixed",
    width: "25%",
  },

  //mobile
  mloginBlock: {
    padding: "200px 0vw 40px 0vw",
    [theme.breakpoints.down("sm")]: {
      padding: "100px 0vw 50px",
    },
    width: "70%",
    justifyContent: "center",
    // margin: "5% 0 10px 0",
  },
  mcontainer: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
  },
  mpageTitle: {
    marginTop: 20,
    color: "grey",
    fontSize: 26,
    textAlign: "center",
    fontWeight: 500,
    marginBottom: 0,
    color: "#CB4179",
    // letterSpacing: 1,
    fontFamily: "Montserrat",
  },

  mpageSubtitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
    fontFamily: "Poppins",
  },
  mpageSubtitle2: {
    color: "grey",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 30,
    width: "100%",
    fontFamily: "Poppins",
  },

  mloginBtn: {
    marginTop: 30,
    marginBottom: 20,
    borderRadius: 4,
    width: "100%",
  },
  mtextField: {
    width: "100%",
    borderColor: "red",
  },
  mformRow: {
    margin: "0 auto 20px",
  },
  ma_href: {
    textDecoration: "none",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 30,
    color: "#CB4179",
  },
});
