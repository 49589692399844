import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useStyles from './styles';
// import DetailProd from './detailProduct';
// import TabPO from './tabPO';
import ListProduct from './listProduct';
import SeacrhToko from './searchToko';
import TabPanel from '../../../../components/TabPanel';
// import { useData } from '../../../components/DataProvider';

function SmartPO({ value, loading, setLoading, response, setResponse, handleFind, ongkir, setOngkir }) {
    // const { dataMitraCurrent } = useData();

    const classes = useStyles();
    return (<TabPanel value={value} index="smartPO">

        <div className={classes.pageWrap}>

            <Switch>
                <Route path="/purchaseOrders/smartPO/searchToko" component={SeacrhToko} />
                <Route path="/purchaseOrders/smartPO" ><ListProduct loading={loading} setLoading={setLoading} response={response} setResponse={setResponse} handleFind={handleFind} ongkir={ongkir} setOngkir={setOngkir} /></Route>
                <Redirect to="/purchaseOrders/smartPO" />
            </Switch>



        </div>
    </TabPanel>
    );
}

export default SmartPO;
