import React, { useState, useEffect, forwardRef } from "react";
import useStyles from "./styles";
import { useCollection } from "react-firebase-hooks/firestore";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import { useSnackbar } from "notistack";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
// import ExcelRead from './addFileExcel';
import { useData } from "../../../components/DataProvider";
import qs from "query-string";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory, useLocation } from "react-router-dom";
import SetAdmin from "./admin";
import SetMitra from "./mitra";
// import AddProduct from "./add";
// import tableIcons from "../../../config/table-icons";
// import Edit from "@material-ui/icons/Edit";
// import Container from "@material-ui/core/Container";
// import PostAddIcon from "@material-ui/icons/PostAdd";
// import Fab from "@material-ui/core/Fab";
// import MaterialTable from "material-table";

function Admin() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  // const { enqueueSnackbar } = useSnackbar();
  // const [openAddProduct, setOpenAddProduct] = useState({
  //   mode: "Tambah",
  //   open: false,
  // });

  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "admin"
  );
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);

  const [tabs] = useState([
    // {
    //     label: "Shipping", value: "shipping"
    // },
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Mitra",
      value: "mitra",
    },
  ]);

  const { company_id } = useData();

  const col = firestore.collection(`mitra/${company_id}/admin`);

  const [snapshot, loading] = useCollection(col);

  const [table, setTable] = useState({
    columns: [
      { title: "Email", field: "email", editable: "never" },
      { title: "Password_tmp", field: "password_tmp" },
      { title: "access_role", field: "access_role" },
      {
        title: "Diunggah",
        field: "created_at",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData && rowData.created_at !== null ? (
              <span>{unixToDate(rowData.created_at?.toMillis())}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: "Diubah",
        field: "updated_at",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData && rowData.updated_at !== null ? (
              <span>{unixToDate(rowData.updated_at?.toMillis())}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  if (
    // || komisiLoading
    loading
  ) {
    return <PageLoading />;
  }

  return (
    <>
      <div className={classes.root}>
        <Tabs
          indicatorColor="primary"
          className={classes.tabWrap}
          textColor="primary"
          value={tabValue || "profil"}
          variant="fullWidth"
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <div className={classes.content}>
          {/* <Alamat uid={company_id} value={tabValue} use_available={form.use_available} shipLoading={shipLoading} />
            <Komisi value={tabValue} />
            <PickupAgent value={tabValue} formAgent={form} />
            <RateidList value={tabValue} />
            <Subdomain uid={company_id} value={tabValue} /> */}
          <SetMitra value={tabValue} />
          <SetAdmin value={tabValue} />
          {/* <SellerLevel uid={company_id} value={tabValue} />
          <Shipping uid={company_id} value={tabValue} settLoading={shipLoading} form={form} handleChange={handleChange} formShip={formShip} handleSubmit={handleSubmit} isSubmitting={isSubmitting} error={error} /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;
