import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";

function AddProduct({ dialog: { mode, open, id, data }, handleClose }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { company_id , hideSetting , dropshipSetting} = useData();

  const [form, setForm] = useState({
    email: "",
    password_tmp: "",
    access_role: "",
    akses: [],
  });

  const [error, setError] = useState({
    email: "",
    password_tmp: "",
    access_role: "",
    akses: "",
  });

  useEffect(() => {
    const defaultData = {
      email: "",
      password_tmp: "",
      access_role: "",
      akses: [],
    };

    const defaultError = {
      email: "",
      password_tmp: "",
      access_role: "",
      akses: "",
    };
    if (mode === "Tambah") {
      setForm(defaultData);
      setError(defaultError);
    } else if (mode === "Edit") {
      setForm(data);
      setError(defaultError);
    }
  }, [mode, data]);

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(form)

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // console.log(mode, open, id, data)

  const validate = async () => {
    const newError = { ...error };

    if (mode === "Tambah") {
      if (!form.email) {
        newError.email = "Email harus diisi";
      }

      if (!form.password_tmp) {
        newError.password_tmp = "Password_tmp harus diisi";
      }
      if (form.password_tmp?.length < 8) {
        newError.password_tmp = "Password_tmp harus minimal 6 karakter";
      }
    }

    // AKSES ROLE NULL
    if (!form.access_role) {
      newError.access_role = "Acces_role harus diisi";
    }
    return newError;
  };
  // console.log(form.password_tmp?.length)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const prodCol = firestore.collection(`mitra/${company_id}/admin`);

        const accesCol = firestore.collection(
          `mitra/${company_id}/access_control_list`
        );

        if (mode === "Tambah") {
          await prodCol.doc(`${company_id}-${form.email}`).set({
            ...form,
            created_at: FieldValue.serverTimestamp(),
            updated_at: FieldValue.serverTimestamp(),
            password: form?.password_tmp,
          });

          await accesCol.doc(`${company_id}-${form.email}`).set({
            // ...form,
            access_email: form.email,
            mitra_id: company_id,
            created_at: FieldValue.serverTimestamp(),
            updated_at: FieldValue.serverTimestamp(),
          });
        } else if (mode === "Edit") {
          await prodCol.doc(`${id}`).set({
            ...form,
            // created_at: FieldValue.serverTimestamp(),
            updated_at: FieldValue.serverTimestamp(),
            password: form?.password_tmp,
          });

          // await accesCol.add({
          //     ...form,
          //     created_at: FieldValue.serverTimestamp(),
          //     updated_at: FieldValue.serverTimestamp(),
          // });
        }

        if (mode === "Tambah") {
          enqueueSnackbar("Admin ditambahkan", { variant: "success" });
        } else if (mode === "Edit") {
          enqueueSnackbar("Admin Berhasil Di Ubah", { variant: "success" });
        }

        handleClose();
      } catch (e) {
        const newError = {};

        newError.title = e.message;

        setError(newError);
      }

      setSubmitting(false);
    }
  };

  // ROLE AKSESS
  const role = ["administrator", "gudang"];
  // PATH
  const akses = [
    {
      path: "/",
      name: "Home",
      icon: "iconHome",
      component: "home",
    },
    {
      path: "/admin",
      name: "Admin",
      icon: "iconAdmin",
      component: "admin",
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: "iconAnalytics",
      component: "analytics",
    },
    // {
    //     path: "/feedback",
    //     name: "Feedback",
    //     icon: "iconFeedback",
    //     component: 'feedback',

    // },
    {
      path: "/seller",
      name: "List Drop Shipper",
      icon: "iconSeller",
      component: "seller",
    },
    // {
    //     path: "/companies",
    //     name: "Mitra",
    //     icon: "iconCompanies",
    //     component: 'companies',

    // },
    {
      path: "/notification",
      name: "Notifications",
      icon: "iconNotification",
      component: "notification",
    },
    {
      path: "/orders",
      name: "Orders",
      icon: "iconOrders",
      component: "orders",
    },
    {
      path: "/pencairan",
      name: "Pencairan",
      icon: "iconPencairan",
      component: "pencairan",
    },
    {
      path: "/pencairanRekber",
      name: "Pencairan Rekber",
      icon: "iconPencairanRekber",
      component: "pencairanRekber",
    },
    {
      path: "/productStock",
      name: "Product Stock",
      icon: "iconStock",
      component: "stock",
    },
    // {
    //     path: "/promoOngkir",
    //     name: "Promo Ongkir",
    //     icon: "iconPromoOngkir",
    //     component: 'promoOngkir',

    // },
    {
      path: "/purchaseOrders",
      name: "Purchase Orders",
      icon: "iconPurchaseOrders",
      component: "purchaseOrders",
    },
    {
      path: "/reward",
      name: "Reward",
      icon: "iconReward",
      component: "reward",
      sub: [
        {
          path: "/reward",
          name: "Reward",
          icon: "iconReward",
          component: "reward",
        },
        {
          path: "/penukaran",
          name: "Penukaran",
          icon: "iconPenukaran",
          component: "penukaran",
        },
      ],
    },

    {
      path: "/settings",
      name: "Settings",
      icon: "iconSettings",
      component: "settings",
    },
  ];

  // console.log('==>',akses)
  // console.log(form?.akses)

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Admin</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="email"
              name="email"
              label="Email"
              value={form?.email}
              onChange={handleChange}
              error={error.email ? true : false}
              helperText={error.email}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="password_tmp"
              name="password_tmp"
              label="Password"
              onChange={handleChange}
              error={error.password_tmp ? true : false}
              helperText={error.password_tmp}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="access_role"
              name="access_role"
              margin="normal"
              required
              value={form.access_role}
              onChange={(event, value) => {
                handleChange({ target: { name: "access_role", value } });
              }}
              disabled={isSubmitting}
              options={role}
              renderInput={(params) => (
                <TextField
                  helperText={error.access_role}
                  error={error.access_role ? true : false}
                  {...params}
                  // label="Access Role"
                  label="Status Role"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="akses"
              name="akses"
              margin="normal"
              value={form?.akses}
              disableCloseOnSelect
              onChange={(event, value) => {
                handleChange({ target: { name: "akses", value } });
              }}
              disabled={isSubmitting}
              // options={akses ?? []} // ketika semua permintaan hide di buka baik yang reward dan dropship
              options={akses?.filter((v) => v.name !== "List Drop Shipper" && v.name !== "Reward" && v.name !== "Notifications" && v.name !== "Pencairan") ?? []}
              getOptionLabel={(option) => option?.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )
              }}
              renderInput={(params) => (
                <TextField
                  helperText={error.akses}
                  error={error.akses ? true : false}
                  {...params}
                  // label="Akses"
                  label="URL Akses"
                  variant="outlined"
                />
              )}
            />

            {/* <Autocomplete
                            multiple
                            id="tags-standard"
                            value={form?.akses}
                            onChange={(event, value) => {

                                handleChange({ target: { name: "akses", value } })

                            }}
                            options={akses}
                            getOptionLabel={(option) => option.name}
                            defaultValue={[akses[13]]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Multiple values"
                                    placeholder="Favorites"
                                />
                            )}
                        /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddProduct;
