import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useStyles from './styles';
import DetailProd from './detailProduct';
import TabPO from './tabPO';
import { useData } from '../../../components/DataProvider';
import SmartPO from './smartPO';
import DetailProdMin from './minimumBelanja/detailProduct';

function PurchaseOrders() {
    const { dataMitraCurrent } = useData();

    const classes = useStyles();
    return (
        <div className={classes.pageWrap}>

            {
                dataMitraCurrent?.isBlock !== true && <Switch>
                    <Route path="/purchaseOrders/detailProduct/:productId" component={DetailProd} />
                    <Route path="/purchaseOrders/detailProdMin/:productId" component={DetailProdMin} />
                    <Route path="/purchaseOrders" component={TabPO} />
                    <Route path="/purchaseOrders/smartPO" component={SmartPO} />
                   <Redirect to="/purchaseOrders" /></Switch>
            }



        </div>

    );
}

export default PurchaseOrders;
