import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    containerverify: {
        paddingTop : '100px'
    },
    logo: {
        // display: 'flex',
        justifyContent: 'center'
    },
    root :{
        boxShadow: 'none'
    },
    paper : {
        boxShadow : "none",
        alignItems:'center',
        alignSelf : 'center'
    },
    pageEror: {
        color : 'red',
        fontSize: 15,
    },
    container: {
        padding: 36,
        marginTop: '8%',
    },
    paperWrap: {
        marginTop: 35,
        marginBottom: 25,
    },
    pageWrap: {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 8,
        overflow: 'hidden',
    },
    logo: {
        display: 'flex',
        justifyContent: 'center'
    },
    pageTitle: {
        marginTop: 15,
        marginBottom: 20,
        color: 'grey',
        fontSize: 17,
        textAlign: 'center',
    },
    img : {
        height :350,
        // width:250,
        alignSelf : 'center'
    }
}))


export default useStyles;