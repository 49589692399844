import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import Typography from '@material-ui/core/Typography';

import { Card } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import HargaVar from './dataVar';
import useStyles from './styles';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function DataProdMitra({ prodId, varId }) {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();

    const prodDoc = firestore.doc(`products/${prodId}`);

    const [prodSnapshot, prodLoading] = useDocument(prodDoc);

    const [prodForm, setProdForm] = useState();

    useEffect(() => {
        if (prodSnapshot) {
            const data = prodSnapshot.data();
            setProdForm({
                ...data,
            });
        }
    }, [prodSnapshot]);
    const thumb = prodForm?.thumbnail?.[0];
    const position = thumb?.lastIndexOf?.(".");
    const thumbnail300 =
        thumb?.substring(0, position) +
        "_300x300" +
        thumb?.substring(position, position?.length);
    // console.log('mitprod', prodForm?.product_id)

    if (prodLoading) {
        return <PageLoading />
    }
    return <>
        <Card className={classes.cardProd}>
            <CardActionArea className={classes.cardAct}
                onClick={() => {
                    history.push(`/cart/detailProdMitra/${prodForm?.product_id}_${varId}_${params?.mitraId}`)
                }}
            >
                <CardMedia
                    component="img"
                    alt={prodForm?.nama}
                    width='200px'
                    height="220px"
                    image={thumbnail300}
                    title={prodForm?.nama}
                    className={classes.image}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h4">
                        {prodForm?.nama}
                    </Typography>
                    <HargaVar
                        prodId={prodId}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    </>
}