// React
import React, { useState } from "react";

// image
import platform from "../../images/platform.svg";

// Material UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import isEmail from "validator/lib/isEmail";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GoogleIcon from "../../images/google.svg";
import Logo from "../../components/Logo";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Firebase
import {
  useFirebase,
  auth,
  GoogleAuthProvider,
} from "../../components/FirebaseProvider";

//React Router Dom
import { Redirect, useHistory } from "react-router-dom";

import PageLoading from "../../components/loading/pageLoading";
import { useSnackbar } from "notistack";
import { Image } from "@material-ui/icons";

function MLogin(props) {
  // Styles
  const { classes, location } = props;

  // Firebase
  const { loading, user } = useFirebase();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  //media query
  const hiden = useMediaQuery("(min-width:1000px )");

  // react-router-dom
  const history = useHistory();

  // google.auth
  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();

      const result = await auth.signInWithPopup(provider);
      // const logUser = auth.currentUser;

      // setDialog({ open: true, dataSub: logUser?.uid });

      // console.log(result.additionalUserInfo.isNewUser)
      console.log(result);
      // history.push(`/validate`)
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  // State Data
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [pass, setPass] = useState(true);

  // State Error
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  // State Submitting
  const [isSubmitting, setSubmitting] = useState(false);

  //Handle Change Textfield
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // Validasi
  const validate = () => {
    const newError = { ...error };
    const { email, password } = form;

    if (!email) {
      newError.email = "Email Harus Di Isi";
    } else if (!isEmail(email)) {
      newError.email = "Email Tidak Valid";
    }

    if (!password) {
      newError.password = "Password Harus Di Isi";
    }

    return newError;
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        await auth.signInWithEmailAndPassword(form.email, form.password);
        // history.push(`/validate`)
      } catch (e) {
        const newError = {};
        // console.log(e.message);
        switch (e.code) {
          case "auth/user-not-found":
            newError.email = "Email tidak terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/wrong-password":
            newError.password = "Password salah";
            break;
          case "auth/user-disabled":
            newError.email = "Pengguna di blokir";
            break;
          default:
            newError.email = "Terjadi kesalahan, silahkan coba lagi";
            break;
        }

        setError(newError);
        setSubmitting(false);
      }
    }
  };

  // Loading
  if (loading) {
    return <PageLoading />;
  }

  // console.log(location);
  // Redirect User Terautentikasi
  if (user) {
    const redirectTo =
      location?.state && location.state.from && location.state.from.pathname
        ? location.state.from.pathname
        : "/";
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={classes.mcontainer}>
      {/* <Container maxWidth="sm"> */}
      <div className={classes.mloginBlock}>
        <Typography className={classes.mpageTitle}>Selamat Datang</Typography>
        <Typography className={classes.mpageSubtitle}>
          Masukkan email dan password Anda!
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.mformRow}>
            <TextField
              variant="outlined"
              autoComplete="off"
              disabled={isSubmitting}
              id="email"
              name="email"
              placeholder="Masukkan Email"
              className={classes.mtextField}
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              error={error.email ? true : false}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       {/* <EmailIcon className={classes.iconColor} /> */}
              //     </InputAdornment>
              //   ),
              // }}
            />
          </div>
          <div className={classes.mformRow}>
            <TextField
              autoComplete="off"
              variant="outlined"
              disabled={isSubmitting}
              id="password"
              name="password"
              placeholder="Masukkan Password"
              className={classes.mtextField}
              type={pass == true ? "password" : "text"}
              value={form.password}
              onChange={handleChange}
              helperText={error.password}
              error={error.password ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => setPass(!pass)}>
                      {pass == true ? (
                        <VisibilityOffIcon className={classes.iconColor} />
                      ) : (
                        <VisibilityIcon className={classes.iconColor} />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.lupakatasandi}>
            <a className={classes.textKatasandi} href="/resetPassword">
              Lupa Kata Sandi
            </a>
          </div>
          <div className={classes.mformRow}>
            <Button
              disabled={isSubmitting}
              className={classes.mloginBtn}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                isSubmitting === true && <CircularProgress size={15} />
              }
            >
              Masuk
            </Button>
            {/* <Button
                variant="outlined"
                onClick={handleLogin}
                fullWidth
                startIcon={<img src={GoogleIcon} alt="google icon" className={classes.googleLogo} />}
            >
                Sign in with Google
            </Button>    */}
          </div>
          <div>
            <Typography className={classes.mpageSubtitle2}>
              Butuh Bantuan?{" "}
              <Typography
                className={classes.ma_href}
                display="inline"
              style={{cursor: "pointer"}}

                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send/?phone=628118336243&text&app_absent=0`
                  )
                }
              >
                {" "}
                Hubungi CS
              </Typography>
            </Typography>
          </div>
        </form>
      </div>
      {/* </Container> */}
    </div>
  );
}

export default withStyles(styles)(MLogin);
