import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import {
  FieldValue,
  firestore,
  GeoPoint,
} from "../../../components/FirebaseProvider";
import DialogKelurahan from "../../../components/GetKelurahan/dialogSearch";
import useStyles from "../styles";
import { validate } from "numeral";
import { useSnackbar } from "notistack";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { geohashForLocation } from "geofire-common";
import GoogleMapReact from "google-map-react";
import { red } from "@material-ui/core/colors";
import RoomIcon from "@material-ui/icons/Room";
import KeyApi from "../../../config/keyApiGoogleMap";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

export default function Broadcast() {
  const { broadcastingList, company_id } = useData();
  const settDoc = firestore.doc(`mitra/${company_id}/settings/alamat`);
  const [settSnapshot, settLoading] = useDocument(settDoc);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [drop, setDrop] = React.useState(false);
  const [values, setValue] = useState(null);
  const [form, setForm] = useState({
    provider: "",
    alamat_lengkap: "",
    koordinat: { latitude: -6.200511971721622, longitude: 106.849624984375 },
    pickupCoverage: { sicepat: false, jne: false },
  });
  const classes = useStyles();
  const CompDoc = firestore.doc(`mitra/${company_id}`);
  const [compSnapshot, compLoading] = useDocument(CompDoc);
  // const dataProfile = compSnapshot?.data();
  const directApiAddres = CompDoc.collection("settings").doc("alamat");
  const [directAddress, loadingAddres] = useDocument(directApiAddres);

  useEffect(() => {
    if (settSnapshot) {
      const activeProvider = settSnapshot?.data?.()?.provider;
      const data = settSnapshot?.data?.()?.[activeProvider];
      setForm({
        ...form,
        koordinat: data?.koordinat,
        provider: activeProvider,
      });
    }
  }, [settSnapshot]);

  useEffect(() => {
    setForm({
      ...form,
      pickupCoverage: {
        sicepat: form?.kelurahan?.coverage_pickup === "Y" ? true : false,
        jne: form?.kelurahan?.kode_origin_jne ? true : false,
        ninja:
          form?.kelurahan?.kode_ninja_lt1 && form?.kelurahan?.kode_ninja_lt2
            ? true
            : false,
        jnt: form?.kelurahan?.kode_origin_jnt ? true : false,
      },
    });
  }, [form?.kelurahan]);

  const salinAlamatLengkap = () => {
    const activeProvider = settSnapshot?.data?.()?.provider;
    const data = settSnapshot?.data?.()?.[activeProvider];
    setForm({
      ...form,
      alamat_lengkap: data?.alamat_lengkap,
    });
    enqueueSnackbar("Alamat Lengkap Sebelum nya berhasil disalin", {
      variant: "success",
    });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    try {
      const settDoc = firestore.doc(`mitra/${company_id}/settings/alamat`);
      const settBroadcast = firestore.doc(`broadcast/${e.id}`);
      const updProd = {
        [form?.provinsi?.provider]: {
          alamat_lengkap: form.alamat_lengkap,
          //judul: form.judul,
          //nama_penerima: form.nama_penerima,
          provinsi: form?.provinsi,
          kota: form?.kota,
          kecamatan: form?.kecamatan,
          kelurahan: form?.kelurahan,
          coverage_pickup_sicepat:
            form?.kelurahan?.coverage_pickup === "Y" ? true : false,
          coverage_pickup_jne:
            form?.kelurahan?.kode_origin_jne !== null ? true : false,
          updated_at: FieldValue.serverTimestamp(),
          koordinat: new GeoPoint(
            form?.koordinat?.latitude,
            form?.koordinat?.longitude
          ),
        },
        provider: form?.provinsi?.provider,
        drop: drop,
      };

      await settDoc.set(updProd, { merge: true });

      const hash = geohashForLocation([
        form?.koordinat?.latitude,
        form?.koordinat?.longitude,
      ]);
      await firestore.doc(`geo_mitra/${company_id}`).set(
        {
          geohash: hash,
          lat: form?.koordinat?.latitude,
          lng: form?.koordinat?.longitude,
        },
        { merge: true }
      );

      await settBroadcast.set(
        { list_update: [...e.list_update, company_id] },
        { merge: true }
      );

      enqueueSnackbar("Alamat berhasil disimpan", { variant: "success" });
    } catch (e) {
      const newError = {};
      // console.log(e.message)
      newError.title = e.message;
      enqueueSnackbar(`Alamat gagal disimpan,${e.message}`, {
        variant: "error",
      });

      setError(newError);
    }

    setSubmitting(false);
  };

  const handleClick = (mark) => {
    setForm({
      ...form,
      koordinat: { latitude: mark.lat, longitude: mark.lng },
    });
  };
  const localtion = values?.label;

  useEffect(() => {
    async function GetLtLng() {
      if (localtion) {
        await geocodeByAddress(localtion)
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            setForm({
              ...form,
              koordinat: { latitude: lat, longitude: lng },
            });
            // setlatlang({
            //     lat: lat, lng: lng
            // })

            // setValue()
            // setZoom(10)
          });
      }
    }
    GetLtLng();
  }, [localtion]);
  return (
    <>
      {directAddress?.exists &&
        broadcastingList?.map((val) => {
          return (
            <Dialog
              open={!val?.list_update?.includes(company_id)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              fullScreen
              style={{ width: "80%", height: "70%", margin: "auto" }}
            >
              <DialogTitle>{val.title}</DialogTitle>
              <DialogContent className={classes.termParagraf}>
                <DialogContentText>{val.body}</DialogContentText>
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 12,
                              // marginLeft: 16,
                              marginBottom: 5,
                              color: "#8f877d",
                            }}
                          >
                            Cari Nama Desa atau Kelurahan
                          </p>
                          <div
                            style={{
                              width: 497,
                              border: "1px solid #A0A0A0",
                              color: "#8f877d",
                              borderRadius: "7px",
                              padding: "12px",
                            }}
                            onClick={() => setOpen(true)}
                          >
                            <p
                              style={{
                                margin: "5px 0px",
                                color: "#000",
                                fontSize: 15,
                              }}
                            >
                              {form?.kelurahan?.name ? (
                                <>
                                  {form.kelurahan?.name} - Kec.{" "}
                                  {form.kecamatan?.name}
                                  {"\n"}
                                  {form.kota?.name}, {form.provinsi?.name},{" "}
                                  {form.kelurahan?.postal_code}
                                </>
                              ) : (
                                "Klik untuk mencari"
                              )}
                            </p>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            className={classes.autocomplete}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="alamat_lengkap"
                            name="alamat_lengkap"
                            label="Alamat Lengkap"
                            value={form.alamat_lengkap || ""}
                            onChange={handleChange}
                            error={error.alamat_lengkap ? true : false}
                            helperText={error.alamat_lengkap}
                            fullWidth
                            multiline
                            variant="outlined"
                          />
                          {settSnapshot && (
                            <Typography
                              style={{
                                fontSize: 12,
                                marginTop: 16,
                                cursor: "pointer",
                              }}
                              color="primary"
                              onClick={() => salinAlamatLengkap()}
                            >
                              salin dari alamat sebelumnya
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 30 }}>
                        <Typography className={classes.mapLabel}>
                          Pickup Coverage :{" "}
                        </Typography>
                        <Grid container spacing={1}>
                          {form?.pickupCoverage?.sicepat === true ? (
                            <Grid item>
                              <Chip
                                label="SiCepat"
                                color="primary"
                                icon={<DoneIcon />}
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <Chip
                                label="SiCepat"
                                icon={<CloseIcon />}
                                disabled
                              />
                            </Grid>
                          )}
                          {form?.pickupCoverage?.jne === true ? (
                            <Grid item>
                              <Chip
                                label="JNE"
                                color="primary"
                                icon={<DoneIcon />}
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <Chip label="JNE" icon={<CloseIcon />} disabled />
                            </Grid>
                          )}
                          {form?.pickupCoverage?.ninja === true ? (
                            <Grid item>
                              <Chip
                                label="Ninja"
                                color="primary"
                                icon={<DoneIcon />}
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <Chip
                                label="Ninja"
                                icon={<CloseIcon />}
                                disabled
                              />
                            </Grid>
                          )}
                          {form?.pickupCoverage?.jnt === true ? (
                            <Grid item>
                              <Chip
                                label="JNT"
                                color="primary"
                                icon={<DoneIcon />}
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <Chip label="JNT" icon={<CloseIcon />} disabled />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container lg={6} md={6} xs={12}>
                      <Grid item xs={12}>
                        {/* <MyPlaces keyGoogle='AIzaSyAMUMuva5VkEeo4WbgbTcL5d-xxoTI54Ig' /> */}
                        <div style={{ width: "100%" }}>
                          <GooglePlacesAutocomplete
                            placeholder="Type in an address"
                            selectProps={{
                              values,
                              onChange: setValue,
                              placeholder: "Cari Pin Point",
                            }}
                            // autocompletionRequest={{
                            //     componentRestrictions: {
                            //         country: ["id"]
                            //     }
                            // }}
                            apiKey="AIzaSyAMUMuva5VkEeo4WbgbTcL5d-xxoTI54Ig"
                            // apiOptions={{ language: "id", region: "id" }}
                          />
                        </div>
                        <div className={classes.mapBox}>
                          <GoogleMapReact
                            bootstrapURLKeys={{ key: KeyApi }}
                            defaultCenter={{
                              lat: -6.2,
                              lng: 106.816666,
                            }}
                            defaultZoom={8}
                            center={{
                              lat: form?.koordinat?.latitude,
                              lng: form?.koordinat?.longitude,
                            }}
                            // zoom={zoom}
                            // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                            onClick={handleClick}
                          >
                            <RoomIcon
                              lat={
                                form?.koordinat?.latitude || -6.200511971721622
                              }
                              lng={
                                form?.koordinat?.longitude || 106.849624984375
                              }
                              style={{
                                color: red[500],
                                position: "absolute",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          </GoogleMapReact>
                          <form action="" method="post">
                            <input
                              type="text"
                              id="lat"
                              name="lat"
                              value={
                                form?.koordinat?.latitude || -6.200511971721622
                              }
                            />
                            <input
                              type="text"
                              id="lng"
                              name="lng"
                              value={
                                form?.koordinat?.longitude || 106.849624984375
                              }
                            />
                          </form>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justify="flex-end"
                        alignItems="flex-end"
                        style={{ display: "flex" }}
                      >
                        <Grid item xs={3}>
                          <Button
                            disabled={isSubmitting || !form?.kelurahan}
                            onClick={() => handleSubmit(val)}
                            size="large"
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.btnSave}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogKelurahan
                    open={open}
                    onClose={() => setOpen(false)}
                    value={(v) => {
                      setForm((f) => ({
                        ...f,
                        ...v,
                      }));
                    }}
                  />
                </>
              </DialogContent>
            </Dialog>
          );
        })}
    </>
  );
}
