import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { firestore, FieldValue, functions } from '../../../components/FirebaseProvider';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';
import Typography from '@material-ui/core/Typography';
import Autocomplete from "@material-ui/lab/Autocomplete";

import uniqBy from 'lodash/uniqBy';
import Virtualize from '../../../components/Virtualization';
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';


function AddOrderMore({ open, handleClose }) {
    const history = useHistory();

    const { company_id, dataMitraCurrent, pricing_procedure, pricing_conditions, dataMitraLevel, } = useData();

    const [form, setForm] = useState({
        product: '',
        variant: '',
        qty: '',

    });


    const [error, setError] = useState({
        product: '',
        variant: '',
        qty: '',

    });

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        // console.log(e.target.type)
        setForm({
            ...form,
            [e.target.name]: value
        })
        // console.log(form)

        setError({
            ...error,
            [e.target.name]: ''
        })
        // console.log(form);
    }

    // carts doc
    const cartsDoc = firestore.doc(`mitra/${company_id}/carts/${company_id}`);
    const [POSnapshot,] = useDocument(cartsDoc);
    const [CartsForm, setCartsForm] = useState();
    useEffect(() => {
        if (POSnapshot) {
            const data = POSnapshot.data();
            setCartsForm({
                ...data,
            });
        }
    }, [POSnapshot]);
    // console.log(CartsForm?.buyer_level)
    // load pricing
    const [hargaPricing, setHargaPricing] = useState()
    useEffect(() => {
        try {
            setSubmitting(true)
            async function CekPricing() {
                if (form?.variant && CartsForm?.buyer_level) {
                    const loadRecords = functions.httpsCallable('pricing-loadRecords');

                    const body = JSON.stringify({
                        item: { products: form?.product, variants: [form?.variant], id: form?.product?.id },
                        pricing_procedure,
                        pricing_conditions,
                        level: { level: dataMitraCurrent?.mitra_level, buyer_level: CartsForm?.buyer_level },
                        profil: { id: company_id },
                        location: 'manual'
                    })


                    const result = await loadRecords({
                        body
                    });
                    // console.log(result)
                    setHargaPricing(result)
                    return result?.data ?? {};
                }
            };
            CekPricing()
            setSubmitting(false)
        } catch (e) {

        }
    }, [CartsForm?.buyer_level, company_id, dataMitraCurrent?.mitra_level, form?.product, form?.variant, pricing_conditions, pricing_procedure])

    // product_stock
    // mitra
    const prodStokQuery = firestore.collection(`mitra/${company_id}/products_stock`);

    const [stokData,] = useCollectionData(prodStokQuery)
    const mitra = stokData?.map((stok) => {
        return stok
    })
    const prodIdSet = [...new Map(mitra?.map?.(item => [item?.productId, item]))?.values()];
    const prodIdSetLength = prodIdSet?.length
    let prodFilter = []
    for (var i = 0; i < prodIdSetLength; i++) {
        var filterProd = firestore.doc(`products/${prodIdSet?.[i]?.productId ? prodIdSet?.[i]?.productId : 'prod' || 'prod'}`);
        filterProd.get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                // prodFilter.push(doc.data())
                let data = doc.data()
                if (!data?.sembunyikan_produk) {
                    prodFilter.push(doc.data())
                }
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

    }

    // console.log(prodFilter)

    // collvariant
    let uniques = uniqBy?.(stokData, 'variantId')

    const varId = uniques?.map((stok) => {
        return stok?.variantId
    })
    // const [variantsSelect, setVariants] = useState()
    let variantFilter = []

    if (varId && form?.product?.product_id) {
        for (var j = 0; j < varId?.length; j++) {
            // console.log([varId?.[j]])

            var filterVar = firestore.collectionGroup(`variants`).where('product_id', '==', form?.product?.product_id || '').where('variant_id', '==', varId?.[j] || '');
            filterVar.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // console.log(doc.data()?.product_id, ' => ', doc.data());
                    variantFilter.push(doc.data())
                });
                // console.log(variantFilter)
                // setVariants(variantFilter)
            });

        }
    }
    // console.log(form?.variant?.harga_normal * form?.qty)

    const validate = async () => {
        const newError = { ...error };

        if (!form.product) {
            newError.product = 'Product harus diisi';
        }



        if (!form.variant) {
            newError.variant = 'Variant harus diisi';
        }

        if (!form.qty) {
            newError.qty = 'Total jumlah qty harus diisi';
        }

        // if (!totalOmset) {
        //     newError.qty = 'Total pencairan harus diisi';
        // }


        return newError;
    }



    // alamat_id


    let alamatKey = []
    if (CartsForm?.subcarts) {
        const objAlamat = Object.keys(CartsForm?.subcarts)

        alamatKey.push(objAlamat)
    }
    // console.log(alamatKey?.[0]?.[0])




    // cek stok
    const [stok, setStok] = useState()

    useEffect(() => {
        async function getStok() {
            if (form?.variant) {
                const stokDoc = await firestore.doc(`mitra/${company_id}/products_stock/${form?.variant?.product_id}_${form?.variant?.variant_id}`).get()
                if (stokDoc.exists) {
                    const dataStok = stokDoc.data()
                    // console.log(dataStok)
                    setStok(dataStok?.stok_available)
                }
                // console.log(stokDoc.data())
            }
        }
        getStok()
    }, [form?.variant])
    // console.log(stok)
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                // const orderCol = firestore.collection(`mitra/${company_id}/orders`);

                // const orderBaru = await orderCol.add({
                //     // ...form,
                //     mitra_id: company_id,
                //     status: 'completed',
                //     status_pembayaran: 'paid',
                //     status_pengiriman: 'completed',
                //     created_at: FieldValue.serverTimestamp(),
                //     updated_at: FieldValue.serverTimestamp(),
                //     total_pembayaran: form?.variant?.harga_normal * form?.qty,
                //     kode_order: `m_${Date.now()}`,
                //     paid_at: FieldValue.serverTimestamp(),
                // });
                // const suborderCol = firestore.collection(`mitra/${company_id}/orders/jih/suborders`);

                // await suborderCol.add({
                //     // qty_pending: FieldValue.increment(+form?.qty),
                //     // orderId: orderBaru?.id,
                //     paid_at: FieldValue.serverTimestamp(),
                //     created_at: FieldValue.serverTimestamp(),
                //     updated_at: FieldValue.serverTimestamp(),
                //     productsId: [`${form?.product?.id}_${form?.variant?.id}`],
                //     products: [form?.product],
                //     products_brand: [form?.product?.brand?.nama],
                //     products_kategori1: [form?.product?.kategori1?.nama],
                //     products_kategori2: [form?.product?.kategori2?.nama],
                //     products_kategori3: [form?.product?.kategori3?.nama],
                //     products_name: [form?.product?.nama],
                //     products_qty: [form?.qty],
                //     status: 'completed',
                //     status_pembayaran: 'paid',
                //     total_pembayaran: form?.variant?.harga_normal * form?.qty,
                //     total_tagihan: form?.variant?.harga_normal * form?.qty,
                //     status_cod: false,


                // }, { merge: true })
                const prdStocDoc = firestore.doc(`mitra/${company_id}/carts/${company_id}`);
                const hargaTagihan = hargaPricing?.data?.priceslocal * form?.qty
                const potongan = form?.variant?.harga_normal - hargaPricing?.data?.priceslocal?.[0]
                await prdStocDoc.set({
                    subcarts:
                    {
                        [alamatKey?.[0]?.[0] ? alamatKey?.[0]?.[0] : 'alamat']:
                        {
                            berat_total: FieldValue.increment(+form?.variant?.berat_paket * form?.qty),
                            height: form?.variant?.tinggi_paket,
                            length: form?.variant?.panjang_paket,
                            width: form?.variant?.lebar_paket,
                            total_pembayaran: FieldValue.increment(+hargaTagihan),
                            total_tagihan: FieldValue.increment(+hargaTagihan),
                            products:
                            {
                                [`${form?.product?.product_id}_${form?.variant?.variant_id}`]: {
                                    created_at: FieldValue.serverTimestamp(),
                                    jumlah: form?.qty,
                                    nama: form?.product?.nama,
                                    product: form?.product?.product_id,
                                    promo: false,
                                    harga: hargaTagihan,
                                    berat: (form?.variant?.berat_paket * form?.qty),
                                    harga_potongan : potongan ? potongan : 0,
                                }
                            },
                            // pengiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
                            // biaya_pengiriman: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate,
                            // biaya_pengiriman_before: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate

                        },

                    },
                    // kode_order: `m_${subdomain?.[0]}_${Date.now()}`,
                    // created_at: FieldValue.serverTimestamp(),
                    updated_at: FieldValue.serverTimestamp(),
                    // metode_pembayaran: pembayaran,
                    // metode_pengiriman: pengiriman
                }, { merge: true })

                handleClose()
                enqueueSnackbar('Order ditambahkan', { variant: "success" })
                history.replace("/orders/cartOrderManual");

            } catch (e) {
                const newError = {};
                enqueueSnackbar('Order gagal ditambahkan', `${e.message}`, { variant: "error" })
                console.log(e.message)
                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullScreen
        >
            <DialogTitle>Tambah Order</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Virtualize
                            value={prodFilter}
                            data={form?.product}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="variant"
                            name="variant"
                            margin="normal"
                            required
                            value={form?.variant || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "variant", value } });
                            }}
                            disabled={isSubmitting}
                            options={variantFilter || ''}
                            getOptionLabel={(option) => `${option?.variant1?.nilai ?

                                `${option?.variant1?.nilai}_${option?.variant2?.nilai}`

                                : ''}`}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option?.variant1?.label}:{option?.variant1?.nilai} {option?.variant2?.label}:{option?.variant2?.nilai}
                                </React.Fragment> || ''
                            )}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.variant}
                                    error={error?.variant ? true : false}
                                    {...params}
                                    label="Variant"
                                    variant="outlined"

                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="qty"
                            name="qty"
                            type='number'
                            label="QTY"
                            onChange={handleChange}
                            error={error.qty ? true : false}
                            helperText={error.qty}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            form?.qty > stok &&
                            <Typography color='error'>*Stok Tidak Mencukupi</Typography>
                        }
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormLabel component="legend">Metode Pembayaran</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={pembayaran} onChange={handleChangePembayaran}>
                            <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                            <FormControlLabel value="doku" control={<Radio />} label="Doku" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel component="legend">Metode Pengiriman</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={pengiriman} onChange={handleChangePengiriman}>
                            <FormControlLabel value="pickup" control={<Radio />} label="Pickup" />
                            <FormControlLabel value="ekspedisi" control={<Radio />} label="Ekspedisi" />
                        </RadioGroup>
                    </Grid> */}
                    {/* <Grid>
                        <Button
                            onClick={(event) => {
                                setDialog((dialog) => ({
                                    ...dialog,
                                    // dataProd: form?.variant2,
                                    open: true,
                                    // mitra_id: company_id,
                                    // mitra_asal: mitraAsal,
                                    // jumlah: jumlah,
                                    // formKurir: ongkir,
                                    // isSubmitting: { isSubmitting }
                                }));
                            }}
                        >
                            Add Alamat
                        </Button>
                        <AddAlamatOrder
                            dialog={dialog}
                            handleClose={() => {
                                setDialog({ open: false, data: {} });
                            }}
                            setalamat={setalamat}
                        />
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button

                    onClick={handleSubmit}
                    disabled={isSubmitting || form?.qty > stok}
                    color="primary"
                >
                    Tambah ke cart
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddOrderMore.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddOrderMore;
