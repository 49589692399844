/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  FieldValue,
  firestore,
  functions,
} from "../../../../components/FirebaseProvider";
import PageLoading from "../../../../components/loading/pageLoading";
import { currency } from "../../../../utils/formatter";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Carousel from "nuka-carousel";
import HargaVar from "./dataVar";
// Material-UI
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
// import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import OutlinedInput from '@material-ui/core/Input';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import TextField from '@material-ui/core/TextField'

// Icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import {
  NavigateBefore,
  NavigateNext,
  MoreVert,
  DriveEta,
} from "@material-ui/icons";
import useStyles from "./styles";
import { useData } from "../../../../components/DataProvider";
// import AddPurchaseOrders from "./add";
import { useSnackbar } from "notistack";
import CekOngkir from "./cekOngkir";
import { useDebounce } from "use-debounce/lib";
import MitraData from "./mitraData";
import AddAlamat from "./addAlamat";
import PilihAlamat from "./pilihAlamat";
import { loadPricingRecords } from "../../../../utils/pricing";

export default function DetailProdMin({ prodId }) {
  const params = useParams();
  const classes = useStyles();
  const {
    company_id,
    pricing_procedure,
    pricing_conditions,
    dataMitraCurrent,
    subdomaintList,
  } = useData();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const prodDoc = firestore.doc(`products/${params.productId}`);

  const upline = dataMitraCurrent?.ref_3
    ? dataMitraCurrent?.ref_3?.mitra_id
    : dataMitraCurrent?.ref_2
    ? dataMitraCurrent?.ref_2?.mitra_id
    : dataMitraCurrent?.ref_1?.mitra_id;
  const tokoDoc = firestore
    .collection(`mitra/${upline}/sellers_webstore`)
    .doc(upline);

  const productsStockCollection = firestore
    .collection(`mitra/${upline}/products_stock`)
    .where("stok_available", "!=", 0);

  const [userToko, loadingToko] = useDocumentData(tokoDoc);
  const [products_stock] = useCollectionData(productsStockCollection, {
    idField: "id",
  });

  const [prodSnapshot, prodLoading] = useDocument(prodDoc);

  const [prodForm, setProdForm] = useState();
  // const [errorProd, setErrorProd] = useState({});

  useEffect(() => {
    if (prodSnapshot) {
      const data = prodSnapshot.data();
      setProdForm({
        ...data,
      });
    }
  }, [prodSnapshot]);
  // console.log(prodSnapshot?.id)

  const [form, setForm] = useState();
  // console.log(form);
  const [error, setError] = useState({});

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(e.target.type);

    setForm({
      ...form,
      [e.target.name]: value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // resi marketplace
  const [state, setState] = React.useState(false);

  const handleChangeSwicth = (event) => {
    setState(event.target.checked);
  };
  const pilihKurir = ["JNE", "JNT", "SICEPAT", "PICKUP"];

  //   variants
  const varCol = prodDoc.collection(`variants`);

  const [varData, loadingVar] = useCollectionData(varCol, {
    idField: "id",
  });

  let upVariant = [];
  for (let i = 0; i < products_stock?.length; i++) {
    const element = products_stock[i].variantId;
    upVariant.push(element);
  }
  const filVarData = varData?.filter((val) =>
    upVariant.includes(val?.variant_id)
  );

  const prodStokQuery = firestore
    .collectionGroup(`products_stock`)
    .where("variantId", "==", form?.variant2?.id || "")
    .where("productId", "==", params.productId);
  const prodIdSet = [
    ...new Map(
      filVarData?.map?.((item) => [item?.variant1?.nilai, item])
    )?.values(),
  ];
  const [stokData, loadingStok, err] = useCollectionData(prodStokQuery);
  const mitra = stokData?.filter((stok) => {
    if (stok?.mitraId !== company_id) {
      if (stok?.stok_available > 0) {
        return stok;
      }
    }
  });
  const arrayMitra = mitra?.length;
  // const [loopingMitra, setLoopMitra] = useState()
  let mitraToko = [];
  for (var i = 0; i < arrayMitra; i++) {
    const key = i;
    const mitraSellerDoc = firestore.doc(`mitra/${mitra?.[i]?.mitraId}`);
    mitraSellerDoc
      .get()
      .then((doc) => {
        if (doc.exists) {
          const stokBarang = mitra?.[key]?.stok_available;

          const loopMitra = { ...doc.data(), stokBarang };
          if (loopMitra?.mitra_level <= dataMitraCurrent?.mitra_level) {
            const fill = loopMitra.mitra_id === upline && loopMitra;
            fill && mitraToko.push(fill);
          }
          // console.log("Document data:", doc.data());
          // setLoopMitra(doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    // const [dataMitraSeller] = useDocument(mitraSellerDoc)
    // mitraToko.push(
    //     dataMitraSeller
    // )
  }
  const varId = mitra?.map((stok) => {
    return stok?.variantId;
  });

  // const mitraFix = mitraId ?? [0]
  const varDoc = varCol?.doc(`${varId?.[0]}`);

  const [varSnapshot, varLoading] = useDocument(varDoc);

  const [varForm, setVarForm] = useState();
  useEffect(() => {
    if (varSnapshot) {
      const data = varSnapshot.data();
      setVarForm({
        ...data,
      });
    }
  }, [varSnapshot]);
  const var1 = varData?.map((var1) => {
    return var1?.variant1?.label;
  });
  // variat2
  const var2Col = prodDoc
    .collection(`variants`)
    .where(
      "variant1.nilai",
      "==",
      form?.variant1?.variant1?.nilai || "variant1"
    );

  const [var2Data, loadingVar2] = useCollectionData(var2Col, {
    idField: "id",
  });
  const var2 = varData?.map((var1) => {
    return var1?.variant2?.label;
  });
  const fillVar2 = var2Data?.filter((val) =>
    upVariant.includes(val?.variant_id)
  );
  // mitraCol
  const mitraCol = firestore
    .collection(`mitra`)
    .where("mitraId", "==", form?.toko?.mitraId || form?.toko?.mitra_id || "");

  const [mitraData, loadingMitra] = useCollectionData(mitraCol, {
    idField: "id",
  });
  const mitraDoc = mitraData?.map((mit) => {
    return mit;
  });
  // pricing
  const [hargaPricing, setHargaPricing] = useState();
  useEffect(() => {
    try {
      async function CekPricing() {
        if (form?.variant2 && mitraDoc?.[0]?.mitra_level) {
          const loadRecords = functions.httpsCallable("pricing-loadRecords");

          const body = JSON.stringify({
            item: {
              products: prodForm,
              variants: [form?.variant2],
              id: prodForm?.id,
            },
            pricing_procedure,
            pricing_conditions,
            level: {
              buyer_level: dataMitraCurrent?.mitra_level,
              level: mitraDoc?.[0]?.mitra_level,
            },
            profil: { id: company_id },
            location: "mitra",
          });

          const result = await loadRecords({
            body,
          });
          // console.log(result)
          setHargaPricing(result);
          return result?.data ?? {};
        }
      }
      CekPricing();
    } catch (e) {}
  }, [form?.variant2, mitraDoc?.[0]?.mitra_level]);
  // let hargaLevel = {}
  // if (form?.variant2) {

  //     <loadPricingRecords id={prodForm} variants={form?.variant2} pricing_procedure={pricing_procedure}
  //         pricing_conditions={pricing_conditions} level={dataMitraCurrent?.level_mitra} profil={company_id} />
  // }
  // console.log(hargaPricing?.data?.priceslocal?.[0])
  // addAlamat
  const [dialog, setDialog] = useState({
    open: false,
    data: {},
  });

  // Pilih Alamat
  const [selectAlamatDialog, setSeletAlamatDialog] = useState({
    open: false,
    dala: {},
  });

  // sudomai
  // mitraCol
  const subdomainCol = firestore
    .collection(`subdomains`)
    .where("mitra_id", "==", company_id || "");

  const [subdomainData, loadingSubdomain] = useCollectionData(subdomainCol, {
    idField: "id",
  });
  const subdomain = subdomainData?.map((mit) => {
    return mit?.mitra_unique_id;
  });

  const subdomainSellerCol = firestore
    .collection(`subdomains`)
    .where("mitra_id", "==", form?.toko?.mitraId || form?.toko?.mitra_id || "");

  const [subdomainSellerData, loadingSellerSubdomain] = useCollectionData(
    subdomainSellerCol,
    {
      idField: "id",
    }
  );
  const subdomainSeller = subdomainSellerData?.map((mit) => {
    return mit?.mitra_unique_id;
  });

  // setJumlahOver
  // const stokTersedia = form?.toko?.stok_available - form?.toko?.stok_booked
  // console.log('toko', form?.toko?.mitraId);
  // jumlah
  const [jumlah, setJumlah] = useState(1);

  // debounce harga
  const debHarga = hargaPricing?.data?.priceslocal?.[0] * jumlah;
  const [debouncedHarga] = useDebounce(debHarga, 1000);
  const [debouncedJumlah] = useDebounce(jumlah, 1000);
  // open menu alamat
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // change alamat
  const [alamat, setalamat] = useState();
  const handleChangealamat = (event) => {
    setalamat({
      ...alamat,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(`${params?.productId}_${varId?.[0]}`, varId?.[0])
  const [isSubmitting, setSubmitting] = useState(false);
  const hargaTagihan = hargaPricing?.data?.priceslocal?.[0] * jumlah;

  const handleSimpan = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const purchaseDoc = firestore
        .collection(`purchase_orders`)
        .doc(company_id);

      if (state === false) {
        await purchaseDoc.set({
          seller_mitra: { ...mitraDoc?.[0], mitra_unique_id: subdomainSeller },
          subcarts: {
            [alamat?.alamat?.id ? alamat?.alamat?.id : "alamat"]: {
              berat_total: form?.variant2?.berat_paket * jumlah,
              height: form?.variant2?.tinggi_paket,
              length: form?.variant2?.panjang_paket,
              width: form?.variant2?.lebar_paket,
              total_pembayaran:
                hargaTagihan + ongkir?.pengiriman?.finalRate ||
                hargaTagihan + ongkir?.[0]?.data?.[0]?.finalRate,
              total_tagihan: hargaTagihan,
              products: {
                [`${params?.productId}_${varId?.[0]}`]: {
                  created_at: FieldValue.serverTimestamp(),
                  jumlah: jumlah,
                  nama: prodForm?.nama,
                  product: prodForm?.product_id,
                  variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`,
                  promo: false,
                  harga: hargaTagihan,
                  berat: form?.variant2?.berat_paket * jumlah,
                },
              },
              pengiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
              biaya_pengiriman:
                ongkir?.pengiriman?.finalRate ||
                ongkir?.[0]?.data?.[0]?.finalRate,
              biaya_pengiriman_before:
                ongkir?.pengiriman?.finalRate ||
                ongkir?.[0]?.data?.[0]?.finalRate,
            },
          },
          kode_po: `p_${subdomainSeller}_${subdomain?.[0]}_${Date.now()}`,
          created_at: FieldValue.serverTimestamp(),
          updated_at: FieldValue.serverTimestamp(),
        });
      } else if (state === true) {
        await purchaseDoc.set({
          seller_mitra: { ...mitraDoc?.[0], mitra_unique_id: subdomainSeller },
          subcarts: {
            [alamat?.alamat?.id ? alamat?.alamat?.id : "alamat"]: {
              berat_total: form?.variant2?.berat_paket * jumlah,
              height: form?.variant2?.tinggi_paket,
              length: form?.variant2?.panjang_paket,
              width: form?.variant2?.lebar_paket,
              total_pembayaran: hargaTagihan,
              total_tagihan: hargaTagihan,
              products: {
                [`${params?.productId}_${varId?.[0]}`]: {
                  created_at: FieldValue.serverTimestamp(),
                  jumlah: jumlah,
                  nama: prodForm?.nama,
                  product: prodForm?.product_id,
                  variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`,
                  promo: false,
                  harga: hargaTagihan,
                  berat: form?.variant2?.berat_paket * jumlah,
                },
              },

              biaya_pengiriman: 0,
              biaya_pengiriman_before: 0,
              resi_otomatis_mp: true,
              resi_otomatis_mp_kurir: form?.kurir,
              resi_otomatis_mp_resi: form?.resi,
            },
          },
          kode_po: `p_${subdomainSeller}_${subdomain?.[0]}_${Date.now()}`,
          created_at: FieldValue.serverTimestamp(),
          updated_at: FieldValue.serverTimestamp(),
        });
      }
      enqueueSnackbar("PO Berhasil Ditambahkan", {
        variant: "success",
      });
      history.replace("/cart");
    } catch (e) {
      setError(e.message);
    }
    setSubmitting(false);
  };
  // mitra_asal
  // mitra_asal
  const mitraAsalDoc = firestore.doc(
    `mitra/${
      form?.toko?.mitraId || form?.toko?.mitra_id || "mitra_asal"
    }/settings/alamat`
  );
  const [mitraSnapshot] = useDocument(mitraAsalDoc);

  const [mitraAsal, setMitraAsal] = useState();

  useEffect(() => {
    if (mitraSnapshot) {
      const data = mitraSnapshot.data();
      setMitraAsal({
        ...data,
      });
    }
  }, [mitraSnapshot]);

  // const mitra_asal = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
  // kurir
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {},
    uid: {},
  });

  // cek-ongkir
  const destinationDoc = firestore.doc(`mitra/${company_id}/settings/alamat`);
  const [settSnapshotDest] = useDocument(destinationDoc);

  const [formDest, setFormDest] = useState();

  useEffect(() => {
    if (settSnapshotDest) {
      const data = settSnapshotDest.data();
      setFormDest({
        ...data,
      });
    }
  }, [settSnapshotDest]);

  // console.log('data', form?.variant2?.berat_paket * jumlah)
  const [ongkir, setOngkir] = useState();
  const handleChangeOngkir = (event) => {
    setOngkir({
      ...ongkir,
      [event.target.name]: event.target.value,
    });
  };

  const [kurir_regular, setKurir_regular] = useState({});
  const provider_detail = alamat
    ? alamat?.alamat?.[alamat?.alamat?.provider] ?? {}
    : formDest?.[formDest?.provider] ?? {};

  useEffect(() => {
    if (
      form?.toko &&
      debouncedJumlah &&
      formDest &&
      form?.variant2 &&
      form?.variant2?.berat_paket &&
      hargaPricing?.data?.priceslocal?.[0] &&
      form?.variant2?.panjang_paket &&
      form?.variant2?.lebar_paket &&
      form?.variant2?.tinggi_paket &&
      mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id &&
      formDest?.[formDest?.provider] &&
      state === false
    ) {
      setSubmitting(true);
      async function cekOngkir() {
        try {
          const getOngkir = functions.httpsCallable("shipping-ongkir");
          let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;

          let destination = provider_detail?.kecamatan?.id;

          if (mitraAsal?.provider === "directapi") {
            origin = {};
            destination = {};
            let origin_sicepat = mitraAsal?.[
              mitraAsal?.provider
            ]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
            let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

            if (
              origin_sicepat &&
              destination_sicepat &&
              mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat
            ) {
              origin.sicepat = origin_sicepat;
              destination.sicepat = destination_sicepat;
            }
            let origin_jne =
              mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
            let destination_jne =
              provider_detail?.kelurahan?.kode_destination_jne;
            if (
              origin_jne &&
              destination_jne &&
              mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne
            ) {
              origin.jne = origin_jne;
              destination.jne = destination_jne;
            }
          }

          // const destination = alamat?.kecamatan?.id
          // const origin = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
          // const destinationCoord = alamat?.koordinat ? `${alamat.koordinat?.latitude}, ${alamat.koordinat?.longitude}` : null;
          const weight = form?.variant2?.berat_paket * debouncedJumlah;
          const price = hargaPricing?.data?.priceslocal?.[0] * debouncedJumlah;
          const length = form?.variant2?.panjang_paket;
          const width = form?.variant2?.lebar_paket;
          const height = form?.variant2?.tinggi_paket;
          // const mitra_tujuan = form?.[form?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)

          let sub = {
            id_asal: origin,
            id_tujuan: destination,
            berat: weight,
            panjang: length,
            lebar: width,
            tinggi: height,
            harga: price,
            cod: 0,
            type: 2,
          };
          const response = await getOngkir(sub);

          let data = {};
          switch (response.data.provider) {
            case "directapi":
              data = Object.entries(response.data.items)
                .map(([key, value]) => {
                  return {
                    title: key,
                    data: value?.map?.((item) => {
                      if (key === "sicepat") {
                        return {
                          provider: "sicepat",
                          ...item,
                          name: "SiCepat",
                          rate_name: item.service,
                          rate_id: item.service,
                          finalRate: item.tariff,
                          kurir: "sicepat",
                          tipe: item.service,
                        };
                      } else if (key === "jne") {
                        return {
                          provider: "jne",
                          ...item,
                          name: "JNE",
                          service: item.service_display,
                          rate_id: item.service_code,
                          rate_name: item.service_display,
                          finalRate: parseInt(item.price),
                          kurir: "jne",
                          tipe: item.service_code,
                        };
                      }
                    }),
                  };
                })
                .filter((item) => item.data.length > 0);
              // data = response.data.items.map(item => {

              //   return {
              //     title: item.description,
              //     data: [
              //       {
              //         ...item,
              //         name: item.description,
              //         rate_name: item.service,
              //         rate_id: item.service,
              //         finalRate: item.tariff
              //       }
              //     ]
              //   }
              // })
              break;
            case "rajaongkir":
              data = response.data.items
                .map((item) => {
                  return {
                    title: item.name,
                    data: item.costs.map((cost) => {
                      return {
                        ...cost,
                        rate_id: `${item.code}-${cost.service}`,
                        finalRate: cost?.cost?.[0]?.value ?? 0,
                        name: item.name,
                        rate_name: cost.service,
                      };
                    }),
                  };
                })
                .filter((item) => item.data.length > 0);

              break;
            case "shipper":
              if (kurir_regular) {
                data = Object.entries(kurir_regular)
                  .map(([key, value]) => {
                    return {
                      title: key,
                      data: value,
                    };
                  })
                  .filter((item) => item.data.length > 0);
              }
              break;
            default:
          }
          // console.log('respo', response?.data);
          setKurir_regular(data);
          setOngkir(data);

          setSubmitting(false);
        } catch (e) {
          console.log(e.message);
        }
      }
      cekOngkir();
    }
  }, [
    debouncedJumlah,
    form?.toko,
    alamat?.alamat?.[alamat?.alamat?.provider],
    formDest,
    form?.variant2,
    JSON.stringify(mitraAsal),
    JSON.stringify(kurir_regular),
    hargaPricing,
    state,
  ]);

  if (prodLoading || loadingVar) {
    return <PageLoading />;
  }
  return (
    <>
      <Grid container className={classes.con}>
        <Grid item xs={12} className={classes.pageTitleA}>
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackIcon />
          </Button>
          <Typography
            className={classes.pageTitleB}
            component="h1"
            variant="h6"
          >
            Product Detail
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.carousel2}>
            <Carousel
              renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                currentSlide !== 0 ? (
                  <IconButton size="small" onClick={previousSlide}>
                    <NavigateBefore fontSize="large" />
                  </IconButton>
                ) : null
              }
              renderCenterRightControls={({
                nextSlide,
                slideCount,
                currentSlide,
              }) =>
                currentSlide !== slideCount - 1 ? (
                  <IconButton size="small" onClick={nextSlide}>
                    <NavigateNext fontSize="large" />
                  </IconButton>
                ) : null
              }
            >
              {prodForm?.thumbnail?.map?.((thum, i) => {
                const position = thum?.lastIndexOf?.(".");
                const thumbnail300 =
                  thum?.substring(0, position) +
                  "_300x300" +
                  thum?.substring(position, position?.length);
                return (
                  <img src={thumbnail300} alt="" className={classes.carousel} />
                );
              })}
            </Carousel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.margin3}>
            <Typography variant="h6" component="h1">
              {prodForm?.nama}{" "}
            </Typography>
            {varForm?.harga_normal ? (
              <Typography variant="h6" color="error" component="h1">
                {currency(
                  hargaPricing?.data?.priceslocal?.[0]
                    ? hargaPricing?.data?.priceslocal?.[0]
                    : varForm?.harga_normal
                )}
              </Typography>
            ) : (
              <HargaVar prodId={prodSnapshot?.id} file="detailProduct" />
            )}
          </div>
          <Typography variant="subtitle1" className={classes.margin4}>
            {prodForm?.deskripsi_singkat}
          </Typography>
          <Typography variant="subtitle2">
            {prodForm?.deskripsi_lengkap}
          </Typography>

          <Grid container spacing={2} className={classes.margin2}>
            <Grid item xs={4}>
              <Autocomplete
                id="variant1"
                name="variant1"
                margin="normal"
                required
                value={form?.variant1 || ""}
                onChange={(event, value) => {
                  handleChange({ target: { name: "variant1", value } });
                }}
                disabled={loadingVar}
                options={prodIdSet || ""}
                getOptionLabel={(option) => option?.variant1?.nilai || ""}
                renderInput={(params) => (
                  <TextField
                    helperText={error?.variant1}
                    error={error?.variant1 ? true : false}
                    {...params}
                    label={var1?.[0]}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="variant2"
                name="variant2"
                margin="normal"
                required
                value={form?.variant2 || ""}
                onChange={(event, value) => {
                  handleChange({ target: { name: "variant2", value } });
                }}
                disabled={loadingVar || loadingVar2}
                options={fillVar2 || ""}
                getOptionLabel={(option) => option?.variant2?.nilai || ""}
                renderInput={(params) => (
                  <TextField
                    helperText={error?.variant2}
                    error={error?.variant2 ? true : false}
                    {...params}
                    label={var2?.[0]}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="toko"
                name="toko"
                margin="normal"
                required
                value={form?.toko || ""}
                onChange={(event, value) => {
                  handleChange({ target: { name: "toko", value } });
                }}
                disabled={loadingVar}
                options={mitraToko ?? []}
                getOptionLabel={(option) => option?.nama || ""}
                renderOption={(option) => (
                  <React.Fragment>
                    {option?.nama}, Stok: {option?.stokBarang}
                    {/* <MitraData opt={true} stok={option?.stok_available} mitraId={option?.mitraId} level={dataMitraCurrent?.mitra_level} /> */}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    helperText={error?.toko}
                    error={error?.toko ? true : false}
                    {...params}
                    label="Pilih Toko"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingStok || varLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {form?.toko && (
              <Grid item xs={4}>
                <FormControl>
                  <OutlinedInput
                    variant="outlined"
                    value={jumlah}
                    InputProps={{
                      readOnly: true,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={
                            !form?.toko || jumlah >= form?.toko?.stokBarang
                          }
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setJumlah((jumlah) => jumlah + 1);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setJumlah((jumlah) =>
                              jumlah === 1 ? jumlah : jumlah - 1
                            );
                          }}
                          disabled={jumlah === 1}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {
              hargaPricing?.data?.priceslocal?.[0] && (
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end"
                  }}
                >
                  <Typography
                    style={{ float: "right" }}
                    variant="body1"
                    color="error"
                    component="h1"
                  >
                    Total Harga : {currency(debouncedHarga)}
                  </Typography>
                </Grid>
              )
              // :
              // <Typography style={{ float: 'right' }}>
              //     <HargaVar
              //         prodId={prodSnapshot?.id}
              //     />
              // </Typography>
            }

            {form?.toko && (
              <Grid item xs={12}>
                <div className={classes.jp1}>
                  <Typography>Alamat Pengirim</Typography>
                  <Typography>Nama Toko : {form?.toko?.nama}</Typography>
                  <Typography>
                    Alamat : {mitraAsal?.[mitraAsal?.provider]?.alamat_lengkap}-
                    {mitraAsal?.[mitraAsal?.provider]?.kelurahan?.name}
                  </Typography>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid className={classes.jp1} container>
                <Grid item xs={11}>
                  <Typography>Alamat Tujuan</Typography>
                  {/* <MitraData opt={false} mitraId={form?.toko?.mitraId} /> */}
                  <Typography>
                    {alamat
                      ? alamat?.alamat?.[alamat?.alamat?.provider]
                          ?.alamat_lengkap
                      : provider_detail?.kecamatan?.id}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    // style={{ float: 'right' }}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        setDialog((dialog) => ({
                          ...dialog,
                          // dataProd: form?.variant2,
                          open: true,
                          // mitra_id: company_id,
                          // mitra_asal: mitraAsal,
                          // jumlah: jumlah,
                          // formKurir: ongkir,
                          // isSubmitting: { isSubmitting }
                        }));
                      }}
                    >
                      Tambah Alamat
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setSeletAlamatDialog((dialogAlamat) => ({
                          ...dialogAlamat,
                          // dataProd: form?.variant2,
                          open: true,
                          // mitra_id: company_id,
                          // mitra_asal: mitraAsal,
                          // jumlah: jumlah,
                          // formKurir: ongkir,
                          // isSubmitting: { isSubmitting }
                        }));
                      }}
                    >
                      Pilih Alamat
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography>Resi Otomatis Marketplace</Typography>
              <Switch
                checked={state}
                onChange={handleChangeSwicth}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              {state === true && (
                <Grid container>
                  <Grid item xs={6}>
                    <Autocomplete
                      style={{ marginRight: 16 }}
                      id="kurir"
                      name="kurir"
                      margin="normal"
                      required
                      value={form?.kurir || ""}
                      onChange={(event, value) => {
                        handleChange({ target: { name: "kurir", value } });
                      }}
                      disabled={loadingVar || loadingVar2}
                      options={pilihKurir || ""}
                      // getOptionLabel={(option) => option?.kurir?.nilai || ''}
                      renderInput={(params) => (
                        <TextField
                          helperText={error?.kurir}
                          error={error?.kurir ? true : false}
                          {...params}
                          label="Kurir"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // style={{ marginLeft: 16 }}
                      // margin="normal"
                      variant="outlined"
                      label="No Resi"
                      id="resi"
                      name="resi"
                      value={form?.resi}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            </Grid> */}
            <Grid item xs={12}>
              {ongkir && state === false ? (
                <Grid
                  container
                  className={classes.jp1}
                  onClick={(event) => {
                    setEditDialog((editDialog) => ({
                      ...editDialog,
                      dataProd: form?.variant2,
                      open: true,
                      mitra_id: company_id,
                      mitra_asal: mitraAsal,
                      jumlah: jumlah,
                      formKurir: ongkir,
                      isSubmitting: { isSubmitting },
                    }));
                  }}
                >
                  <Typography style={{ marginBottom: 10 }} variant="body2">
                    Jasa Pengiriman
                  </Typography>
                  <Grid item xs={11}>
                    {ongkir?.pengiriman?.name ? (
                      <>
                        <Typography>
                          {ongkir?.pengiriman?.name || ""}-
                          {ongkir?.pengiriman?.description || ""}(
                          {ongkir?.pengiriman?.service || ""})
                        </Typography>
                        <Typography>
                          Biaya : {currency(ongkir?.pengiriman?.finalRate)}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography>
                          {ongkir?.[0]?.data?.[0]?.name || ""}-
                          {ongkir?.[0]?.data?.[0]?.description || ""}(
                          {ongkir?.[0]?.data?.[0]?.service || ""})
                        </Typography>
                        <Typography>
                          Biaya : {currency(ongkir?.[0]?.data?.[0]?.finalRate)}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      <MoreVert />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : isSubmitting ? (
                <div className={classes.jp2}>
                  <div className={classes.jp3}>
                    <CircularProgress size={15} />
                    <Typography className={classes.ltx}>Loading...</Typography>
                  </div>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!form?.toko || !ongkir}
                fullWidth
                onClick={handleSimpan}
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<AddShoppingCartIcon />}
              >
                Tambah Ke Keranjang
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid>
            
            {
                hargaPricing?.data?.priceslocal?.[0] ?
                    <Typography style={{ float: 'right' }} variant="h6" color="error" component="h1">{currency(debouncedHarga)}</Typography> :
                    <Typography style={{ float: 'right' }}>
                        <HargaVar
                            prodId={prodSnapshot?.id}
                        />
                    </Typography>
            }

        </Grid> */}
      <CekOngkir
        editDialog={editDialog}
        handleClose={() => {
          setEditDialog({ open: false, dataSub: {} });
        }}
        handleEditKurir={handleChangeOngkir}
        kurir_regular={kurir_regular}
      />
      <AddAlamat
        dialog={dialog}
        handleClose={() => {
          setDialog({ open: false, data: {} });
        }}
      />
      <PilihAlamat
        dialogAlamat={selectAlamatDialog}
        handleClose={() => {
          setSeletAlamatDialog({ open: false, data: {} });
        }}
        handleChangealamat={handleChangealamat}
        alamatdata={alamat}
      />
      {/* <Fab
            color='secondary'
            disabled={!form?.toko || loadingMitra || isSubmitting || loadingSubdomain}
            className={classes.fab1}
            onClick={handleSimpan}
        >
            <AddShoppingCartIcon />
        </Fab> */}
    </>
  );
}
