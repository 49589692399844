import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    //cart.js
    transaction: {
        border: '1px solid #cd3478',
        borderRadius: '5px',
        padding: '5px',
        marginBottom: 16,
        backgroundColor : '#f9f9f9f9',
        boxShadow: 5,
        paddingLeft : 20,
        paddingRight : 5,
    },
    pathTransaktion : {
        marginTop : 5
    },
    transactionItems: {
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 5,
        '& span': {
            marginRight: 5
        },
        fontWeight : 'bold',
    },
    isEmpty: {
        marginTop: 100,
        textAlign: "center",
    },
    container: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(4),
        borderRadius: 10,
        overflow: 'hidden'
    },
    kode: {
        padding : theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    iconBck: {
        color: 'white',
        marginRight: 10,
    },
    subContainer: {
        padding: theme.spacing(4),
        paddingBottom: 0,
    },
    btn: {
        padding: theme.spacing(4),
    },
    gt: {
        color: theme.palette.primary.main,
    },

    // prodMitra.js
    pageTitleA: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: 5
    },
    pageTitleB: {
        marginLeft: '20px',
    },
    sliderWrap: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
    },
    pagination: {
        '& > *': {
            display: 'flex',
            marginTop: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center'
        },
    },

    // dataProdMitra.js
    cardProd: {
        margin: theme.spacing(1),
    },
    cardAct: {
        padding: theme.spacing(2)
    },
    image: {
        borderRadius: '5px',
    },

    // detailProdMitra.js
    con: {
        padding: 30
    },
    pageTitle: {
        paddingBottom: '20px',
        marginLeft: '10px',
    },
    carousel: {
        width: 480,
        borderRadius: '10px'
    },
    carousel2: {
        paddingRight: 30,
    },
    margin3: {
        marginBottom: theme.spacing(2),
    },
    margin4: {
        marginBottom: theme.spacing(0.5),
    },
    margin2: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    jp1: {
        border: '2px solid #cd3478',
        borderRadius: '5px',
        padding: '13px',
        cursor: 'pointer',
    },
    jp2: {
        border: '1px solid #bbb',
        borderRadius: '5px',
        padding: '13px',
        color: '#7d7d7d'
    },
    jp3: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    ltx: {
        color: theme.palette.primary.main,
        marginLeft: '10px',
    },


}));

export default useStyles;
