// Material UI
import InputBase from "@material-ui/core/InputBase";

// Icon's
import SearchIcon from "@material-ui/icons/Search";

// Styles
import useStyles from "../components/loading/appLoading/styles";

// Components
// import { Gap } from "../../atoms";

//
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

function Header({ placeholder, value, onChange, onClick }) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: "#000",
        boxShadow: "0px 15px 10px -15px rgba(0,0,0,0.3)",
      }}
    >
      <IconButton size="small" onClick={onClick}>
        <ArrowBack className={classes.icon} />
      </IconButton>
      {/* <Gap width={16} /> */}
      <div className={classes.searchBox}>
        <SearchIcon className={classes.icSearch} />
        {/* <Gap width={10} /> */}
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          inputProps={{ "aria-label": placeholder }}
          fullWidth
        />
      </div>
      {/* <Gap width={16} /> */}
    </div>
  );
}

export default Header;
