import React, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { firestore, FieldValue } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import Virtualize from '../../../components/Virtualization';


function EditProductStock({ open, handleClose , ...openAddStock}) {
    const { company_id } = useData();
    useEffect(() => {
        if (openAddStock){
            setForm({
                stok_available : openAddStock?.openAddStock?.data?.stok_available,
                variantId : openAddStock?.openAddStock?.data?.variantId,
                product : openAddStock?.openAddStock?.data?.productId,
            })
        }
    },[openAddStock?.openAddStock?.data])

    const [form, setForm] = useState({
        product: '',
        variantId: '',
        stok_available: '',
        stok_booked: ''

    });
    const [error, setError] = useState({
        product: '',
        variantId: '',
        stok_available: '',
        stok_booked: ''

    });
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        let value = e.target.value;
        console.log(e.target.value);
        setForm({
            ...form,
            [e.target.name]: value,
        });
        setError({
            ...error,
            [e.target.name]: "",
        });
    }
    
    // // sellers_webstore
    // const webstoreDoc = firestore.doc(`mitra/${company_id}/sellers_webstore/${company_id}`);
    // const [dataWebs, loadDataWebs] = useDocumentData(webstoreDoc)

    const validate = async () => {
        const newError = { ...error };
        if (!form.stok_available) {
            newError.stok_available = 'stok_available harus diisi';
        }
        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();
        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const prodCol = firestore.collection(`mitra/${company_id}/products_stock`);
                await prodCol.doc(`${form?.product}_${form?.variantId}`).set({
                    // ...form,
                    // productId: form?.product?.id,
                    // stok_booked: form.stok_booked || 0,
                    // variantId: form?.variantId?.id,
                    // mitraId: company_id,
                    // created_at: FieldValue.serverTimestamp(),
                    // updated_at: FieldValue.serverTimestamp(),
                    // sku: form?.product?.id + form?.variantId?.id
                    stok_available: Number(form.stok_available) || 0,
                }, {merge : true});
                // // if(dataWebs?.products.filter((data) => {
                // //     return data === form?.product?.id
                // // }))
                // const filterProd = dataWebs?.products.find((data) => {
                //     return data === form?.product?.id
                // })
                // if (!filterProd) {
                //     await webstoreDoc.set({
                //         products: [...(dataWebs?.products ?? []), form?.product?.id]
                //     }, { merge: true })
                // }
                enqueueSnackbar('Edit Stock Berhasil', { variant: "success" })
                handleClose()

            } catch (e) {
                const newError = {};
                enqueueSnackbar('Edit Stock Gagal', { variant: "error" })
                newError.title = e.message;
                setError(newError);
            }
            setSubmitting(false)
        }
    }
    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Edit Stok Produk</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="stok_available"
                            name="stok_available"
                            label="Tersedia"
                            value={form?.stok_available}
                            onChange={handleChange}
                            error={error.stok_available ? true : false}
                            helperText={error.stok_available}
                            fullWidth
                            type='number'
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Simpan
                </Button>
            </DialogActions>
        </Dialog>
    )
}

EditProductStock.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default EditProductStock;
