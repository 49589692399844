import React, { useEffect, useState, useCallback } from "react";
import TabPanel from "../../../components/TabPanel";
import {
  FieldValue,
  firestore,
} from "../../../components/FirebaseProvider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import { lowerCase } from "../../../utils/text";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import { customAlphabet } from "nanoid/non-secure";
import { useData } from "../../../components/DataProvider";
import { useDocument } from 'react-firebase-hooks/firestore';
import { IconButton, Typography } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { FileCopy, WhatsApp } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
export default function Subdomain({ value }) {
  const nanoid = customAlphabet(
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    4
  );

  const { enqueueSnackbar } = useSnackbar();
  const { company_id, subdomaintList } = useData();
  const classes = useStyles();

  const [subdomain, setSubdomain] = useState("");
  const [subDis, setSubdis] = useState(false);
  const [infoMitra, setInfoMitra] = useState({ name: '', mitra_level: 1, nama_level: '', telepon: '', alamat: '' })
  // console.log(infoMitra)
  const subdomainRef = firestore.collection(`subdomains`);
  //.where("mitra_id", "==", company_id);

  const compDoc = firestore.doc(`mitra/${company_id}`);
  const [compSnapshot, compLoading] = useDocument(compDoc);

  const directApiAddres = compDoc.collection('settings').doc('alamat')
  const [directAddress , loadingAddres] = useDocument(directApiAddres)

  const levelSettingDoc = firestore.doc(`settings/sellers_level`);
  const [levelSetting, loadingLevelSetting] = useDocument(levelSettingDoc);

  const sellersRef = firestore.collection(`mitra/${company_id}/sellers`);
  const sellersLevelRef = firestore.collection(`mitra/${company_id}/sellers_level`);
  //const sellersLoginRef = firestore.collection(`mitra/${company_id}/sellers_login`);
  const sellersWebstoreRef = firestore.collection(`mitra/${company_id}/sellers_webstore`);
  const sellersWebstorePathRef = firestore.collection(`mitra/${company_id}/sellers_webstore_path`);



  //const subDomainRef = firestore.collection('subdomains')
  const [errors, setErrors] = useState({});
  const checkSubdomain = useCallback(async () => {
    const x = await subdomainRef.doc(lowerCase(subdomain)).get();
    return x.exists;
  });

  const validate = async () => {
    let newErrors = {};

    if (subdomain === "") {
      newErrors.subdomain = "Subdomain wajib diisi";
    } else {
      if (subdomain.length < 3) {
        newErrors.subdomain = "subdomain minimal 3 karakter";
      } else if (subdomain.length > 22) {
        newErrors.subdomain = "subdomain maximal 21 karakter";
      } else if (!isAlphanumeric(subdomain)) {
        newErrors.subdomain =
          "Subdomain hanya boleh terdiri dari huruf dan angka";
      } else if (await checkSubdomain()) {
        newErrors.subdomain = "Subdomain sudah digunakan, gunakan nama lain";
      }
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSave = useCallback(async () => {
    const check = await validate();
    if (!check.subdomain) {
      if (
        window.confirm(`Yakin Ingin menggunakan nama subdomain ${subdomain}? , dan subdomain tidak dapat di ubah lagi setelah berhasil di simpan`)
      ) {
        let mitra_unique_id = "";

        //<<<=== Company Unique ID Logic =====>>>
        //jika text subdomain < 6, gunakan subdomain sebagai company unique id
        //jika lebih, company unique id adalah 2 karakter pertama subdomain + 4 karakter random dari nanoid()

        if (subdomain.length > 6) {
          mitra_unique_id = subdomain.substring(0, 2) + nanoid();
        } else {
          mitra_unique_id = subdomain;
        }

        await subdomainRef.doc(lowerCase(subdomain)).set(
          {
            mitra_unique_id: mitra_unique_id,
            created_at: FieldValue.serverTimestamp(),
            user_id: company_id,
            mitra_id: company_id
          },
          { merge: true }
        );

        //set default sellers doc
        await sellersRef.doc(company_id).set(
          {
            created_at: FieldValue.serverTimestamp(),
            nama: infoMitra.name,
            agreed: true,
            level: infoMitra.mitra_level,
            kode_referral: '-'
          },
          { merge: true }
        );

        //set default sellers_level doc
        await sellersLevelRef.doc(company_id).set(
          {
            is_active: true,
            level: infoMitra.mitra_level,
            level_custom: infoMitra.nama_level,
            sellerId: 'mitra'
          },
          { merge: true }
        );

        //set default sellers_webstore
        await sellersWebstoreRef.doc(company_id).set(
          {
            created_at: FieldValue.serverTimestamp(),
            nama: infoMitra.name,
            path: 'mitra',
            products: [],
            wa: infoMitra.telepon
          },
          { merge: true }
        );

        //set default webstore path milik mitra
        await sellersWebstorePathRef.doc('mitra').set(
          {
            created_at: FieldValue.serverTimestamp(),
            user_id: company_id
          },
          { merge: true }
        );

        enqueueSnackbar("Subdomain Berhasil Disimpan", { variant: "success" });
        setSubdis(true);
      }
    }
  });

  const handleChange = (e) => {
    setSubdomain(e.target.value);
  };

  useEffect(() => {
    if (compSnapshot) {
      const data = compSnapshot.data()
      setInfoMitra({
        ...infoMitra,
        name: data.nama,
        mitra_level: data.mitra_level,
        telepon: data.telepon,
        alamat: data.alamat
      })
    }

  }, [compSnapshot]);

  useEffect(() => {
    if (levelSetting) {
      const data = levelSetting.data()
      setInfoMitra({
        ...infoMitra,
        nama_level: data?.custom_name[infoMitra.mitra_level]
      })
    }

  }, [levelSetting]);

  useEffect(() => {
    if (subdomainRef) {
      subdomainRef
        .where("mitra_id", "==", company_id)
        .get()
        .then((snapshot) => {
          setSubdomain(snapshot.docs[0]?.id ? snapshot.docs[0]?.id : "");

          if (snapshot.docs[0]?.id) {
            setSubdis(true);
          }
        });
    }

  }, []);

  let domain = 'nibrasplay.xyz'
  if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
    domain = 'nibrasplay.com'
  }
  const handleCopy = async () => {
      await navigator.clipboard.writeText(`https://${subdomaintList[0]?.id}.${domain}/mitra`)
      enqueueSnackbar("Link Berhasil di Salin", { variant: "success" , autoHideDuration: 1000});
  }
  const handleShare = async () => {
    window.open('https://api.whatsapp.com/send?text='+encodeURIComponent
    ('Dapatkan fashion muslim favorit kamu dan maksimalkan gaya kamu di Nibrasplay. Klik disini untuk melihat produk lebih lanjut \n' 
    + `https://${subdomaintList[0]?.id}.${domain}/mitra`),'_blank');
}
  return (
    <TabPanel value={value} index="subdomain">
        {!directAddress?.exists ?
          <Grid item xs={12} lg={12}>
              <Alert severity="error">Maaf Lengkapi Alamat Pickup Anda !!!</Alert>
          </Grid>
        :
          <>
            <Grid container>
              {
                !infoMitra?.name ?
                  <h3>Harap mengisi profil terlebih dahulu!</h3> :
                  <Grid direction="column" lg={6}>
                  <div className={classes.copy}>
                    <TextField
                      disabled={subDis}
                      value={subdomain}
                      className={classes.grid}
                      fullWidth
                      label="Subdomain"
                      name="subdomain"
                      variant="outlined"
                      type="text"
                      onChange={handleChange}
                      error={errors.subdomain ? true : false}
                      helperText={errors.subdomain}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {`${domain}/mitra`}
                          </InputAdornment>
                        )
                      }}
                    />
                    {subdomaintList.length > 0 ? 
                    <>
                      <Button className={classes.btnCopy}
                        size="large"
                        color="primary"
                        variant="contained" 
                        onClick={handleCopy}
                        startIcon={<FileCopy />}
                        >
                        Salin
                      </Button>
                      <Button className={classes.btnCopy}
                        size="large"
                        color="primary"
                        variant="contained" 
                        onClick={handleShare}
                        startIcon={<WhatsApp />}
                        >
                        Share
                      </Button>
                    </>
                    : null}
              </div>
                  </Grid>
              }

              <Grid item xs={12} lg={12}>
                <p style={{"fontSize" : 16, color : 'red'}}>
                {`> .`} Subdomain Maximal 21 Character !!!
                </p>
                <p style={{"fontSize" : 16, color : 'red'}}>
                 {`> .`} Subdomain tidak boleh memiliki spasi dan karakter khusus seperti "
                  ~!@#$%^&*()_+=". !!!<br />
                </p>
                <p style={{"fontSize" : 16, color : 'red'}}>
                {`> .`} Subdomain tidak dapat diubah setelah disimpan, pastikan subdomain
                  yang dimasukan sudah benar. !!!
                </p>
                
              </Grid>
              
              <Grid item xs={12} lg={12}>
                <Button
                  className={classes.btnSave}
                  disabled={subDis || !infoMitra?.name}
                  onClick={handleSave}
                  size="large"
                  color="primary"
                  variant="contained"
                // fullWidth
                >
                  {" "}
                  Simpan{" "}
                </Button>
              </Grid>
            </Grid>
          </>
        }
    </TabPanel>
  );
}
