// Nibrasplay Dev
let AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/9f62dae6-e8d8-4462-b778-fa3ceddf0890/page/sALPC";

// Nibrasplay Prod
if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/0f413feb-cced-432a-a651-41a3ca285f68/page/ENFlB"
}

// Kulaku Prod
// else if (process.env.REACT_APP_ENV === "KULAKU_PROD") {
//     AnalyticsEmbed = "";
// }

// Kulaku Dev
else if (process.env.REACT_APP_ENV === "KULAKU_DEV") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/3a511afc-5a3e-4437-b30f-0279e9ec81b3/page/ENFlB";
}

// Reform Prod
else if (process.env.REACT_APP_ENV === "REFORM_PROD") {
    AnalyticsEmbed = "https://datastudio.google.com/embed/reporting/efd24e34-9672-4513-9130-3aa5e812746e/page/Gg3";
}

// Reform Dev
// else if (process.env.REACT_APP_ENV === "REFORM_DEV") {
//     AnalyticsEmbed = "";
// }

export default AnalyticsEmbed;
