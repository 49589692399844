import React, { useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    firestore,

    FieldValue,

} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import { useData } from "../../../components/DataProvider";
// Icon
import DialogKelurahan from "../../../components/GetKelurahan/dialogSearch";

function AddAlamatOrder({ dialog: { open, data }, handleClose, setalamat }) {
    const { enqueueSnackbar } = useSnackbar();
    // console.log(data)
    // const { treeDataKategori, brandList, peoductList } = useData();
    // const shippingProviderWithKelurahan = ['shipper', 'sicepat', 'directapi'];

    const classes = useStyles();

    const { company_id } = useData();

    const [form, setForm] = useState({
        subcarts: "",
        url: "",
        target_id: "",
        target_nama: "",
        sorting_number: ""
    });
    // console.log(data);
    const [error, setError] = useState({
        subcarts: "",
        url: "",
        target_id: "",
        target_nama: "",
    });

    // dialog search
    const [openSearch, setOpen] = useState(false);

    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }



    const validate = async () => {
        const newError = { ...error };
        if (!form.alamat_lengkap) {
            newError.alamat_lengkap = 'alamat_lengkap harus diisi';
        }
        return newError;
    }
    const [isSubmitting, setSubmitting] = useState(false);
    //console.log(form?.koordinat)
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const settDoc = firestore.collection(`mitra/${company_id}/alamat_order_manual`);

                const updProd = {
                    [form?.provinsi?.provider]: {
                        alamat_lengkap: form.alamat_lengkap,
                        //judul: form.judul,
                        //nama_penerima: form.nama_penerima,
                        provinsi: form?.provinsi,
                        kota: form?.kota,
                        kecamatan: form?.kecamatan,
                        kelurahan: form?.kelurahan,
                        coverage_pickup_sicepat: form?.kelurahan?.coverage_pickup_sicepat === 'Y' ? true : false,
                        coverage_pickup_jne: form?.kelurahan?.kode_origin_jne !== null ? true : false,
                        updated_at: FieldValue.serverTimestamp(),
                        // koordinat: new GeoPoint(form?.koordinat?.latitude, form?.koordinat?.longitude)
                    },
                    provider: form?.provinsi?.provider,
                    // drop: drop

                };

                const addAlamatOrder = await settDoc.add(updProd, { merge: true });
                setalamat({
                    [form?.provinsi?.provider]: {
                        alamat_lengkap: form.alamat_lengkap,
                        //judul: form.judul,
                        //nama_penerima: form.nama_penerima,
                        provinsi: form?.provinsi,
                        kota: form?.kota,
                        kecamatan: form?.kecamatan,
                        kelurahan: form?.kelurahan,
                        coverage_pickup_sicepat: form?.kelurahan?.coverage_pickup_sicepat === 'Y' ? true : false,
                        coverage_pickup_jne: form?.kelurahan?.kode_origin_jne !== null ? true : false,
                        updated_at: FieldValue.serverTimestamp(),
                        // koordinat: new GeoPoint(form?.koordinat?.latitude, form?.koordinat?.longitude)
                    },
                    provider: form?.provinsi?.provider,
                    id: addAlamatOrder?.id
                })
                handleClose()
                enqueueSnackbar('Alamat berhasil disimpan', { variant: "success" })

            } catch (e) {
                const newError = {};
                // console.log(e.message)
                newError.title = e.message;
                enqueueSnackbar(`Alamat gagal disimpan,${e.message}`, { variant: "error" })

                setError(newError);
            }

            setSubmitting(false)
        }
    }

    // console.log("Latitude is :", form?.koordinat?.latitude);
    // console.log("Longitude is :", form?.koordinat?.longitude);


    // if (loadingStok) {
    //     return <CircularProgress />;
    // }

    return (
        <Dialog
            // disableBackdropClick={isSubmitting}
            // disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Tambah Alamat Penerima</DialogTitle>
            <DialogContent dividers>

                {/* <Grid container spacing={2}> */}
                {/* <Grid item lg={6} md={6} xs={12}> */}
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <p style={{ margin: 0, fontSize: 12, marginLeft: 16, marginBottom: 5, color: "#8f877d", }}>Cari Nama Desa atau Kelurahan</p>

                        <div
                            style={{
                                width: 550,
                                border: "1px solid #A0A0A0",
                                color: "#8f877d",
                                borderRadius: '7px',
                                padding: '12px'
                            }}
                            onClick={() => setOpen(true)}
                        >

                            <p style={{ margin: "5px 0px", color: "#000", fontSize: 15 }}>
                                {form?.kelurahan?.name ? (
                                    <>
                                        {form.kelurahan?.name} - Kec. {form.kecamatan?.name}
                                        {"\n"}
                                        {form.kota?.name}, {form.provinsi?.name},{" "}
                                        {form.kelurahan?.postal_code}
                                    </>
                                ) : ('Klik untuk mencari'
                                    // <span style={{ color: "#BABABA", fontSize: 14, height: 50}}>
                                    //   Contoh : Pancoran
                                    // </span>
                                )}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.autocomplete}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="alamat_lengkap"
                            name="alamat_lengkap"
                            label="Alamat Lengkap untuk penerima"
                            value={form.alamat_lengkap || ''}
                            onChange={handleChange}
                            error={error.alamat_lengkap ? true : false}
                            helperText={error.alamat_lengkap}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={drop}
                                    onChange={handleChecked}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Drop Barang (tidak di pickup)"
                        />
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <Typography className={classes.mapLabel}>Pickup Coverage : </Typography>
                        <Grid container spacing={1}>
                            {
                                form?.pickupCoverage?.sicepat === true ?
                                    <Grid item>
                                        <Chip label="SiCepat" color="primary" icon={<DoneIcon />} />
                                    </Grid>
                                    :
                                    <Grid item>
                                        <Chip label="SiCepat" icon={<CloseIcon />} disabled />
                                    </Grid>
                            }
                            {
                                form?.pickupCoverage?.jne === true ?
                                    <Grid item>
                                        <Chip label="JNE" color="primary" icon={<DoneIcon />} />
                                    </Grid>
                                    :
                                    <Grid item>
                                        <Chip label="JNE" icon={<CloseIcon />} disabled />
                                    </Grid>
                            }
                        </Grid>
                    </Grid> */}
                    {/* <div item xs={12}>
                        <TextField
                            className={classes.autocomplete}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="judul"
                            name="judul"
                            label="Judul"
                            value={form.judul || ''}
                            onChange={handleChange}
                            error={error.judul ? true : false}
                            helperText={error.judul}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </div> */}
                    {/* <div item xs={12}>
                        <TextField
                            className={classes.autocomplete}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="nama_penerima"
                            name="nama_penerima"
                            label="Nama Penerima"
                            value={form.nama_penerima || ''}
                            onChange={handleChange}
                            error={error.nama_penerima ? true : false}
                            helperText={error.nama_penerima}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </div> */}
                </Grid>
                {/* </Grid> */}
                <Grid item container lg={6} md={6} xs={12}>
                    {/* <Grid item xs={12}>
                    <Typography className={classes.mapLabel}>Koordinat</Typography>
                    <div className={classes.mapBox}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: KeyApi }}
                            defaultCenter={{
                                lat: form?.koordinat?.latitude || -6.200000,
                                lng: form?.koordinat?.longitude || 106.816666
                            }}
                            defaultZoom={8}
                            // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                            onClick={handleClick}
                        >
                            <RoomIcon
                                lat={form?.koordinat?.latitude || -6.200511971721622}
                                lng={form?.koordinat?.longitude || 106.849624984375}
                                style={{
                                    color: red[500], position: 'absolute',
                                    transform: 'translate(-50%, -50%)'
                                }} />

                        </GoogleMapReact>
                        <form action="" method="post">
                            <input type="text" id="lat" name="lat" value={form?.koordinat?.latitude || -6.200511971721622} />
                            <input type="text" id="lng" name="lng" value={form?.koordinat?.longitude || 106.849624984375} />
                        </form>
                    </div>

                </Grid> */}

                    {/* <Grid item xs={12} justify="flex-end" alignItems="flex-end" style={{ display: 'flex' }}>
                        <Grid item xs={3} >
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                size="large"
                                color="primary"
                                variant="contained"
                                fullWidth
                                className={classes.btnSave}
                            >Simpan</Button>
                        </Grid>
                    </Grid> */}
                </Grid>






                {/* </Grid> */}




            </DialogContent>
            <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose}>
                    Batal
                </Button>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
                    Simpan
                </Button>
            </DialogActions>
            <DialogKelurahan
                open={openSearch}
                onClose={() => setOpen(false)}
                value={(v) => {
                    setForm((f) => ({
                        ...f,
                        ...v,
                    }));
                }}
            />
        </Dialog>
    );
}

AddAlamatOrder.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddAlamatOrder;
