import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./pages/404";
import Login from "./pages/login";
import Private from "./pages/private";
import PrivateRoute from "./components/routing/PrivateRoute";
import FirebaseProvider from "./components/FirebaseProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import DashboardUIProvider from "./components/DashboardUIProvider";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./config/theme";
import MitraRoute from "./components/routing/MitraValidateRoute";
import Validation from "./pages/validate";
import VerifyEmail from "./pages/verify";
import ResetPassword from "./pages/reset";

function App() {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <FirebaseProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <DashboardUIProvider>
            <Router>
              <Switch>
                <MitraRoute path="/validate" component={Validation} />
                <PrivateRoute exact path="/" component={Private} />
                {/* <PrivateRoute path="/companies" component={Private} /> */}
                <PrivateRoute path="/product" component={Private} />
                <PrivateRoute path="/reward" component={Private} />
                <PrivateRoute path="/penukaran" component={Private} />
                <PrivateRoute path="/promoOngkir" component={Private} />
                <PrivateRoute path="/categories" component={Private} />
                <PrivateRoute path="/brand" component={Private} />
                <PrivateRoute path="/productStock" component={Private} />
                <PrivateRoute path="/orders" component={Private} />
                <PrivateRoute path="/purchaseOrders" component={Private} />
                <PrivateRoute path="/banner" component={Private} />
                <PrivateRoute path="/slider" component={Private} />
                {/* <PrivateRoute path="/pricing" component={Private} /> */}
                <PrivateRoute path="/analytics" component={Private} />
                <PrivateRoute path="/settings" component={Private} />
                {/* <PrivateRoute path="/integrations" component={Private} /> */}
                <PrivateRoute path="/pencairanRekber" component={Private} />
                <PrivateRoute path="/pencairan" component={Private} />
                <PrivateRoute path="/rekber" component={Private} />
                <PrivateRoute path="/notification" component={Private} />
                <PrivateRoute path="/admin" component={Private} />
                {/* <PrivateRoute path="/feedback" component={Private} /> */}
                <PrivateRoute path="/seller" component={Private} />
                <PrivateRoute path="/cart" component={Private} />
                <PrivateRoute path="/smartPO" component={Private} />
                <Route path="/login" component={Login} />
                <Route path="/verify" component={VerifyEmail} />
                <Route path="/resetPassword" component={ResetPassword} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </DashboardUIProvider>
        </ThemeProvider>
      </FirebaseProvider>
    </SnackbarProvider>
  );
}

export default App;
