import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  auth,
  FieldValue,
  firestore,
  useFirebase,
} from "../../components/FirebaseProvider";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../components/Logo";
import { useData } from "../../components/DataProvider";

function Validation() {
  const history = useHistory();
  const { user } = useFirebase();
  const classes = useStyles();

  const { company_id } = useData();
  // back to login
  const handleLogout = (e) => {
    auth.signOut();
    history.push(`/login`);
  };
  // firestore
  const nibrasIdCol = firestore.collection(`nibras_id`);
  const [data, loadData] = useCollectionData(nibrasIdCol, { idField: "id" });
  // findData

  // State Data
  const [form, setForm] = useState();

  // State Error
  const [error, setError] = useState();
  //Handle Change Textfield
  const handleChange = (e) => {
    setForm(e.target.value);

    setError("");
  };
  let findNibrasId = data?.find((nibras) => {
    return nibras?.nibras_id === form;
  });

  // role
  const akses = [
    {
      path: "/",
      name: "Home",
      icon: "iconHome",
      component: "home",
    },
    {
      path: "/admin",
      name: "Admin",
      icon: "iconAdmin",
      component: "admin",
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: "iconAnalytics",
      component: "analytics",
    },
    // {
    //     path: "/feedback",
    //     name: "Feedback",
    //     icon: "iconFeedback",
    //     component: 'feedback',

    // },
    {
      path: "/seller",
      name: "List Drop Shipper",
      icon: "iconSeller",
      component: "seller",
    },
    // {
    //     path: "/companies",
    //     name: "Mitra",
    //     icon: "iconCompanies",
    //     component: 'companies',

    // },
    {
      path: "/notification",
      name: "Notifications",
      icon: "iconNotification",
      component: "notification",
    },
    {
      path: "/orders",
      name: "Orders",
      icon: "iconOrders",
      component: "orders",
    },
    {
      path: "/pencairan",
      name: "Pencairan",
      icon: "iconPencairan",
      component: "pencairan",
    },
    {
      path: "/pencairanRekber",
      name: "Pencairan Rekber",
      icon: "iconPencairanRekber",
      component: "pencairanRekber",
    },
    {
      path: "/productStock",
      name: "Product Stock",
      icon: "iconStock",
      component: "stock",
    },
    // {
    //     path: "/promoOngkir",
    //     name: "Promo Ongkir",
    //     icon: "iconPromoOngkir",
    //     component: 'promoOngkir',

    // },
    {
      path: "/purchaseOrders",
      name: "Purchase Orders",
      icon: "iconPurchaseOrders",
      component: "purchaseOrders",
    },

    {
      path: "/reward",
      name: "Reward",
      icon: "iconReward",
      component: "reward",
      sub: [
        {
          path: "/reward",
          name: "Reward",
          icon: "iconReward",
          component: "reward",
        },
        {
          path: "/penukaran",
          name: "Penukaran",
          icon: "iconPenukaran",
          component: "penukaran",
        },
      ],
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "iconSettings",
      component: "settings",
    },
  ];
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSimpan = async (e) => {
    setSubmitting(true);
    try {
      if (!findNibrasId?.nibras_id) {
        throw new Error("nibrasId tidak ditemukan");
      }
      /// DON'T OPEN COMMENT THIS IS :)

      // const compDoc = firestore.doc(`mitra/${company_id}`);
      // await compDoc?.set({
      //     created_at: FieldValue.serverTimestamp(),
      //     mitra_level: findNibrasId?.mitra_level
      // }, { merge: true })
      // const nibrasIddoc = firestore.doc(`nibras_id/${findNibrasId?.id}`);
      // await nibrasIddoc.set({
      //     mitra_id: company_id
      // }, { merge: true })
      // const adminDoc = firestore.doc(`mitra/${company_id}/admin/${company_id}`);
      // await adminDoc.set({
      //     access_role: 'mitra',
      //     akses: akses
      // }, { merge: true })
      // const accesRoleDoc = firestore.doc(`mitra/${company_id}/access_control_list/${company_id}`);
      // await accesRoleDoc.set({
      //     access_email: user?.email,
      //     mitra_id: company_id
      // }, { merge: true })
      history.push(`/`);
    } catch (e) {
      setError(e.message);
    }
    setSubmitting(false);
  };
  // console.log(findNibrasId)
  return (
    <>
      <Container maxWidth="xs" className={classes.container}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography className={classes.pageTitle}>
          Silahkan Masukkan Nibras ID
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="nibras_id"
              label="Nibras ID"
              value={form}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              helperText={error}
              error={error ? true : false}
              InputProps={{
                endAdornment: loadData && <CircularProgress size={24} />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isSubmitting}
              onClick={handleSimpan}
              fullWidth
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isSubmitting}
              fullWidth
              onClick={handleLogout}
              color="primary"
            >
              Logout
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send/?phone=628118336243&text&app_absent=0`
                )
              }
              color="primary"
            >
              Hubungi CS
            </Button>
          </Grid>
        </Grid>
      </Container>
      {/* <PrivateRoute nibrasId={findNibrasId?.nibras_id} /> */}
    </>
  );
}

export default Validation;
