import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { firestore } from '../../../components/FirebaseProvider';
import Paper from '@material-ui/core/Paper';
import { useData } from '../../../components/DataProvider';
import Stars from '@material-ui/icons/Stars';
import Chip from '@material-ui/core/Chip';


function Alamat({ dialog: { open, userId, alamat_utama }, handleClose }) {
    const classes = useStyles();

    const { company_id } = useData();

    const alamatCol = firestore.collection(`mitra/${company_id}/sellers/${userId}/alamat`);

    const [dataAlamat, loadingDataAlamat] = useCollectionData(alamatCol, { idField: 'id' });

    // console.log('25', alamat_utama)
    if (loadingDataAlamat) {
        return <PageLoading />
    }
    return <Dialog

        open={open}
        onClose={handleClose}
        fullWidth
    >
        <DialogTitle>Daftar Alamat</DialogTitle>
        <DialogContent dividers>
            {
                dataAlamat?.map?.((alamat) => {
                    // console.log('39', alamat?.id)
                    return <div key={alamat.id}>
                        <Paper variant="outlined" className={classes.paper} >
                            <div className={classes.headerAlamat}>
                                <Typography className={classes.titleAlamat}>{alamat?.judul}</Typography>
                                {
                                    alamat?.id === alamat_utama &&
                                    <Chip size="small" label="Alamat Utama" color="secondary" icon={<Stars />}/>
                                }
                            </div>
                            <div className={classes.contentAlamat}>
                                <Typography className={classes.receiver}>{alamat?.nama_penerima}</Typography>
                                <br/>
                                <Typography>{alamat?.alamat_lengkap}</Typography>
                                <Typography>
                                    {alamat?.[alamat?.provider]?.kelurahan?.name && 'Kel. ' + (alamat?.[alamat?.provider]?.kelurahan?.name) + ', '}
                                    {alamat?.[alamat?.provider]?.kecamatan?.name && 'Kec. ' + (alamat?.[alamat?.provider]?.kecamatan?.name) + ', '}
                                    {alamat?.[alamat?.provider]?.kota?.name && (alamat?.[alamat?.provider]?.kota?.name) + ', '}
                                    {alamat?.[alamat?.provider]?.provinsi?.name}
                                </Typography>
                                <br/>
                                <Typography>Koordinat</Typography>
                                <Typography>Latitude :{alamat?.koordinat?.latitude}</Typography>
                                <Typography>Longitude :{alamat?.koordinat?.longitude}</Typography>
                                <br/>
                                <Typography>Provider : {alamat?.provider}</Typography>
                            </div>
                        </Paper>
                    </div>
                })
            }

        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >Kembali</Button>
        </DialogActions>
    </Dialog>
}
Alamat.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default Alamat;