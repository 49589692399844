import React, { useEffect, useState } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { firestore } from "../../../components/FirebaseProvider";
import { useDocument } from "react-firebase-hooks/firestore";

import useStyles from "./styles";
import Fitur from "./fitur";
import Alamat from "./alamat";
import Company from "./company";
// import Shipping from './shipping';
//import SellerLevel from './seller_level';
import Subdomain from "./subdomain";
import { useData } from "../../../components/DataProvider";

function Settings() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { company_id, broadcastingList } = useData();
  //console.log(location)
  const CompDoc = firestore.doc(`mitra/${company_id}`);
  const [compSnapshot, compLoading] = useDocument(CompDoc);
  const dataProfile = compSnapshot?.data();
  const [tabValue, setTabValue] = useState(
    qs.parse(location.search)?.tab ?? "profil"
  );
  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };
  useEffect(() => {
    history.push(`?tab=${tabValue}`);
  }, [tabValue, history]);

  const settDoc = firestore.doc(`settings/shipping`);
  const [settSnapshot, shipLoading] = useDocument(settDoc);
  const [form, setForm] = useState({
    use_available: "",
  });

  useEffect(() => {
    if (settSnapshot) {
      const data = settSnapshot.data();
      setForm({
        ...data,
      });
    }
  }, [settSnapshot]);

  const useDoc = form.use_available;
  //console.log(useDoc)
  const ShippDoc = firestore.doc(
    `settings/shipping/availables/${useDoc || "raja"}`
  );
  const [shippSnapshot] = useDocument(ShippDoc);
  const [formShip, setFormShip] = useState({
    url: "",
    key: "",
  });

  useEffect(() => {
    if (shippSnapshot) {
      const data = shippSnapshot.data();
      setFormShip({
        ...data,
      });
    }
  }, [shippSnapshot]);

  // const handleSubmit = async e => {
  //     setSubmitting(true);
  //     try {
  //         await settDoc.set({
  //             use_available: form.use_available,
  //         }, { merge: true });

  //         await ShippDoc.set({
  //             key: '003035be71bda1f1c19c95d5c3d88af3',
  //             url: 'https://pro.rajaongkir.com/api',
  //             created_at: FieldValue.serverTimestamp(),
  //         }, { merge: true })
  //         enqueueSnackbar('Shipping berhasil disimpan', { variant: "success" })
  //         window.location.reload(true)
  //     } catch (e) {
  //         setError(e.message)
  //         enqueueSnackbar(`Shipping gagal disimpan,${e.message}`, { variant: "error" })
  //     }
  //     setSubmitting(false);
  // }
  // const [kelurahan, setKelurahan] = useState([]);
  // useEffect(() => {
  //     async function fetchKelurahan() {
  //         try {
  //             const getKelurahan = functions.httpsCallable("getKelurahan");
  //             if (form.kecamatan.id) {
  //                 const response = await getKelurahan({ kecamatan_id: form?.kecamatan?.id });
  //                 setKelurahan(response.data)
  //             }
  //         } catch (e) {
  //             console.log(e.message)
  //         }
  //     }
  //     fetchKelurahan();
  // }, [form.kecamatan.id])

  const [tabs] = useState([
    // {
    //     label: "Shipping", value: "shipping"
    // },
    {
      label: "Profil",
      value: "profil",
    },
    {
      label: "Alamat Pickup",
      value: "alamat",
    },
    {
      label: "Subdomain",
      value: "subdomain",
    },
    {
      label: "Fitur",
      value: "fitur",
    },
    // {
    //     label: "Seller App", value: "Seller_app"
    // },
    // {
    //     label: "Seller Level", value: "seller_level"
    // },
    // {
    //     label: "Webstore", value: "webstore"
    // },
  ]);

  return (
    <div className={classes.paperWrap}>
      <Tabs
        indicatorColor="primary"
        className={classes.tabWrap}
        textColor="primary"
        value={tabValue || "profil"}
        variant="fullWidth"
        onChange={handleChangeTab}
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <div className={classes.content}>
        <Alamat
          uid={company_id}
          value={tabValue}
          use_available={form.use_available}
          shipLoading={shipLoading}
          dataProfile={dataProfile}
          broadcastingList={broadcastingList}
        />
        {/* <Komisi value={tabValue} />
                <PickupAgent value={tabValue} formAgent={form} />
                <RateidList value={tabValue} /> */}
        <Subdomain uid={company_id} value={tabValue} />
        <Fitur uid={company_id} value={tabValue} />
        <Company value={tabValue} />
        {/* <SellerLevel uid={company_id} value={tabValue} /> */}
        {/* <Shipping uid={company_id} value={tabValue} settLoading={shipLoading} form={form} handleChange={handleChange} formShip={formShip} handleSubmit={handleSubmit} isSubmitting={isSubmitting} error={error} /> */}
      </div>
    </div>
  );
}

export default Settings;
