import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { functions ,firestore} from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDocument } from "react-firebase-hooks/firestore";


function AddSeller({ open, handleClose }) {
    const { company_id, dataMitraCurrent } = useData();
    const uidDoc = firestore.doc(`mitra/${company_id}`);
    const [uid] = useDocument(uidDoc);
    // console.log('DAT=>',uid?.data()?.mitra_level);
    const mitra_level = uid?.data()?.mitra_level
    const [form, setForm] = useState({
        path: '',
        nama: '',
        namaToko: '',
        no_hp: +62,
        level: null,
        password: '',
        referral: ''

    });
    // console.log('DATAFORM==>', form);
    const [level, setlevel] = useState(null)
    const [error, setError] = useState({
        path: '',
        nama: '',
        namaToko: '',
        no_hp: '',
        level: '',
        password: '',
        referral: ''
    });
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);
    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        setForm({
            ...form,
            [e.target.name]: value,
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }
    // SELECT LEVEL & LABEL
    const [selectLevel, setSelectLevel] = useState([])

    const validate = async () => {
        const newError = { ...error };

        if (!form.nama) {
            newError.nama = 'Nama harus diisi';
        }
        if (!form.path) {
            newError.path = 'Subdomain harus diisi';
        }
        if (!form.namaToko) {
            newError.namaToko = 'Nama Toko harus diisi';
        }
        if (!form.no_hp) {
            newError.no_hp = 'Wa harus diisi';
        }
        if (!form.level) {
            newError.level = 'Level harus diisi';
        }
        if (!form.password) {
            newError.password = 'Password harus diisi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();
        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const addUsers = functions.httpsCallable("seller-addSeller");
                const response = await addUsers({ ...form, mitra_id: company_id });
                setForm(response.data)
                enqueueSnackbar('Seller ditambahkan', { variant: "success" })
                handleClose();
            } catch (e) {
                const newError = {};
                enqueueSnackbar(`Seller gagal ditambahkan,${e.message}`, { variant: "error" })
                handleClose();
                newError.title = e.message;
                setError(newError);
            }
            setSubmitting(false)
        }
    }
    useEffect(() => {
        if (mitra_level === 1) {
            setSelectLevel([
            // {
            //     level : "1",
            //     label : "Distributor"
            // },
            // {
            //     level : "2",
            //     label : "Nibras House"
            // },
            {
                level : "3",
                label : "Agen"
            },
            {
                level : "4",
                label : "Reseller"
            },])
        }else if (mitra_level === 2) {
            setSelectLevel([{
                level : "3",
                label : "Agen"
            },
            {
                level : "4",
                label : "Reseller"
            }])
        } else if (mitra_level === 3) {
            setSelectLevel([ {
                level : "4",
                label : "Reseller"
            }])

        }  else if (mitra_level === 4) {
            setSelectLevel([ {
                level : "4",
                label : "Reseller"
            }])
        }
    }, [mitra_level])
    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Tambah Dropshipper</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="company_id"
                            name="company_id"
                            label="Company_id"
                            onChange={handleChange}
                            error={error.company_id ? true : false}
                            helperText={error.company_id}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid> */}
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="nama"
                            name="nama"
                            label="Nama "
                            onChange={handleChange}
                            error={error.nama ? true : false}
                            helperText={error.nama}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="namaToko"
                            name="namaToko"
                            label="Nama Toko"
                            onChange={handleChange}
                            error={error.namaToko ? true : false}
                            helperText={error.namaToko}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="path"
                            name="path"
                            label="Path"
                            onChange={handleChange}
                            error={error.path ? true : false}
                            helperText={error.path}
                            fullWidth
                            variant="outlined"
                            multiline
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="no_hp"
                            name="no_hp"
                            label="No. Hp (+62)"
                            onChange={handleChange}
                            error={error.no_hp ? true : false}
                            helperText={error.no_hp}
                            fullWidth
                            variant="outlined"
                        />
                        {/* <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="wa"
                            placeholder="Enter a phone number"
                            guide={false}
                            id="wa"
                            name="wa"
                            error={error.wa ? true : false}
                            helperText={error.wa}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        /> */}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="password"
                            name="password"
                            label="Password"
                            onChange={handleChange}
                            error={error.password ? true : false}
                            helperText={error.password}
                            fullWidth
                            variant="outlined"
                            multiline
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            id="level"
                            name="level"
                            margin="normal"
                            required
                            value={level || ''}
                            onChange={(event, value) => {
                                if (value){
                                    setlevel(value)
                                    handleChange({ target: { name: "level", value: Number(value.level) }});
                                }
                            }}
                            options={selectLevel || ''}
                            getOptionLabel={(options) => options.label || ''}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.level}
                                    error={error?.level ? true : false}
                                    {...params}
                                    label='Level'
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    {form.level !== 1 && form?.level ?
                            <Grid item xs={6}>
                                <TextField
                                    autoComplete="off"
                                    disabled={isSubmitting}
                                    id="referral"
                                    name="referral"
                                    label="Referral"
                                    onChange={handleChange}
                                    error={error.referral ? true : false}
                                    helperText={error.referral}
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                />
                            </Grid> : null
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddSeller.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddSeller;
