import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {
  firestore,
  FieldValue,
  functions,
} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import dataMonth from "./month.json";
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Switch } from "@material-ui/core";
import { Clear, FlipCameraAndroid } from "@material-ui/icons";

function AddMitra({ dialog: { mode, open, id, data }, handleClose }) {
  const { company_id, dataMitraCurrent, dataMitraLevel, hideSetting } = useData();
  const [DateFormat, setDateFormat] = useState('')
  const [form, setForm] = useState({
    email: "",
    password: "",
    nibras_id: "",
    level: "",
    nama_toko : ""
  });
  const [monthData, setMonthData] = useState([]);
  const [error, setError] = useState({
    email: "",
    password: "",
    nibras_id: "",
    level: "",
    nama_toko : ""
  });

  useEffect(() => {
    const defaultData = {
      email: "",
      password: "",
      nibras_id: "",
      level: "",
      nama_toko : ""
    };
    const defaultError = {
      email: "",
      password: "",
      nibras_id: "",
      level: "",
      nama_toko : ""
    };
    if (mode === "Tambah") {
      setForm(defaultData);
      setError(defaultError);
    } else if (mode === "Edit") {
      setForm(data);
      setError(defaultError);
    }
  }, [mode, data]);

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [handleSwitch, sethandleSwitch] = useState(false)
  const [handleMitraMenu, setMitraMenu] = useState(false)

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(form)
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  //fungsi check
  const checkNibrasId = () => {
    console.log(form);
    form.nibras_id == ""
      ? enqueueSnackbar(`Id Aman`, {
          variant: "success",
        })
      : enqueueSnackbar(`Id Tidak ada`, {
          variant: "error",
        });
  };

  const validate = async () => {
    const newError = { ...error };
    if (!form.email) {
      newError.email = "Email harus diisi";
    }
    if (mode === "Tambah") {
      if (!form.password) {
        newError.password = "password harus diisi";
      }
      if (form.password?.length < 8) {
        newError.password = "password harus minimal 6 karakter";
      }
    }
    if (!form.nama_toko) {
        newError.nama_toko = 'Nama Toko Wajib Di Isi';
    }
    if (!form.nibras_id && handleSwitch){
        newError.nibras_id = `Masukan Kode ${form?.level?.no > 2 ? form?.level?.no === 3 ? "Patner" : "Corner" : ""} ID  Anda`
    }
    return newError;
  };
  
  // const [level_mitra, setLevelMitra] = useState(dataMitraCurrent?.mitra_level);
  const levelAvailable = dataMitraCurrent?.mitra_level + 1;
  let deptLevel = [];
  
  // let test = []
  for (var i = levelAvailable; i <= dataMitraLevel?.depth; i++) {
    if (dataMitraLevel?.custom_name?.[i] !== 'Nibras House'){ // check apakah data nya tidak dengan kata itu
      deptLevel.push({ level:  dataMitraLevel?.custom_name?.[i], no: i });
    }
    // deptLevel.push(i.toString());
    // setLevelMitra(i)
  }
  // const GenerateDate = () => {
  //   var today = new Date();
  //   var yyyy = today.getFullYear();
  //   var mm = String(today.getMonth() + 1).padStart(2, '0');
  //   var dd = String(today.getDate()).padStart(2, '0');
  //   var hh = String(today.getHours())
  //   var mnt = String(today.getMinutes())
  //   var ss = String(today.getSeconds())
  //   today = yyyy + '-' + mm + '-' + dd + '-' + hh + '-' + mnt + '-' + ss;
  //   return setDateFormat(today)
  // }
  // console.log('==DATE== >',DateFormat)

  // set nibras_id\
  const setNibrasIdDoc = firestore.doc(`mitra/${company_id}/settings/fitur`);
  const [dataNibrasCounter] = useDocumentData(setNibrasIdDoc);

  const setDocSetting = firestore.doc(`settings/nibras_id_counter`);
  const [resultDocSetting] = useDocumentData(setDocSetting);

  // zero padStart
  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const [nibrasId, setNibrasId] = useState();
  const [fieldCounter, setFieldCounter] = useState();

  useEffect(() => {
    // setGetCodeMonth
    async function getMontCode() {
      var today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const dd = String(today.getDate()).padStart(2, '0');
      const hh = String(today.getHours())
      const mnt = String(today.getMinutes())
      const ss = String(today.getSeconds())
      today = yyyy + '-' + mm + '-' + dd + '-' + hh + '-' + mnt + '-' + ss;
      // set to UseState
      setDateFormat(today)

      setLoading(true)
      try {
        const getMonth = dataMonth?.filter((val)=> val.id === Number(mm))?.map((val) => {
          return {
            id : val.id,
            name : val.name,
            code : val.code
          };
        })
        setMonthData(getMonth)
      } catch (error) {
        setLoading(false)
      }
    }
    if (dataMitraCurrent?.mitra_level === 1) {
      if (form?.level?.no === 2) {
        setNibrasId(`H${zeroPad(dataNibrasCounter?.nibras_house + 1, 5)}`);
        setFieldCounter(`nibras_house`);
      } else if (form?.level?.no === 3) {
        setNibrasId(`A2${company_id}${DateFormat.substring(2,4)}${monthData[0].code}${zeroPad(dataNibrasCounter?.agen_db ? dataNibrasCounter?.agen_db + 1 : 1 , 4)}`);
        setFieldCounter(`agen_db`);
      } else if (form?.level?.no === 4) {
        setNibrasId(`R2${company_id}${DateFormat.substring(2,4)}${monthData[0].code}${zeroPad(dataNibrasCounter?.reseller_db ? dataNibrasCounter?.reseller_db + 1 : 1 , 4)}`);
        setFieldCounter(`reseller_db`);
      }
    } else if (dataMitraCurrent?.mitra_level === 2) {
        if (form?.level?.no === 3) {
          // setNibrasId(`A2${zeroPad(dataNibrasCounter?.agen_nhs + 1, 7)}`);
          setNibrasId(`AN2${company_id}${DateFormat.substring(2,4)}${monthData[0]?.code}${zeroPad(dataNibrasCounter?.agen_nhs ? dataNibrasCounter?.agen_nhs + 1 : 1 , 4)}`);
          setFieldCounter(`agen_nhs`);
        } else if (form?.level?.no === 4) {
          // setNibrasId(`R2${zeroPad(dataNibrasCounter?.reseller_nhs + 1, 7)}`);
          setNibrasId(`RN2${company_id}${DateFormat.substring(2,4)}${monthData[0]?.code}${zeroPad(dataNibrasCounter?.reseller_nhs ? dataNibrasCounter?.reseller_nhs + 1 : 1 , 4)}`);
          setFieldCounter(`reseller_nhs`);
        }
    } else if (dataMitraCurrent?.mitra_level === 3) {
      setNibrasId(`RA2${DateFormat.substring(2,4)}${monthData[0]?.code}${zeroPad(resultDocSetting?.reseller_agen ? resultDocSetting?.reseller_agen + 1 : 1 , 9)}`);
      setFieldCounter(`reseller_agen`);
    }
    getMontCode()
  }, [form?.level, dataNibrasCounter, dataMitraCurrent]);

  // console.log('LV ==>' , form?.level?.no)
  // console.log('STATE ==>' , nibrasId)
  // console.log("FORM ==>" , form?.nibras_id)
  // console.log("TYPE==>", handleSwitch)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
       // kalkulasi register all
      let mitraID = nibrasId.replace(/-/g,'')
      try {
        // // set to docID
        const addMitra = functions.httpsCallable("mitra-addMitra");
        if (!handleSwitch){
          await addMitra({
            email: form?.email,
            password: form?.password,
            level: form?.level?.no,
            nibras_id: nibrasId,
            mitra_upline: company_id,
            nama : form?.nama_toko,
            mitra_id : mitraID,
            type : 'generate',
            mitra_aktif : handleMitraMenu
          });
          
          // count no urut in mitraDOC
          await setNibrasIdDoc.set({
            [fieldCounter]: FieldValue.increment(+1)
          }, { merge: true })
          
          // kalkulasi register all
          await setDocSetting.set({
            [fieldCounter]: FieldValue.increment(+1)
          }, { merge: true })
        } else {
          await addMitra({
            email: form?.email,
            password: form?.password,
            level: form?.level?.no,
            nibras_id: form?.nibras_id,
            mitra_upline: company_id,
            nama : form?.nama_toko,
            mitra_id : form?.nibras_id,
            flaging : handleSwitch,
            mitra_aktif : handleSwitch
          });
        }

          // const addMitraSubbaccountDoku = functions.httpsCallable("jokulDoku-createSubAccount");
          // await addMitraSubbaccountDoku({
          //     email: form?.email
          // })
        enqueueSnackbar("Mitra ditambahkan", { variant: "success" });
        handleClose();
        window.location.reload()
      } catch (e) {
        const newError = {};
        enqueueSnackbar(`Mitra gagal ditambahkan,${e.message}`, {
          variant: "error",
        });
        handleClose();
        newError.title = e.message;
        console.log(e.message);
        setError(newError);
      }
      setSubmitting(false);
    }
  };

  const handleChangeSwitch = async (e) => {
    sethandleSwitch((prev) => !prev);
    setMitraMenu(false);
  };

  const handleChangeMitraMenu = async (e) => {
    setMitraMenu((prev) => !prev);
  };

  const handleCloseCheck = async () => {
    handleClose(false)
    sethandleSwitch(false)
}
  // const [selectLevel, setSelectLevel] = useState([`nibras house`, `agen`, `reseller`])
  // console.log(selectLevel)
  // useEffect(() => {
  //     if (dataMitraCurrent?.mitra_level === 2) {
  //         setSelectLevel(['agen', 'reseller'])
  //     } else if (dataMitraCurrent?.mitra_level === 3) {
  //         setSelectLevel(['reseller'])
  //     }
  // }, [dataMitraCurrent?.mitra_level])
  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Tambah Mitra</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Autocomplete
              id="level"
              name="level"
              margin="normal"
              required
              value={form?.level || ""}
              onChange={(event, value) => {
                handleChange({ target: { name: "level", value } });
              }}
              // disabled={loadingStok || loadingVariants || cekLoad}
              options={deptLevel || ""}
              getOptionLabel={(option) => option?.level?.toUpperCase()}
              renderInput={(params) => (
                <TextField
                  helperText={error?.level}
                  error={error?.level ? true : false}
                  {...params}
                  label="Level"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          
          {!hideSetting?.data()?.disableNibrasID ? 
          <>            
            {dataMitraCurrent?.mitra_level < 3 && form?.level ? 
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={handleSwitch}
                      onChange={handleChangeSwitch}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={`Sudah terdaftar sebagai Nibras ${form?.level?.no > 2 ? form?.level?.no === 3 ? "Patner" : "Corner" : ""}`}
                />
              </Grid>
            : null }
          </>
          : null}

          {!handleSwitch ?
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={true}
                id="nibras_id"
                name="nibras_id"
                label="Nibras ID"
                value={nibrasId || ""}
                onChange={handleChange}
                fullWidth
                multiline
                variant="outlined"
              />
            </Grid>
          :
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                disabled={false}
                id="nibras_id"
                name="nibras_id"
                label={`Nibras ${form?.level?.no > 2 ? form?.level?.no === 3 ? "Patner" : "Corner" : ""} ID`}
                value={form?.nibrasId}
                onChange={handleChange}
                // InputProps={{
                //     endAdornment: (
                //         <InputAdornment position="end">
                //             <IconButton onClick={() => setNibrasId('')}>
                //                 <Clear size={24} value={15} />
                //             </IconButton>         
                //         </InputAdornment>
                //     )
                // }}
                error={error.nibras_id ? true : false}
                helperText={error.nibras_id}
                fullWidth
                variant="outlined"
              />
            </Grid>
          } 

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="nama"
              name="nama_toko"
              label="Nama Toko "
              value={form?.nama_toko}
              onChange={handleChange}
              error={error.nama_toko ? true : false}
              helperText={error.password}
              fullWidth
              multiline
              variant="outlined"
              // inputProps={{maxLength : 20}}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="email"
              name="email"
              label="Email"
              value={form?.email}
              onChange={handleChange}
              error={error.email ? true : false}
              helperText={error.email}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={isSubmitting}
              id="password"
              name="password"
              label="Password "
              value={form?.password}
              onChange={handleChange}
              error={error.password ? true : false}
              helperText={error.password}
              fullWidth
              multiline
              variant="outlined"
            />
          </Grid>

          {/* {
            form?.level >= 2 ?
            <Grid item xs={6}>
                <TextField
                    autoComplete="off"
                    disabled={isSubmitting}
                    id="mitra_upline"
                    name="mitra_upline"
                    label="mitra_upline"
                    value={form?.mitra_upline}
                    onChange={handleChange}
                    error={error.mitra_upline ? true : false}
                    helperText={error.mitra_upline}
                    fullWidth
                    variant="outlined"
                    multiline
                />
          </Grid> : null
          } */}
        </Grid>
      </DialogContent>
      <DialogActions >
        {form?.level?.no !== undefined && !handleSwitch ? 
            <Grid xs={12} style={{ marginLeft :15 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={handleMitraMenu}
                    onChange={handleChangeMitraMenu}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                // label={`Sudah terdaftar sebagai ${form?.level?.no > 2 ? form?.level?.no === 3 ? "Agen" : "Reseller" : ""} Lama`}
                label={`Buka semua fitur pengguna`}
              />
            </Grid>
          : null }
        <Button disabled={isSubmitting} onClick={handleCloseCheck}>
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddMitra.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddMitra;
