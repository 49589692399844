import React, { useEffect, useState } from "react";
import {
    FieldValue,
    firestore,
    functions,
} from "../../../components/FirebaseProvider";
import useStyles from "./styles";

// material ui core
import PageLoading from "../../../components/loading/pageLoading";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// react router

import RoomIcon from "@material-ui/icons/Room";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ScheduleIcon from "@material-ui/icons/WatchLater";
import { CircularProgress, TextField } from "@material-ui/core";

import { currency } from "../../../utils/formatter";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import UpdateStok from "./updateStokProd";
import LacakOrder from "./tracking";
import  CancelOrdersPO  from "./cancelOrdersPO";

function DataOrder({
    order,
    buttonLoading,
    changeStatus,
    handleReveral,
    getDataSubOrder,
    enqueueSnackbar,
    getOrders,
    dataSubOrder,
    setOpenAddDialog
}) {
    const classes = useStyles();
    const subOrd = order?.id;
    const { company_id } = useData();
    const callExpired = React.useRef(false)
    const [CancelOrder, setCancel] = useState({
        open: false,
        data : {}
    });

    const subordCol = firestore.collection(`mitra/${order?.mitra_id}/orders/${subOrd}/suborders`);

    const [jumlahOrder, loadJumlahOrder] = useCollectionData(subordCol, {
        idField: "id",
    });
    // console.log(jumlahOrder);
    const data = jumlahOrder?.map((ord) => {
        return ord;
    });

    const jumlahAlamat = data?.length;

    const jumlahBarang = jumlahOrder?.map?.((sub) => {
        return sub?.products_qty?.reduce?.((val, currVal) => {
            return val + currVal;
        });
    });

    // expired_order
    useEffect(() => {
        if (order?.expiration_date <= Date.now() && !callExpired.current && order?.status === 'pending') {
            callExpired.current = true;
            async function callSetOrderToExpired() {
                try {
                    const setOrderToExpired = functions.httpsCallable("order-setExpired")
                    await setOrderToExpired({ order_id: order?.id })
                } catch (e) {
                    console.log("failed to set order", e.message)
                }
            }
            callSetOrderToExpired();
        }
    }, [order?.expiration_date, order?.id, order?.status]);

    // goods_issue
    const handleGoodsIssue = order => async () => {
        try {
            // setButtonLoading(true);

            const varCol = firestore.collection(`mitra/${company_id}/orders/${order.id}/suborders`);
            varCol.get()
                .then((snapshot) => {
                    snapshot.docs.forEach(doc => {
                        updateSuborderIssue(order.id, doc.id)
                    })
                })
                .catch((err) => {
                    console.log('Error : ', err)
                })
            // setButtonLoading(false);

            enqueueSnackbar('Status berhasil dirubah', { variant: "success" })

        } catch (e) {
            console.log(e.message)
        }
    }
    const updateSuborderIssue = async (orderId, suborderId) => {
        const ordDoc = firestore.doc(`mitra/${company_id}/orders/${orderId}`);

        await ordDoc.set({
            goods_issue: true,

        }, { merge: true })
        const varCol = firestore.collection(`mitra/${company_id}/orders/${orderId}/suborders`);


        await varCol.doc(suborderId).set({
            goods_issue: true,
            updated_at: FieldValue.serverTimestamp()
        }, {
            merge: true
        })
            .then(res => {
                console.log('Success update sub order status')
            })
            .catch(err => {
                console.log('Failed to update sub order status')
            })
        getDataSubOrder(orderId, true);
        getOrders()
    }

    const FiturNot = async () => {
        enqueueSnackbar('Maaf Fitur Belum Tersedia', { variant: "secondary" })
    }


    // pickup
    const [loading, setLoading] = useState(false);

    // time
    const date = new Date()
    date.setHours(23, 0)
    const newFormat = date?.toISOString()?.substring(0, 16)
    const [time, setTime] = React.useState(newFormat);
    // var theBigDay = new Date();
    // theBigDay.setHours(7, 0);
    // console.log('date', `${time.split('T')?.[0]} ${time.split('T')?.[1]}`)
    const handleChangeDate = (newValue) => {
        setTime(newValue.target.value);
    };
    const [pickupRespon, setPickup] = useState()
    const handlePickup = order => async () => {

        setLoading(true);

        try {
            const varCol = firestore.collection(`mitra/${company_id}/orders/${order.id}/suborders`);
            varCol.get()
                .then((snapshot) => {
                    snapshot.docs.forEach(doc => {
                        pickupReq(order.id, doc.id)
                    })
                })
                .catch((err) => {
                    console.log('Error : ', err)
                })



        } catch (e) {
            console.log('err', e.message)

            enqueueSnackbar(e.message, { variant: "error" });
            setLoading(false);
        }
    }

    const pickupReq = async (orderId, suborderId) => {
        try {
            const shippingPickup = functions.httpsCallable("shipping-pickup");

            const response = await shippingPickup({
                order_id: orderId,
                suborder_id: suborderId,
                datePickup: `${time.split('T')?.[0]} ${time.split('T')?.[1]}`
            });

            const data = response.data;
            const ordDoc = firestore.doc(`mitra/${company_id}/orders/${orderId}`);

            await ordDoc.set({
                status: 'on-delivery',
                status_pengiriman: 'on-delivery',
                updated_at: FieldValue.serverTimestamp()

            }, { merge: true })
            const varCol = firestore.collection(`mitra/${company_id}/orders/${orderId}/suborders`);


            await varCol.doc(suborderId).set({
                status: 'on-delivery',
                status_pengiriman: 'on-delivery',
                updated_at: FieldValue.serverTimestamp()
            }, {
                merge: true
            })
            setPickup(data)
            // console.log(data)
            setLoading(false);
            getOrders()
            getDataSubOrder(order.id, true)
            enqueueSnackbar('Pickup Request Berhasil ', { variant: "success" })

        } catch (e) {
            console.log(e.message)
        }
    }
    if (loadJumlahOrder) {
        return <PageLoading />;
    }
    return (
        <div className={classes.orderBox}>
            <div className={classes.orderRowTop}>
                <div className={classes.orderColTopLeft}>

                    <div className={classes.innerRow}>
                        <div className={classes.timelineStatus}>
                            <div className={classes.timelineBox}>
                                <div
                                    className={`${order.status === "pending" ||
                                        order.status === "paid" ||
                                        order.status === "cod" ||
                                        order.status === "on-process" ||
                                        order.status === "on-delivery" ||
                                        order.status === "received"
                                        ? `${classes.checkpoint} ${classes.active}`
                                        : classes.checkpoint
                                        }`}
                                >
                                    <ScheduleIcon />
                                </div>
                            </div>
                            <div className={classes.timelineBox}>
                                <div
                                    className={`${order.status === "paid" ||
                                        order.status === "cod" ||
                                        order.status === "on-process" ||
                                        order.status === "on-delivery" ||
                                        order.status === "received"
                                        ? `${classes.checkpoint} ${classes.active}`
                                        : classes.checkpoint
                                        }`}
                                >
                                    <CheckCircleIcon />
                                </div>
                            </div>
                            <div className={classes.timelineBox}>
                                <div
                                    className={`${order.status === "on-process" ||
                                        order.status === "on-delivery" ||
                                        order.status === "received"
                                        ? `${classes.checkpoint} ${classes.active}`
                                        : classes.checkpoint
                                        }`}
                                >
                                    <AutorenewIcon />
                                </div>
                            </div>
                            <div className={classes.timelineBox}>
                                <div
                                    className={`${order.status === "on-delivery" ||
                                        order.status === "received"
                                        ? `${classes.checkpoint} ${classes.active}`
                                        : classes.checkpoint
                                        }`}
                                >
                                    <LocalShippingIcon />
                                </div>
                            </div>
                            <div className={classes.timelineBox}>
                                <div
                                    className={`${order.status === "received"
                                        ? `${classes.checkpoint} ${classes.active}`
                                        : classes.checkpoint
                                        }`}
                                >
                                    <RoomIcon />
                                </div>
                            </div>
                        </div>
                        <div className={classes.innerRow}>
                            {/* <Typography className={classes.orderLabel} component="h4" variant="h5">Transaction Info</Typography> */}
                            <div className={classes.transactionBox}>
                                <div className={classes.transactionRow}>
                                    <Typography
                                        className={classes.transactionLabel}
                                        component="h4"
                                        variant="h5"
                                    >
                                        Transaksi Via
                                    </Typography>
                                    <Typography className={classes.transactionValue}>
                                        : {order?.order_info?.location}{" "}
                                        <span className={classes.sellerName}>
                                            {order?.order_info?.domain !== "sellerapp"
                                                ? order?.order_info?.domain
                                                : ""}
                                        </span>
                                    </Typography>
                                </div>
                                <div className={classes.transactionRow}>
                                    <Typography className={classes.transactionItems}>
                                        <span>
                                            {data.length > 0
                                                ? jumlahBarang?.reduce?.((val, currVal) => {
                                                    return val + currVal;
                                                })
                                                : jumlahBarang}
                                        </span>
                                        Barang,
                                    </Typography>
                                    <Typography className={classes.transactionItems}>
                                        Dikirim ke {jumlahAlamat} Alamat
                                    </Typography>
                                </div>
                                {/* <div className={classes.transactionRow}>
                                <Typography className={classes.transactionLabel}>Status Transaksi</Typography>
                                <Typography className={classes.transactionValue}>: {order?.transaction_info?.transaction_status}</Typography>
                                </div>
                                <div className={classes.transactionRow}>
                                    <Typography className={classes.transactionLabel}>Tipe Pembayaran</Typography>
                                    <Typography className={classes.transactionValue}>: {order?.transaction_info?.payment_type}</Typography>
                                </div> */}
                            </div>
                            <div className={classes.totalPricing}>
                            <div className={classes.innerRowBot}>
                                <Typography
                                    className={classes.orderLabelBot}
                                    component="h4"
                                    variant="h5"
                                >
                                    Total Harga Normal
                                </Typography>
                                <Typography className={classes.orderValueBot}>
                                    : {currency(order?.total_tagihan_harga_normal)}
                                </Typography>
                            </div>
                            <div className={classes.innerRowBot}>
                                <Typography
                                    className={classes.orderLabelBot}
                                    component="h4"
                                    variant="h5"
                                >
                                    Diskon
                                </Typography>
                                <Typography className={classes.orderValueBot}>
                                    : {(order?.total_tagihan_harga_normal - order?.total_tagihan) / order?.total_tagihan_harga_normal * 100}%
                                </Typography>
                            </div>
                            <div className={classes.innerRowBot}>
                                <Typography
                                    className={classes.orderLabelBot}
                                    component="h4"
                                    variant="h5"
                                >
                                    Potongan
                                </Typography>
                                <Typography className={classes.orderValueBot}>
                                    : {currency(order?.total_tagihan_harga_normal - order?.total_tagihan)}
                                </Typography>
                            </div>
                               {order?.total_promo !== 0 &&
                                <div className={classes.innerRowBot}>
                                    <Typography
                                        className={classes.orderLabelBot}
                                        component="h4"
                                        variant="h5"
                                    >
                                        Promo
                                    </Typography>
                                    <Typography className={classes.orderValueBot}>
                                        : {currency(order?.total_promo)}
                                    </Typography>
                                </div>
                               }
                                <div className={classes.innerRowBot}>
                                    <Typography
                                        className={classes.orderLabelBot}
                                        component="h4"
                                        variant="h5"
                                    >
                                        Total Tagihan
                                    </Typography>
                                    <Typography className={classes.orderValueBot}>
                                        : {currency(order?.total_tagihan)}
                                    </Typography>
                                </div>
                                <div className={classes.innerRowBot}>
                                    <Typography
                                        className={classes.orderLabelBot}
                                        component="h4"
                                        variant="h5"
                                    >
                                        Total biaya pengiriman
                                    </Typography>
                                    <Typography className={classes.orderValueBot}>
                                        : {currency(order?.total_biaya_pengiriman)}
                                    </Typography>
                                </div>
                                <div className={classes.innerRowBot}>
                                    <Typography
                                        className={classes.orderLabelBot}
                                        component="h4"
                                        variant="h5"
                                    >
                                        Total Pembayaran
                                    </Typography>
                                    <Typography className={classes.orderValueBot}>
                                        : {currency(order?.total_pembayaran)}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.processWrap}>
                                <div className={classes.innerRow}>
                                    {
                                        order?.status_pembayaran === 'pending' &&
                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={() => {
                                                window.open(order?.payment_info?.url)
                                            }}
                                        >
                                            Bayar Sekarang
                                        </Button>
                                    }
                                    {
                                        order?.status_pembayaran === 'pending' && 
                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={() => {
                                                setCancel(CancelOrders => ({
                                                    order : order,
                                                    open : true,
                                                    status : 'pending'
                                                }))
                                            }}
                                            style={{marginLeft : 10}}
                                        >
                                            Cancel PO
                                        </Button>
                                    }
                                    {/*
                                    {
                                        order?.status_pembayaran === 'paid' && 
                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={() => {FiturNot()}}
                                            style={{marginLeft : 10}}
                                        >
                                            Cancel PO
                                        </Button>
                                    } */}

                                    {/* {
                                        order?.status === 'on-process' &&
                                        order?.goods_issue !== true &&
                                        <Button
                                            style={{ marginRight: 10 }}

                                            // disabled={suborder?.goods_issue === true}
                                            onClick={handleGoodsIssue(order)}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Goods Issue
                                        </Button>
                                    }

                                    {order?.status === 'on-process' &&
                                        order?.goods_issue === true &&
                                        <>
                                            <TextField
                                                id="datetime-local"
                                                label="Date Pickup"
                                                type="datetime-local"
                                                // ampm={false}
                                                defaultValue={time}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChangeDate}
                                                variant='outlined'
                                            />
                                            <Button
                                                style={{ marginTop: 10 }}
                                                disabled={loading || order?.status === 'on-delivery'}
                                                onClick={handlePickup(order)}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Pickup Request
                                            </Button></>
                                    } */}
                                    {/* {order.status === "on-process" ||
                                        order.status === "received" ? (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) => {
                                                    // setOpenAddDialog(true);
                                                    setOpenAddDialog(tracking => ({
                                                        // console.log(...tracking)
                                                        // suborderId: order,
                                                        open: true,
                                                        orderId: order?.id

                                                    }));
                                                }}
                                            >
                                                Lacak
                                            </Button>

                                        </>
                                    ) : null} */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.orderColTopRight}>
                    {
                        (order.status === "received" || order.status === "completed") && order?.update_stok !== true ? (
                            <>
                                <UpdateStok orderId={order?.id} mitraId={order?.mitra_id} getOrders={getOrders} />
                            </>
                        ) : null}

                </div>
            </div>
            <CancelOrdersPO
                CancelOrders={CancelOrder}
                handleClose={() => {
                    setCancel({
                        open : false,
                        data: getOrders,
                    })
                }}
                order={order}
                dataSubOrder={dataSubOrder}
            />
           
        </div>
    );
}

export default DataOrder;
