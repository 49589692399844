import React, { useState, useEffect, forwardRef } from 'react';
import Container from '@material-ui/core/Container';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Fab from '@material-ui/core/Fab';
import MaterialTable from 'material-table';
import useStyles from './style';
import AddProduct from './add';
import { useCollection, } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { unixToDate } from '../../../utils/dateFormatter';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import tableIcons from '../../../config/table-icons';
import { firestore, } from '../../../components/FirebaseProvider';
import { useData } from '../../../components/DataProvider';
import Edit from '@material-ui/icons/Edit';

function Table() {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { company_id } = useData();
	const [openAddProduct, setOpenAddProduct] = useState(false);
	const col = firestore.collection(`mitra/${company_id}/products_reward`);
	const [snapshot, loading] = useCollection(col);
	const [table, setTable] = useState({
		columns: [
			{
				title: 'Gambar', field: 'thumbnail',
				render: rowData => {
					const firstImage = rowData.thumbnail?.[0]
					if (firstImage)
						return <img alt="" src={firstImage} height="120px" />
					return <p>Belum Ada Gambar</p>
				}
			},
			{ title: 'Nama', field: 'nama' },
			{ title: 'Deskripsi Singkat', field: 'deskripsi_singkat' },
			{
				title: 'Diunggah', field: 'created_at', editable: 'never',
				render: rowData =>
					<>
						{
							rowData && rowData.created_at !== null ?
								<span>{unixToDate(rowData.created_at?.toMillis())}</span>
								:
								<span>-</span>
						}
					</>
			},
			{
				title: 'Diubah', field: 'updated_at', editable: 'never',
				render: rowData =>
					<>
						{
							rowData && rowData.updated_at !== null ?
								<span>
									{unixToDate(rowData.updated_at?.toMillis())}
								</span>
								:
								<span>-</span>
						}
					</>
			},

		],
		data: [
		],
	});

	useEffect(() => {
		if (snapshot && !snapshot.empty) {
			setTable(table => {
				return {
					...table,
					data: snapshot.docs.map(doc => {
						return {
							uid: doc.id,
							...doc.data()
						}
					})
				}
			})
		} else {
			setTable(table => {
				return {
					...table,
					data: []
				}
			})
		}
	}, [snapshot]);


	// if (loading || komisiLoading) {
	// 	return <PageLoading />
	// }

	return (
		<>
			<div className={classes.root}>
				{
					loading ?
						<PageLoading />
						:
						<Container className={classes.tableCon} maxWidth="lg">
							<div className={classes.tableWrap}>
								<MaterialTable
									icons={tableIcons}
									title="Daftar Produk Reward"
									columns={table.columns}
									data={table.data}
									components={{
										Container: props => <div {...props} elevation={0} />
									}}
									editable={{

										onRowDelete: async (data) => {
											await firestore.doc(`mitra/${company_id}/products_reward/${data.uid}`).delete();
											enqueueSnackbar('Produk Berhasil Dihapus', { variant: "success" })
										},
									}}

									actions={[
										{
											icon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
											tooltip: 'Edit',
											onClick: (event, data) => {
												history.push(`/reward/edit/${data.uid}`);
											}
										}

									]}

									localization={{
										body: {
											emptyDataSourceMessage: 'Tidak Ada Data',
											addTooltip: 'Tambah',
											editTooltip: 'Ubah',
											deleteTooltip: 'Hapus',
											editRow: {
												deleteText: 'Anda Yakin Akan Menghapus Data Ini ?',
												cancelTooltip: 'Batal',
												saveTooltip: 'Ya'
											}
										},
										toolbar: {
											searchTooltip: 'Cari',
											searchPlaceholder: 'Cari Data'
										},
										header: {
											actions: 'Actions'
										},
										pagination: {
											labelRowsSelect: 'Baris',
											labelDisplayedRows: ' {from}-{to} Dari {count} Baris',
											firstTooltip: 'Halaman Pertama',
											previousTooltip: 'Halaman Sebelumnya',
											nextTooltip: 'Halaman Selanjutnya',
											lastTooltip: 'Halaman Terakhir'
										}
									}}
								/>
							</div>
						</Container>
				}

			</div>
			<div className={classes.fab1}>
				<Fab
					color="secondary"
					onClick={() => {
						setOpenAddProduct(true);
					}}
					tooltip
				>
					<PostAddIcon />
				</Fab>
				<AddProduct
					open={openAddProduct}
					handleClose={() => {
						setOpenAddProduct(false);
					}}
				/>
			</div>
		</>
	);
}

export default Table;
