import { useEffect, useState } from "react";
import dataBank from "./bank.json";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  firestore,
  FieldValue,
  storage,
} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Clear, PhotoCamera } from "@material-ui/icons";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function VerifikasiBank({ open, handleClose, data, title }) {
  console.log(data);
  const { enqueueSnackbar } = useSnackbar();
  const { company_id } = useData();
  const [form, setForm] = useState({
    bank_tujuan: { name: data?.name, swiftcode: data?.swift_code },
    no_rek: data?.no_rek,
    nama_rek: data?.nama_rek,
  });

  const [error, setError] = useState({
    bank_tujuan: "",
    no_rek: "",
    nama_rek: "",
  });

  // data Bank
  const [Bank, setBank] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [Image, setImage] = useState({ imageLabel: data?.imageLabel, value: data?.value});
  const [errorProd, setErrorProd] = useState({});
  const [prodForm, setProdForm] = useState();
  const [hideAktif, setHideAktif] = useState(false);
  const [erorrCheck, seterorrCheck] = useState("");
  console.log(Image);
  useEffect(() => {
    async function fetchBank() {
      setLoading(true);
      try {
        const getBank = dataBank.map((bank) => bank.name);

        const response = await getBank();

        setBank(response.data);
      } catch (e) {}
      setLoading(false);
    }

    fetchBank();
  }, []);

  const filteredBank = dataBank.filter((Bank) => {
    return Bank.name.toLowerCase().includes(filter.toLowerCase());
  });

  const validate = async () => {
    const newError = { ...error };

    if (!form.bank_tujuan) {
      newError.bank_tujuan = "Bank tujuan harus diisi";
    }

    if (!form.no_rek) {
      newError.no_rek = "No rekening harus diisi";
    }
    if (!form.nama_rek) {
      newError.nama_rek = "Nama rekening harus diisi";
    }
    if (!Image.imageLabel) {
      setErrorProd.thumbnail = "Harap Masukan Data Diri Anda";
    }
    if (!Image) {
      setErrorProd.thumbnail = "Harap Masukan Data Diri Anda";
    }
    if (!hideAktif) {
      seterorrCheck("Anda belum menyetujui penyerahan data diri");
    }

    return newError;
  };
  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
      enqueueSnackbar("Harap Masukan Data Diri Anda", { variant: "error" });
    } else {
      setLoading(true);
      try {
        const MitraBank = firestore.doc(`mitra_bank/${data.id}`);
        if (title) {
          await MitraBank.set(
            {
              ...Image,
              no_rek: form?.no_rek,
              swift_code: form?.bank_tujuan?.swiftcode,
              name: form?.bank_tujuan?.name,
              // created_at: FieldValue.serverTimestamp(),
              // mitra_id: company_id,
              nama_rek: form?.nama_rek,
              check: false,
              check_admin: false,
              verifikasi: true,
              status_check : null ,
            },
            { merge: true }
          );
        } else {
          await MitraBank.set(
            {
              ...Image,
              // no_rek: form?.no_rek,
              // swift_code: form?.bank_tujuan?.swiftcode,
              // name: form?.bank_tujuan?.name,
              // created_at: FieldValue.serverTimestamp(),
              // mitra_id: company_id,
              // nama_rek: form?.nama_rek,
              check: false,
              // check_admin: false,
              verifikasi: true,
            },
            { merge: true }
          );
        }

        setForm({
          bank_tujuan: "",
          no_rek: "",
          nama_rek: "",
        });
        setImage({});
        handleClose();
        enqueueSnackbar("Data Berhasil Dikirim", { variant: "success" });

        // history.push(`product/edit/${form.product_id}`)
      } catch (e) {
        const newError = {};
        enqueueSnackbar("Data Gagal Dikirim", { variant: "error" });

        newError.title = e.message;

        setError(newError);
      }

      setLoading(false);
    }
  };
  // handel upload photo
  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    // console.log('==>', file)
    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      setErrorProd((errorProd) => ({
        ...errorProd,
        thumbnail: `Tipe file tidak didukung: ${file.type}`,
      }));
    } else if (file.size >= 512000) {
      setErrorProd((errorProd) => ({
        ...errorProd,
        thumbnail: `Ukuran file terlalu besar > 500 KB`,
      }));
    } else {
      const reader = new FileReader();
      reader.onabort = () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: `Proses pembacaan file dibatalkan`,
        }));
      };
      reader.onerror = () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: "File tidak bisa dibaca",
        }));
      };
      reader.onload = async () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: "",
        }));
        // setSubmitting(true);
        try {
          const productsStorageRef = storage.ref(`data_pribadi_mitra`);
          const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));
          const thumbnailRef = productsStorageRef.child(
            `${company_id}/data_pribadi_${company_id}_${Date.now()}${thumbnailExt}`
          );
          const thumbnailSnapshot = await thumbnailRef.putString(
            reader.result,
            "data_url"
          );

          const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

          setProdForm((currentForm) => ({
            ...currentForm,
            thumbnail: thumbnailUrl,
          }));
          setImage({ imageLabel: file.name, value: thumbnailUrl });
        } catch (e) {
          setErrorProd((errorProd) => ({
            ...errorProd,
            thumbnail: e.message,
          }));
        }
        // setSubmitting(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(e.target.type)
    setForm({
      ...form,
      [e.target.name]: value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };
  // handle clear image
  const HandleClearImage = async () => {
    let thumbnailURL = Image.value;
    if (Image.value) {
      await storage.refFromURL(thumbnailURL).delete();
    }
    setImage({});
  };

  useEffect(() => {
    setImage({ imageLabel: data?.imageLabel, value: data?.value});
    setForm({
      bank_tujuan: { name: data?.name, swiftcode: data?.swift_code },
      no_rek: data?.no_rek,
      nama_rek: data?.nama_rek,
    });
  }, [data])

  // handleClose Modal
  const HandleCloseModal = () => {
    handleClose(false);
    setImage({ imageLabel: data?.imageLabel, value: data?.value});
    setForm({
      bank_tujuan: { name: data?.name, swiftcode: data?.swift_code },
      no_rek: data?.no_rek,
      nama_rek: data?.nama_rek,
    });
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        `Apakah Anda Yakin Ingin menghapus Data Rekening ini dari akun anda ?`
      )
    ) {
      await firestore.doc(`mitra_bank/${data.id}`).delete();
      enqueueSnackbar("Data Rekening Berhasil Dihapus", { variant: "success" });
    }
    handleClose();
  };
  return (
    <Dialog
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      open={open}
      // onClose={handleClose}
    >
      <DialogTitle>Verifikasi Rekening</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            {!Image.imageLabel ? (
              <>
                <Grid item xs={6}>
                  <input
                    accept="image/jpeg,image/png,image/jpg"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleUploadProduct}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<PhotoCamera />}
                    >
                      Verifikasi Data Diri Anda
                    </Button>
                    {errorProd.thumbnail && (
                      <Typography color="error">
                        {errorProd.thumbnail}
                      </Typography>
                    )}
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <p style={{ fontSize: 13, color: "red" }}>
                    {`> .`} Data Diri Berupa KTP
                    <br />
                    {`> .`} Foto KTP di haruskan jelas
                  </p>
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12}>
                  <div style={{ textAlign: "center" }}>
                    <Typography color="primary">
                      {Image.imageLabel}
                      {"  "}
                      <Button
                        onClick={() => HandleClearImage()}
                        color="primary"
                      >
                        <Clear />
                      </Button>
                    </Typography>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {title ? (
              <Autocomplete
                id="bank_tujuan"
                name="bank_tujuan"
                margin="normal"
                required
                value={form?.bank_tujuan}
                onChange={(event, value) => {
                  handleChange({ target: { name: "bank_tujuan", value } });
                }}
                // disabled={loadingStok || loadingVariants || cekLoad}
                options={filteredBank || ""}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    helperText={error?.bank_tujuan}
                    error={error?.bank_tujuan ? true : false}
                    {...params}
                    label="Bank Tujuan"
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <TextField
                value={data?.name}
                label="Bank Tujuan"
                variant="outlined"
                disabled={!title}
                inputProps={{ style: { color: "#000" } }}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={!title}
              id="no_rek"
              name="no_rek"
              value={form?.no_rek}
              label="No Rekening"
              fullWidth
              type="number"
              variant="outlined"
              inputProps={{ style: { color: "#000" } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              disabled={!title}
              id="nama_rek"
              name="nama_rek"
              value={form?.nama_rek}
              label="Atas Nama"
              error={error.nama_rek ? true : false}
              helperText={error.nama_rek}
              fullWidth
              variant="outlined"
              inputProps={{ style: { color: "#000" } }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              onClick={() => handleDelete()}
              variant="outlined"
              style={{ color: "red", borderColor: "red" }}
            >
              hapus
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => HandleCloseModal()}
          variant="outlined"
        >
          Batal
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || !Image.imageLabel}
          color="primary"
          variant="outlined"
        >
          Kirim
        </Button>
      </DialogActions>
    </Dialog>
  );
}
VerifikasiBank.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
