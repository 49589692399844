import React, { useState, useEffect, Fragment } from 'react';
import { FieldValue, functions, firestore } from '../../../components/FirebaseProvider';
import useStyles from './styles';
// import COD from '../../../images/cod.svg'

// material ui core
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import PageLoading from '../../../components/loading/pageLoading';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { set } from 'date-fns';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// lab

// react router
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RoomIcon from '@material-ui/icons/Room';
import ErrorIcon from '@material-ui/icons/Error';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Search from '@material-ui/icons/Search';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// import LabelPreview from '../../../components/LabelPreview';
import TabPanel from '../../../components/TabPanel';
import { blue, green, grey, orange, purple, red } from '@material-ui/core/colors';
import { unixToDate } from '../../../utils/dateFormatter';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';
import DataOrder from './dataOrder';
import DataSuborder from './dataSuborder';
import OrderExpired from './setOrderExoired';


function ListPo({ value }) {
    const classes = useStyles();

    const { company_id } = useData();

    const [startDate, handleStartDateChange] = useState(set(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    }));
    const [endDate, handleEndDateChange] = useState(set(new Date(), {
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999
    }));
    const [search, setSeacrh] = useState();
    const [filter, setFilter] = useState('pending');
    const [activeOrderId, setActiveOrderId] = useState();
    const [dataOrder, setDataOrder] = useState();
    const [dataSubOrder, setDataSubOrder] = useState();
    const [loadingDataorder, setLoadingDataOrder] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const prodStokQuery = firestore.collectionGroup(`orders`).where('user_id', '==', company_id).where('order_info.domain', '==', 'mitra');

    const subOrderCol = (orderId, userId) => firestore.collection(`mitra/${userId}/orders/${orderId}/suborders`);

    useEffect(() => {
        getAllOrders()
    }, [filter])

    useEffect(() => {
        if (search && search.length > 0) getOrdersWithSearch()
        else getOrders()
    }, [search,])

    const getAllOrders = () => {
        setLoadingDataOrder(true);
        getOrders()
    }

    const getOrders = () => {
        let query = prodStokQuery.where('created_at', '>=', startDate).where('created_at', '<=', endDate)
        if (filter) {
            query = query.where("status", "==", filter)
        }
        query.get()
            .then((snapshot) => {
                setLoadingDataOrder(false)
                let dumpArr = []
                snapshot.docs.forEach(doc => {
                    const id = doc.id
                    const data = { id, ...doc.data() }
                    dumpArr.push(data)
                })
                // console.log(dumpArr)
                setDataOrder(dumpArr)
            })
            .catch(err => {
                setLoadingDataOrder(false)
                console.log('Error : ', err)
            })
    }

    const getDataSubOrder = (order, update) => {
        // console.log('sub', order?.mitra_id)
        const orderId = order?.id
        const userId = order?.mitra_id
        if (orderId === activeOrderId && !update) {
            return setActiveOrderId('')
        }
        setActiveOrderId(orderId);
        subOrderCol(orderId, userId).get()
            .then((snapshot) => {
                let dumpArr = []
                snapshot.docs.forEach(doc => {
                    const id = doc.id
                    const data = { id, ...doc.data() }
                    dumpArr.push(data)
                })
                setDataSubOrder(dumpArr)
            })
            .catch(err => {
                console.log('Get Data Suborder Error : ', err)
            })
    }

    // console.log('suborder', dataSubOrder);
    const getOrdersWithSearch = () => {
        prodStokQuery.where('kode_order', '==', search).get()
            .then((snapshot) => {
                setLoadingDataOrder(false)
                let dumpArr = []
                snapshot.docs.forEach(doc => {
                    const id = doc.id
                    const data = { id, ...doc.data() }
                    dumpArr.push(data)
                })
                setDataOrder(dumpArr)
            })
            .catch(err => {
                setLoadingDataOrder(false)
                // console.log('Error : ', err)
            })
    }

    const handleAlertClose = () => {
        setShowAlert(false)
        setIsError('')
    }

    const [openAddDialog, setOpenAddDialog] = useState(false);
    // const [editDialog, setEditDialog] = useState({
    //     open: false,
    //     data: {},
    //     uid: {}
    // });

    const handleChangeTab = (event, value) => {
        setFilter(value);

    }
    const [showResults, setShowResults] = React.useState(false)

    // const handleReveral = async (data) => {
    //     try {
    //         const users = firestore.collection(`mitra/${company_id}/sellers`);

    //         const dataUser = (await users.doc(data.order_info.owner_id).get()).data();
    //         console.log(data);

    //         const subOrder = firestore.collection(`mitra/${company_id}/orders/${data.orderId}/suborders`);
    //         const dataOrder = firestore.collection(`mitra/${company_id}/orders`);
    //         // console.log(dataLama);
    //         if (dataUser.tipe_akun === "reseller") {
    //             await subOrder.doc(data.id).set(
    //                 {
    //                     ref_agen: dataUser.ref_agen,
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                     ref_distributor: dataUser.ref_distributor,
    //                 },
    //                 { merge: true }
    //             );
    //             await dataOrder.doc(data.orderId).set(
    //                 {
    //                     ref_agen: dataUser.ref_agen,
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                     ref_distributor: dataUser.ref_distributor,
    //                 },
    //                 { merge: true }
    //             );
    //         } else if (dataUser.tipe_akun === "agen") {
    //             await subOrder.doc(data.id).set(
    //                 {
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                     ref_distributor: dataUser.ref_distributor,
    //                 },
    //                 { merge: true }
    //             );
    //             await dataOrder.doc(data.orderId).set(
    //                 {
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                     ref_distributor: dataUser.ref_distributor,
    //                 },
    //                 { merge: true }
    //             );
    //         } else if (dataUser.tipe_akun === "distributor") {
    //             await subOrder.doc(data.id).set(
    //                 {
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                 },
    //                 { merge: true }
    //             );
    //             await dataOrder.doc(data.orderId).set(
    //                 {
    //                     ref_mentorbisnis: dataUser.ref_mentorbisnis,
    //                 },
    //                 { merge: true }
    //             );
    //         }
    //         getDataSubOrder(data.orderId, true);
    //         enqueueSnackbar("kode reveral Berhasil Diperbarui", { variant: "success" });
    //         //   console.log("ubah berhasil");
    //     } catch (e) {
    //         //   console.log("ubah galal");
    //         console.log(e.message);
    //     }
    // };


    // const subOrd = dataOrder?.map((ord) => {
    //     return ord?.id
    // })

    // console.log(dataOrder)

    // const subOrdCol = firestore.collection(`orders/${subOrd}/suborders`);

    // const [jumlahOrder, loadJumlahOrder] = useCollectionData(subOrdCol, { idField: 'id' });
    // const subOrder = jumlahOrder?.map((ord) => {
    //     return ord.id
    // })
    // console.log(dataOrder?.map((ord) => {
    //     return ord.id
    // }))
    // if (loadJumlahOrder) {
    //     return <PageLoading />
    // }
    return <>
        <TabPanel value={value} index="listPO">
            {/* <Container maxWidth='lg'> */}
            <Paper className={classes.paperWrap2}>
                <div>
                    <div className={classes.headerFilterContainer}>
                        <Typography className={classes.margin3} component="h1" variant="h6">Daftar List PO Mitra</Typography>
                        <Typography>Filter Berdasarkan Tanggal</Typography>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <KeyboardDatePicker
                                                maxDate={new Date()}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Dari Tanggal"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <KeyboardDatePicker
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Sampai Tanggal"
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => getAllOrders()}>Search</Button>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='flex-end'>
                            <Grid item>
                                <TextField
                                    label="Cari Kode Order"
                                    value={search}
                                    id="search"
                                    name="search"
                                    onChange={(e) => {
                                        setSeacrh(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Search />
                            </Grid>
                        </Grid>
                        {loadingDataorder ?
                            <PageLoading size={50} color='inherit' /> : ''}
                    </div>
                    <div className={classes.tabsWrap}>
                        <div className={classes.tabLink}>
                            <Tabs indicatorColor="primary"
                                variant="fullWidth"
                                className={classes.tab}
                                textColor="primary"
                                value={filter}
                                onChange={handleChangeTab}>
                                <Tab label="Belum Bayar" value='pending' icon={<WatchLaterIcon />} />
                                <Tab label="Konfirmasi" value='paid' icon={<CheckCircleIcon />} />
                                <Tab label="Diproses" value='on-process' icon={<AutorenewIcon />} />
                                <Tab label="Dikirim" value='on-delivery' icon={<LocalShippingIcon />} />
                                <Tab label="Diterima" value='received' icon={<RoomIcon />} />
                                <Tab label="Selesai" value='completed' icon={<RoomIcon />} />
                                <Tab label="Expired" value='expired' icon={<RoomIcon />} />
                                <Tab label="Cancel" value='canceled'  icon={<RoomIcon />}/>
                            </Tabs>
                        </div>
                        <TabPanel value={(e) => {
                            setFilter('pending');
                        }} index='pending' />

                        <TabPanel value={(e) => {
                            setFilter('paid');
                        }} index='paid' />
                        <TabPanel value={(e) => {
                            setFilter('on-process');
                        }} index='on-process' />
                        <TabPanel value={(e) => {
                            setFilter('on-delivery');
                        }} index='on-delivery' />
                        <TabPanel value={(e) => {
                            setFilter('received');
                        }} index='received' />
                        <TabPanel value={(e) => {
                            setFilter('completed');
                        }} index='completed' />
                        <TabPanel value={(e) => {
                            setFilter('expired');
                        }} index='expired' />
                         <TabPanel value={(e) => {
                            setFilter('canceled');
                        }} index='canceled' />
                        {
                            showAlert && (
                                <Snackbar
                                    open={showAlert}
                                    autoHideDuration={5000}
                                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                    onClose={handleAlertClose}>
                                    <Alert onClose={handleAlertClose} severity={isError ? 'error' : 'success'}>
                                        {alertText}
                                    </Alert>
                                </Snackbar>
                            )
                        }
                        {
                            dataOrder?.length <= 0 &&
                            <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Order</Typography>
                        }
                    </div>
                    {
                        dataOrder?.map?.((order) => {

                            return <><Paper key={order.id} className={classes.paper}>
                                <div className={classes.orderBlock}>
                                    <OrderExpired dataOrder={order} getOrders={getOrders} />
                                    <div className={classes.orderStatus}>
                                        {
                                            order.status === 'pending' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: orange[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: orange[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: orange[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'paid' || order.status === 'cod' ?
                                                <>
                                                    <div className={classes.statusBarItem}>
                                                        <FiberManualRecordIcon style={{ color: green[500] }} />
                                                        <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                    </div>

                                                    <div className={classes.statusBarItem}>
                                                        <MonetizationOnIcon style={{ color: green[500] }} />
                                                        <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                    </div>
                                                    <div className={classes.statusBarItem}>
                                                        <LocalShippingIcon style={{ color: green[500] }} />
                                                        <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                    </div></> : null
                                        }
                                        {
                                            order.status === 'received' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'completed' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: green[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'on-delivery' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: purple[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: purple[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: purple[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'on-process' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: blue[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: blue[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: blue[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'expired' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: grey[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: grey[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: grey[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                        {
                                            order.status === 'canceled' &&
                                            <>
                                                <div className={classes.statusBarItem}>
                                                    <FiberManualRecordIcon style={{ color: red[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status}</Typography>
                                                </div>

                                                <div className={classes.statusBarItem}>
                                                    <MonetizationOnIcon style={{ color: red[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pembayaran}</Typography>
                                                </div>
                                                <div className={classes.statusBarItem}>
                                                    <LocalShippingIcon style={{ color: red[500] }} />
                                                    <Typography className={classes.statusLabel}>{order.status_pengiriman}</Typography>
                                                </div></>
                                        }
                                    </div>

                                    <div className={classes.btnDetailWrap}>

                                        <Button variant="contained" className={classes.btnDetail} onClick={() => {
                                            setShowResults(open => ({
                                                // ...open,
                                                [order.id]: !open[order.id]
                                            }))
                                            getDataSubOrder(order);

                                        }}>
                                            <Typography>Details</Typography>
                                            <ExpandMoreIcon className={classes.detailIcon} />
                                        </Button>
                                    </div>
                                </div>
                                {
                                    showResults[order.id] ? <DataOrder
                                        order={order}
                                        buttonLoading={buttonLoading}
                                        // changeStatus={changeStatus}
                                        // handleReveral={handleReveral}
                                        activeOrderId={activeOrderId}
                                        dataSubOrder={dataSubOrder}
                                        setOpenAddDialog={setOpenAddDialog}
                                        openAddDialog={openAddDialog}
                                        getDataSubOrder={getDataSubOrder}
                                        enqueueSnackbar={enqueueSnackbar}
                                        getOrders={getOrders}
                                    /> : null
                                }
                                <DataSuborder
                                    order={order}
                                    buttonLoading={buttonLoading}
                                    // changeStatus={changeStatus}
                                    activeOrderId={activeOrderId}
                                    dataSubOrder={dataSubOrder}
                                    setOpenAddDialog={setOpenAddDialog}
                                    openAddDialog={openAddDialog}
                                    // setEditDialog={setEditDialog}
                                    // editDialog={editDialog}
                                    getDataSubOrder={getDataSubOrder}
                                    enqueueSnackbar={enqueueSnackbar}
                                    getOrders={getOrders}
                                />
                            </Paper>
                            </>
                        })
                    }
                    {/* <Edit
                        editDialog={editDialog}
                        handleClose={() => {
                            setEditDialog({ open: false, dataSub: {} });
                        }}
                        handleEditKurir={handleEditKurir}
                    /> */}

                </div>
            </Paper>
            {/* </Container> */}
        </TabPanel>
    </>
}

export default ListPo;
