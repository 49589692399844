import React from "react";
import { currency } from "../../../utils/formatter";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import PublicIcon from "@material-ui/icons/Public";
import { indigo } from "@material-ui/core/colors";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { useData } from "../../../components/DataProvider";

export default function KomisiBox(props) {
  const { levelSetting } = useData();
  // console.log(props);
  const custom_name = levelSetting?.data?.()?.custom_name;
  const fix = require("lodash");
  const classes = useStyles();
  return (
    <div className={classes.komisiBox}>
      {
        custom_name &&
        Object?.keys?.(custom_name)?.map?.((key) => {
          return (
            <div className={classes.komisiRow}>
              <div className={classes.komisiLevel}>
                <Typography
                  className={classes.orderLabel}
                  component="h4"
                  variant="h5"
                >
                  {custom_name?.[key]}
                </Typography>
                <Typography className={classes.innerValue}>
                  <span className={classes.innerValue}>
                    {/* {props.order?.[`ref_${key}`]
                      ? `${props.order?.[`ref_${key}`]?.user_id}`
                      : "-"} */}
                    {props?.order?.[`ref_${key}`]
                      ? `${props?.order?.[`ref_${key}`]?.path}`
                      : "-"}
                  </span>
                </Typography>
              </div>
              <Typography className={classes.subdomLine}>
                <PublicIcon
                  className={classes.iconLeft}
                  style={{ color: indigo[500] }}
                />{" "}
                {props?.order?.[`ref_${key}`]
                  ? `${props?.order?.[`ref_${key}`]?.path}`
                  : "-"}
              </Typography>
              <Typography className={classes.komisiLine}>
                <AccountBalanceWalletIcon
                  color="primary"
                  className={classes.iconLeft}
                />{" "}
                {currency(
                  fix.round(`${props?.order?.[`ref_${key}`]?.komisi}`) || 0
                )}
              </Typography>
            </div>
          );
        })
      }
    </div>
  );
}
