import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    iframe: {
        marginTop: '-10vh',
        border: 0,
        width: '100%',
        height: '100vh',
        borderRadius: 0
    }
}));

export default useStyles;
