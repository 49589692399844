import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CartOrderManual from './cartOrderManual';
import ListOrder from './listOrder';
import useStyles from './styles';

function Orders() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Switch>
                <Route path="/orders" component={ListOrder} />
                <Route path="/orders/cartOrderManual" component={CartOrderManual} />
                <Redirect to="/orders" />
            </Switch>
        </div>

    );
}

export default Orders;