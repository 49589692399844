import React, { useEffect, useState } from 'react';
import TabPanel from '../../../components/TabPanel';

// material ui 
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FieldValue, firestore, functions, GeoPoint } from '../../../components/FirebaseProvider';
import { useDocument } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
// import KeyApi from '../../../config/keyApi';
import RoomIcon from '@material-ui/icons/Room';
import { red } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { fr } from 'date-fns/locale';
import KeyApi from '../../../config/keyApiGoogleMap';
// eslint-disable-next-line no-sequences
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogKelurahan from '../../../components/GetKelurahan/dialogSearch';
import { geohashForLocation } from 'geofire-common';
// import MyPlaces from './searchPinPoint';
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-google-places-autocomplete";
import { Alert } from '@material-ui/lab';

export default function Alamat({ value, uid, use_available, shipLoading, dataProfile, broadcastingList }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isBroadcast = broadcastingList?.find(e => e.type === 'update_alamat')
    const settDoc = firestore.doc(`mitra/${uid}/settings/alamat`);
    const [settSnapshot, settLoading] = useDocument(settDoc);
    const shippingProviderWithKelurahan = ['shipper', 'sicepat', 'directapi'];


    const [form, setForm] = useState({
        provider: '',
        alamat_lengkap: '',
        koordinat: { latitude: -6.200511971721622, longitude: 106.849624984375 },
        pickupCoverage: { sicepat: false, jne: false, ninja : false, jnt : false }
        // judul: '',
        // nama_penerima: ''
    });

    // dialog search
    const [open, setOpen] = useState(false);


    const [error, setError] = useState({})

    useEffect(() => {
        if (settSnapshot) {
            const activeProvider = settSnapshot?.data?.()?.provider
            const data = settSnapshot?.data?.()?.[activeProvider]
            setForm({
                ...form,
                ...data,
                provider: activeProvider
            });

        }

    }, [settSnapshot]);

    const handleChange = e => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }



    useEffect(() => {
        setForm({ ...form, pickupCoverage: {
        sicepat: form?.kelurahan?.coverage_pickup === 'Y' ? true : false,
        jne: form?.kelurahan?.kode_origin_jne ? true : false,
        ninja : form?.kelurahan?.kode_ninja_lt1 && form?.kelurahan?.kode_ninja_lt2 ? true : false,
        jnt : form?.kelurahan?.kode_origin_jnt ? true : false
    } })
    }, [form?.kelurahan?.id])

    // console.log("=>",form)

    const validate = async () => {
        const newError = { ...error };



        if (!form.alamat_lengkap) {
            newError.alamat_lengkap = 'alamat_lengkap harus diisi';
        }


        return newError;
    }
    const [isSubmitting, setSubmitting] = useState(false);
    //console.log(form?.koordinat)
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const settDoc = firestore.doc(`mitra/${uid}/settings/alamat`);
                
                if ( isBroadcast ) {
                    const settBroadcast = firestore.doc(`broadcast/${isBroadcast?.id}`);
                    await settBroadcast.set(
                        { list_update: [...isBroadcast?.list_update, uid] },
                        { merge: true }
                      );
                
                }
                const updProd = {
                    [form?.provinsi?.provider]: {
                        alamat_lengkap: form?.alamat_lengkap,
                        //judul: form.judul,
                        //nama_penerima: form.nama_penerima,
                        provinsi: form?.provinsi,
                        kota: form?.kota,
                        kecamatan: form?.kecamatan,
                        kelurahan: form?.kelurahan,
                        coverage_pickup_sicepat: form?.kelurahan?.coverage_pickup === 'Y' ? true : false,
                        coverage_pickup_jne: form?.kelurahan?.kode_origin_jne !== null ? true : false,
                        updated_at: FieldValue.serverTimestamp(),
                        koordinat: new GeoPoint(form?.koordinat?.latitude, form?.koordinat?.longitude)
                    },
                    provider: form?.provinsi?.provider,
                    drop: drop
                    
                };
                
                await settDoc.set(updProd, { merge: true });

                
                const hash = geohashForLocation([form?.koordinat?.latitude, form?.koordinat?.longitude]);
                await firestore.doc(`geo_mitra/${uid}`).set({
                    geohash: hash,
                    lat: form?.koordinat?.latitude,
                    lng: form?.koordinat?.longitude
                }, { merge: true });

                enqueueSnackbar('Alamat berhasil disimpan', { variant: "success" })

            } catch (e) {
                const newError = {};
                // console.log(e.message)
                newError.title = e.message;
                enqueueSnackbar(`Alamat gagal disimpan,${e.message}`, { variant: "error" })
                setError(newError);
            }

            setSubmitting(false)
        }
    }

    // console.log("Latitude is :", form?.koordinat?.latitude);
    // console.log("Longitude is :", form?.koordinat?.longitude);

    const handleClick = (mark) => {
        setForm({
            ...form,
            koordinat: { latitude: mark.lat, longitude: mark.lng },

        })
    }

    // drop
    const [drop, setDrop] = React.useState(false);

    const handleChecked = (event) => {
        setDrop(event.target.checked);
    };

    // search pinpoint
    const [values, setValue] = useState(null);
    const localtion = values?.label;

    useEffect(() => {
        async function GetLtLng() {
            if (localtion) {
                await geocodeByAddress(localtion)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        setForm({
                            ...form,
                            koordinat: { latitude: lat, longitude: lng }
                        })
                        // setlatlang({
                        //     lat: lat, lng: lng
                        // })
                        console.log({ lat, lng });

                        // setValue()
                        // setZoom(10)
                    });
            }
        }
        GetLtLng()
    }, [localtion]);

    if (settLoading || shipLoading) {
        return <PageLoading />
    }
    return (
        <>      
            <TabPanel value={value} index="alamat">
                {!dataProfile?.alamat || !dataProfile?.telepon ? 
                    <Grid item xs={12} lg={12}>
                        <Alert severity="error"> Maaf Lengkapi Profile Anda Terlebih Dahulu !!!</Alert>
                    </Grid>
                :
                    <>
                        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Alamat Pusat</Typography> 
                        <Typography variant="subtitle2">Provider Shipping :{form.provider}</Typography>
                        <Typography variant="subtitle2">Alamat Lengkap : {form.alamat_lengkap}</Typography>
                        <Typography variant="subtitle2">Kelurahan : {form.kelurahan.name}</Typography>
                        <Typography variant="subtitle2">Kordinat : {form.kordinat}</Typography> */}
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <p style={{ margin: 0, fontSize: 12, marginLeft: 16, marginBottom: 5, color: "#8f877d", }}>Cari Nama Desa atau Kelurahan</p>
                                        <div
                                            style={{
                                                width: 497,
                                                border: "1px solid #A0A0A0",
                                                color: "#8f877d",
                                                borderRadius: '7px',
                                                padding: '12px'
                                            }}
                                            onClick={() => setOpen(true)}
                                        >
                                            <p style={{ margin: "5px 0px", color: "#000", fontSize: 15 }}>
                                                {form?.kelurahan?.name ? (
                                                    <>
                                                        {form.kelurahan?.name} - Kec. {form.kecamatan?.name}
                                                        {"\n"}
                                                        {form.kota?.name}, {form.provinsi?.name},{" "}
                                                        {form.kelurahan?.postal_code}
                                                    </>
                                                ) : ('Klik untuk mencari'
                                                    // <span style={{ color: "#BABABA", fontSize: 14, height: 50}}>
                                                    //   Contoh : Pancoran
                                                    // </span>
                                                )}
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.autocomplete}
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="alamat_lengkap"
                                            name="alamat_lengkap"
                                            label="Alamat Lengkap (untuk Pickup pengiriman)"
                                            value={form.alamat_lengkap || ''}
                                            onChange={handleChange}
                                            error={error.alamat_lengkap ? true : false}
                                            helperText={error.alamat_lengkap}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={drop}
                                                    onChange={handleChecked}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Drop Barang (tidak di pickup)"
                                        /> */}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className={classes.mapLabel}>Pickup Coverage : </Typography>
                                        <Grid container spacing={1}>
                                            {
                                                form?.pickupCoverage?.sicepat === true ?
                                                <Grid item>
                                                    <Chip label="SiCepat" color="primary" icon={<DoneIcon />} />
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Chip label="SiCepat" icon={<CloseIcon />} disabled />
                                                </Grid>
                                            }
                                            {
                                                form?.pickupCoverage?.jne === true ?
                                                <Grid item>
                                                    <Chip label="JNE" color="primary" icon={<DoneIcon />} />
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Chip label="JNE" icon={<CloseIcon />} disabled />
                                                </Grid>
                                            }
                                            {
                                                form?.pickupCoverage?.ninja === true ?
                                                <Grid item>
                                                    <Chip label="Ninja" color="primary" icon={<DoneIcon />} />
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Chip label="Ninja" icon={<CloseIcon />} disabled />
                                                </Grid>
                                            }
                                            {
                                                form?.pickupCoverage?.jnt === true ?
                                                <Grid item>
                                                    <Chip label="JNT" color="primary" icon={<DoneIcon />} />
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Chip label="JNT" icon={<CloseIcon />} disabled />
                                                </Grid>
                                            }
                                            {/* {
                                                form?.kelurahan?.postal_code ?
                                                <Grid item>
                                                    <Chip label="POS" color="primary" icon={<DoneIcon />} />
                                                </Grid>
                                                :
                                                <Grid item>
                                                    <Chip label="POS" icon={<CloseIcon />} disabled />
                                                </Grid>
                                            } */}
                                        </Grid>
                                    </Grid>

                                    {/* <div item xs={12}>
                                        <TextField
                                            className={classes.autocomplete}
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="judul"
                                            name="judul"
                                            label="Judul"
                                            value={form.judul || ''}
                                            onChange={handleChange}
                                            error={error.judul ? true : false}
                                            helperText={error.judul}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </div> 
                                    <div item xs={12}>
                                        <TextField
                                            className={classes.autocomplete}
                                            autoComplete="off"
                                            disabled={isSubmitting}
                                            id="nama_penerima"
                                            name="nama_penerima"
                                            label="Nama Penerima"
                                            value={form.nama_penerima || ''}
                                            onChange={handleChange}
                                            error={error.nama_penerima ? true : false}
                                            helperText={error.nama_penerima}
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                        />
                                    </div> */}
                                </Grid>
                            </Grid>
                            <Grid item container lg={6} md={6} xs={12}>
                                <Grid item xs={12}>
                                    <Typography className={classes.mapLabel}>Koordinat</Typography>
                                    {/* <MyPlaces keyGoogle='AIzaSyAMUMuva5VkEeo4WbgbTcL5d-xxoTI54Ig' /> */}
                                    <div style={{ width: "100%" }}>
                                        <GooglePlacesAutocomplete
                                            placeholder="Type in an address"
                                            selectProps={{
                                                values,
                                                onChange: setValue,
                                                placeholder: 'Cari Pin Point'
                                            }}
                                            // autocompletionRequest={{
                                            //     componentRestrictions: {
                                            //         country: ["id"]
                                            //     }
                                            // }}
                                            apiKey='AIzaSyAMUMuva5VkEeo4WbgbTcL5d-xxoTI54Ig'
                                        // apiOptions={{ language: "id", region: "id" }}
                                        />
                                    </div>
                                    <div className={classes.mapBox}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: KeyApi }}
                                            defaultCenter={{
                                                lat: -6.200000,
                                                lng: 106.816666
                                            }}
                                            defaultZoom={8}
                                            center={{
                                                lat: form?.koordinat?.latitude,
                                                lng: form?.koordinat?.longitude
                                            }}
                                            // zoom={zoom}
                                            // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                            onClick={handleClick}
                                        >
                                            <RoomIcon
                                                lat={form?.koordinat?.latitude || -6.200511971721622}
                                                lng={form?.koordinat?.longitude || 106.849624984375}
                                                style={{
                                                    color: red[500], position: 'absolute',
                                                    transform: 'translate(-50%, -50%)'
                                                }} />

                                        </GoogleMapReact>
                                        <form action="" method="post">
                                            <input type="text" id="lat" name="lat" value={form?.koordinat?.latitude || -6.200511971721622} />
                                            <input type="text" id="lng" name="lng" value={form?.koordinat?.longitude || 106.849624984375} />
                                        </form>
                                    </div>

                                </Grid>

                                <Grid item xs={12} justify="flex-end" alignItems="flex-end" style={{ display: 'flex' }}>
                                    <Grid item xs={3} >
                                        <Button
                                            disabled={isSubmitting}
                                            onClick={handleSubmit}
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            className={classes.btnSave}
                                        >Simpan</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <DialogKelurahan
                            open={open}
                            onClose={() => setOpen(false)}
                            value={(v) => {
                                setForm((f) => ({
                                    ...f,
                                    ...v,
                                }));
                            }}
                        />
                    </>
                }
            </TabPanel>
        </>
    )
}
