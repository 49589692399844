import React, { useState } from "react";
import {
  FieldValue,
  firestore,
  functions,
} from "../../../components/FirebaseProvider";
import useStyles from "./styles";

// material ui core
import PageLoading from "../../../components/loading/pageLoading";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// react router

import RoomIcon from "@material-ui/icons/Room";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ScheduleIcon from "@material-ui/icons/WatchLater";
import { CircularProgress, TextField } from "@material-ui/core";
import CancelOrders from "./cancelOrders";

import { currency } from "../../../utils/formatter";
import { Fragment } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import KomisiBox from "./komisiBox";
import { useData } from "../../../components/DataProvider";
import { set } from "date-fns";
function ShowOrder({
  order,
  buttonLoading,
  changeStatus,
  handleReveral,
  getDataSubOrder,
  enqueueSnackbar,
  getOrders,
  setOpenAddDialog,
  dataSubOrder,
}) {
  const classes = useStyles();
  const subOrd = order?.id;
  const { company_id, hideSetting } = useData();
  const [HideRefund, setHideRefund] = useState(hideSetting.data().hideRefund);
  const [paymentNow, setDisablePayment] = useState(
    hideSetting.data().disabledPaymentNow
  );
  // console.log("==>", HideRefund)
  const subOrdCol = firestore.collection(
    `mitra/${company_id}/orders/${subOrd}/suborders`
  );

  const [jumlahOrder, loadJumlahOrder] = useCollectionData(subOrdCol, {
    idField: "id",
  });
  // console.log(jumlahOrder);
  const data = jumlahOrder?.map((ord) => {
    return ord;
  });

  // console.log("==<",subOrdCol)
  const jumlahAlamat = data?.length;

  const jumlahBarang = jumlahOrder?.map?.((sub) => {
    return sub?.products_qty?.reduce?.((val, currVal) => {
      return val + currVal;
    });
  });

  // goods_issue
  const handleGoodsIssue = (order) => async () => {
    try {
      // setButtonLoading(true);

      const varCol = firestore.collection(
        `mitra/${company_id}/orders/${order.id}/suborders`
      );
      varCol
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            updateSuborderIssue(order.id, doc, doc?.data());
          });
        })
        .catch((err) => {
          console.log("Error : ", err);
        });
      // setButtonLoading(false);

      enqueueSnackbar("Status berhasil dirubah", { variant: "success" });
    } catch (e) {
      console.log(e.message);
    }
  };
  const updateSuborderIssue = async (orderId, suborder, prodId) => {
    // console.log(prodId)
    const ordDoc = firestore.doc(`mitra/${company_id}/orders/${orderId}`);

    await ordDoc.set(
      {
        goods_issue: true,
        goodsissue_at: FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    const varCol = firestore.collection(
      `mitra/${company_id}/orders/${orderId}/suborders`
    );
    const lengthSub = prodId?.productIds?.length;
    for (var i = 0; i < lengthSub; i++) {
      if (i !== undefined) {
        const prdStocDoc = firestore.doc(
          `mitra/${company_id}/products_stock/${prodId?.productIds?.[i]}`
        );
        await prdStocDoc.set(
          {
            stok_booked: FieldValue.increment(-prodId?.products_qty?.[i]),
            updated: FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      }
    }

    await varCol
      .doc(suborder?.id)
      .set(
        {
          goods_issue: true,
          goodsissue_at: FieldValue.serverTimestamp(),
        },
        {
          merge: true,
        }
      )
      .then((res) => {
        console.log("Success update sub order status");
      })
      .catch((err) => {
        console.log("Failed to update sub order status");
      });
    getDataSubOrder(orderId, true);
    getOrders();
  };

  // pickup
  const [loading, setLoading] = useState(false);

  // time
  const date = new Date();
  date.setHours(23, 0);
  const newFormat = date?.toISOString()?.substring(0, 16);
  const [time, setTime] = React.useState(newFormat);
  // var theBigDay = new Date();
  // theBigDay.setHours(7, 0);
  // console.log('date', `${time.split('T')?.[0]} ${time.split('T')?.[1]}`)
  const handleChangeDate = (newValue) => {
    setTime(newValue.target.value);
  };
  const [CancelOrder, setCancel] = useState({
    open: false,
    data: {},
  });
  const [pickupRespon, setPickup] = useState();
  const handlePickup = (order) => async () => {
    setLoading(true);

    try {
      const varCol = firestore.collection(
        `mitra/${company_id}/orders/${order.id}/suborders`
      );
      varCol
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            pickupReq(order.id, doc);
          });
        })
        .catch((err) => {
          console.log("Error : ", err);
        });
    } catch (e) {
      // console.log('err', e.message)

      enqueueSnackbar(e.message, { variant: "error" });
      setLoading(false);
    }
  };

  const pickupReq = async (orderId, suborder) => {
    setLoading(true);
    try {
      if (!suborder?.data()?.resi_otomatis_mp) {
        const shippingPickup = functions.httpsCallable("shipping-pickup");

        // console.log( '==>>>',orderId , "<" , suborder?.id , `${time?.split('T')?.[0]} ${time?.split('T')?.[1]}}`)
        const response = await shippingPickup({
          order_id: orderId,
          suborder_id: suborder?.id,
          datePickup: `${time?.split("T")?.[0]} ${time?.split("T")?.[1]}`,
        });
        const data = response.data;
        setPickup(data);
      }
      const ordDoc = firestore.doc(`mitra/${company_id}/orders/${orderId}`);

      await ordDoc.set(
        {
          status: "on-delivery",
          status_pengiriman: "on-delivery",
          pickup_at: FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
      const varCol = firestore.collection(
        `mitra/${company_id}/orders/${orderId}/suborders`
      );

      await varCol.doc(suborder?.id).set(
        {
          status: "on-delivery",
          status_pengiriman: "on-delivery",
          pickup_at: FieldValue.serverTimestamp(),
        },
        {
          merge: true,
        }
      );
      setLoading(false);
      getOrders();
      getDataSubOrder(order.id, true);
      enqueueSnackbar("Pickup Request Berhasil ", { variant: "success" });
    } catch (e) {
      console.log(e.message);
      const error = e.message;
      enqueueSnackbar(`Pickup Gagal,${e.message}`, { variant: "error" });

      if (error.includes("502")) {
        alert("Server sedang sibuk, Silahkan coba lagi nanti");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  let split = order?.kode_order?.substring(0, 1);
  // console.log(split)

  if (loadJumlahOrder) {
    return <PageLoading />;
  }
  return (
    <>
      <div className={classes.orderBox}>
        <div className={classes.orderRowTop}>
          <div className={classes.orderColTopLeft}>
            <div className={classes.innerRow}>
              <div className={classes.timelineStatus}>
                <div className={classes.timelineBox}>
                  <div
                    className={`${
                      order.status === "pending" ||
                      order.status === "paid" ||
                      order.status === "cod" ||
                      order.status === "on-process" ||
                      order.status === "on-delivery" ||
                      order.status === "received"
                        ? `${classes.checkpoint} ${classes.active}`
                        : classes.checkpoint
                    }`}
                  >
                    <ScheduleIcon />
                  </div>
                </div>
                <div className={classes.timelineBox}>
                  <div
                    className={`${
                      order.status === "paid" ||
                      order.status === "cod" ||
                      order.status === "on-process" ||
                      order.status === "on-delivery" ||
                      order.status === "received"
                        ? `${classes.checkpoint} ${classes.active}`
                        : classes.checkpoint
                    }`}
                  >
                    <CheckCircleIcon />
                  </div>
                </div>
                <div className={classes.timelineBox}>
                  <div
                    className={`${
                      order.status === "on-process" ||
                      order.status === "on-delivery" ||
                      order.status === "received"
                        ? `${classes.checkpoint} ${classes.active}`
                        : classes.checkpoint
                    }`}
                  >
                    <AutorenewIcon />
                  </div>
                </div>
                <div className={classes.timelineBox}>
                  <div
                    className={`${
                      order.status === "on-delivery" ||
                      order.status === "received"
                        ? `${classes.checkpoint} ${classes.active}`
                        : classes.checkpoint
                    }`}
                  >
                    <LocalShippingIcon />
                  </div>
                </div>
                <div className={classes.timelineBox}>
                  <div
                    className={`${
                      order.status === "received"
                        ? `${classes.checkpoint} ${classes.active}`
                        : classes.checkpoint
                    }`}
                  >
                    <RoomIcon />
                  </div>
                </div>
              </div>
              <div className={classes.innerRow}>
                {/* <Typography className={classes.orderLabel} component="h4" variant="h5">Transaction Info</Typography> */}
                <div className={classes.transactionBox}>
                  <div className={classes.transactionRow}>
                    <Typography
                      className={classes.transactionLabel}
                      component="h4"
                      variant="h5"
                    >
                      Transaksi Via
                    </Typography>
                    <Typography className={classes.transactionValue}>
                      : {order?.order_info?.location}{" "}
                      <span className={classes.sellerName}>
                        {order?.order_info?.domain !== "sellerapp"
                          ? order?.order_info?.domain
                          : ""}
                      </span>
                    </Typography>
                  </div>
                  <div className={classes.transactionRow}>
                    <Typography className={classes.transactionItems}>
                      <span>
                        {data.length > 0
                          ? jumlahBarang?.reduce?.((val, currVal) => {
                              return val + currVal;
                            })
                          : jumlahBarang}
                      </span>
                      Barang,
                    </Typography>
                    <Typography className={classes.transactionItems}>
                      Dikirim ke {jumlahAlamat} Alamat
                    </Typography>
                  </div>
                  {/* <div className={classes.transactionRow}>
                                    <Typography className={classes.transactionLabel}>Status Transaksi</Typography>
                                    <Typography className={classes.transactionValue}>: {order?.transaction_info?.transaction_status}</Typography>
                                    </div>
                                    <div className={classes.transactionRow}>
                                        <Typography className={classes.transactionLabel}>Tipe Pembayaran</Typography>
                                        <Typography className={classes.transactionValue}>: {order?.transaction_info?.payment_type}</Typography>
                                    </div> */}
                </div>
                <div className={classes.totalPricing}>
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total Tagihan
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      : {currency(order?.total_tagihan)}
                    </Typography>
                  </div>
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total Promo
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      : {currency(order?.total_promo)}
                    </Typography>
                  </div>
                  {order?.order_info?.location === "nipos" ? (
                    <div className={classes.innerRowBot}>
                      <Typography
                        className={classes.orderLabelBot}
                        component="h4"
                        variant="h5"
                      >
                        Total Diskon
                      </Typography>
                      <Typography className={classes.orderValueBot}>
                        : {currency(order?.total_diskon_produk_nipos)}
                      </Typography>
                    </div>
                  ) : null}
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total biaya pengiriman
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      :{" "}
                      {order?.order_info?.location === "nipos"
                        ? currency(order?.total_ongkir_produk_nipos)
                        : currency(order?.total_biaya_pengiriman)}
                    </Typography>
                  </div>
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total biaya asuransi
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      : {currency(order?.total_biaya_asuransi_pengiriman)}
                    </Typography>
                  </div>
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total biaya COD
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      : {currency(order?.fee_cod)}
                    </Typography>
                  </div>
                  <div className={classes.innerRowBot}>
                    <Typography
                      className={classes.orderLabelBot}
                      component="h4"
                      variant="h5"
                    >
                      Total Pembayaran
                    </Typography>
                    <Typography className={classes.orderValueBot}>
                      : {currency(order?.total_pembayaran)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.processWrap}>
                  <div className={classes.innerRow}>
                    {order.status_pengiriman === "ready-for-processing" && (
                      <>
                        {/* <Typography className={classes.orderLabel} component="h4" variant="h5">Action :</Typography> */}

                        <Button
                          disabled={buttonLoading}
                          variant="contained"
                          color="primary"
                          onClick={changeStatus(order)}
                          //   onClick={() => console.log(order)}
                        >
                          {buttonLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <Typography>Process</Typography>
                          )}
                        </Button>
                      </>
                    )}
                    {order?.status === "on-process" &&
                      order?.goods_issue !== true && (
                        <Button
                          style={{ marginRight: 10 }}
                          // disabled={suborder?.goods_issue === true}
                          onClick={handleGoodsIssue(order)}
                          variant="contained"
                          color="primary"
                        >
                          Goods Issue
                        </Button>
                      )}
                    {order?.status === "on-process" &&
                      order?.goods_issue === true &&
                      order?.status !== "received" && (
                        <>
                          <TextField
                            id="datetime-local"
                            label="Date Pickup"
                            type="datetime-local"
                            // ampm={false}
                            defaultValue={time}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleChangeDate}
                            variant="outlined"
                          />
                          <Button
                            style={{ marginTop: 10 }}
                            disabled={
                              loading || order?.status === "on-delivery"
                            }
                            onClick={handlePickup(order)}
                            variant="contained"
                            color="primary"
                          >
                            Pickup Request
                          </Button>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.orderColTopRight}>
            <KomisiBox order={order} />
            {order.status === "canceled" || (
              <Button
                variant="contained"
                className={classes.buttonReveral}
                color="primary"
                size="small"
                onClick={() => handleReveral(data[0])}
              >
                update reveral
              </Button>
            )}

            {order?.status_pembayaran === "paid" &&
              order?.status_pengiriman === "ready-for-processing" &&
              HideRefund !== true && (
                <>
                  <Button
                    variant="contained"
                    className={classes.buttenCancel}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setCancel((CancelOrders) => ({
                        order: order,
                        open: true,
                        status: "paid",
                      }));
                    }}
                  >
                    Cancel Orders
                  </Button>
                </>
              )}
            {order?.status_pembayaran === "pending" &&
              order?.status_pengiriman === "pending" && (
                <>
                  {!HideRefund ? (
                    <Button
                      variant="contained"
                      className={classes.buttenCancel}
                      color="primary"
                      size="small"
                      onClick={() => {
                        setCancel((CancelOrders) => ({
                          data: order,
                          open: true,
                          status: "pending",
                        }));
                      }}
                    >
                      Cancel Orders
                    </Button>
                  ) : null}
                  {split == "m" ? (
                    <Button
                      variant="contained"
                      style={{ float: "right" }}
                      className={classes.buttonReveral}
                      color="primary"
                      size="small"
                      onClick={() => window.open(order?.payment_info?.url)}
                    >
                      Bayar Sekarang
                    </Button>
                  ) : null}
                </>
              )}
          </div>
          <div></div>
        </div>
      </div>
      <CancelOrders
        CancelOrders={CancelOrder}
        handleClose={() => {
          setCancel({
            open: false,
            data: getOrders,
          });
        }}
        order={order}
        dataSubOrder={dataSubOrder}
      />
    </>
  );
}

export default ShowOrder;
