import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Fab from "@material-ui/core/Fab";
import MaterialTable from "material-table";
import useStyles from "./styles";
import AddProduct from "./add";
import EditProductStock from "./edit";
import { useCollection } from "react-firebase-hooks/firestore";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import { useSnackbar } from "notistack";
import tableIcons from "../../../config/table-icons";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
import ExcelRead from "./addExcel";
import { useData } from "../../../components/DataProvider";
import UploadIcon from "@material-ui/icons/CloudUpload";
import MUIDataTable from "mui-datatables";
import { Button, Grid, IconButton, Switch, TextField } from "@material-ui/core";
import { Delete, EditSharp, SaveAlt, SaveOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

function Stock() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openAddStock, setOpenChangeStock] = useState({
    mode: "edit",
    open: false,
    data: {},
  });
  // const komisiDoc = firestore.doc(`settings/komisi`);
  // const [komisiSnapshot, komisiLoading] = useDocument(komisiDoc);
  // const [komisiForm, setKomisiForm] = useState();
  // console.log(komisiForm)
  // useEffect(() => {
  // 	if (komisiSnapshot) {

  // 		const data = komisiSnapshot.data()
  // 		setKomisiForm({
  // 			...data,
  // 		});
  // 	}
  // console.log("?",change, idDoc)

  const [openAddDialog, setOpenAddDialog] = useState({
    open: false,
  });
  // }, [komisiSnapshot]);
  const { company_id, subdomaintList } = useData();
  const col = firestore.collection(`mitra/${company_id}/products_stock`);
  const handleCheck = async () => {
    const updProd = {
        // ...form,
        // ref_1: { sellerId: levelsForm?.sellerId, path: form?.ref_1?.path, user_id: domForm?.user_id },
        // kode_referral: form?.ref_1?.path,
        // updated_at: FieldValue.serverTimestamp(),


    };

    // await usersDoc.set(updProd, { merge: true });

    enqueueSnackbar('Jaringan Upline Diperbarui', { variant: "success" })

  }

  const [snapshot, loading] = useCollection(col);
  const [table, setTable] = useState({
    columns: [
      {
        label: "Actions",
        name: "uid",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            // console.log('==>',data)
            return (
              <>
                <IconButton
                  aria-label="delete"
                  onClick={async () => {
                    if (
                      window.confirm(`apakah anda yakin menghapus stok ini?`)
                    ) {
                      await firestore
                        .doc(`mitra/${company_id}/products_stock/${data}`)
                        .delete();
                      enqueueSnackbar(
                        `Berhasil Menghapus Stok Product ${data}`,
                        {
                          variant: "success",
                        }
                      );
                    }
                    // window.location.reload();
                  }}
                >
                  <Delete />
                </IconButton>

                <IconButton
                  aria-label="edit"
                  onClick={async () => {
                    let data = await firestore
                      .doc(`mitra/${company_id}/products_stock/${value}`)
                      .get();
                    let result = data.data();
                    //   console.log('==>',result)
                    setOpenChangeStock((openAddDialog) => ({
                      ...openAddDialog,
                      data: result,
                      mode: "Edit",
                      id: value,
                      open: true,
                    }));
                  }}
                >
                  <EditSharp />
                </IconButton>
              </>
            );
          },
        },
      },
      {
        label: "Product ID",
        name: "productId",
        options: {
          // filter : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Nama Product",
        name: "nama_product",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Warna",
        name: "variant2",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Ukuran",
        name: "variant1",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Stok Tersedia",
        name: "stok_available",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            // let uid = tableMeta?.rowData[0]
            return <>{data}</>;
          },
        },
      },
      {
        label: "Stok Booked",
        name: "stok_booked",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>0</p>;
            }
          },
        },
      },
    //   {
    //     label: "Sembunyikan Produk",
    //     name: "hide",
    //     options: {
    //       // filter : false,
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         return <Switch defaultChecked />;
    //       },
    //     },
    //   },
    ],

    data: [],
  });

  const options = {
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 35, 50],
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "600px",
    download: false,
    print: false,
    empty: true,
    selectableRows: "none",
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: true,
    textLabels: {
      body: {
        noMatch: "Maaf Data Stok Tidak Di Temukan",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Filter Berdasarkan ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Baris per Halaman:",
        displayRows: "Dari",
      },
      toolbar: {
        search: "Cari Produk",
        downloadCsv: "Download CSV",
        print: "Print Data Product",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      selectedRows: {
        text: "Baris Di Tandai",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  useEffect(async () => {
    if (snapshot && !snapshot.empty) {
      const data = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const id_p = doc.data().productId;
          const id_v = doc.data().variantId;
          const variant = await firestore
            .doc(`products/${id_p}/variants/${id_v}`)
            .get();
          const nama_product = await firestore.doc(`products/${id_p}`).get();
          // console.log('==>',doc?.data());
          return {
            uid: doc.id,
            ...doc.data(),
            variant2: variant?.data()?.variant2?.nilai,
            variant1: variant?.data()?.variant1?.nilai,
            nama_product: nama_product.data()?.nama,
          };
        })
      );
      setTable((table) => {
        return {
          ...table,
          data,
        };
      });
      // setForm({
      //     ...data,
      //     stok_available : data
      // })
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);
  if (
    loading
    // || komisiLoading
  ) {
    return <PageLoading />;
  }

  return (
    <>
      {subdomaintList.length !== 0 ? (
        <>
          <div className={classes.root}>
            {loading ? (
              <PageLoading />
            ) : (
              <Container className={classes.tableCon} maxWidth="lg">
                <div className={classes.tableWrap}>
                  <MUIDataTable
                    title={"List Product Stock"}
                    columns={table?.columns}
                    data={table?.data}
                    options={options}
                  />
                </div>
              </Container>
            )}
          </div>
          <div className={classes.fab1}>
            <Fab
              style={{ marginRight: 10 }}
              color="secondary"
              onClick={() => {
                setOpenAddProduct(true);
              }}
              tooltip
            >
              <PostAddIcon />
            </Fab>
            <AddProduct
              open={openAddProduct}
              handleClose={() => {
                setOpenAddProduct(false);
              }}
            />

            <EditProductStock
              open={openAddStock?.open}
              handleClose={() => {
                setOpenChangeStock({ open: false });
              }}
              openAddStock={openAddStock}
            />

            {/* <Fab color="primary" variant="extended" className={classes.fab}
                            onClick={(e) => {
                                setOpenAddDialog({
                                    open: true,
                                });
                            }}
                        >
                            <UploadIcon className={classes.uploadIcon} /> <span>Upload File</span>
                        </Fab>
                        <ExcelRead
                            dialog={openAddDialog}
                            handleClose={() => {
                                setOpenAddDialog({
                                    open: false,
                                });
                            }}
                        /> */}
          </div>
        </>
      ) : (
        <div className={classes.root}>
          <Container className={classes.tableCon} maxWidth="lg">
            <Alert severity="error">
              {" "}
              Maaf Lengkapi Profile , Alamat Pickup serta Subdomain Anda
              Terlebih Dahulu !!!
            </Alert>
          </Container>
        </div>
      )}
    </>
  );
}

export default Stock;
