import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useStyles from './styles';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { firestore, FieldValue, useFirebase } from '../../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';

function AddNotif({ open, handleClose }) {

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const { company_id } = useData();


    const { treeDataKategori, brandList, peoductList } = useData();

    // console.log(dataUsers)



    const [form, setForm] = useState({
        title: '',
        message: '',
        data: { target_type: '', target_id: '', }
    });

    // console.log(form)
    const [error, setError] = useState({
        title: '',
        message: '',
        data: { target_type: '', target_id: '', }
    });

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    const handleChangeTarget = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            data: { target_type: form.data.target_type, target_id: e.target.value }
        })
    }
    const handleChangeTargetName = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            data: { target_type: form.data.target_type, target_id: form.data.target_id, target_nama: e.target.value }
        })
    }

    // const kategoriLevel2 = form.kategori1?.sub ?? [];
    // const kategoriLevel3 = form.kategori2?.sub ?? [];

    const handleSubmit = async e => {



        setSubmitting(true)
        try {
            const notifCol = firestore.collection(`mitra/${company_id}/notifications`);

            await notifCol.add({
                ...form,
                data: {
                    target_type: form.data.target_type,
                    target_id: form.data.target_id.id || form.data.target_id || '',
                    target_nama: form.data.target_id.nama || form.data.target_nama || ''
                },
                created_at: FieldValue.serverTimestamp(),
                updated_at: FieldValue.serverTimestamp()
            })

            enqueueSnackbar('Notif ditambahkan', { variant: "success" })
            handleClose();

        } catch (e) {
            const newError = {};

            newError.title = e.message;

            setError(newError);
        }

        setSubmitting(false)

    }

    const kategCol = firestore.collection(`categories`);

    const [kategData, loadingKateg] = useCollectionData(kategCol, { idField: 'id' })

    // useEffect(() => {
    //     async function fetchTarget() {
    //         try {
    //             if (form.data.target_type.nama === 'kategori1') {
    //                 return kategData.map((katego) => {
    //                     return katego.level === 1
    //                 })

    //             }
    //         } catch (e) {

    //         }
    //     }
    //     fetchTarget();

    // }, [form.data.target_type, kategData]);

    // useEffect(() => {
    //     if (form.data.target_type === 'kategori2') {
    //         kategData.map((kat) => {
    //             return kat.level === 2
    //         })


    //     }
    // }, [form.data.target_type, kategData]);

    const kateg2 = kategData?.filter((katego) => {
        return katego.level === 2
    })

    const kateg3 = kategData?.filter((kategor) => {
        return kategor.level === 3

    })

    // console.log(form?.data?.target_id?.id)
    // console.log(form.data.target_id)


    const target = [
        'kategori1',
        'kategori2',
        'kategori3',
        'brand',
        'promo',
        'produk',
        'webview'
    ];

    if (loadingKateg) {
        return <PageLoading />
    }

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Tambah Notifikasi Untuk Seller</DialogTitle>
            <DialogContent dividers>
                {/* <Typography component="h1" variant="h5">Push</Typography> */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.margin}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="title"
                            name="title"
                            label="Judul"
                            value={form.title || ''}
                            onChange={handleChange}
                            error={error.title ? true : false}
                            helperText={error.title}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            className={classes.margin}
                            id="target_type"
                            name="target_type"
                            margin="normal"
                            required
                            value={form.data.target_type}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "data", value: { ...form.data, target_type: value } } })
                            }}
                            disabled={isSubmitting}
                            options={target}
                            renderInput={(params) => <TextField helperText={error.data?.target_type}
                                error={error.data?.target_type ? true : false} {...params} label="Tipe Target" variant="outlined"
                            />}
                        />
                    </Grid>
                    {form.data.target_type === 'kategori1' ?
                        <Grid item xs={6}>
                            <Autocomplete
                                className={classes.margin}
                                id="target_id"
                                name="target_id"
                                margin="normal"
                                required
                                value={form.data.target_id}
                                onChange={(event, value) => {
                                    handleChange({ target: { name: "data", value: { ...form.data, target_id: value } } })
                                }}
                                disabled={isSubmitting}
                                options={treeDataKategori}
                                getOptionLabel={(option) => option?.nama}
                                renderInput={(params) => <TextField helperText={error.data?.target_id}
                                    error={error.data?.target_id ? true : false} {...params} label="Target Id" variant="outlined"
                                />}
                            />
                        </Grid> : ''
                    }
                    {
                        form.data.target_type === 'kategori2' ?
                            <Grid item xs={6}>
                                <Autocomplete
                                    className={classes.margin}
                                    id="target_id"
                                    name="target_id"
                                    margin="normal"
                                    required
                                    value={form.data.target_id}
                                    onChange={(event, value) => {
                                        handleChange({ target: { name: "data", value: { ...form.data, target_id: value } } })
                                    }}
                                    disabled={isSubmitting}
                                    options={kateg2}
                                    getOptionLabel={(option) => option?.nama}
                                    renderInput={(params) => <TextField helperText={error.data.target_id}
                                        error={error.data.target_id ? true : false} {...params} label="Target Id" variant="outlined"
                                    />}
                                />
                            </Grid> : ''
                    }
                    {form.data.target_type === 'kategori3' ?
                        <Grid item xs={6}>
                            <Autocomplete
                                className={classes.margin}
                                id="target_id"
                                name="target_id"
                                margin="normal"
                                required
                                value={form.data.target_id}
                                onChange={(event, value) => {
                                    handleChange({ target: { name: "data", value: { ...form.data, target_id: value } } })
                                }}
                                disabled={isSubmitting}
                                options={kateg3}
                                getOptionLabel={(option) => option?.nama}
                                renderInput={(params) => <TextField helperText={error.data.target_id}
                                    error={error.data.target_id ? true : false} {...params} label="Target Id" variant="outlined"
                                />}
                            />
                        </Grid> : ''
                    }
                    {form.data.target_type === 'brand' ?
                        <Grid item xs={6}>
                            <Autocomplete
                                className={classes.margin}
                                id="target_id"
                                name="target_id"
                                margin="normal"
                                required
                                value={form.data.target_id}
                                onChange={(event, value) => {
                                    handleChange({ target: { name: "data", value: { ...form.data, target_id: value } } })
                                }}
                                disabled={isSubmitting}
                                options={brandList}
                                getOptionLabel={(option) => option?.nama}
                                renderInput={(params) => <TextField helperText={error.data.target_id}
                                    error={error.data.target_id ? true : false} {...params} label="Target Id" variant="outlined"
                                />}
                            />
                        </Grid> : ''
                    }
                    {
                        form.data.target_type === 'produk' ?
                            <Grid item xs={6}>
                                <Autocomplete
                                    className={classes.margin}
                                    id="target_id"
                                    name="target_id"
                                    margin="normal"
                                    required
                                    value={form.data.target_id}
                                    onChange={(event, value) => {
                                        handleChange({ target: { name: "data", value: { ...form.data, target_id: value } } })
                                    }}
                                    disabled={isSubmitting}
                                    options={peoductList}
                                    getOptionLabel={(option) => option?.nama}
                                    renderInput={(params) => <TextField helperText={error.data.target_id}
                                        error={error.data.target_id ? true : false} {...params} label="Target Id" variant="outlined"
                                    />}
                                />
                            </Grid> : ''
                    }
                    {form.data.target_type === 'promo' ?
                        <Grid item xs={6}>
                            <TextField
                                className={classes.margin}
                                autoComplete="off"
                                disabled={isSubmitting}
                                id="target_id"
                                name="target_id"
                                label="target_id"
                                value={form.data.target_id || ''}
                                onChange={handleChangeTarget}
                                error={error.data.target_id ? true : false}
                                helperText={error.data.target_id}
                                fullWidth
                                multiline
                                variant="outlined"
                            />
                        </Grid> : ''
                    }
                    {form.data.target_type === 'webview' ?
                        <Grid item xs={6}>
                            <TextField
                                className={classes.margin}
                                autoComplete="off"
                                disabled={isSubmitting}
                                id="target_id"
                                name="target_id"
                                label="target_id"
                                value={form.data.target_id || ''}
                                onChange={handleChangeTarget}
                                error={error.data.target_id ? true : false}
                                helperText={error.data.target_id}
                                fullWidth
                                multiline
                                variant="outlined"
                            />
                        </Grid> : ''
                    }
                    <Grid item xs={6}>
                        <TextField
                            className={classes.margin}
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="message"
                            name="message"
                            label="Pesan"
                            value={form.message || ''}
                            onChange={handleChange}
                            error={error.message ? true : false}
                            helperText={error.message}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddNotif.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddNotif;
