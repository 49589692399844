import numeral from 'numeral';

numeral.register('locale', 'id', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'rb',
        million: 'jt',
        billion: 'm',
        trillion: 't'
    },
    currency: {
        symbol: 'Rp'
    }
})

numeral.locale('id');
// rupiah
export const currency = (number) => {
    return numeral(number).format('$0,0');
}
// titik setelah 3 angka
export const delimiters = (number) => {
    return numeral(number).format("0,0");
};