import React from 'react';
import { useBarcode } from '@createnextapp/react-barcode';

function BarcodeORC({value}) {
  const { inputRef } = useBarcode({
    value: value,
    options: {
        height: 50,
        width: 1,
        fontSize: 14,
        // margin: 20
    }
  });

  return <svg alt="" ref={inputRef} />;
};

export default BarcodeORC;