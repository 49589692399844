import React, { useState } from "react";

import useStyles from "./styles";

// material ui core
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { CircularProgress, IconButton } from "@material-ui/core";
import {
    blue,
    green,
    grey,
    orange,
    purple,
} from "@material-ui/core/colors";
// lab
// import DateTimePicker from '@material-ui/lab/DateTimePicker';
import { unixToDateStrip } from '../../../utils/dateFormatter';

import { currency } from "../../../utils/formatter";
import { Fragment } from "react";
import { useData } from "../../../components/DataProvider";
import LabelPreview from "../../../components/LabelPreview";
import { FieldValue, firestore, functions } from "../../../components/FirebaseProvider";
import { TimelineConnector } from "@material-ui/lab";
import DataOrder from "./dataOrder";
// import LacakOrder from "./tracking";
import PrintLabelPO from "./printLabel";
import { FileCopy } from "@material-ui/icons";
import TrackingPO from "./tracking";

function DataSuborder({
    order,
    buttonLoading,
    changeStatus,
    activeOrderId,
    dataSubOrder,
    setOpenAddDialog,
    openAddDialog,
    setEditDialog,
    getDataSubOrder,
    enqueueSnackbar,
    getOrders
}) {
    const classes = useStyles();
    const { company_id } = useData();

    // console.log(subOrdCol);
    const [labelPreviewVisible, setLabelPreviewVisible] = useState(false)
    const [resiUrl, setResiUrl] = useState('');

    const openLabelPreview = (url) => {
        setResiUrl(url)
        setLabelPreviewVisible(true)
    }
    const [showResults] = React.useState(false);

    // print label
    const [printLabel, setPrintLabel] = useState({
        open: false,
        order: {},
        subOrder: {}
    });
    // // tracking
    const [openAddDialogTrck, setOpenAddDialogTrac] = useState({
        openTrac: false,
        suborder: {},
        orderId : ""
    });

    const CopyCliboard = async (param) => {
        let kode = param?.kode
        let type = param?.type
        navigator.clipboard.writeText(kode)
        enqueueSnackbar(`Kode ${type} Berhasil di Salin`, { variant: "success" , autoHideDuration: 850});
    }
    return (
        <>
            {activeOrderId === order.id && !dataSubOrder && (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress size={20} />
                </div>
            )}

            {showResults ? (
                <DataOrder
                    order={order}
                    buttonLoading={buttonLoading}
                    changeStatus={changeStatus}
                    activeOrderId={activeOrderId}
                    dataSubOrder={dataSubOrder}
                    setOpenAddDialog={setOpenAddDialog}
                    openAddDialog={openAddDialog}
                    getDataSubOrder={getDataSubOrder}
                    enqueueSnackbar={enqueueSnackbar}
                    getOrders={getOrders}
                />
            ) : null}

            {activeOrderId === order.id &&
                dataSubOrder?.map((suborder, index) => {
                    let harga_normal = suborder?.total_tagihan_harga_normal
                    let tagihan = suborder?.total_tagihan
                    let diskon_level =  harga_normal - tagihan 
                    return (
                        <Fragment key={index}>
                            <div className={classes.suborderWrap}>
                                <div className={classes.suborderBox}>
                                    <Grid container className={classes.suborderContainer}>
                                        <Grid container spacing={2} xs={12}>
                                            <Grid item xs={12} lg={6} md={6}>
                                                <div className={classes.detailCol}>
                                                    <div className={classes.detailRow}>
                                                        <Typography
                                                            className={classes.detailTitle}
                                                            component="h4"
                                                            variant="h5"
                                                        >
                                                            Sub Order {index + 1}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.detailRow}>
                                                        <Typography
                                                            className={classes.orderLabel}
                                                            component="h4"
                                                            variant="h5"
                                                        >
                                                            Kode Suborder :
                                                        </Typography>
                                                        <Typography className={classes.orderValueRed}>
                                                            {suborder?.id}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.detailRow}>
                                                        <div className={classes.detailProduct}>
                                                            <div className={classes.detailProductThumb}>
                                                                <Typography
                                                                    className={classes.orderLabel}
                                                                    component="h4"
                                                                    variant="h5"
                                                                >
                                                                    Products :
                                                                </Typography>{" "}
                                                                {suborder?.products?.map?.((prods, idx) => {
                                                                    const firstImage = prods.thumbnail?.[0];
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={classes.detailProduct}
                                                                                container
                                                                            >
                                                                                <div
                                                                                    className={classes.detailProductThumb}
                                                                                    key={prods}
                                                                                >
                                                                                    <div className={classes.imgWrap}>
                                                                                        <img
                                                                                            alt=""
                                                                                            src={firstImage}
                                                                                            width="80%"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={classes.detailProductDesc}
                                                                                >
                                                                                    <div
                                                                                        className={classes.detailProductRow}
                                                                                    >
                                                                                        <div
                                                                                            className={classes.productDesc}
                                                                                        >
                                                                                            <div key={prods}>
                                                                                                <Typography
                                                                                                    className={classes.brandName}
                                                                                                    variant="subtitle2"
                                                                                                >
                                                                                                    {prods.nama}
                                                                                                </Typography>
                                                                                            </div>
                                                                                            <Typography
                                                                                                className={
                                                                                                    classes.productDescItem
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    prods?.variant?.variant1
                                                                                                        ?.nilai
                                                                                                }
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                className={
                                                                                                    classes.productDescItem
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    prods?.variant?.variant2
                                                                                                        ?.nilai
                                                                                                }
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                className={
                                                                                                    classes.productDescItem
                                                                                                }
                                                                                            >
                                                                                                x
                                                                                                {suborder.products_qty.filter(
                                                                                                    // eslint-disable-next-line array-callback-return
                                                                                                    (currVal, i) => {
                                                                                                        if (idx === i) {
                                                                                                            return currVal;
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={classes.detailProductRow}
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                classes.productCategory
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                className={classes.categoryText}
                                                                                                variant="subtitle2"
                                                                                            >
                                                                                                {prods?.kategori1?.nama} /{" "}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                className={classes.categoryText}
                                                                                                variant="subtitle2"
                                                                                            >
                                                                                                {prods?.kategori2?.nama} /{" "}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                className={classes.categoryText}
                                                                                                variant="subtitle2"
                                                                                            >
                                                                                                {prods?.kategori3?.nama}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={classes.detailProductRow}
                                                                                    >
                                                                                        <div>
                                                                                            <Typography
                                                                                                className={classes.brandName}
                                                                                                variant="subtitle2"
                                                                                            >
                                                                                                {prods?.brand?.nama}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className={classes.detailProductDesc}></div>
                                                        </div>
                                                    </div>

                                                    <div className={classes.detailRow}>
                                                        <Typography
                                                            className={classes.orderLabel}
                                                            component="h4"
                                                            variant="h5"
                                                        >
                                                            <bold>Order Note :</bold>
                                                        </Typography>
                                                        <Typography className={classes.orderValueRed}>
                                                            {suborder?.note?.[0]}
                                                        </Typography>
                                                    </div>

                                                    <div>
                                                        <Typography
                                                            className={classes.orderLabel}
                                                            component="h4"
                                                            variant="h5"
                                                        >
                                                            Shipper error :
                                                        </Typography>
                                                        {suborder?.shipper_error?.map((error) => {
                                                            return (
                                                                <>
                                                                    {" "}
                                                                    <div className={classes.innerRowBot}>
                                                                        <Typography
                                                                            className={classes.addressLabel}
                                                                            component="h4"
                                                                            variant="h5"
                                                                        >
                                                                            Title
                                                                        </Typography>
                                                                        <Typography
                                                                            className={classes.errorValueRed}
                                                                        >
                                                                            : {error?.title}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.innerRowBot}>
                                                                        <Typography
                                                                            className={classes.addressLabel}
                                                                            component="h4"
                                                                            variant="h5"
                                                                        >
                                                                            Content
                                                                        </Typography>
                                                                        <Typography
                                                                            className={classes.errorValueRed}
                                                                        >
                                                                            : {error?.content}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </div>

                                                    <div className={classes.detailRow2}>
                                                        <Typography
                                                            className={classes.orderLabel}
                                                            component="h4"
                                                            variant="h5"
                                                        >
                                                            Rincian Biaya
                                                        </Typography>
                                                        {/* TOTAL GROS / HARGA ASLI BARANG  */}
                                                        <div className={classes.innerRowBot}>
                                                            <Typography
                                                                className={classes.orderLabelBot}
                                                                component="h4"
                                                                variant="h5"
                                                            >
                                                                Total Harga Normal
                                                            </Typography>
                                                            <Typography className={classes.orderValueBot}>
                                                                : {currency(suborder?.total_tagihan_harga_normal)}
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.innerRowBot}>
                                                            <Typography
                                                                className={classes.orderLabelBot}
                                                                component="h4"
                                                                variant="h5"
                                                            >
                                                                Total Diskon Level
                                                            </Typography>
                                                            <Typography className={classes.orderValueBot}>
                                                                : - {currency(diskon_level)}
                                                            </Typography>
                                                        </div>
                                                        {/* TOTAL SETELAH ADA DISKON LEVEL MITRA  */}
                                                        <div className={classes.innerRowBot}>
                                                            <Typography
                                                                className={classes.orderLabelBot}
                                                                component="h4"
                                                                variant="h5"
                                                            >
                                                                Total Tagihan
                                                            </Typography>
                                                            <Typography className={classes.orderValueBot}>
                                                                : {currency(suborder?.total_tagihan)}
                                                            </Typography>
                                                        </div>
                                                        <div className={classes.innerRowBot}>
                                                            <Typography
                                                                className={classes.orderLabelBot}
                                                                component="h4"
                                                                variant="h5"
                                                            >
                                                                Biaya Pengiriman
                                                            </Typography>
                                                            <Typography className={classes.orderValueBot}>
                                                                : {currency(suborder?.biaya_pengiriman)}
                                                            </Typography>
                                                        </div>                                                        
                                                        <div className={classes.innerRowBot}>
                                                            <Typography
                                                                className={classes.orderLabelBot}
                                                                component="h4"
                                                                variant="h5"
                                                            >
                                                                Total Pembayaran
                                                            </Typography>
                                                            <Typography className={classes.orderValueBot}>
                                                                : {currency(suborder?.total_pembayaran)}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={6} md={6}>
                                                <div className={classes.detailCol}>
                                                    <div className={classes.detailRow}>
                                                        <div className={classes.statusBar}>
                                                            {order.status === "pending" && (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: orange[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: orange[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: orange[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {order.status === "paid" || order?.status === 'cod' ? (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                            {order.status === "received" && (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: green[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {order.status === "on-delivery" && (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: purple[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: purple[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: purple[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {order.status === "on-process" && (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: blue[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: blue[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: blue[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {order.status === "expire" && (
                                                                <>
                                                                    <div className={classes.statusBarItem}>
                                                                        <FiberManualRecordIcon
                                                                            style={{ color: grey[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status}
                                                                        </Typography>
                                                                    </div>

                                                                    <div className={classes.statusBarItem}>
                                                                        <MonetizationOnIcon
                                                                            style={{ color: grey[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pembayaran}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.statusBarItem}>
                                                                        <LocalShippingIcon
                                                                            style={{ color: grey[500] }}
                                                                        />
                                                                        <Typography className={classes.statusLabel}>
                                                                            {order.status_pengiriman}
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={classes.detailRow}>
                                                        <div className={classes.addressDelivery}>
                                                            <div className={classes.addressBox}>
                                                                <Typography
                                                                    className={classes.orderLabel}
                                                                    component="h4"
                                                                    variant="h5"
                                                                >
                                                                    {" "}
                                                                    <h4><b>Alamat</b></h4>
                                                                </Typography>
                                                                <div className={classes.addressRow}>
                                                                    <Typography className={classes.addressLabel}>
                                                                        Alamat Tujuan
                                                                    </Typography>
                                                                    <Typography className={classes.addressValue}>
                                                                        : {suborder?.alamat_tujuan?.alamat_lengkap}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.addressRow}>
                                                                    <Typography className={classes.addressLabel}>
                                                                        Kelurahan/Desa
                                                                    </Typography>
                                                                    <Typography className={classes.addressValue}>
                                                                        : {suborder?.alamat_tujuan?.directapi?.kelurahan?.name}{" "}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.addressRow}>
                                                                    <Typography className={classes.addressLabel}>
                                                                        Kecamatan
                                                                    </Typography>
                                                                    <Typography className={classes.addressValue}>
                                                                        : {suborder?.alamat_tujuan?.directapi?.kecamatan?.name}{" "}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.addressRow}>
                                                                    <Typography className={classes.addressLabel}>
                                                                        Kabupaten/Kota
                                                                    </Typography>
                                                                    <Typography className={classes.addressValue}>
                                                                        : {suborder?.alamat_tujuan?.directapi?.kota?.name}{" "}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.addressRow}>
                                                                    <Typography className={classes.addressLabel}>
                                                                        Provinsi
                                                                    </Typography>
                                                                    <Typography className={classes.addressValue}>
                                                                        : {suborder?.alamat_tujuan?.directapi?.provinsi?.name}{" "}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div className={classes.courier}>
                                                                <Typography className={classes.courierText}>
                                                                    <h4><b>Kurir</b></h4>
                                                                    {suborder?.kurir?.nama?.toUpperCase()} -{" "}
                                                                    {suborder?.kurir?.layanan}{" "}
                                                                </Typography>
                                                                {order.status === "on-delivery" ||
                                                                    order.status === "received" ||
                                                                    order.status === "completed" ? 
                                                                    <Typography className={classes.courierText}>
                                                                        <h4>
                                                                            <b>Resi</b>
                                                                        </h4>
                                                                        {suborder?.shipping_info?.resi}
                                                                        { suborder?.shipping_info?.resi ?
                                                                        <IconButton onClick={() => CopyCliboard({kode :suborder?.shipping_info?.resi, type: 'Resi'})}>
                                                                        <FileCopy size={12} value={15} />
                                                                        </IconButton> : null}  
                                                                        </Typography> 
                                                                        :  <Typography className={classes.courierText}>
                                                                        <h4>
                                                                            <b>Resi</b>
                                                                        </h4>
                                                                        Belum ada
                                                                        </Typography>
                                                                    }
                                                                {
                                                                    order.status_pengiriman === "ready-for-processing" ? (
                                                                        <Typography className={classes.button}>
                                                                            {/* <Button
                                                                                variant="contained"
                                                                                size="small"
                                                                                color="primary"
                                                                                onClick={(event) => {
                                                                                    setEditDialog((editDialog) => ({
                                                                                        ...editDialog,
                                                                                        dataSub: suborder,
                                                                                        id: suborder?.id,
                                                                                        open: true,
                                                                                        company_id: company_id
                                                                                    }));
                                                                            
                                                                                }}
                                                                            >
                                                                                edit kurir
                                                                            </Button> */}
                                                                        </Typography>
                                                                    ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.detailRow}>
                                                        <div className={classes.btnPrintWrap}>
                                                        {order.status === "on-delivery" ||
                                                            order.status === "completed" ||
                                                            order.status === "received" ? (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        // setOpenAddDialog(true);
                                                                        setOpenAddDialogTrac(tracking => ({
                                                                            ...tracking,
                                                                            suborderId: suborder,
                                                                            openTrac: true,
                                                                            orderId: order?.id,
                                                                            mitra_id : order?.mitra_id
                                                                        }));
                                                                    }}
                                                                >
                                                                    Lacak PO
                                                                </Button>
                                                            </>
                                                        ) : null}
                                                            <Button
                                                                style={{ marginLeft: 15 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(event) => {
                                                                    setPrintLabel(printLabel => ({
                                                                        ...printLabel,
                                                                        order: order,
                                                                        subOrder: suborder,
                                                                        compId: company_id,
                                                                        open: true
                                                                    }))
                                                                }
                                                                }
                                                            >
                                                                Print Label PO
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Fragment >
                    );
                })}
            <PrintLabelPO
                printLabel={printLabel}
                // subOrder={suborder}
                // compId={company_id}
                handleClose={() => {
                    setPrintLabel({
                        open: false,
                        order: {},
                        subOrder: {},
                    });
                }}
            />


            <LabelPreview
                onClose={() => setLabelPreviewVisible(false)}
                visible={labelPreviewVisible}
                resiUrl={resiUrl} />
            <TrackingPO
                openAddDialogTrac={openAddDialogTrck}
                handleClose={() => {
                    setOpenAddDialogTrac({
                        openTrac: false,
                        suborder: {},
                    });
                }}
            />
            {/* <Grid item xs={12}>
            <div className={classes.btnWrap}>
                <Button variant="contained" color="primary" className={classes.btnDetail} onClick={() => getDataSubOrder(order.id)}>
                    <Typography>Details</Typography>
                </Button>
            </div>
        </Grid> */}
        </>
    );
}

export default DataSuborder;
