import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { currency } from '../../../utils/formatter';
import { functions } from '../../../components/FirebaseProvider';


function CekKredit({ dialog: { id, open }, handleClose }) {
    const classes = useStyles();

    const [tracking, setTracking] = useState([]);

    useEffect(() => {
        if (open) {
            const getTotalTransaksiSeller = async () => {
                const transaksi = functions.httpsCallable("statistic-getTotalTransaksiSeller");
                const res = await transaksi({ user_id: id });
                // console.log(res)


                setTracking(res);
            };

            getTotalTransaksiSeller();
        }
    }, [id, open]);

    console.log(tracking)

    return <Dialog

        open={open}
        onClose={handleClose}
        fullWidth
    >
        <DialogTitle>Data Kredit</DialogTitle>
        <DialogContent dividers>
            <div className={classes.jp1}>
                <div className={classes.liquidSection}>

                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Transaksi SellerApp</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_transaksi_sellerapp) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Transaksi WebStore</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_transaksi_webstore) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi WebStore</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_webstore) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi WebStore Confirmed</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_webstore_confirmed) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Transaksi Jaringan</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_transaksi_jaringan) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi Jaringan</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_jaringan) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi Jaringan Confirmed</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_jaringan_confirmed) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi Saya</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_saya) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Komisi Confirmed</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_komisi_confirmed_saya) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Topup</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_topup) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Belanja Dengan Kredit</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_belanja_dengan_kredit) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Pencairan Approved</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_pencairan_approved) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Saldo Kredit</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_saldo_kredit) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Belanja Dengan Kredit</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_belanja_dengan_kredit) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Saldo Kredit Available</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_saldo_kredit_available) || ''}</Typography>
                    </div>
                    <div className={classes.liquidRow}>
                        <Typography className={classes.liquidLabel2}>Total Saldo Kredit Confirmed Available</Typography>
                        <Typography className={classes.liquidValue}>: {currency(tracking?.data?.total_saldo_kredit_confirmed_available) || ''}</Typography>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >Kembali</Button>
        </DialogActions>
    </Dialog>
}
CekKredit.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default CekKredit;