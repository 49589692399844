
import React from 'react';
import { useDashboardUI } from '../../../components/DashboardUIProvider';
import srcDashboard from '../../../config/dashboardEmbed';
import useStyles from './styles';

function Home() {
    const classes = useStyles();
    const { toggleAppBar } = useDashboardUI();
    React.useEffect(() => {
        toggleAppBar();
        return () => {
            toggleAppBar();
        }
    }, [toggleAppBar])
    return <>
        <iframe title="Analytics" width="100%" src={srcDashboard} frameBorder="0" className={classes.iframe} allowFullScreen >Analytics</iframe>
    </>
}

export default Home;