
import React from 'react';
import { useDashboardUI } from '../../../components/DashboardUIProvider';
import AnalyticsEmbed from '../../../config/analitycsEmbed';
// import srcAnalytics from '../../../config/analytics';
import useStyles from './styles';

function Analytics() {
    const classes = useStyles();
    const { toggleAppBar } = useDashboardUI();

    React.useEffect(() => {
        toggleAppBar();
        return () => {
            toggleAppBar();
        }
    }, [toggleAppBar])
    return <>
        <iframe title="Analytics" width="100%" src={AnalyticsEmbed} frameBorder="0" className={classes.iframe} allowFullScreen >Analytics</iframe>
    </>
}

export default Analytics;