import React, { useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useData } from "../../../components/DataProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import Virtualize from "../../../components/Virtualization";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, Typography } from "@material-ui/core";

function AddProduct({ open, handleClose }) {
  const { treeDataKategori, company_id, peoductList } = useData();

  let array = [];
  useEffect(() => {
    peoductList.forEach((e) => {
      if (e?.sembunyikan_produk !== true) {
        array.push(e);
      }
    });
  });

  const [form, setForm] = useState({
    product: "",
    variantId: "",
    stok_available: "",
    stok_booked: "",
  });
  const [error, setError] = useState({
    product: "",
    variantId: "",
    stok_available: "",
    stok_booked: "",
  });
  const [listUkuran, setlistUkuran] = useState([{}]);
  const [listWarna, setlistWarna] = useState([{}]);
  const { enqueueSnackbar } = useSnackbar();
  const [willProduk, setwillProduk] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    let index = e.target.idx;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    if (e.target.name === "listUkuran") {
      setlistUkuran(
        [...listUkuran].map((object, idx) => {
          if (idx === index) {
            return value;
          } else {
            return object;
          }
        })
      );
    } else if (e.target.name === "listWarna") {
      setlistWarna(
        [...listWarna].map((object, idx) => {
          if (idx === index) {
            return value;
          } else {
            return object;
          }
        })
      );
    } else if (e.target.name === "product") {
      setForm({
        ...form,
        [e.target.name]: value,
      });
      setlistUkuran([{}]);
      setlistWarna([{}]);
    } else {
      setForm({
        ...form,
        [e.target.name]: value,
      });
    }
    setError({
      ...error,
      [e.target.name]: "",
    });
  };
  const handleDelete = (e) => {
    let value = e.target.val;
    if (e.target.name === "listWarna") {
      const filter = listWarna?.filter((val) => val.kode !== value.kode);
      setlistWarna(filter);
    } else {
      const filter = listUkuran?.filter((val) => val.kode !== value.kode);
      setlistUkuran(filter);
    }
  };
  // sellers_webstore
  const webstoreDoc = firestore.doc(
    `mitra/${company_id}/sellers_webstore/${company_id}`
  );
  const close = () => {
    setForm({
      product: "",
      variantId: "",
      stok_available: "",
      stok_booked: "",
    });
    setlistUkuran([{}]);
    setlistWarna([{}]);
    handleClose();
  };
  const [dataWebs, loadDataWebs] = useDocumentData(webstoreDoc);
  const validate = async () => {
    const newError = { ...error };
    if (!form.product) {
      newError.product = "productId harus diisi";
    }
    // if (!form.variantId) {
    //   newError.variantId = "variantId harus diisi";
    // }
    // if (!form.stok_available) {
    //   newError.stok_available = "stok_available harus diisi";
    // }
    return newError;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const prodCol = firestore.collection(
          `mitra/${company_id}/products_stock`
        );
        for (let index = 0; index < willProduk.length; index++) {
          const data = willProduk[index];

          await prodCol.doc(`${data?.id}_${data?.kode}`).set({
            productId: data?.id,
            stok_available: Number(data?.stok_available) || 0,
            // stok_booked: Number(data?.stok_booked) || 0,
            variantId: data?.kode,
            mitraId: company_id,
            created_at: FieldValue.serverTimestamp(),
            updated_at: FieldValue.serverTimestamp(),
            sku: data?.id + data?.kode,
          });
        }
        // if(dataWebs?.products.filter((data) => {
        //     return data === form?.product?.id
        // }))
        const filterProd = dataWebs?.products.find((data) => {
          return data === form?.product?.id;
        });
        if (!filterProd) {
          await webstoreDoc.set(
            {
              products: [...(dataWebs?.products ?? []), form?.product?.id],
            },
            { merge: true }
          );
        }
        enqueueSnackbar("Product ditambahkan", { variant: "success" });
        close();
      } catch (e) {
        const newError = {};
        enqueueSnackbar("Product gagal ditambahkan", { variant: "error" });
        newError.title = e.message;
        setError(newError);
      }
      setSubmitting(false);
    }
  };
  const varCol = firestore.collection(`products/${form?.product?.id}/variants`);
  const [varData, loadingVar] = useCollectionData(varCol, {
    idField: "id",
  });
  let varUkuran = [];
  let kodeUkuran = [];
  let varWarna = [];
  let kodeWarna = [];
  for (let i = 0; i < varData?.length; i++) {
    const ukuran = varData[i]?.variant1?.nilai;
    const warna = varData[i]?.variant2?.nilai;
    const kode = varData[i]?.id;
    varUkuran.push(ukuran);
    kodeUkuran.push(kode.substring(0, 2));
    varWarna.push(warna);
    kodeWarna.push(kode.substring(2));
  }
  const var1 = [...new Set(varUkuran)];
  const kodevar1 = [...new Set(kodeUkuran)];
  const var2 = [...new Set(varWarna)];
  const kodevar2 = [...new Set(kodeWarna)];
  let variantUkuran = [];
  for (let i = 0; i < var1?.length; i++) {
    const ukuran = var1[i];
    const kode = kodevar1[i];
    variantUkuran.push({ value: String(ukuran), kode: kode });
  }
  let variantWarna = [];
  for (let i = 0; i < var2.length; i++) {
    const warna = var2[i];
    const kode = kodevar2[i];
    variantWarna.push({ value: warna, kode: kode });
  }
  const warna = variantWarna.filter((el) => {
    return !listWarna.some((f) => {
      return f.kode === el.kode;
    });
  });
  const ukuran = variantUkuran.filter((el) => {
    return !listUkuran.some((f) => {
      return f.kode === el.kode;
    });
  });
  useEffect(() => {
    let dataProd = [];
    listWarna?.map((val) => {
      listUkuran?.map((value) => {
        const stok = willProduk.filter(
          (data) => data.warna === val.value && data.ukuran === value.value
        );
        if (val.value && value.value) {
          const data = {
            id: form?.product?.product_id,
            nama: form?.product?.nama,
            ukuran: value?.value,
            warna: val?.value,
            kode: `${value.kode}${val.kode}`,
            stok_available: stok[0]?.stok_available
              ? stok[0]?.stok_available
              : 0,
          };
          dataProd.push(data);
        }
      });
    });
    setwillProduk(dataProd);
  }, [listUkuran, listWarna]);

  const handleStok = (e, idx) => {
    let value = e.target.value;
    let index = idx;
    setwillProduk(
      [...willProduk].map((object, idx) => {
        if (idx === index) {
          return { ...object, stok_available: value };
        } else {
          return object;
        }
      })
    );
  };

  const handleTerapkan = (value) => {
    if (willProduk?.length >= 1) {
      setwillProduk(
        [...willProduk].map((object, idx) => {
          return { ...object, stok_available: value };
        })
      );
      enqueueSnackbar("Stok berhasil diterapkan ke semua barang", {
        variant: "success",
      });
    }
  };
  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
      fullScreen
      style={{ width: "50%", height: "90%", margin: "auto" }}
    >
      <DialogTitle>Tambah Stok Produk</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Virtualize
              value={array}
              data={form?.product}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            {listWarna?.map((val, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    marginBlock: 10,
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <Autocomplete
                      id="listWarna"
                      name="listWarna"
                      margin="normal"
                      disableClearable
                      required
                      value={val || ""}
                      onChange={(event, value) => {
                        handleChange({
                          target: { name: "listWarna", value, idx },
                        });
                      }}
                      disabled={isSubmitting || loadingVar}
                      options={warna || ""}
                      getOptionLabel={(option) => option?.value || ""}
                      renderOption={(option) => {
                        return <div>{`${option?.value}`}</div>;
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={error?.listWarna}
                          error={error?.listWarna ? true : false}
                          {...params}
                          label={idx === 0 ? "Varian" : ""}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingVar ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {variantWarna?.length <= 1 ||
                  listWarna?.length <= 1 ? null : (
                    <IconButton
                      style={{ marginLeft: 20, borderRadius: 10 }}
                      onClick={() => {
                        handleDelete({
                          target: { name: "listWarna", val, idx },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
            {variantWarna?.length < 1 ||
            listWarna?.length === variantWarna?.length ? null : (
              <Button
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  color: "#007DD8",
                }}
                onClick={() => {
                  setlistWarna([...listWarna, {}]);
                }}
              >
                + Tambah Varian
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {listUkuran?.map((val, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    marginBlock: 10,
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <Autocomplete
                      id="listUkuran"
                      disableClearable
                      name="listUkuran"
                      margin="normal"
                      required
                      value={val || ""}
                      onChange={(event, value) => {
                        handleChange({
                          target: { name: "listUkuran", value, idx },
                        });
                      }}
                      disabled={isSubmitting || loadingVar}
                      options={ukuran || ""}
                      getOptionLabel={(option) => option?.value || ""}
                      renderOption={(option) => {
                        return <div>{`${option?.value}`}</div>;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            helperText={error?.listUkuran}
                            error={error?.listUkuran ? true : false}
                            {...params}
                            label={idx === 0 ? "Ukuran" : ""}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingVar ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {variantUkuran?.length <= 1 ||
                  listUkuran.length <= 1 ? null : (
                    <IconButton
                      style={{ marginLeft: 20, borderRadius: 10 }}
                      onClick={() => {
                        handleDelete({
                          target: { name: "listUkuran", val, idx },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
            {variantUkuran?.length < 1 ||
            listUkuran?.length === variantUkuran?.length ? null : (
              <Button
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  color: "#007DD8",
                }}
                onClick={() => {
                  setlistUkuran([...listUkuran, {}]);
                }}
              >
                + Tambah Ukuran
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                autoComplete="off"
                disabled={isSubmitting}
                id="stok_available"
                name="stok_available"
                label="Tersedia"
                onChange={handleChange}
                error={error.stok_available ? true : false}
                helperText={error.stok_available}
                // fullWidth
                type="number"
                variant="outlined"
              />
              <Button
                style={{ marginLeft: 20, textTransform: "none" }}
                onClick={() => handleTerapkan(form?.stok_available)}
                variant="contained"
                color="primary"
              >
                Terapkan semua
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            {willProduk &&
              willProduk.map((val, idx) => {
                return (
                  <div style={{ display: "flex" }}>
                    <Grid xs={2} item>
                      <TextField
                        disabled
                        label={idx === 0 ? "Kode Produk" : ""}
                        value={val?.id}
                        fullWidth
                        variant="outlined"
                        inputProps={{ style: { color: "#000" } }}
                        style={{ borderRadius: 0 }}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <TextField
                        disabled
                        label={idx === 0 ? "Nama Produk" : ""}
                        value={val?.nama}
                        fullWidth
                        variant="outlined"
                        inputProps={{ style: { color: "#000" } }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled
                        label={idx === 0 ? "Varian" : ""}
                        value={val?.warna}
                        fullWidth
                        variant="outlined"
                        inputProps={{ style: { color: "#000" } }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled
                        label={idx === 0 ? "Ukuran" : ""}
                        value={val?.ukuran}
                        fullWidth
                        variant="outlined"
                        inputProps={{ style: { color: "#000" } }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        // disabled
                        label={idx === 0 ? "Stok" : ""}
                        value={val?.stok_available}
                        onChange={(e) => handleStok(e, idx)}
                        fullWidth
                        variant="outlined"
                        inputProps={{ style: { color: "#000" } }}
                        type="number"
                      />
                    </Grid>
                  </div>
                );
              })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={() => close()}>
          Batal
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || willProduk?.length < 1}
          color="primary"
        >
          Buat
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddProduct;
