import React from "react";
import { useCollectionData, useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import { FieldValue, firestore, functions } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import { currency } from "../../../utils/formatter";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from 'nuka-carousel';
import HargaVar from './dataVar';
// Material-UI
import { Button, IconButton } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
// import Fab from "@material-ui/core/Fab"
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

// Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MoreVert, NavigateBefore, NavigateNext } from "@material-ui/icons";
import useStyles from "./styles";
import { useData } from "../../../components/DataProvider";
// import AddPurchaseOrders from "./add";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CekOngkir from "./cekOngkir";
import { useDebounce } from "use-debounce/lib";

export default function DetailProdMitra({ prodId }) {
    const params = useParams();
    const classes = useStyles();
    const { company_id, pricing_procedure, pricing_conditions, dataMitraCurrent } = useData();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const productId = params?.mitraId.split('_');
    // console.log('42', productId?.[2])
    // grouping variant

    const prodDoc = firestore.doc(`products/${productId?.[0]}`);

    const [prodSnapshot, prodLoading] = useDocument(prodDoc);

    const [prodForm, setProdForm] = useState();
    // const [errorProd, setErrorProd] = useState({});

    useEffect(() => {
        if (prodSnapshot) {
            const data = prodSnapshot.data();
            setProdForm({
                ...data,
            });
        }
    }, [prodSnapshot]);
    // console.log('58', prodForm)

    const [form, setForm] = useState();
    // console.log('data', form);
    const [error, setError] = useState({});

    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        // console.log(e.target.type);

        setForm({
            ...form,
            [e.target.name]: value,
        });

        setError({
            ...error,
            [e.target.name]: "",
        });
    };
    // const varCol = prodDoc.collection(`variants`);

    // const [varData, loadingVar] = useCollectionData(varCol, {
    //     idField: "id",
    // });
    const prodStokQuery = firestore.collectionGroup(`products_stock`).where('mitraId', '==', productId?.[2] || '').where('productId', '==', productId?.[0]);

    const [stokData, loadingStok, err] = useCollectionData(prodStokQuery, { idField: 'id' })
    const varId = stokData?.map((stok) => {
        return stok?.variantId
    })
    // console.log(varId)
    // colGropu variants
    const [variants, setVar] = useState()

    useEffect(() => {

        async function getProductDetail() {

            const allVar = await Promise.all(stokData?.map?.(async (p) => {

                const query = await firestore.collectionGroup(`variants`)
                    .where('product_id', '==', productId?.[0] || 'BAA258')
                    .where('variant_id', '==', p?.variantId || '02302')
                    .get();
                if (!query.empty) {
                    const newProducts = await Promise.all(
                        query.docs.map(async (product) => {
                            // test.push(product)
                            // const variants = await product.ref.collection("variants").get();
                            return {
                                id: product.id,
                                ...product.data(),
                                // variants: variants.docs.map((variant) => {
                                //     return {
                                //         id: variant.id,
                                //         ...variant.data(),
                                //     };
                                // }),
                            };
                        })
                    );
                    // test=newProducts
                    // setVar(newProducts);

                    // console.log(newProducts)
                    return newProducts?.[0]



                }
                //                     if (query.exists) {
                //     const variantData = query.data();

                //     return variantData;

                // }
            }) ?? [])
            // console.log(allVar)

            setVar(allVar)
        }
        getProductDetail()
    }, [JSON.stringify(stokData)])
    const prodIdSet = [...new Map(variants?.map?.(item => [item?.variant1?.nilai, item]))?.values()];

    const variants2 = variants?.filter((stok) => {
        if (stok?.variant1?.nilai === form?.variant1?.variant1?.nilai) {
            return stok
        }
    })

    // console.log(variants)
    // const varId = mitra?.map((stok) => {
    //     return stok?.variantId
    // })

    // const mitraFix = mitraId ?? [0]
    const varDoc = firestore.doc(`mitra/${productId?.[2]}/products_stock/${productId?.[0]}_${form?.variant2?.variant_id}`);

    const [cekStok, cekLoad] = useDocument(varDoc);

    const [cekStokForm, setCekstokForm] = useState();
    useEffect(() => {
        if (cekStok) {
            const data = cekStok.data();
            setCekstokForm({
                ...data,
            });
        }
    }, [cekStok]);

    // stok_available
    const stokTersedia = cekStokForm?.stok_available


    const var1 = variants?.map((var1) => {
        return var1?.variant1?.label
    })
    const var2 = variants?.map((var1) => {
        return var1?.variant2?.label
    })
    // console.log(var1?.[0])

    // variat2
    // const var2Col = firestore.collection(`products/${productId?.[0]}/variants`).where('variant1.nilai', '==', form?.variant1?.variant1?.nilai || 'variant1');

    // const [var2Data, loadingVar2, er] = useCollectionData(var2Col, {
    //     idField: "id",
    // });
    // console.log('133', var2Data, er)


    // addToCart
    const [dialog, setDialog] = useState({
        open: false,
        data: {},
    });

    // mitraCol
    // const mitraCol = firestore.collection(`mitra`).where('mitraId', '==', form?.toko?.mitraId || '');

    // const [mitraData, loadingMitra] = useCollectionData(mitraCol, {
    //     idField: "id",
    // });

    // sudomai
    // mitraCol
    // const subdomainCol = firestore.collection(`subdomains`).where('mitra_id', '==', company_id || '');

    // const [subdomainData, loadingSubdomain] = useCollectionData(subdomainCol, {
    //     idField: "id",
    // });
    // PO Coll
    // pricing
    const [hargaPricing, setHargaPricing] = useState()
    useEffect(() => {
        try {
            async function CekPricing() {
                if (form?.variant2) {
                    const loadRecords = functions.httpsCallable('pricing-loadRecords');

                    const body = JSON.stringify({
                        item: { products: prodForm, variants: [form?.variant2], id: prodForm?.id },
                        pricing_procedure,
                        pricing_conditions,
                        level: { level: dataMitraCurrent?.mitra_level },
                        profil: { id: company_id }
                    })


                    const result = await loadRecords({
                        body
                    });
                    // console.log(result)
                    setHargaPricing(result)
                    return result?.data ?? {};
                }
            };
            CekPricing()
        } catch (e) {

        }
    }, [form?.variant2])

    const purchaseDoc = firestore.collection(`purchase_orders`).doc(company_id);
    const [dataPO, loadDataPO] = useDocumentData(purchaseDoc)
    // setJumlahOver
    // jumlah
    const [jumlah, setJumlah] = useState(1);
    // debounce harga
    const debHarga = hargaPricing?.data?.priceslocal?.[0] * jumlah;
    const [debouncedHarga] = useDebounce(debHarga, 1000);
    const [debouncedJumlah] = useDebounce(jumlah, 1000);
    const [isSubmitting, setSubmitting] = useState(false);
    const hargaTagihan = (hargaPricing?.data?.priceslocal?.[0] * jumlah);
    const [ongkir, setOngkir] = useState()
    const ongkirNow = ongkir?.pengiriman?.finalRate ? ongkir?.pengiriman?.finalRate : ongkir?.[0]?.data?.[0]?.finalRate
    // console.log('toko', ongkirNow);
    // state perhitungan
    // console.log(form?.variant2?.berat_paket * debouncedJumlah)

    // Doc alamat
    let alamatKey = []
    if (dataPO?.subcarts) {
        const objAlamat = Object.keys(dataPO?.subcarts)

        alamatKey.push(objAlamat)
    }
    // console.log(alamatKey)

    const handleSimpan = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {

            const totPem1 = hargaTagihan + ongkirNow;
            const totTag = dataPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_tagihan
            if (formPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp) {
                await purchaseDoc.set({
                    subcarts:
                    {
                        [alamatKey ? alamatKey?.[0] : 'alamat']:
                        {
                            berat_total: FieldValue.increment(+form?.variant2?.berat_paket * jumlah), total_pembayaran: hargaTagihan + totTag, total_tagihan: FieldValue.increment(+hargaTagihan),
                            products:
                            {
                                [`${productId?.[0]}_${form?.variant2?.variant_id}`]: { created_at: FieldValue.serverTimestamp(), jumlah: jumlah, nama: prodForm?.nama, product: productId?.[0], variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`, promo: false, harga: hargaTagihan, berat: (form?.variant2?.berat_paket * jumlah) }
                            },
                            biaya_pengiriman: 0,
                            biaya_pengiriman_before: 0

                        }
                    },

                    updated_at: FieldValue.serverTimestamp(),
                }, { merge: true });
            } else if (!formPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp) {
                await purchaseDoc.set({
                    subcarts:
                    {
                        [alamatKey ? alamatKey?.[0] : 'alamat']:
                        {
                            berat_total: FieldValue.increment(+form?.variant2?.berat_paket * jumlah), total_pembayaran: totPem1 + totTag, total_tagihan: FieldValue.increment(+hargaTagihan),
                            pengiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
                            products:
                            {
                                [`${productId?.[0]}_${form?.variant2?.variant_id}`]: { created_at: FieldValue.serverTimestamp(), jumlah: jumlah, nama: prodForm?.nama, product: productId?.[0], variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`, promo: false, harga: hargaTagihan, berat: (form?.variant2?.berat_paket * jumlah) }
                            },
                            biaya_pengiriman: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate,
                            biaya_pengiriman_before: ongkir?.pengiriman?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate

                        }
                    },

                    updated_at: FieldValue.serverTimestamp(),
                }, { merge: true });
            }


            enqueueSnackbar("PO Berhasil Ditambahkan", {
                variant: "success",
            });
            history.replace("/cart");


        } catch (e) {
            setError(e.message);
        }
        setSubmitting(false);

    };
    // // mitra_asal
    // mitra_asal
    const mitraAsalDoc = firestore.doc(`mitra/${productId?.[2] || 'mitra_asal'}/settings/alamat`);
    const [mitraSnapshot] = useDocument(mitraAsalDoc);



    const [mitraAsal, setMitraAsal] = useState();


    useEffect(() => {
        if (mitraSnapshot) {

            const data = mitraSnapshot.data()
            setMitraAsal({
                ...data,
            });
        }

    }, [mitraSnapshot]);
    //    mitra destination
    const pathAlamat = alamatKey?.[0]?.[0] === 'alamat' ? `mitra/${company_id}/settings/alamat` : `mitra/${company_id}/alamat/${alamatKey ? alamatKey?.[0] : 'alamat'}`
    const destinationDoc = firestore.doc(pathAlamat);
    const [settSnapshotDest, loadingAlamat] = useDocument(destinationDoc);



    const [formDest, setFormDest] = useState();
    // console.log(formDest)

    useEffect(() => {
        if (settSnapshotDest) {

            const data = settSnapshotDest.data()
            setFormDest({
                ...data,
            });
        }

    }, [settSnapshotDest]);

    // purchaseDoc
    const PODoc = firestore.doc(`purchase_orders/${company_id}`);
    const [POsnapshot] = useDocument(PODoc);



    const [formPO, setFromPO] = useState();


    useEffect(() => {
        if (POsnapshot) {

            const data = POsnapshot.data()
            setFromPO({
                ...data,
            });
        }

    }, [POsnapshot]);
    // console.log('PO', formPO?.subcarts?.alamat?.berat_total)
    const beratProdPO = formPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total;


    const handleChangeOngkir = (event) => {
        setOngkir({
            ...ongkir,
            [event.target.name]: event.target.value
        });
    };
    const [kurir_regular, setKurir_regular] = useState({});

    useEffect(() => {
        if (debouncedJumlah && formDest && form?.variant2 && form?.variant2?.berat_paket &&
            hargaPricing?.data?.priceslocal?.[0] &&
            form?.variant2?.panjang_paket &&
            form?.variant2?.lebar_paket &&
            form?.variant2?.tinggi_paket && mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id && formDest?.[formDest?.provider] &&
            !formPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp) {

            setSubmitting(true);
            async function cekOngkir() {
                const getOngkir = functions.httpsCallable("shipping-ongkir");
                let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;
                const provider_detail = formDest?.[formDest?.provider] ?? {};

                let destination = provider_detail?.kecamatan?.id;

                if (mitraAsal?.provider === 'directapi') {
                    origin = {}
                    destination = {}
                    let origin_sicepat = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
                    let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

                    if (origin_sicepat && destination_sicepat && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat) {
                        origin.sicepat = origin_sicepat;
                        destination.sicepat = destination_sicepat;
                    }
                    let origin_jne = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
                    let destination_jne = provider_detail?.kelurahan?.kode_destination_jne;
                    if (origin_jne && destination_jne && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne) {
                        origin.jne = origin_jne;
                        destination.jne = destination_jne;
                    }
                }

                // const destination = alamat?.kecamatan?.id
                // const origin = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
                // const destinationCoord = alamat?.koordinat ? `${alamat.koordinat?.latitude}, ${alamat.koordinat?.longitude}` : null;
                const weight = form?.variant2?.berat_paket * debouncedJumlah;
                const weightTot = weight + beratProdPO;
                const price = hargaPricing?.data?.priceslocal?.[0] * debouncedJumlah;
                const length = form?.variant2?.panjang_paket;
                const width = form?.variant2?.lebar_paket;
                const height = form?.variant2?.tinggi_paket;
                // const mitra_tujuan = form?.[form?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)

                let sub = {
                    id_asal: origin,
                    id_tujuan: destination,
                    berat: weightTot,
                    panjang: length,
                    lebar: width,
                    tinggi: height,
                    harga: price,
                    cod: 0,
                    type: 2


                };
                const response = await getOngkir(sub);



                let data = {}
                switch (response.data.provider) {

                    case 'directapi':
                        data = Object.entries(response.data.items).map(([key, value]) => {
                            return {
                                title: key,
                                data: value?.map?.((item) => {

                                    if (key === "sicepat") {
                                        return {
                                            provider: "sicepat",
                                            ...item,
                                            name: "SiCepat",
                                            rate_name: item.service,
                                            rate_id: item.service,
                                            finalRate: item.tariff,
                                            kurir: 'sicepat',
                                            tipe: item.service
                                        }
                                    } else if (key === "jne") {
                                        return {
                                            provider: "jne",
                                            ...item,
                                            name: "JNE",
                                            service: item.service_display,
                                            rate_id: item.service_code,
                                            rate_name: item.service_display,
                                            finalRate: parseInt(item.price),
                                            kurir: 'jne',
                                            tipe: item.service_code
                                        }
                                    }
                                }),
                            };
                        }).filter((item) => item.data.length > 0);
                        // data = response.data.items.map(item => {

                        //   return {
                        //     title: item.description,
                        //     data: [
                        //       {
                        //         ...item,
                        //         name: item.description,
                        //         rate_name: item.service,
                        //         rate_id: item.service,
                        //         finalRate: item.tariff
                        //       }
                        //     ]
                        //   }
                        // })
                        break;
                    case 'rajaongkir':
                        data = response.data.items.map(item => {
                            return {
                                title: item.name,
                                data: item.costs.map(cost => {
                                    return {
                                        ...cost,
                                        rate_id: `${item.code}-${cost.service}`,
                                        finalRate: cost?.cost?.[0]?.value ?? 0,
                                        name: item.name,
                                        rate_name: cost.service
                                    }
                                })
                            }
                        }).filter((item) => item.data.length > 0);

                        break;
                    case 'shipper':
                        if (kurir_regular) {
                            data = Object.entries(kurir_regular)
                                .map(([key, value]) => {
                                    return {
                                        title: key,
                                        data: value,
                                    };
                                })
                                .filter((item) => item.data.length > 0);
                        }
                        break;
                    default:
                }
                // console.log('respo', response?.data);
                setKurir_regular(data);
                setOngkir(data)

                setSubmitting(false);
            };
            cekOngkir();
        }
    }, [debouncedJumlah, formDest, form?.variant2, JSON.stringify(mitraAsal), JSON.stringify(kurir_regular), hargaPricing?.data?.priceslocal?.[0], !formPO?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp]);
    // console.log(stokTersedia)
    // kurir
    const [editDialog, setEditDialog] = useState({
        open: false,
        data: {},
        uid: {}
    });



    if (prodLoading || loadingStok || loadDataPO || loadingAlamat) {
        return <PageLoading />
    }
    return <>
        <Grid container className={classes.con}>
            <Grid item xs={12} className={classes.pageTitleA}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography className={classes.pageTitleB} component="h1" variant="h6">Tambah Product</Typography>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.carousel2}>
                    <Carousel
                        renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                            currentSlide !== 0 ? (
                                <IconButton size="small" onClick={previousSlide}>
                                    <NavigateBefore fontSize="large" />
                                </IconButton>
                            ) : null
                        }
                        renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) =>
                            currentSlide !== slideCount - 1 ? (
                                <IconButton size="small" onClick={nextSlide}>
                                    <NavigateNext fontSize="large" />
                                </IconButton>
                            ) : null
                        }
                    >
                        {
                            prodForm?.thumbnail?.map((thum, i) => {
                                // const thumb = prodForm?.thumbnail?.[0];
                                const position = thum?.lastIndexOf?.(".");
                                const thumbnail300 =
                                    thum?.substring(0, position) +
                                    "_300x300" +
                                    thum?.substring(position, position?.length);
                                return <img src={thumbnail300} alt='' className={classes.carousel} />
                            })
                        }
                    </Carousel>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.margin3}>
                    <Typography variant="h6" component="h1">{prodForm?.nama} </Typography>
                    {
                        hargaPricing?.data?.priceslocal?.[0] ?
                            <Typography variant="h6" color="error" component="h1">
                                {currency(hargaPricing?.data?.priceslocal?.[0] ? hargaPricing?.data?.priceslocal?.[0] : prodForm?.harga_normal)}
                            </Typography>
                            :
                            <HargaVar prodId={prodSnapshot?.id} file="detailProduct" />
                    }
                </div>
                <Typography variant='subtitle1' className={classes.margin4}>{prodForm?.deskripsi_singkat}</Typography>
                <Typography variant='subtitle2'>{prodForm?.deskripsi_lengkap}</Typography>

                <Grid container spacing={2} className={classes.margin2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="variant1"
                            name="variant1"
                            margin="normal"
                            required
                            value={form?.variant1 || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "variant1", value } });
                            }}
                            disabled={loadingStok}
                            options={prodIdSet || ''}
                            getOptionLabel={(option) => option?.variant1?.nilai || ''}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.variant1}
                                    error={error?.variant1 ? true : false}
                                    {...params}
                                    label={var1?.[0]}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="variant2"
                            name="variant2"
                            margin="normal"
                            required
                            value={form?.variant2 || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "variant2", value } });
                            }}
                            disabled={loadingStok || cekLoad}
                            options={variants2 || ''}
                            getOptionLabel={(option) => option?.variant2?.nilai || ''}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.variant2}
                                    error={error?.variant2 ? true : false}
                                    {...params}
                                    label={var2?.[0]}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <OutlinedInput
                                variant='outlined'
                                value={jumlah}
                                InputProps={{
                                    readOnly: true,
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={jumlah >= stokTersedia}
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                setJumlah(jumlah => jumlah + 1);
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                setJumlah(jumlah => jumlah === 1 ? jumlah : jumlah - 1);
                                            }}
                                            disabled={jumlah === 1}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    {
                        hargaPricing?.data?.priceslocal?.[0] &&
                        <Grid item xs={12}>
                            <Typography style={{ float: 'right' }} variant="body1" color="error" component="h1">Total Harga : {currency(debouncedHarga)}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            ongkir ?
                                <Grid
                                    container
                                    className={classes.jp1}
                                    onClick={(event) => {
                                        setEditDialog((editDialog) => ({
                                            ...editDialog,
                                            dataProd: form?.variant2,
                                            open: true,
                                            mitra_id: company_id,
                                            mitra_asal: mitraAsal,
                                            jumlah: jumlah,
                                            formKurir: ongkir,
                                            isSubmitting: { isSubmitting }
                                        }));
                                    }}
                                >

                                    <Typography variant="body2" style={{ marginBottom: 10 }}>Jasa Pengiriman</Typography>
                                    <Grid item xs={11}>
                                        {
                                            ongkir?.pengiriman?.name ?
                                                <>
                                                    <Typography>{ongkir?.pengiriman?.name || ''}-{ongkir?.pengiriman?.description || ''}({ongkir?.pengiriman?.service || ''})</Typography>
                                                    <Typography>Biaya : {currency(ongkir?.pengiriman?.finalRate)}</Typography>
                                                    <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography>{ongkir?.[0]?.data?.[0]?.name || ''}-{ongkir?.[0]?.data?.[0]?.description || ''}({ongkir?.[0]?.data?.[0]?.service || ''})</Typography>
                                                    <Typography>Biaya : {currency(ongkir?.[0]?.data?.[0]?.finalRate)}</Typography>
                                                    <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography>
                                                </>
                                        }
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            variant='contained'
                                            size='large'
                                            color='primary'
                                        >
                                            <MoreVert />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                :

                                isSubmitting ?
                                    <div className={classes.jp2}>

                                        <div className={classes.jp3}>
                                            <CircularProgress size={15} />
                                            <Typography className={classes.ltx}>Loading...</Typography>
                                        </div>
                                        :
                                        <Typography>Jasa Pengiriman</Typography>

                                    </div> : null
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            color='secondary'
                            disabled={!form?.variant2 || isSubmitting || cekLoad || stokTersedia === 0}
                            onClick={handleSimpan}
                            variant='contained'
                            size="large"
                            startIcon={<AddShoppingCartIcon />}
                        >
                            Tambah Ke Keranjang
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <CekOngkir
            editDialog={editDialog}
            handleClose={() => {
                setEditDialog({ open: false, dataSub: {} });
            }}
            handleEditKurir={handleChangeOngkir}
            kurir_regular={kurir_regular}
        />


    </>
}