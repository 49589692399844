let host = "typesense-dev.nibrasplay.co.id";
let searchKey = "O90ahEjoBJ9W35ToNqtXhMPF5MTn6NYJ";
let indexName = "products";
let indexAlamat = "alamat";

if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
  host = "typesense-dev.nibrasplay.co.id";
  searchKey = "JuqhgrJHAn7rwXomeCzNNaQ1ndszvdMx";
  indexName = "products_prod";
  indexAlamat = "alamat_prod";
}

export { host, searchKey, indexName, indexAlamat };
