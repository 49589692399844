import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore, } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import { useData } from "../../../components/DataProvider";

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from "./styles";

export default function LevelSeller({users_id, name, sid}) {
    const classes = useStyles();
    const { company_id } = useData();

    const levelsCol = firestore.doc(`mitra/${company_id}/sellers_level/${users_id}`);

    const [dataLevels, loadingDataLevels] = useDocument(levelsCol);
    const [level, setLevel] = useState();

    useEffect(() => {
        if (dataLevels) {

            const data = dataLevels.data()
            setLevel({
                ...data,
            });
        }

    }, [dataLevels]);
    // console.log(users_id.users_id)
    if (loadingDataLevels) {
        return <PageLoading />
    }

    return <>
        <div className={classes.sellerRow}>
            <Typography className={classes.h_title}>{name? name : '-'}</Typography>
            {
                level?.is_active === true ?
                <Tooltip title="Seller Aktif" placement="right" arrow>
                    <CheckCircleIcon className={classes.active} />
                </Tooltip>
                :
                <Tooltip title="Seller Nonaktif" placement="right" arrow>
                    <CancelIcon className={classes.nonact} />
                </Tooltip>
            }
        </div>
        <div className={classes.sellerRow, classes.sellerRow2}>
            <Typography className={classes.rowLabel, classes.clr}>{level?.level && 'Level ' + level?.level}</Typography>
        </div>
        <div className={classes.sellerRow, classes.sellerRow2}>
            <Typography className={classes.clr2}>{level?.sellerId? level?.sellerId + ' | ' : ''}{sid || ''}</Typography>
        </div>

    </>
}

