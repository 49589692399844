import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ListSeller from './listSellers';
import Notification from './notification';

function Seller() {
    return (
        <Switch>
            <Route path="/seller/notification/:sellerId" component={Notification} />
            <Route path="/seller" component={ListSeller} />
            <Redirect to="/Product" />
        </Switch>
    );
}

export default Seller;
