/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Paper from "@material-ui/core/Paper";
import PageLoading from "../../../components/loading/pageLoading";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Alamat from "./alamat";
import Fab from "@material-ui/core/Fab";
import AddSeller from "./add";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// lab
import Pagination from "@material-ui/lab/Pagination";

// icon
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LaunchIcon from "@material-ui/icons/Launch";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import PhonesSeller from "./dataSeller";
import WebSeller from "./sellers_webstore";
import LevelSeller from "./sellers_level";
import EditUpline from "./editUpline";
import { useData } from "../../../components/DataProvider";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function ListSeller() {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = React.useState("");
    const { company_id } = useData();
    const listCol = firestore.collection(`mitra/${company_id}/sellers`);
    const [dataUsers, loadingDataKomisi] = useCollectionData(listCol, {
        idField: "id",
    });

    // add Seller
    const [openAddSeller, setOpenAddSeller] = useState(false);
    // edit upline
    const [openEditUpline, setOpenEditUpline] = useState({
        open: false,
        userId: null,
        company_id: null,
    });

    // const usersdata = dataUsers?.map?.((user) => {
    //     return user
    // })
    // console.log(usersdata?.id)

    const itemsPerPage = 10;
    const [page, setPage] = React.useState(1);
    const noOfPages = Math.ceil(dataUsers?.length / itemsPerPage);

    const handleChangePage = (event, value) => {
        setPage(value);
    };
    // const [form, setForm] = useState({
    //     nama: '',
    //     message: '',
    //     data: { tipe_target: '', target_id: '' }
    // });

    // const [error, setError] = useState({
    //     nama: '',
    //     message: '',
    //     data: { tipe_target: '', target_id: '' }
    // });
    // useEffect(() => {
    //     if (snapshot) {
    //         const data = snapshot.data()
    //         setForm({
    //             ...data,
    //         });
    //     }
    // }, [snapshot]);

    // const handleChange = (e) => {
    //     setForm({
    //         ...form,
    //         [e.target.name]: e.target.value
    //     })
    //     setError({
    //         ...error,
    //         [e.target.name]: ''
    //     })
    // }

    // const komisiCol = firestore.collection(`users`);
    // const [dataUsers, loadingDataList] = useCollectionData(komisiCol, { idField: 'id' });

    const phonesCol = firestore.doc(
        `mitra/${company_id}/sellers_login/${searchTerm || "0"}`
    );

    const [dataPhones] = useDocument(phonesCol);
    const [phones, setPhones] = useState();
    useEffect(() => {
        if (dataPhones) {
            const data = dataPhones.data();
            setPhones({
                ...data,
            });
        }
    }, [dataPhones]);
    // console.log(phones)
    const levelsCol = firestore.doc(
        `mitra/${company_id}/sellers_level/${phones?.user_id || "0"}`
    );

    const [dataLevels] = useDocument(levelsCol);
    const [level, setLevel] = useState();
    useEffect(() => {
        if (dataLevels) {
            const data = dataLevels.data();
            setLevel({
                ...data,
            });
        }
    }, [dataLevels]);
    // console.log(level)
    const webstoresCol = firestore.doc(
        `mitra/${company_id}/sellers_webstore/${phones?.user_id || "0"}`
    );

    const [dataWeb] = useDocument(webstoresCol);

    const [web, setWeb] = useState();
    useEffect(() => {
        if (dataWeb) {
            const data = dataWeb.data();
            setWeb({
                ...data,
            });
        }
    }, [dataWeb]);
    // console.log(web)

    const userDoc = dataUsers?.filter?.((user) => {
        if (user?.id === phones?.user_id) {
            return user;
        }
    });
    // console.log(userDoc?.nama)

    // const alamatCol = firestore.collection?.(`users/${user?.id}/alamat`);

    // const [dataAlamat, loadingDataAlamat] = useCollectionData(alamatCol, { idField: 'id' });
    // console.log(searchTerm)

    // lihat alamat
    const [openAddDialog, setOpenAddDialog] = useState({
        open: false,
        userId: null,
    });

    // console.log(dataLevels)
    // const [searchResults, setSearchResults] = React.useState([]);
    // const handleChangeSearch = e => {
    //     setSearchTerm(e.target.value);
    // };
    // React.useEffect(() => {
    //     const results = phone?.filter(person =>
    //         person.toLowerCase().includes(searchTerm)
    //     );
    //     setSearchResults(results);
    // }, [phone, searchTerm]);
    // const result = searchResults?.map((item) => {
    //     return item
    // })
    // const whatsApp = dataPhones?.filter?.((phones) => {
    //     if (searchTerm === phones?.id) {
    //         return phones.id
    //     }
    // })
    // const watsap = whatsApp?.map((wea) => {
    //     return wea?.id
    // })
    // const whats = whatsApp?.map((wea) => {
    //     return wea?.user_id
    // })
    // const what = whats?.[0]
    // const levelDoc = dataLevels?.filter?.((phones) => {
    //     if (what === phones?.id) {
    //         return phones.id
    //     }
    // })
    // const webDoc = dataWeb?.filter?.((phones) => {
    //     if (what === phones?.id) {
    //         return phones.id
    //     }
    // })
    // const changeStatusDomain = web => async () => {
    //     try {
    //         await webstoresCol.doc(web?.id).set({
    //             changesubdomain: 0,
    //             updated_at: FieldValue.serverTimestamp()
    //         }, {
    //             merge: true
    //         })
    //     } catch (e) {
    //         console.log(e.message)
    //     }
    // }
    // // edit Wa
    // const [openEditWa, setOpenEditWa] = useState(false);
    // console.log(whats?.[0])

    if (loadingDataKomisi) {
        return <PageLoading />;
    }
    return (
        <>
            <Container maxWidth="lg">
                <Paper className={classes.paperWrap}>
                    <div className={classes.pageWrap}>
                        <Grid container style={{ marginBottom: 30 }}>
                            <Grid item xs={9}>
                                <Typography
                                    className={classes.pageTitle}
                                    component="h1"
                                    variant="h6"
                                >
                                    Daftar Dropshipper
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Search No.WA"
                                    value={searchTerm}
                                    id="search"
                                    name="search"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    helperText="Search Menggunankan Format +62"
                                    onChange={(e) => {
                                        setSearchTerm(e?.target?.value);
                                    }}
                                    InputProps={{
                                        endAdornment: <Search color="secondary" />,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {searchTerm
                            ? userDoc
                                ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                ?.map?.((users) => {
                                    return (
                                        <div key={users.id}>
                                            <Paper variant="outlined" className={classes.paper}>
                                                {/* <Button
                            component={Link}
                            to={`/seller/notification/${users?.id}`}
                            color="primary"
                            variant="outlined"
                            style={{ float: "right" }}
                          >
                            <NotificationsIcon color="primary" />
                            Notif
                          </Button> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.accSummary}
                                                    >
                                                        <div className={classes.sellerRow}>
                                                            <Typography className={classes.h_title}>
                                                                {users?.nama ? users.nama : "-"}
                                                            </Typography>
                                                            {level?.is_active === true ? (
                                                                <Tooltip
                                                                    title="Seller Aktif"
                                                                    placement="right"
                                                                    arrow
                                                                >
                                                                    <CheckCircleIcon
                                                                        className={classes.active}
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip
                                                                    title="Seller Nonaktif"
                                                                    placement="right"
                                                                    arrow
                                                                >
                                                                    <CancelIcon className={classes.nonact} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                (classes.sellerRow, classes.sellerRow2)
                                                            }
                                                        >
                                                            <Typography
                                                                className={(classes.rowLabel, classes.clr)}
                                                            >
                                                                {level?.level && "Level " + level?.level}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            className={
                                                                (classes.sellerRow, classes.sellerRow2)
                                                            }
                                                        >
                                                            <Typography className={classes.clr2}>
                                                                {level?.sellerId
                                                                    ? level?.sellerId + " | "
                                                                    : ""}
                                                                {users?.id || ""}
                                                            </Typography>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                                lg={6}
                                                                className={classes.card}
                                                            >
                                                                <div className={classes.topBox}>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Email
                                                                        </Typography>
                                                                        <Typography className={classes.rowValue}>
                                                                            : {users.email ? users.email : "-"}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            WA Seller
                                                                        </Typography>
                                                                        <PhonesSeller users_id={users?.id} />
                                                                        {/* <IconButton
                                        size="small"
                                        onClick={() => {
                                          setOpenEditWa(true);
                                        }}
                                        className={classes.ml10}
                                      >
                                        <Edit
                                          className={classes.fs20}
                                          color="secondary"
                                        />
                                      </IconButton> */}
                                                                    </div>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Kode Referal
                                                                        </Typography>
                                                                        <Typography className={classes.rowValue}>
                                                                            :
                                                                            {users?.kode_referral
                                                                                ? users.kode_referral
                                                                                : "-"}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.topBox2}>
                                                                        <WebSeller users_id={users?.id} />
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                                lg={6}
                                                                className={classes.card}
                                                            >
                                                                <div className={classes.botBox}>
                                                                    <Typography className={classes.boxTitle}>
                                                                        Alamat Domisili
                                                                    </Typography>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Alamat Utama
                                                                        </Typography>
                                                                        {users?.alamat_utama ? (
                                                                            <>
                                                                                <Typography
                                                                                    className={classes.rowValue}
                                                                                >
                                                                                    : {users?.alamat_utama}
                                                                                </Typography>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={(e) => {
                                                                                        setOpenAddDialog({
                                                                                            open: true,
                                                                                            userId: users.id,
                                                                                            alamat_utama:
                                                                                                users?.alamat_utama,
                                                                                        });
                                                                                    }}
                                                                                    className={classes.ml10}
                                                                                >
                                                                                    <LaunchIcon
                                                                                        className={classes.fs20}
                                                                                        color="secondary"
                                                                                    />
                                                                                </IconButton>
                                                                            </>
                                                                        ) : (
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                : -
                                                                            </Typography>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className={classes.topBox2}>
                                                                    <div
                                                                        className={classes.sellerRow}
                                                                        style={{ marginBottom: 20 }}
                                                                    >
                                                                        <Typography
                                                                            className={
                                                                                (classes.boxTitle, classes.boxTitle2)
                                                                            }
                                                                        >
                                                                            Jaringan Upline
                                                                        </Typography>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setOpenEditUpline({
                                                                                    open: true,
                                                                                    userId: users,
                                                                                    company_id: company_id,
                                                                                });
                                                                            }}
                                                                            className={classes.ml10}
                                                                        >
                                                                            <Edit
                                                                                className={classes.fs20}
                                                                                color="secondary"
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Referal 1
                                                                        </Typography>
                                                                        <Typography className={classes.rowValue}>
                                                                            : {users?.ref_1?.path || "-"}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Referal 2
                                                                        </Typography>
                                                                        <Typography className={classes.rowValue}>
                                                                            : {users?.ref_2?.path || "-"}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.sellerRow}>
                                                                        <Typography className={classes.rowLabel}>
                                                                            Referal 3
                                                                        </Typography>
                                                                        <Typography className={classes.rowValue}>
                                                                            : {users?.ref_3?.path || "-"}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Paper>
                                        </div>
                                    );
                                })
                            : dataUsers
                                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                ?.map?.((users) => {
                                    // const wa = users?.map?.((phones) => {
                                    //     return phones?.level

                                    // })
                                    // console.log(users?.id)
                                    return (
                                        <div key={users?.id}>
                                            <Paper variant="outlined" className={classes.paper}>
                                                {/* <Button
                                            component={Link} to={`/seller/notification/${users?.id}`}
                                            color="primary"
                                            variant='outlined'
                                            style={{ float: 'right' }}
                                        >
                                            <NotificationsIcon color='primary' />Notif
                                        </Button> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.accSummary}
                                                    >
                                                        <Typography>
                                                            <LevelSeller
                                                                users_id={users?.id}
                                                                name={users.nama}
                                                                sid={users.id}
                                                            />
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                    lg={6}
                                                                    className={classes.card}
                                                                >
                                                                    <div className={classes.topBox}>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Email
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                : {users.email ? users.email : "-"}
                                                                            </Typography>
                                                                        </div>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                WA Seller
                                                                            </Typography>
                                                                            <PhonesSeller users_id={users?.id} />
                                                                            {/* <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setOpenEditWa(true);
                                                                }}
                                                                className={classes.ml10}
                                                            >
                                                                <Edit className={classes.fs20} color="secondary" />
                                                            </IconButton> */}
                                                                        </div>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Kode Referal
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                :{" "}
                                                                                {users?.kode_referral
                                                                                    ? users.kode_referral
                                                                                    : "-"}
                                                                            </Typography>
                                                                        </div>
                                                                        <div className={classes.topBox2}>
                                                                            <WebSeller users_id={users?.id} />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                    lg={6}
                                                                    className={classes.card}
                                                                >
                                                                    <div className={classes.botBox}>
                                                                        <Typography className={classes.boxTitle}>
                                                                            Alamat Domisili
                                                                        </Typography>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Alamat Utama
                                                                            </Typography>
                                                                            {users?.alamat_utama ? (
                                                                                <>
                                                                                    <Typography
                                                                                        className={classes.rowValue}
                                                                                    >
                                                                                        : {users?.alamat_utama}
                                                                                    </Typography>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={(e) => {
                                                                                            setOpenAddDialog({
                                                                                                open: true,
                                                                                                userId: users.id,
                                                                                                alamat_utama:
                                                                                                    users?.alamat_utama,
                                                                                            });
                                                                                        }}
                                                                                        className={classes.ml10}
                                                                                    >
                                                                                        <LaunchIcon
                                                                                            className={classes.fs20}
                                                                                            color="secondary"
                                                                                        />
                                                                                    </IconButton>
                                                                                </>
                                                                            ) : (
                                                                                <Typography
                                                                                    className={classes.rowValue}
                                                                                >
                                                                                    : -
                                                                                </Typography>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className={classes.topBox2}>
                                                                        <div
                                                                            className={classes.sellerRow}
                                                                            style={{ marginBottom: 20 }}
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    (classes.boxTitle,
                                                                                        classes.boxTitle2)
                                                                                }
                                                                            >
                                                                                Jaringan Upline
                                                                            </Typography>
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    setOpenEditUpline({
                                                                                        open: true,
                                                                                        userId: users,
                                                                                        company_id: company_id,
                                                                                    });
                                                                                }}
                                                                                className={classes.ml10}
                                                                            >
                                                                                <Edit
                                                                                    className={classes.fs20}
                                                                                    color="secondary"
                                                                                />
                                                                            </IconButton>
                                                                        </div>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Referal 1
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                : {users?.ref_1?.path || "-"}
                                                                            </Typography>
                                                                        </div>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Referal 2
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                : {users?.ref_2?.path || "-"}
                                                                            </Typography>
                                                                        </div>
                                                                        <div className={classes.sellerRow}>
                                                                            <Typography
                                                                                className={classes.rowLabel}
                                                                            >
                                                                                Referal 3
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.rowValue}
                                                                            >
                                                                                : {users?.ref_3?.path || "-"}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* <Button
                            component={Link}
                            to={`/seller/notification/${users?.id}`}
                            color="primary"
                            variant="outlined"
                          >
                            <NotificationsIcon color="primary" />
                            Notif
                          </Button> */}
                                            </Paper>
                                        </div>
                                    );
                                })}

                        <Pagination
                            className={classes.pagination}
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={1}
                            showFirstButton
                            showLastButton
                            color="primary"
                        />
                    </div>
                </Paper>
            </Container>
            <Alamat
                // userId={users.id}
                dialog={openAddDialog}
                // alamatId={users.alamat_utama}
                handleClose={() => {
                    setOpenAddDialog({
                        open: false,
                        userId: null,
                    });
                }}
            />
            {/* <Alamat
            // userId={users.id}
            dialog={openAddDialog}
            // alamatId={users.alamat_utama}
            handleClose={() => {
                setOpenAddDialog({
                    open: false,
                    userId: null,
                });
            }}
        />
       
        <EditWa
            open={openEditWa}
            handleClose={() => {
                setOpenEditWa(false);
            }}
        />
        */}
            <EditUpline
                dialog={openEditUpline}
                handleClose={() => {
                    setOpenEditUpline({
                        open: false,
                        userId: null,
                        company_id: null,
                    });
                }}
            />
            <div className={classes.fab1}>
                <Fab
                    color="secondary"
                    onClick={() => {
                        setOpenAddSeller(true);
                    }}
                    tooltip
                >
                    <PostAddIcon />
                </Fab>
                <AddSeller
                    open={openAddSeller}
                    handleClose={() => {
                        setOpenAddSeller(false);
                    }}
                />
            </div>
        </>
    );
}

export default ListSeller;
