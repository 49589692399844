import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  firestore,
  storage,
  FieldValue,
} from "../../../components/FirebaseProvider";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import UploadIcon from "@material-ui/icons/CloudUpload";
import useStyles from "./style";
// import AddThumbnailVar from './addThumbnailVar';

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";

import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

// material ui icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useData } from "../../../components/DataProvider";

function AddVariant({
  dialog: { mode, open, data, id },
  handleClose,
  product,
  variants,
}) {
  const { enqueueSnackbar } = useSnackbar();
  // console.log(product)

  const params = useParams();

  const { company_id } = useData();

  const classes = useStyles();

  const [newId, setNewId] = useState();


  // const komisiDoc = firestore.doc(`settings/komisi`);

  // const [komisiSnapshot, komisiLoading] = useDocument(komisiDoc);

  // const [komisiForm, setKomisiForm] = useState();

  // console.log(komisiForm)

  // useEffect(() => {
  //     if (komisiSnapshot) {

  //         const data = komisiSnapshot.data()
  //         setKomisiForm({
  //             ...data,
  //         });
  //     }

  // }, [komisiSnapshot]);

  const variantCol = firestore.collection(
    `mitra/${company_id}/products_reward/${params.productId}/variants`
  );

  const [varForm, setVarForm] = useState({
    produk_reward_id: params.productId,
    variant_id: "",
    variant1: { label: product.variant1_label, nilai: "" },
    variant2: { label: product.variant2_label, nilai: "" },
    stok_available: "",
    stok_booked: "",
    thumbnail: [],
    harga_poin: "",
    // harga_distributor: 0,
    // harga_reseller: 0,
    // harga_mentorbisnis: 0,
    // harga_agen: 0,
    berat_paket: "",
    panjang_paket: "",
    lebar_paket: "",
    tinggi_paket: "",
    alamat_rak: "",
    sku: "",
  });
  // console.log(varForm);
  const [errorVar, setErrorVar] = useState({
    variant_id: "",
    variant2: "",
    variant1: "",
    stok_available: "",
    stok_booked: "",
    thumbnail: "",
    harga_poin: "",
    berat_paket: "",
    panjang_paket: "",
    lebar_paket: "",
    tinggi_paket: "",
    sku: "",
    // alamat_rak: "",
  });
  useEffect(() => {
    const defaultData = {
      variant_id: "",
      variant1: { label: "product.variant1_label", nilai: "" },
      variant2: { label: "product.variant2_label", nilai: "" },
      stok_available: "",
      stok_booked: "",
      thumbnail: [],
      harga_poin: "",
      berat_paket: "",
      panjang_paket: "",
      lebar_paket: "",
      tinggi_paket: "",
      alamat_rak: "",
      sku: "",
    };

    const defaultError = {
      variant_id: "",
      variant1: "",
      variant2: "",
      stok_available: "",
      stok_booked: "",
      thumbnail: '',
      harga_poin: "",
      //   harga_distributor: "",
      //   harga_reseller: "",
      //   harga_mentorbisnis: "",
      //   harga_agen: "",
      //   alamat_rak: "",
      berat_paket: "",
      panjang_paket: "",
      lebar_paket: "",
      tinggi_paket: "",
      sku: "",
    };
    if (mode === "Tambah") {
      setVarForm(defaultData);
      setErrorVar(defaultError);
    } else if (mode === "Edit") {
      setVarForm(data);
      setErrorVar(defaultError);
    }
  }, [mode, data]);

  const [isSubmitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    // console.log(e.target.type);

    setVarForm({
      ...varForm,
      [e.target.name]: value,
    });

    setErrorVar({
      ...errorVar,
      [e.target.name]: "",
    });
  };
  const handleChangeThumb = (e) => {
    setVarForm({
      ...varForm,
      [e.target.name]: e.target.value,
      thumbnail: e.target.value,
    });
  };

  const handleChangeHarga = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    setVarForm({
      ...varForm,
      [e.target.name]: value,
    });
    setErrorVar({
      ...errorVar,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newError = { ...errorVar };

    if (!varForm.variant_id) {
      newError.variant_id = "variant_id variant wajib diisi";
    }

    if (!varForm.sku) {
      newError.sku = "SKU variant wajib diisi";
    }

    if (!varForm.stok_available) {
      newError.stok_available = "stok tersedia variant wajib diisi";
    }

    if (!varForm.stok_booked) {
      newError.stok_booked = "stok dipesan variant wajib diisi";
    }

    if (!varForm.thumbnail) {
      newError.thumbnail = "thumbnail variant wajib diisi";
    }

    if (!varForm.harga_poin) {
      newError.harga_poin = "harga poin wajib diisi";
    }

    if (!varForm.berat_paket) {
      newError.berat_paket = "berat paket variant wajib diisi";
    }

    if (!varForm.lebar_paket) {
      newError.lebar_paket = "lebar paket variant wajib diisi";
    }

    if (!varForm.tinggi_paket) {
      newError.tinggi_paket = " tinggi paket variant wajib diisi";
    }

    if (!varForm.panjang_paket) {
      newError.panjang_paket = " panjang paket variant wajib diisi";
    }

    if (!varForm.variant1?.label) {
      newError.variant1 = "label 1 variant wajib diisi";
    }
    if (!varForm.variant1?.nilai) {
      newError.variant1 = "nilai 1 wajib diisi";
    }
    if (!varForm.variant2?.label) {
      newError.variant2 = "label 2 variant wajib diisi";
    }
    if (!varForm.variant2?.nilai) {
      newError.variant2 = "nilai 2 wajib diisi";
    }

    return newError;
  };

  const handleSimpan = async (e) => {
    e.preventDefault();

    const findErros = validate();
    // console.log(product.id);
    if (Object.values(findErros).some((err) => err !== "")) {
      setErrorVar(findErros);
    } else {
      setSubmitting(true);
      try {
        if (mode === "Tambah") {
          let createNewId = newId;

          if (!newId) {
            createNewId = variantCol.doc().id;
          }

          await variantCol.doc(varForm.variant_id).set(
            {
              ...varForm,
              produk_reward_id: params.productId,
              stok_available: varForm.stok_available || 0,
              stok_booked: varForm.stok_booked || 0,
              variant1: {
                label: product.variant1_label,
                nilai: varForm.variant1.nilai,
              },
              variant2: {
                label: product.variant2_label,
                nilai: varForm.variant2.nilai,
              },
              created_at: FieldValue.serverTimestamp(),
              updated_at: FieldValue.serverTimestamp(),
            },
            {
              merge: true,
            }
          );
          enqueueSnackbar("Variant Berhasil Ditambahkan", {
            variant: "success",
          });

          setNewId(null);
        } else if (mode === "Edit") {
          // console.log("here", mode, id);
          await variantCol.doc(id).set(
            {
              ...varForm,
              variant1: {
                label: product.variant1_label,
                nilai: varForm.variant1.nilai,
              },
              variant2: {
                label: product.variant2_label,
                nilai: varForm.variant2.nilai,
              },
              stok_available: varForm.stok_available || 0,
              stok_booked: varForm.stok_booked || 0,
              updated_at: FieldValue.serverTimestamp(),
              produk_reward_id: params.productId,
            },
            {
              merge: true,
            }
          );
          enqueueSnackbar("Variant Berhasil Diperbarui", {
            variant: "success",
          });
        }
        handleClose();
      } catch (e) {
        console.log(e.message);
        setErrorVar(e.message);
      }
      setSubmitting(false);
    }
  };
  // console.log(handleSimpan);

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];

    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      setErrorVar((errorVar) => ({
        ...errorVar,
        thumbnail: `Tipe file tidak didukung: ${file.type}`,
      }));
    } else if (file.size >= 512000) {
      setErrorVar((errorVar) => ({
        ...errorVar,
        thumbnail: `Ukuran file terlalu besar > 500KB`,
      }));
    } else {
      const reader = new FileReader();

      reader.onabort = () => {
        setErrorVar((errorVar) => ({
          ...errorVar,
          thumbnail: `Proses pembacaan file dibatalkan`,
        }));
      };

      reader.onerror = () => {
        setErrorVar((errorVar) => ({
          ...errorVar,
          thumbnail: "File tidak bisa dibaca",
        }));
      };

      reader.onload = async () => {
        setErrorVar((errorVar) => ({
          ...errorVar,
          thumbnail: "",
        }));
        setSubmitting(true);
        try {
          const productsStorageRef = storage.ref(
            `products_reward/${params.productId}/variants`
          );

          const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));

          let variantId = id;
          if (mode === "Tambah") {
            variantId = newId;

            if (!newId) {
              variantId = variantCol.doc().id;

              setNewId(variantId);
            }
          }

          const thumbnailRef = productsStorageRef.child(
            `${varForm.variant_id}_${Date.now()}${thumbnailExt}`
          );

          const thumbnailSnapshot = await thumbnailRef.putString(
            reader.result,
            "data_url"
          );

          const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

          setVarForm((currentForm) => ({
            ...currentForm,
            thumbnail: [...(varForm?.thumbnail ?? []), thumbnailUrl],
          }));

          // setSomethingChange(true);
        } catch (e) {
          setErrorVar((errorVar) => ({
            ...errorVar,
            thumbnail: e.message,
          }));
        }

        setSubmitting(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (thumb) => async (e) => {
    if (window.confirm("Anda yakin ingin menghapus thumbnail ini?")) {
      const thumbnailURL = thumb;
      if (thumbnailURL) {
        await storage.refFromURL(thumbnailURL).delete();

        setVarForm((varForm) => ({
          ...varForm,
          thumbnail: varForm.thumbnail.filter((t) => t !== thumb),
        }));
      }
    }
  };

  // console.log(varForm);

  //   if (komisiLoading) {
  //     return <PageLoading />;
  //   }

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
      fullScreen
    >
      <DialogTitle>
        <Button onClick={handleClose}>
          <ArrowBackIcon />
        </Button>
        {mode} Variant
      </DialogTitle>

      <DialogContent dividers>
        <div className={classes.grid}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="variant_id"
                name="variant_id"
                label="Variant id Produk"
                value={varForm.variant_id}
                onChange={handleChange}
                error={errorVar.variant_id ? true : false}
                helperText={errorVar.variant_id}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="sku"
                name="sku"
                label="SKU Produk"
                value={varForm.sku}
                onChange={handleChange}
                error={errorVar.sku ? true : false}
                helperText={errorVar.sku}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="variant1"
                name="variant1"
                label={product.variant1_label}
                value={varForm.variant1?.nilai}
                onChange={(e) => {
                  const value = {
                    ...varForm.variant1,
                    nilai: e.target.value,
                  };
                  handleChange({ target: { name: "variant1", value } });
                }}
                error={errorVar.variant1 ? true : false}
                helperText={errorVar.variant1}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="variant2"
                name="variant2"
                label={product.variant2_label}
                value={varForm.variant2?.nilai}
                onChange={(e) => {
                  const value = {
                    ...varForm.variant2,
                    nilai: e.target.value,
                  };
                  handleChange({ target: { name: "variant2", value } });
                }}
                error={errorVar.variant2 ? true : false}
                helperText={errorVar.variant2}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="stok_available"
                name="stok_available"
                label="Stok Tersedia"
                value={varForm.stok_available}
                onChange={handleChange}
                error={errorVar.stok_available ? true : false}
                helperText={errorVar.stok_available}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="stok_booked"
                name="stok_booked"
                label="Stok Dipesan"
                value={varForm.stok_booked}
                onChange={handleChange}
                error={errorVar.stok_booked ? true : false}
                helperText={errorVar.stok_booked}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="harga_poin"
                name="harga_poin"
                label="Harga Poin"
                value={varForm.harga_poin}
                onChange={handleChangeHarga}
                error={errorVar.harga_poin ? true : false}
                helperText={errorVar.harga_poin}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="berat_paket"
                name="berat_paket"
                label="Berat Paket"
                value={varForm.berat_paket}
                onChange={handleChange}
                error={errorVar.berat_paket ? true : false}
                helperText={errorVar.berat_paket}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="outlined"
                disabled={isSubmitting}
                id="panjang_paket"
                name="panjang_paket"
                label="Panjang Paket (cm)"
                value={varForm.panjang_paket}
                onChange={handleChange}
                error={errorVar.panjang_paket ? true : false}
                helperText={errorVar.panjang_paket}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="lebar_paket"
                name="lebar_paket"
                label="Lebar Paket (cm)"
                value={varForm.lebar_paket}
                onChange={handleChange}
                error={errorVar.lebar_paket ? true : false}
                helperText={errorVar.lebar_paket}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="tinggi_paket"
                name="tinggi_paket"
                label="Tinggi Paket (cm)"
                value={varForm.tinggi_paket}
                onChange={handleChange}
                error={errorVar.tinggi_paket ? true : false}
                helperText={errorVar.tinggi_paket}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                disabled={isSubmitting}
                variant="outlined"
                id="alamat_rak"
                name="alamat_rak"
                label="Alamat Rak"
                value={varForm.alamat_rak}
                onChange={handleChange}
                // error={errorVar.alamat_rak ? true : false}
                // helperText={errorVar.alamat_rak}
                fullWidth
              // type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.thumbnailLabel}>
                Thumbnail
              </InputLabel>
              <FormControl className={classes.formControl}>
                <InputLabel variant="outlined" id="demo-mutiple-checkbox-label">
                  Select Thumbnail
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={varForm.thumbnail ?? []}
                  onChange={handleChangeThumb}
                  input={<Input />}
                  renderValue={(selected) => selected?.join?.(", ")}
                // MenuProps={MenuProps}
                >
                  {variants
                    ?.reduce((prev, curr) => {
                      return [...prev, ...(curr.thumbnail ?? [])];
                    }, [])
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={varForm.thumbnail.indexOf?.(name) > -1}
                        />
                        <img src={name} width="200" height="230" alt="" />{" "}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={classes.previewFotoVar}>
                {varForm.thumbnail?.map?.((thumb) => {

                  return (
                    <>
                      <Grid key={thumb}>
                        <img src={thumb} width="200" height="230" alt="" />

                        <IconButton className={classes.xIcon}>
                          <HighlightOffIcon onClick={handleDelete(thumb)} />
                        </IconButton>
                      </Grid>
                    </>
                  );
                })}
              </div>
              <div className={classes.uploadFotoKursus}>
                <input
                  className={classes.hideInputFile}
                  type="file"
                  id="upload-thumbnail-variants"
                  accept="image/jpeg,image/png,image/jpg"
                  onChange={handleUploadFile}
                />
                <label htmlFor="upload-thumbnail-variants">
                  <Button
                    disabled={isSubmitting}
                    variant="outlined"
                    component="span"
                  >
                    Upload
                    <UploadIcon className={classes.iconRight} />
                  </Button>
                </label>
                {errorVar.thumbnail && (
                  <Typography color="error">{errorVar.thumbnail}</Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={handleClose}
          variant="contained"
        >
          Batal
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={handleSimpan}
          color="primary"
          variant="contained"
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddVariant.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddVariant;
