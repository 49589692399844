import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import {
//   firestore,
//   functions,
// } from "../../../components/FirebaseProvider";
// import Grid from "@material-ui/core/Grid";
// import useStyles from "./styles";
import {
  List,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  Typography,
  // Typography,
} from "@material-ui/core";
import { currency } from "../../../utils/formatter";
// import { useDocument } from "react-firebase-hooks/firestore";
function CekOngkir({
  editDialog: { open, formKurir },
  kurir_regular,
  handleClose,
  handleEditKurir,
}) {
  // const classes = useStyles();
  // const location = useLocation();
  // const { enqueueSnackbar } = useSnackbar();

  // console.log(kurir_regular)
  const lengthKurir = kurir_regular?.length;
  var fieldsArray = [];
  for (var i = 0; i < lengthKurir; i++) {
    fieldsArray.push(
      kurir_regular?.[i]?.data ? (
        <>
          <Typography>{kurir_regular?.[i]?.title.toUpperCase()}</Typography>
          {kurir_regular?.[i]?.data
            ?.filter((value, index) => {
              return (
                value?.service !== "KEPO" &&
                value?.service !== "REG" &&
                !value?.service?.includes("JTR") &&
                !value?.service?.includes("CTC")
              );
            })
            .map?.((item, i) => {
              return (
                <>
                  {item?.price !== 0 && item?.serviceName !== "Cargo" && (
                    <List key={i}>
                      <ListItem
                        key={i}
                        button
                        // disabled={isSubmitting}
                        // selected={selectedIndex === 0}
                        value={formKurir?.pengiriman}
                        // disabled={isSubmitting}
                        // selected={selectedIndex === 0}
                        onClick={(event, value) => {
                          handleEditKurir({
                            target: { name: "pengiriman", value: item },
                          });
                          handleClose();
                        }}
                      >
                        <ListItemText
                          primary={`${item?.provider?.toUpperCase()} ${
                            item?.provider === "jne" ||
                            item?.provider === "ninja" ||
                            item?.provider === "jnt"
                              ? ""
                              : item?.description
                          } - ${
                            item?.provider === "ninja"
                              ? item?.serviceName :
                              item?.provider === "jnt" ?
                              item?.nameService
                              : item?.service
                          }`}
                          secondary={`${currency(item?.finalRate)} 
                            - ( ${
                              item?.provider === "jne"
                                ? item?.etd_from !== null
                                  ? `${item?.etd_from} - ${item.etd_thru} hari`
                                  : `0 hari`
                                : `${item?.etd}`
                            } 
                            )
                        `}
                        />
                      </ListItem>
                    </List>
                  )}
                </>
              );
            })}
        </>
      ) : (
        <ListItemText primary="Belum Tersedia" />
      )
    );
  }

  // for (var i = 0; i < lengthKurir; i++) {
  //   fieldsArray.push(
  //     kurir_regular?.[i]?.data ? (
  //           <>
  //             <Typography>{kurir_regular?.[i]?.title}</Typography>

  //     { kurir_regular?.[i]?.data?.map?.((item, i) => {
  //         return (
  //           <>
  //             <List key={i}>
  //               <ListItem
  //                 key={i}
  //                 button
  //                 // disabled={isSubmitting}
  //                 // selected={selectedIndex === 0}
  //                 value={formKurir?.pengiriman}
  //                 // disabled={isSubmitting}
  //                 // selected={selectedIndex === 0}
  //                 onClick={(event, value) => {
  //                   handleEditKurir({
  //                     target: { name: "pengiriman", value: item },
  //                   });
  //                   handleClose();
  //                 }}
  //               >
  //                 <ListItemText
  //                   primary={`${item?.provider?.toUpperCase()} ${
  //                     item?.provider === "jne" ? "" : item?.description
  //                   } - ${item?.service}`}
  //                   secondary={`${currency(item?.finalRate)}
  //                           - ( ${
  //                             item?.provider === "jne"
  //                               ? item?.etd_from !== null
  //                                 ? `${item?.etd_from} - ${item.etd_thru} hari`
  //                                 : `0 hari`
  //                               : `${item.etd}`
  //                           }
  //                           )
  //                       `}
  //                 />
  //               </ListItem>
  //             </List>
  //           </>
  //         );
  //       }) }
  //         </>

  //     ) : (
  //       <ListItemText primary="Belum Tersedia" />
  //     )
  //   );
  // }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Pilih Jasa Pengiriman</DialogTitle>
      <DialogContent dividers>{fieldsArray}</DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={handleClose}>
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CekOngkir.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CekOngkir;
