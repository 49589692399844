import { useDocumentData } from "react-firebase-hooks/firestore"
import { firestore } from "../../../../components/FirebaseProvider"

export function DataJumlah(props) {
    //   variants
    const variantDoc = firestore.doc(`products/${props?.prodId}/variants/${props?.varId}`)
    const [variantData] = useDocumentData(variantDoc)

    return (variantData?.berat_paket) + (props?.jumlah)
}
