// Nibrasplay Dev
let srcDashboard = "https://datastudio.google.com/embed/reporting/81916fff-e48e-4bc1-b0be-cbcd185df482/page/Gg3";

// Nibrasplay Prod
if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
    srcDashboard = "https://datastudio.google.com/embed/reporting/2834a492-0ad3-4021-90f3-9f67cdbf5692/page/Gg3"
}

// Kulaku Prod
// else if (process.env.REACT_APP_ENV === "KULAKU_PROD") {
//     srcDashboard = "";
// }

// Kulaku Dev
else if (process.env.REACT_APP_ENV === "KULAKU_DEV") {
    srcDashboard = "https://datastudio.google.com/embed/reporting/0f102690-719b-401c-859e-b9304aebce1e/page/Gg3";
}

// Reform Prod
else if (process.env.REACT_APP_ENV === "REFORM_PROD") {
    srcDashboard = "https://datastudio.google.com/embed/reporting/efd24e34-9672-4513-9130-3aa5e812746e/page/Gg3";
}

// Reform Dev
// else if (process.env.REACT_APP_ENV === "REFORM_DEV") {
//     srcDashboard = "";
// }

export default srcDashboard;
