import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";
import { firestore, functions, FieldValue } from "../../../components/FirebaseProvider";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PageLoading from "../../../components/loading/pageLoading";
import { currency } from "../../../utils/formatter";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Container } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CekOngkir from "./cekOngkir";
import CircularProgress from '@material-ui/core/CircularProgress';

import { MoreVert, } from "@material-ui/icons";

export default function Cart({ value }) {
    const classes = useStyles()

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { company_id } = useData();

    const PODoc = firestore.doc(`purchase_orders/${company_id}`);

    const [POSnapshot, POLoading] = useDocument(PODoc);

    const [POForm, setProdForm] = useState();
    useEffect(() => {
        if (POSnapshot) {
            const data = POSnapshot.data();
            setProdForm({
                ...data,
            });
        }
    }, [POSnapshot]);

    // cek ongkir
    // mitra_asal
    const mitraAsalDoc = firestore.doc(`mitra/${POForm?.seller_mitra?.id || POForm?.seller_mitra?.mitraId || 'mitra_asal'}/settings/alamat`);
    const [mitraSnapshot] = useDocument(mitraAsalDoc);

    let alamatKey = []
    if (POForm?.subcarts) {
        const objAlamat = Object.keys(POForm?.subcarts)

        alamatKey.push(objAlamat)
    }
    // console.log(alamatKey)
    const [mitraAsal, setMitraAsal] = useState();


    useEffect(() => {
        if (mitraSnapshot) {

            const data = mitraSnapshot.data()
            setMitraAsal({
                ...data,
            });
        }

    }, [mitraSnapshot]);
    //    mitra destination
    const pathAlamat = alamatKey?.[0]?.[0] === 'alamat' ? `mitra/${company_id}/settings/alamat` : `mitra/${company_id}/alamat/${alamatKey ? alamatKey?.[0] : 'alamat'}`
    const destinationDoc = firestore.doc(pathAlamat);
    const [settSnapshotDest] = useDocument(destinationDoc);

    const [formDest, setFormDest] = useState();
    // console.log(pathAlamat)

    useEffect(() => {
        if (settSnapshotDest) {

            const data = settSnapshotDest.data()
            setFormDest({
                ...data,
            });
        }

    }, [settSnapshotDest]);


    // console.log('PO', formPO?.subcarts?.alamat?.berat_total)
    //  const beratProdPO = formPO?.subcarts?.alamat?.berat_total;

    const [ongkir, setOngkir] = useState()
    const handleChangeOngkir = (event) => {
        setOngkir({
            ...ongkir,
            [event.target.name]: event.target.value
        });
    };
    const [kurir_regular, setKurir_regular] = useState({});
    const [isSubmitting, setSubmitting] = useState(false)
    useEffect(() => {
        if (POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total && mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id
            && formDest?.[formDest?.provider] &&
            POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_pembayaran &&
            POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.length &&
            POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.width &&
            POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.height &&
            !POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp
            //  debouncedJumlah && formDest && form?.variant2 && form?.variant2?.berat_paket &&
            //  form?.variant2?.harga_normal &&
            //  form?.variant2?.panjang_paket &&
            //  form?.variant2?.lebar_paket &&
            //  form?.variant2?.tinggi_paket && mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id && formDest?.[formDest?.provider]
        ) {

            setSubmitting(true);
            async function cekOngkir() {
                const getOngkir = functions.httpsCallable("shipping-ongkir");
                let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;
                const provider_detail = formDest?.[formDest?.provider] ?? {};

                let destination = provider_detail?.kecamatan?.id;

                if (mitraAsal?.provider === 'directapi') {
                    origin = {}
                    destination = {}
                    let origin_sicepat = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
                    let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

                    if (origin_sicepat && destination_sicepat && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat) {
                        origin.sicepat = origin_sicepat;
                        destination.sicepat = destination_sicepat;
                    }
                    let origin_jne = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
                    let destination_jne = provider_detail?.kelurahan?.kode_destination_jne;
                    if (origin_jne && destination_jne && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne) {
                        origin.jne = origin_jne;
                        destination.jne = destination_jne;
                    }
                }

                // const destination = [alamatKey ? alamatKey?.[0] : 'alamat']?.kecamatan?.id
                // const origin = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)
                // const destinationCoord = [alamatKey ? alamatKey?.[0] : 'alamat']?.koordinat ? `${[alamatKey ? alamatKey?.[0] : 'alamat'].koordinat?.latitude}, ${[alamatKey ? alamatKey?.[0] : 'alamat'].koordinat?.longitude}` : null;
                //  const weight = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total;
                const weightTot = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total;
                const price = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_pembayaran;
                const length = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.length;
                const width = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.width;
                const height = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.height;
                // const mitra_tujuan = form?.[form?.provider]?.kelurahan?.kode_sicepat?.substring(0, 3)

                let sub = {
                    id_asal: origin,
                    id_tujuan: destination,
                    berat: weightTot,
                    panjang: length,
                    lebar: width,
                    tinggi: height,
                    harga: price,
                    cod: 0,
                    type: 2


                };
                const response = await getOngkir(sub);

                let data = {}
                switch (response.data.provider) {

                    case 'directapi':
                        data = Object.entries(response.data.items).map(([key, value]) => {
                            return {
                                title: key,
                                data: value?.map?.((item) => {

                                    if (key === "sicepat") {
                                        return {
                                            provider: "sicepat",
                                            ...item,
                                            name: "SiCepat",
                                            rate_name: item.service,
                                            rate_id: item.service,
                                            finalRate: item.tariff,
                                            kurir: 'sicepat',
                                            tipe: item.service
                                        }
                                    } else if (key === "jne") {
                                        return {
                                            provider: "jne",
                                            ...item,
                                            name: "JNE",
                                            service: item.service_display,
                                            rate_id: item.service_code,
                                            rate_name: item.service_display,
                                            finalRate: parseInt(item.price),
                                            kurir: 'jne',
                                            tipe: item.service_code
                                        }
                                    }
                                }),
                            };
                        }).filter((item) => item.data.length > 0);
                        // data = response.data.items.map(item => {

                        //   return {
                        //     title: item.description,
                        //     data: [
                        //       {
                        //         ...item,
                        //         name: item.description,
                        //         rate_name: item.service,
                        //         rate_id: item.service,
                        //         finalRate: item.tariff
                        //       }
                        //     ]
                        //   }
                        // })
                        break;
                    case 'rajaongkir':
                        data = response.data.items.map(item => {
                            return {
                                title: item.name,
                                data: item.costs.map(cost => {
                                    return {
                                        ...cost,
                                        rate_id: `${item.code}-${cost.service}`,
                                        finalRate: cost?.cost?.[0]?.value ?? 0,
                                        name: item.name,
                                        rate_name: cost.service
                                    }
                                })
                            }
                        }).filter((item) => item.data.length > 0);

                        break;
                    case 'shipper':
                        if (kurir_regular) {
                            data = Object.entries(kurir_regular)
                                .map(([key, value]) => {
                                    return {
                                        title: key,
                                        data: value,
                                    };
                                })
                                .filter((item) => item.data.length > 0);
                        }
                        break;
                    default:
                }
                // console.log('respo', response?.data);
                setKurir_regular(data);
                setOngkir(data)

                setSubmitting(false);
            };
            cekOngkir();
        }
    }, [POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.berat_total, formDest?.[formDest?.provider], mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id, POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_pembayaran, POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.length, POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.width, POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.height, !POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp]);

    // dialogOngkir
    const [editDialog, setEditDialog] = useState({
        open: false,
        data: {},
        uid: {}
    });

    const [loading, setLoading] = useState(false);
    const [urlDoku, setUrl] = useState()
    // setOngkir
    const totag = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_tagihan
    const ongkirNow = ongkir?.pengiriman ? ongkir?.pengiriman : ongkir?.[0]?.data?.[0]
    const handleCheckout = async () => {

        setLoading(true);

        try {
            if (!POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp) {
                await PODoc.set({
                    subcarts: {
                        [alamatKey ? alamatKey?.[0] : 'alamat']: {
                            total_pembayaran: ongkirNow?.finalRate ? totag + ongkirNow?.finalRate : totag,
                            pengiriman: ongkirNow,

                        }
                    }
                }, { merge: true })
            } else if (POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.resi_otomatis_mp) {
                await PODoc.set({
                    subcarts: {
                        [alamatKey ? alamatKey?.[0] : 'alamat']: {
                            total_pembayaran: ongkirNow?.finalRate ? totag + ongkirNow?.finalRate : totag,
                            // pengiriman: ongkirNow,

                        }
                    }
                }, { merge: true })
            }

            const paymentOrder = functions.httpsCallable("payment-orderMitra");

            const response = await paymentOrder();

            const data = response.data;
            // console.log(data)
            if (data?.provider === "doku") {
                window.open(data?.doku?.payment?.url);
            }

            setUrl(data)
            setLoading(false);
        } catch (e) {
            // console.log(e.message)

            enqueueSnackbar(e.message, { variant: "error" });
            setLoading(false);
        }
    };
    const handleDeleteProd = (prodKeys, id) => async () => {
        // console.log('del', prodKeys, id)
        try {
            await PODoc.set({
                subcarts: {
                    [alamatKey ? alamatKey?.[0] : 'alamat']: {
                        total_tagihan: FieldValue.increment(-prodKeys?.harga), total_pembayaran: FieldValue.increment(-prodKeys?.harga), berat_total: FieldValue.increment(-prodKeys?.berat),
                        // prngiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
                        products: {
                            [id]: FieldValue.delete()
                        }
                    }
                }
            }, { merge: true })
            // await PODoc.update({
            //     subcarts: {
            //         alamat: {
            //             products: {
            //                 [id]: FieldValue.delete()
            //             }
            //         }
            //     }
            // }, { merge: true })
            enqueueSnackbar(`Produk dihapus`, { variant: "success" });

        } catch (e) {
            // console.log(e.message)
            enqueueSnackbar(e.message, { variant: "error" });

        }
    }
    // var checkoutButton = document?.getElementById('checkout-button');
    // // Example: the payment page will show when the button is clicked
    // checkoutButton?.addEventListener?.('click', function () {
    //     `handleCheckout(${urlDoku?.doku?.payment?.url})`; // Replace it with the response.payment.url you retrieved from the response
    // });
    // const checkOut=handleCheckout.addEventListener('click', function () {
    //     handleCheckout('https://jokul.doku.com/checkout/link/SU5WFDferd561dfasfasdfae123c20200510090550775'); // Replace it with the response.payment.url you retrieved from the response
    // });

    // console.log(Object.keys(POForm?.subcarts))
    let datasuborder = [];
    if (POForm?.subcarts) {
        const objkey = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products
        const DataProd = Object.keys(objkey)
        // console.log(POForm?.subcarts?.alamat?.products?.[DataProd?.[0]])
        const DataLength = DataProd?.length;
        if (DataLength < 1) {
            return <Container maxWidth="xs" className={classes.isEmpty}>
                <Typography variant="h5">Keranjang Masih Kosong</Typography>
                <Button
                    onClick={() => {
                        history.push(`/purchaseOrders`);
                    }}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: 20 }}
                >
                    Kembali
                </Button>
            </Container>
        }
        // if (!POForm?.kode_po) {
        //     return <Container maxWidth="xs" className={classes.isEmpty}>
        //         <Typography variant="h5">Keranjang Masih Kosong</Typography>
        //         <Button
        //             onClick={() => {
        //                 history.push('/purchaseOrders');
        //             }}
        //             color="primary"
        //             variant="contained"
        //             style={{ marginTop: 20 }}
        //         >
        //             Ke List Product
        //         </Button>
        //     </Container>
        // }
        for (var i = 0; i < DataLength; i++) {
            let key = i
            const prodKeys = POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products?.[DataProd?.[i]];
            datasuborder.push(
                <Grid container className={classes.transaction}>
                    <Grid item xs={11}>
                        {/* <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <img src={POForm?.subcarts?.[alamatKey?alamatKey?.[0]:'alamat']?.products?.[DataProd?.[i]]?.thumbnail?.[0]} />
                            </Grid>
                            <Grid item xs={6}> */}
                        {/* <Typography className={classes.transactionItems}>Produk Ke-{key + 1}</Typography> */}
                        <Typography className={classes.transactionItems}>Nama Produk : {POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products?.[DataProd?.[i]]?.nama} </Typography>
                        <Typography className={classes.transactionItems}>Nama Variant : {POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products?.[DataProd?.[i]]?.variant} </Typography>
                        <Typography className={classes.transactionItems}>Jumlah Produk : {POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products?.[DataProd?.[i]]?.jumlah}</Typography>
                        <Typography className={classes.transactionItems}>Harga : {currency(POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.products?.[DataProd?.[i]]?.harga)}</Typography>
                        {/* </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color='secondary'
                            style={{ float: 'right' }}
                            onClick={handleDeleteProd(prodKeys, DataProd?.[i])}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )
        }
    }
    // console.log(POForm?.subcarts?.[alamatKey?alamatKey?.[0]:'alamat']?.total_pembayaran)

    // console.log('===<' , ongkir?.pengiriman?.name)
    if (POLoading) {
        return <PageLoading />
    }

    return <>

        {
            POForm?.kode_po ?
                <Paper className={classes.container}>
                    <div className={classes.kode}>
                        <IconButton
                            onClick={() => {
                                history.goBack();
                            }}
                            className={classes.iconBck}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography>Kode Order : <b>{POForm?.kode_po}</b></Typography>
                    </div>
                    <div className={classes.subContainer}>
                        {datasuborder}
                    </div>
                    <Grid container spacing={3} className={classes.subContainer}>
                        <Grid item xs={6} lg={6}>
                            <Typography>Toko </Typography>
                            <br />
                            <Typography>{POForm?.seller_mitra?.nama}</Typography>
                            <Typography>{POForm?.seller_mitra?.alamat}</Typography>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Typography>Detail Pembayaran</Typography>
                            <br />
                            <Typography>Total Tagihan : {currency(POForm?.subcarts?.[alamatKey ? alamatKey?.[0] : 'alamat']?.total_tagihan)}</Typography>
                            {/* <Typography>Biaya Pengiriman : {currency(POForm?.subcarts?.alamat?.pengiriman?.finalRate)}</Typography> */}
                            <Grid>
                                {
                                    ongkir ?
                                        <Grid
                                            container
                                            className={classes.jp1}
                                            onClick={(event) => {
                                                setEditDialog((editDialog) => ({
                                                    ...editDialog,
                                                    // dataProd: form?.variant2,
                                                    open: true,
                                                    mitra_id: company_id,
                                                    mitra_asal: mitraAsal,
                                                    // jumlah: jumlah,
                                                    formKurir: ongkir,
                                                    isSubmitting: { isSubmitting }
                                                }));
                                            }}
                                        >
                                            <Typography variant="body2" style={{ marginBottom: 10 }}>Jasa Pengiriman</Typography>
                                            <Grid item xs={11}>
                                                {
                                                    ongkir?.pengiriman?.name ?
                                                        <>
                                                            <Typography>{ongkir?.pengiriman?.name || ''}-{ongkir?.pengiriman?.description || ''}({ongkir?.pengiriman?.service || ''})</Typography>
                                                            <Typography>Biaya : {currency(ongkir?.pengiriman?.finalRate)}</Typography>
                                                            <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography>
                                                        </>
                                                        :
                                                        <>
                                                            <Typography>{ongkir?.[0]?.data?.[0]?.name || ''}-{ongkir?.[0]?.data?.[0]?.description || ''}({ongkir?.[0]?.data?.[0]?.service || ''})</Typography>
                                                            <Typography>Biaya : {currency(ongkir?.[0]?.data?.[0]?.finalRate)}</Typography>
                                                            {/* <Typography variant="caption" color="error">(Total Jasa Pengiriman Dengan product Sebelumnya)</Typography> */}
                                                        </>
                                                }
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    variant='contained'
                                                    size='large'
                                                    color='primary'
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        : isSubmitting ?
                                            <div className={classes.jp2}>


                                                <div className={classes.jp3}>
                                                    <CircularProgress size={15} />
                                                    <Typography className={classes.ltx}>Loading...</Typography>
                                                </div>

                                            </div> : null
                                }
                            </Grid>
                            <Divider style={{ margin: '10px 0px' }} />
                            <Typography>Total Pembayaran : <b className={classes.gt}>{currency(ongkirNow?.finalRate ? totag + ongkirNow?.finalRate : totag)}</b></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.btn}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    history.push(`/cart/prodMitra/${POForm?.seller_mitra?.id}`)
                                }}
                                disabled={loading || !POForm?.kode_po}
                                color="secondary"
                                variant="contained"
                                size="large"
                                fullWidth
                            >
                                Tambah Produk Dari Toko Ini
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={handleCheckout}
                                disabled={loading || !POForm?.kode_po}
                                color="primary"
                                variant="contained"
                                size="large"
                                fullWidth
                            >
                                Check Out
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                :
                <Container maxWidth="xs" className={classes.isEmpty}>
                    <Typography variant="h5">Keranjang Masih Kosong</Typography>
                    <Button
                        onClick={() => {
                            history.push(`/purchaseOrders`);
                        }}
                        color="primary"
                        variant="contained"
                        style={{ marginTop: 20 }}
                    >
                        Kembali
                    </Button>
                </Container>
        }

        <CekOngkir
            editDialog={editDialog}
            handleClose={() => {
                setEditDialog({ open: false, dataSub: {} });
            }}
            handleEditKurir={handleChangeOngkir}
            kurir_regular={kurir_regular}
        />
    </>
}