// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: "AIzaSyBR1MJcMdA_0v-jVb8GkTpzCfsd24UbqQU",
  authDomain: "nibrasplay-dev.firebaseapp.com",
  projectId: "nibrasplay-dev",
  storageBucket: "nibrasplay-dev.appspot.com",
  messagingSenderId: "1069291091767",
  appId: "1:1069291091767:web:c62697c4475a79413f0cb2",
  measurementId: "G-L8KHXGSHHR"
};
if (
  process.env.REACT_APP_ENV === "KULAKU_PROD"
) {
  firebaseConfig = {
    apiKey: "AIzaSyBdtFfhhc_yqXQs1kGfKTEfjS44gEPwFQg",
    authDomain: "yasmeera-dev.firebaseapp.com",
    projectId: "yasmeera-dev",
    storageBucket: "yasmeera-dev.appspot.com",
    messagingSenderId: "691308505563",
    appId: "1:691308505563:web:4f907b0c291660d57d1ffe",
    measurementId: "G-3ZC1L4NYK3"
  };
} else if (
  process.env.REACT_APP_ENV === "REFORM_PROD"
) {
  firebaseConfig = {
    apiKey: "AIzaSyBR1MJcMdA_0v-jVb8GkTpzCfsd24UbqQU",
    authDomain: "nibrasplay-dev.firebaseapp.com",
    projectId: "nibrasplay-dev",
    storageBucket: "nibrasplay-dev.appspot.com",
    messagingSenderId: "1069291091767",
    appId: "1:1069291091767:web:c62697c4475a79413f0cb2",
    measurementId: "G-L8KHXGSHHR"
  };
} else if (
  process.env.REACT_APP_ENV === "KULAKU_DEV"
) {
  firebaseConfig = {
    apiKey: "AIzaSyBdtFfhhc_yqXQs1kGfKTEfjS44gEPwFQg",
    authDomain: "yasmeera-dev.firebaseapp.com",
    projectId: "yasmeera-dev",
    storageBucket: "yasmeera-dev.appspot.com",
    messagingSenderId: "691308505563",
    appId: "1:691308505563:web:4f907b0c291660d57d1ffe",
    measurementId: "G-3ZC1L4NYK3"
  };
} else if (
  process.env.REACT_APP_ENV === "REFORM_DEV"
) {
  firebaseConfig = {
    apiKey: "AIzaSyBR1MJcMdA_0v-jVb8GkTpzCfsd24UbqQU",
    authDomain: "nibrasplay-dev.firebaseapp.com",
    projectId: "nibrasplay-dev",
    storageBucket: "nibrasplay-dev.appspot.com",
    messagingSenderId: "1069291091767",
    appId: "1:1069291091767:web:c62697c4475a79413f0cb2",
    measurementId: "G-L8KHXGSHHR"
  };
} else if (
  process.env.REACT_APP_ENV === "NIBRAS_PROD"
) {
  firebaseConfig = {
    apiKey: "AIzaSyDMYwws-LkFpVVdzM5IkMr0yD-x64QyKvE",
    authDomain: "nibrasplay-prod.firebaseapp.com",
    projectId: "nibrasplay-prod",
    storageBucket: "nibrasplay-prod.appspot.com",
    messagingSenderId: "816396362206",
    appId: "1:816396362206:web:2b8ae7e9aaa06c322bfd4f",
    measurementId: "G-XMPZ1BJJ6W"
  };
} else if (
  process.env.REACT_APP_ENV === "NIBRAS_DEV"
) {
  firebaseConfig = {
    apiKey: "AIzaSyBR1MJcMdA_0v-jVb8GkTpzCfsd24UbqQU",
    authDomain: "nibrasplay-dev.firebaseapp.com",
    projectId: "nibrasplay-dev",
    storageBucket: "nibrasplay-dev.appspot.com",
    messagingSenderId: "1069291091767",
    appId: "1:1069291091767:web:c62697c4475a79413f0cb2",
    measurementId: "G-L8KHXGSHHR"
  };
}

export default firebaseConfig;