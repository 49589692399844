import React, { useState } from 'react';
import { firestore, useFirebase } from '../../../components/FirebaseProvider';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Fab from '@material-ui/core/Fab';

import useStyles from './styles';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import { Card } from '@material-ui/core';
import AddNotif from './add';
import { useData } from '../../../components/DataProvider';

function Notification() {
    const classes = useStyles();
    const { company_id } = useData();
    // console.log(params?.sellerId)
    const notifCol = firestore.collection(`mitra/${company_id}/notifications`);
    const [dataNotif, loadingNotif] = useCollectionData(notifCol, { idField: 'id' })
    const [openAddNotif, setOpenAddNotif] = useState(false);

    if (loadingNotif) {
        return <PageLoading />
    }
    return (<>
        <Container maxWidth='lg' spacing={2}>
            <Paper className={classes.paperWrap}>
                <div className={classes.pageWrap}>
                    <Typography className={classes.pageTitle} component="h1" variant="h6">Daftar Notifikasi</Typography>
                    <Grid container>
                        {dataNotif?.length <= 0 &&
                            <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Notifikasi</Typography>}
                        {dataNotif?.map((notif) => {
                            const target = notif?.data?.target_nama;
                            const target2 = notif?.data?.target_id;
                            // console.log(target2)
                            return <Grid className={classes.grid} key={notif.id} item xs={12} md={6} lg={4} xl={4}>
                                <Card className={classes.paper}>
                                    <div className={classes.notifRow}>
                                        <Typography className={classes.notifLabel}>Judul : </Typography>
                                        <Typography className={classes.notifValue}>{notif.title}</Typography>
                                    </div>
                                    <div className={classes.notifRow}>
                                        <Typography className={classes.notifLabel}>Pesan : </Typography>
                                        <Typography className={classes.notifValue}>{notif.message}</Typography>
                                    </div>
                                    <div className={classes.notifRow}>
                                        <Typography className={classes.notifLabel}>Data </Typography>
                                        <Typography className={classes.notifLabel}>Tipe Target :</Typography>
                                        <Typography className={classes.notifValue}>{notif?.data?.target_type || ''}</Typography>
                                    </div>
                                    <div className={classes.notifRow}>
                                        <Typography className={classes.notifLabel}>Target Id :</Typography>
                                        <Typography className={classes.notifValue}>{target2 || ''}</Typography>
                                    </div>
                                    <div className={classes.notifRow}>
                                        <Typography className={classes.notifLabel}>Target Nama :</Typography>
                                        <Typography className={classes.notifValue}>{target || ''}</Typography>
                                    </div>
                                </Card>
                            </Grid>
                        })
                        }
                    </Grid>
                </div>
            </Paper>
        </Container>
        <div className={classes.fab1}>
            <Fab
                color="secondary"
                onClick={() => {
                    setOpenAddNotif(true);
                }}
                tooltip
            >
                <PostAddIcon />
            </Fab>
            <AddNotif
                open={openAddNotif}
                handleClose={() => {
                    setOpenAddNotif(false);
                }}
            />
        </div>
    </>)
}

export default Notification;