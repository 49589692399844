import React, { useEffect, useState } from "react";
import TabPanel from "../../../components/TabPanel";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Card from "@material-ui/core/Card";
import { currency } from "../../../utils/formatter";
import Fab from "@material-ui/core/Fab";
import { unixToDate } from "../../../utils/dateFormatter";
import AddPencairanRekber from "./add";
import { Container, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { green, red } from "@material-ui/core/colors";

export default function ListPencairan({ value, dataKomisi, responOmset }) {
  const classes = useStyles();
  // add pencairan
  const [openAddProduct, setOpenAddProduct] = useState(false);

  const [table, setTable] = useState({
    columns: [
      {
        label: "Tanggal",
        name: "created_at",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let rowData = value;
            return (
              <>
                {rowData && rowData !== null ? (
                  <span>{unixToDate(rowData.toMillis())}</span>
                ) : (
                  <span>-</span>
                )}
              </>
            );
          },
        },
      },
      {
        label: "No Rek",
        name: "tujuan_bank",
        options: {
          sort: false,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let name_rek = value?.no_rek;
            if (name_rek) {
              return name_rek;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Atas Nama",
        name: "tujuan_bank",
        options: {
          sort: false,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let name_rek = value?.nama_rek;
            if (name_rek) {
              return name_rek;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Nama Bank",
        name: "tujuan_bank",
        options: {
          sort: false,
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let name_rek = value?.name;
            if (name_rek) {
              return name_rek;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Pencairan Diajukan",
        name: "amount",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return currency(value);
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Biaya Admin",
        name: "payout_fee",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return currency(value);
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Pencairan Total",
        name: "net_amount",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return currency(value);
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Status",
        name: "payout",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value?.payout?.status;
            if (data === "SUCCESS") {
              return <p style={{ color: green[500] }}>{data}</p>;
            } else {
              return <p style={{ color: red[500] }}>{data ? data : "-"}</p>;
            }
          },
        },
      },
      
    ],

    data: [],
  });
  useEffect(() => {
    if (dataKomisi && dataKomisi.length > 0) {
      setTable((table) => {
        return {
          ...table,
          data: dataKomisi.map((doc) => {
            return {
              uid: doc.id,
              ...doc,
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [dataKomisi]);
  const options = {
    elevation: 0,
    rowsPerPage: 6,
    rowsPerPageOptions: [6, 12, 24, 44, 88, 100],
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "600px",
    download: false,
    print: false,
    empty: true,
    viewColumns : false	,
    selectableRowsHideCheckboxes: true,
    selectableRows: false,
    textLabels: {
      body: {
        noMatch: "Maaf Data Tidak Di Temukan",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Filter Berdasarkan ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Baris per Halaman:",
        displayRows: "Dari",
      },
      toolbar: {
        search: "Cari Mitra",
        downloadCsv: "Download CSV",
        print: "Print Data Product",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      selectedRows: {
        text: "Baris Di Tandai",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    downloadOptions: {
      filename: "ListPencairan.csv",
    },
  };

  return (
    <TabPanel value={value} index="pencairan">
      {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
      <Grid container spacing={8}>
        {/* {dataKomisi?.length <= 0 && (
          <Typography className={classes.noOrderMsg} variant="h5">
            Belum Ada Pencairan
          </Typography>
        )} */}
        <Container maxWidth="lg">
          <MUIDataTable
            // title={"List Pencairan"}
            columns={table?.columns}
            data={table?.data}
            options={options}
          />
        </Container>
        {/* {dataKomisi?.map?.((komisi) => {
          return (
            <Grid
              className={classes.liquidBlock}
              key={komisi.id}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Card variant="outlined" className={classes.card}>
                <div className={classes.liquidSection}>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidTitle}>
                      Tujuan Bank
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      No Rek.
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {komisi?.tujuan_bank?.no_rek || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Atas Nama
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {komisi?.tujuan_bank?.nama_rek || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Nama Bank
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {komisi?.tujuan_bank?.name || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Pencairan Diajukan
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {currency(komisi?.amount) || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Biaya Admin
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {currency(komisi?.payout_fee) || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Pencairan Total
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {currency(komisi?.net_amount) || ""}
                    </Typography>
                  </div>

                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Status
                    </Typography>
                    {komisi?.payout?.payout?.status === "SUCCESS" ? (
                      <Typography className={classes.liquidValueStatusSuccses}>
                        : {komisi?.payout?.payout?.status || ""}
                      </Typography>
                    ) : (
                      <Typography className={classes.liquidValueStatusFailed}>
                        : {komisi?.payout?.payout?.status || ""}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.liquidRow}></div>
                </div>

                <div className={classes.liquidRow}>
                  <Typography className={classes.liquidLabel}>
                    Tanggal
                  </Typography>
                  <Typography className={classes.liquidValue}>
                    : {unixToDate(komisi?.created_at?.toMillis())}
                  </Typography>
                </div>
              </Card>
            </Grid>
          );
        })} */}
      </Grid>
      <div className={classes.fab1}>
        <Fab
          color="secondary"
          onClick={() => {
            setOpenAddProduct(true);
          }}
          tooltip
        >
          <PostAddIcon />
        </Fab>
        <AddPencairanRekber
          totalOmset={responOmset?.[0]?.total_kredit_available}
          open={openAddProduct}
          handleClose={() => {
            setOpenAddProduct(false);
          }}
        />
      </div>
    </TabPanel>
  );
}
