import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore, functions, } from '../../../components/FirebaseProvider';

// material ui core
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import Paper from '@material-ui/core/Paper';
import PageLoading from '../../../components/loading/pageLoading';
import { Button, CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// icon
// import InputLabel from '@material-ui/core/InputLabel';
// import UploadIcon from '@material-ui/icons/CloudUpload';
// import ImageIcon from '@material-ui/icons/Image';

// filter date
import 'date-fns';
import { useData } from '../../../components/DataProvider';
import { useSnackbar } from 'notistack';
import { currency } from '../../../utils/formatter';
import ListPencairan from './listPencairan';
import RekTujuan from './rekBankTujuan';

function PencairanRekber() {
    const classes = useStyles();
    const { company_id } = useData();
    const { enqueueSnackbar } = useSnackbar();
    // pencairan_rekber coll
    const [filter, setFilter] = useState('pencairan');
    const komisiCol = firestore.collection(`pencairan_rekber`).where('mitra_id', '==', company_id);
    // let query = komisiCol
    // if (filter) {
    //     query = query.where("status", "==", filter)
    // }
    // query = query.where('created_at', '>=', startDate).where('created_at', '<=', endDate);
    const [dataKomisi, loadingDataKomisi,] = useCollectionData(komisiCol, { idField: 'id' });
    // console.log(errorLoading)
    const handleChangeTab = (event, value) => {
        setFilter(value);
    }
    const [tabs] = useState([
        {
            label: "Pencairan", value: "pencairan"
        },
        {
            label: "Rek. Bank Tujuan", value: "rek_bank_tujuan"
        },
    ])

    const [loading, setLoading] = useState(false);
    const [responOmset, setRespon] = useState()
    const handleCheckout = async () => {
        setLoading(true);
        try {
            // console.log('cek')
            const paymentOrder = functions.httpsCallable("statistic-getPenjualanMitra");
            const response = await paymentOrder({ mitra_id: company_id });
            const data = response.data;
            // console.log('data', data)
            setRespon(data)
            setLoading(false);
            enqueueSnackbar(`cek total omset berhasil`, { variant: "success" });

        } catch (e) {
            console.log(e.message)
            enqueueSnackbar(e.message, { variant: "error" });
            setLoading(false);
        }
    };


    return <>
        <Container maxWidth='lg'>
            {loadingDataKomisi ?
                <PageLoading size={50} color='inherit' /> : ''}
            <Paper className={classes.paper}>
                <div className={classes.grid}>
                    <Typography component="h1" variant="h6">Daftar Pencairan Rekber</Typography>
                    <Button
                        color="primary"
                        variant='outlined'
                        onClick={handleCheckout}
                        startIcon={loading && <CircularProgress size={15} />}
                    >
                        {loading ? 'Loading' : ' Cek Omset'}
                    </Button>
                    {
                        responOmset?.[0]?.total_penjualan_confirmed >= 0 &&
                        <div className={classes.jp1}>
                            <Typography>Omset Terkonfirmasi : {currency(responOmset?.[0]?.total_penjualan_confirmed)}</Typography>
                            <Typography>Omset Pending : {currency(responOmset?.[0]?.total_penjualan_pending)}</Typography>
                            {/* <Typography>Pencairan Approved : {currency(responOmset?.[0]?.total_pencairan_approved)}</Typography> */}
                            <Typography>Total Pencairan : {currency(responOmset?.[0]?.total_pencairan_pending)}</Typography>
                            {/* <Typography>Total Belanja Dengan Kredit : {currency(responOmset?.[0]?.total_belanja_dengan_kredit)}</Typography> */}
                            {/* <Typography>Total Topup : {currency(responOmset?.[0]?.total_topup)}</Typography> */}
                            <Typography>Total Available for Payout : {currency(responOmset?.[0]?.total_kredit_available)}</Typography>
                        </div>
                    }
                </div>
                <Tabs indicatorColor="primary"
                    className={classes.tabWrap}
                    textColor="primary"
                    value={filter}
                    variant="fullWidth"
                    onChange={handleChangeTab}>
                    {tabs.map(tab => <Tab key={tab.value} label={tab.label} value={tab.value} />)}

                </Tabs>
                <div className={classes.grid}>
                    <ListPencairan value={filter} dataKomisi={dataKomisi} responOmset={responOmset} />
                    <RekTujuan value={filter} mitra_id={company_id} />
                </div>
            </Paper>
        </Container>
    </>
}

export default PencairanRekber;