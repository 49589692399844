import { makeStyles } from '@material-ui/core/styles';
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    paperWrap: {
        marginTop: '35px',
        marginBottom: '30px',
    },
    pageWrap: {
        padding: '30px 30px',
        paddingBottom: 30,
        borderRadius: 8,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    paper: {
        margin: '16px 0',
        background: '#F2F2F2',
        border: '1.5px solid #E5E5E5'
    },
    ml10: {
        marginLeft: 10,
    },
    mb20: {
        marginBottom: 20,
    },
    fs20: {
        fontSize: 20,
    },
    topBox: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'flex-start',
    },
    topBox2: {
        marginTop: 25,
    },
    sellerRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: 5,
    },
    sellerRow2: {
        marginBottom: 0,
    },
    boxTitle: {
        color: theme.palette.textColor.lessTextColor,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    boxTitle2: {
        color: theme.palette.textColor.lessTextColor,
        fontWeight: 'bold',
        marginBottom: 0,
    },
    rowLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: 15,
        width: 120,
    },
    rowValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
    },

    headerAlamat: {
        padding: theme.spacing(2),
        borderBottom: '1.5px solid #E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleAlamat: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '17px'
    },
    contentAlamat: {
        padding: theme.spacing(2),
    },
    receiver: {
        textTransform: 'capitalize',
    },

    header: {
        padding: theme.spacing(3),
        borderBottom: '1.5px solid #E5E5E5',
    },

    h_name: {
        fontWeight: 'bold',
        color: '#4F4F4F',
    },
    h_title: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#4F4F4F',
        textTransform: 'capitalize'
    },
    active: {
        color: green[500],
        width: 20,
        height: 20,
        marginLeft: 10
    },
    nonact: {
        color: red[500],
        width: 20,
        height: 20,
        marginLeft: 10
    },
    clr: {
        color: '#828282'
    },
    clr2: {
        color: '#828282',
        fontSize: '12px',
    },


    button: {
        width: 100,
        padding: theme.spacing(1),
        right: theme.spacing(2)
    },
    grid: {
        display: 'flex',
    },
    card: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    fab1: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    
    viewBtn: {
        marginLeft: 5,
    },
    pagination: {
        '& > *': {
            display: 'flex',
            marginTop: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    loading: {
        position: 'absolute',
        justifyContent: 'center'
    },
    btnEdit: {
        marginTop: 20,
    },
    btnEditWa: {
        marginLeft: theme.spacing(2)
    },


    margin: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    notifRow: {
        marginBottom: 10,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        paddingBottom: 5,
    },
    notifLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: 12,
    },
    notifValue: {
        color: theme.palette.textColor.primeTextColor,
    },
    noOrderMsg: {
        width: 200,
        height: 40,
        marginTop: '13%',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    accSummary:{
        backgroundColor: '#fafafa',
    }
}))


export default useStyles;