// React
import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DLogin from "./desktop";
import MLogin from "./mobile";

function Login(props) {
  const query = useMediaQuery("(min-width: 1000px)");

  return (
    // <Container maxWidth="xs">
    query == true ? <DLogin /> : <MLogin />
    // </Container>
  );
}

export default Login;
