import { useEffect, useState } from "react";
import AddProductSmartPO from "./addProduct";
import useStyles from "./styles";

// material ui
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PostAddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import TextField from '@material-ui/core/TextField';

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FieldValue,
  firestore,
  functions,
} from "../../../../components/FirebaseProvider";
import { useData } from "../../../../components/DataProvider";
import { useDocument } from "react-firebase-hooks/firestore";
import Container from "@material-ui/core/Container";
import { useSnackbar } from "notistack";
import ThumbProd from "./thumbnailProd";
import SmartPOLoading from "../../../../components/loading/smartPOLoading";
import PilihKurir from "./pilihKurir";
import { DataJumlah } from "./dataVariant";
import { currency } from "../../../../utils/formatter";
import GetSubSell from "./getSubdomainSeller";

let dataResponse2 = [];
function ListProduct({
  handleFind,
  response,
  setResponse,
  loading,
  setLoading,
  ongkir,
  setOngkir,
}) {
  // console.log(handleFind)
  const classes = useStyles();
  const history = useHistory();

  const [openAddProduct, setOpenAddProduct] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // list produk
  const {
    company_id,
    subdomaintList,
    pricing_procedure,
    pricing_conditions,
    dataMitraCurrent,
  } = useData();

  const PODoc = firestore.doc(`smart_purchase_orders/${company_id}`);

  const [POSnapshot, POLoading] = useDocument(PODoc);

  const [POForm, setProdForm] = useState();
  useEffect(() => {
    if (POSnapshot) {
      const data = POSnapshot.data();
      setProdForm({
        ...data,
      });
    }
  }, [POSnapshot]);
  let datasuborder = [];

  // ongkir
  const [editDialog, setEditDialog] = useState({
    open: false,
    data: {},
    uid: {},
  });

  // delete produk
  const handleDeleteProd = (prodKeys, id) => async () => {
    // console.log('del', prodKeys, id)
    try {
      await PODoc.set(
        {
          subcarts: {
            alamat: {
              total_tagihan: FieldValue.increment(-prodKeys?.harga),
              total_pembayaran: FieldValue.increment(-prodKeys?.harga),
              berat_total: FieldValue.increment(-prodKeys?.berat),
              // prngiriman: ongkir?.pengiriman || ongkir?.[0]?.data?.[0],
              products: {
                [id]: FieldValue.delete(),
              },
            },
          },
        },
        { merge: true }
      );
      // await PODoc.update({
      //     subcarts: {
      //         alamat: {
      //             products: {
      //                 [id]: FieldValue.delete()
      //             }
      //         }
      //     }
      // }, { merge: true })
      enqueueSnackbar(`Produk dihapus`, { variant: "success" });
    } catch (e) {
      // console.log(e.message)
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  if (POForm?.subcarts) {
    const objkey = POForm?.subcarts?.alamat?.products;
    const DataProd = objkey ? Object.keys(objkey) : null;
    // console.log(POForm?.subcarts?.alamat?.products?.[DataProd?.[0]])
    const DataLength = DataProd?.length;

    // if (!POForm?.kode_po) {
    //     return <Container maxWidth="xs" className={classes.isEmpty}>
    //         <Typography variant="h5">Keranjang Masih Kosong</Typography>
    //         <Button
    //             onClick={() => {
    //                 history.push('/purchaseOrders');
    //             }}
    //             color="primary"
    //             variant="contained"
    //             style={{ marginTop: 20 }}
    //         >
    //             Ke List Product
    //         </Button>
    //     </Container>
    // }
    for (var i = 0; i < DataLength; i++) {
      // let key = i
      const prodKeys = POForm?.subcarts?.alamat?.products?.[DataProd?.[i]];
      const variantId = DataProd?.[i]?.split("_");

      datasuborder.push(
        <Grid container className={classes.transaction}>
          <Grid item xs={11}>
            <ListItem
              alignItems="flex-start"

              //   onClick={() => toDetailProduct(hit.produkId, hit.nama)}
            >
              <ThumbProd
                productId={prodKeys?.product}
                jumlah={prodKeys?.jumlah}
                variantId={variantId?.[1]}
              />
            </ListItem>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="secondary"
              style={{ float: "right" }}
              onClick={handleDeleteProd(prodKeys, DataProd?.[i])}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  }

  // subdomain
  const subdomain = subdomaintList?.map((mit) => {
    return mit?.mitra_unique_id;
  });

  const groupByCategory = response?.reduce?.((group, product) => {
    const { mitraId } = product;
    group[mitraId] = group[mitraId] ?? [];
    group[mitraId].push(product);
    return group;
  }, {});
  const entriesResponse = groupByCategory
    ? Object.entries(groupByCategory)
    : null;

  const indexMitra = response ? Object.keys(groupByCategory) : null;

  const mitraLength = indexMitra?.length;

  const [idMitra, setIdMitra] = useState();
  useEffect(() => {
    async function getProductDetail() {
      const beratProducts = await Promise.all(
        indexMitra?.map?.(async (p) => {
          const variantDoc = await firestore
            .collection("subdomains")
            .where("mitra_id", "==", p)
            .get();
          if (variantDoc.docs) {
            const variantData = variantDoc.docs.map((doc) => doc.data());
            // console.log(variantData)
            return variantData.map((data) => {
              return data?.mitra_unique_id;
            });
          }
        }) ?? []
      );
      setIdMitra(beratProducts);
    }
    getProductDetail();
  }, [JSON.stringify(indexMitra)]);

  const [dataMitra, setdDataMitrs] = useState();
  useEffect(() => {
    async function getProductDetail() {
      const beratProducts = await Promise.all(
        indexMitra?.map?.(async (p) => {
          const variantDoc = await firestore.doc(`mitra/${p}`).get();
          if (variantDoc.exists) {
            const variantData = variantDoc.data();

            return variantData;
          }
        }) ?? []
      );
      setdDataMitrs(beratProducts);
    }
    getProductDetail();
  }, [JSON.stringify(indexMitra)]);
  // console.log(dataMitra)

  // pricing
  let item = [];
  if (entriesResponse) {
    for (var e = 0; e < mitraLength; e++) {
      const [key, items] = entriesResponse?.[e];

      item.push(items);
    }
  }

  // console.log(item?.map((q, i) => {
  //     const t = q?.map((c) => {
  //         return c?.jumlah
  //     })
  //     return t
  // }))

  const [hargaPricing, setHargaPricing] = useState();

  useEffect(() => {
    try {
      async function CekPricing() {
        if (item?.length > 0) {
          const pricing = await Promise.all(
            item?.map(async (q, i) => {
              const t = await Promise.all(
                q?.map(async (c) => {
                  const prodDoc = await firestore
                    .doc(`products/${c?.productId}`)
                    .get();

                  const variantDoc = await firestore
                    .doc(`products/${c?.productId}/variants/${c?.variantId}`)
                    .get();

                  if (prodDoc.exists && variantDoc.exists) {
                    const prodData = prodDoc.data();

                    const variantData = variantDoc.data();
                    const loadRecords = functions.httpsCallable(
                      "pricing-loadRecords"
                    );

                    const body = JSON.stringify({
                      item: {
                        products: prodData,
                        variants: [variantData],
                        id: prodData?.product_id,
                      },
                      pricing_procedure,
                      pricing_conditions,
                      level: {
                        buyer_level: dataMitraCurrent?.mitra_level,
                        level: c?.mitra_detail?.mitra_level,
                      },
                      profil: { id: company_id },
                      location: "mitra",
                    });
                    const result = await loadRecords({
                      body,
                    });

                    return result.data;
                  }
                })
              );
              return t;
            })
          );
          // console.log(pricing)
          setHargaPricing(pricing);
        }
      }
      CekPricing();
    } catch (e) {}
  }, [
    item?.length > 0,
    dataMitraCurrent,
    company_id,
    pricing_conditions,
    pricing_procedure,
    response,
  ]);

  // console.log(hargaPricing)

  // delete item mitra

  const handleRemoveItem = (i, key) => async () => {
    // assigning the list to temp variable
    const ongkArray = [ongkir];
    const temp = [...response];
    const price = [...hargaPricing];

    // removing the element using splice
    temp.splice(key, 1);

    // ongk.splice(key, 1)
    // updating the list
    // delete ongkir?.[key]
    // console.log(ongkir(item => item?.[key] !== key))

    setResponse(temp);
    setHargaPricing();
    setOngkir({});
  };

  if (response) {
    let dataResponse = [];

    // const responseGroup = response?.groupBy?.(({ mitraId }) => mitraId)

    // let responseAfterGrouping = []
    for (var j = 0; j < mitraLength; j++) {
      // responseAfterGrouping.push(j)
      let key = j;
      const handleChangeOngkir = (event) => {
        setOngkir({
          ...ongkir,
          [key]: event.target.value,
        });
      };
      // console.log(Object.keys(ongkir)?.length)
      // const [keyss, items] = entriesResponse?.[j]
      const hargaAfterPricing = hargaPricing?.[j];
      // console.log(hargaAfterPricing)
      const keys = groupByCategory?.[indexMitra?.[j]];

      dataResponse.push(
        <>
          <Typography style={{ marginTop: 12, marginLeft: 18 }}>
            Nama Toko:{" "}
            <Typography display="inline" color="primary">
              {keys?.[0]?.mitra_detail?.nama}
            </Typography>
          </Typography>
          <Typography
            // color="primary"
            style={{ marginTop: 12, marginLeft: 18, cursor: "pointer" }}
          >
            Nomor Telp:{" "}
            <Typography
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send/?phone=${keys?.[0]?.mitra_detail?.telepon}&text&app_absent=0`
                )
              }
              display="inline"
              color="primary"
            >
              {keys?.[0]?.mitra_detail?.telepon}
            </Typography>
          </Typography>

          <div style={{ float: "right" }} className={classes.ogkir}>
            <Grid>
              <Button
                color="primary"
                onClick={(event) => {
                  setEditDialog((editDialog) => ({
                    ...editDialog,
                    // dataProd: form?.variant2,
                    open: true,
                    mitra_id: company_id,
                    // mitra_asal: mitraAsal,
                    jumlah: keys?.map((prodKeys) => {
                      return prodKeys?.jumlah;
                    }),
                    formKurir: ongkir,
                    mitraAsalId: keys?.[0]?.mitraId,
                    prodId: keys?.map((prodKeys) => {
                      return prodKeys?.productId;
                    }),
                    prod: keys,
                    varId: keys?.map((prodKeys) => {
                      return prodKeys?.variantId;
                    }),
                    company_id: company_id,
                    handleEditKurir: handleChangeOngkir,
                    // isSubmitting: { isSubmitting }
                  }));
                }}
              >
                {ongkir?.[key]
                  ? `${ongkir?.[key]?.kurir} - 
                  ${
                    ongkir?.[key]?.kurir === "ninja"
                      ? ongkir?.[key]?.serviceName
                      : ongkir?.[key]?.kurir === "jnt"
                      ? ongkir?.[key]?.nameService
                      : ongkir?.[key]?.service
                  }`
                  : "Pilih Kurir"}
                {/* <DeleteForeverIcon /> */}
              </Button>
            </Grid>
            <Grid>
              {ongkir?.[j] && (
                <Typography color="primary">
                  {currency(ongkir?.[j]?.finalRate)}
                </Typography>
              )}
            </Grid>
          </div>
          {keys?.map((prodKeys, i) => {
            // console.log(allProdData)
            return (
              <Grid container className={classes.transaction}>
                <Grid item xs={11}>
                  <ListItem
                    alignItems="flex-start"

                    //   onClick={() => toDetailProduct(hit.produkId, hit.nama)}
                  >
                    <ThumbProd
                      productId={prodKeys?.productId}
                      jumlah={prodKeys?.jumlah_fulfillment}
                      variantId={prodKeys?.variantId}
                      harga={hargaAfterPricing?.[i]?.priceslocal?.[0]}
                    />
                  </ListItem>
                  <Grid container style={{ marginBottom: 12, marginLeft: 40 }}>
                    <Grid item xs={1}>
                      <Typography className={classes.alamat}>
                        {prodKeys?.stock_from}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.alamat}>
                        {prodKeys?.alamat_mitra?.directapi?.kota?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="secondary"
                    style={{ float: "right" }}
                    onClick={handleRemoveItem(i, key)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Divider variant="fullWidth" />
        </>
      );
    }

    // console.log(entriesResponse)

    const handleSubmitPO = async () => {
      setLoading(true);
      try {
        const purchaseDoc = firestore
          .collection(`smart_purchase_orders`)
          .doc(company_id);
        // const lengthAfterGrouping = responseAfterGrouping?.length
        for (var p = 0; p < mitraLength; p++) {
          const [key, items] = entriesResponse?.[p];
          console.log(ongkir);
          const loopData = await Promise.all(
            items?.map(async (item) => {
              const variantDoc = await firestore
                .doc(`products/${item?.productId}`)
                .get();
              if (variantDoc.exists) {
                const variantData = variantDoc.data();

                return {
                  [item?.variant_id]: {
                    // created_at: FieldValue.serverTimestamp(),
                    jumlah: item?.jumlah_fulfillment,
                    nama: variantData.nama,
                    product: item?.productId,
                    // variant: `${form?.variant2?.variant1?.label} ${form?.variant2?.variant1?.nilai},${form?.variant2?.variant2?.label} ${form?.variant2?.variant2?.nilai}`,
                    promo: false,
                    // harga: hargaTagihan,
                    //  berat: (form?.variant2?.berat_paket * jumlah)
                  },
                };
              }
            }) ?? {}
          );
            console.log(ongkir?.[p])
          await purchaseDoc.set({
            seller_mitra: {
              ...dataMitra?.[p],
              mitra_unique_id: idMitra?.[p],
              mitra_id: dataMitra?.[p]?.mitraId,
            },
            subcarts: {
              alamat: {
                berat_total: ongkir?.[p]?.berat_total,
                //  height: form?.variant2?.tinggi_paket,
                //  length: form?.variant2?.panjang_paket,
                //  width: form?.variant2?.lebar_paket,
                //   total_pembayaran: (hargaTagihan + ongkir?.[p]?.pengiriman?.finalRate) || (hargaTagihan + ongkir?.[0]?.data?.[0]?.finalRate), total_tagihan: hargaTagihan,

                pengiriman: ongkir?.[p] || ongkir?.[0]?.data?.[0],
                biaya_pengiriman:
                  ongkir?.[p]?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate,
                biaya_pengiriman_before:
                  ongkir?.[p]?.finalRate || ongkir?.[0]?.data?.[0]?.finalRate,
              },
            },
            kode_po: `p_${idMitra?.[p]}_${subdomain?.[0]}_${Date.now()}`,
            created_at: FieldValue.serverTimestamp(),
            updated_at: FieldValue.serverTimestamp(),
          });
          for (var m = 0; m < loopData?.length; m++) {
            await purchaseDoc.set(
              {
                subcarts: {
                  alamat: {
                    //  height: form?.variant2?.tinggi_paket,
                    //  length: form?.variant2?.panjang_paket,
                    //  width: form?.variant2?.lebar_paket,
                    //   total_pembayaran: (hargaTagihan + ongkir?.[p]?.pengiriman?.finalRate) || (hargaTagihan + ongkir?.[0]?.data?.[0]?.finalRate), total_tagihan: hargaTagihan,

                    products: loopData?.[m],
                  },
                },
              },
              { merge: true }
            );
          }

          const paymentOrder = functions.httpsCallable("payment-orderMitra");

          const response = await paymentOrder({ type: "smart_po" });

          const result = response.data;
          console.log(result);
        }
        setLoading(false);
        enqueueSnackbar("PO Berhasil Dibuat", {
          variant: "success",
        });
        window.location.reload();
      } catch (e) {
        setLoading(false);
        console.log(e.message);
      }
    };

    return (
      <>
        <IconButton
          onClick={() => {
            history.push(`/purchaseOrders/smartPO?tab=smartPO`);
            setResponse();
          }}
          className={classes.iconBck}
        >
          <ArrowBackIcon />
        </IconButton>
        {dataResponse?.length > 0 ? (
          dataResponse
        ) : (
          <div>
            <Typography className={classes.noOrderMsg}>
              Penjual Tidak Ditemukan
            </Typography>
          </div>
        )}
        <Grid item xs={12}>
          <Button
            className={classes.nextBtn}
            onClick={handleSubmitPO}
            disabled={
              !ongkir ||
              Object.keys(ongkir)?.length < mitraLength ||
              loading ||
              dataResponse?.length < 1
            }
            color="primary"
            variant="contained"
          >
            Buat PO
          </Button>
        </Grid>
        <PilihKurir
          editDialog={editDialog}
          handleClose={() => {
            setEditDialog({ open: false, dataSub: {} });
          }}
          // handleEditKurir={handleChangeOngkir}
          // kurir_regular={kurir_regular}
        />
      </>
    );
  }
  // console.log(Object.keys(POForm?.subcarts?.alamat?.products).length)
  if (loading) {
    return <SmartPOLoading />;
  }
  return (
    <>
      <IconButton
        onClick={() => {
          history.goBack();
        }}
        className={classes.iconBck}
      >
        <ArrowBackIcon />
      </IconButton>
      {POForm?.subcarts?.alamat?.products &&
      Object.keys(POForm?.subcarts?.alamat?.products).length > 0 ? (
        datasuborder
      ) : (
        <>
          <Typography className={classes.noOrderMsg} variant="h5">
            Belum Ada Produk Yang Ditambahkan
          </Typography>
          <Typography className={classes.noOrderMsg2} variant="h5">
            Klik Tombol + Untuk Menambah Produk
          </Typography>
        </>
      )}
      <Grid item xs={12}>
        <Button
          className={classes.nextBtn}
          onClick={handleFind}
          disabled={
            (POForm?.subcarts?.alamat?.products &&
              Object.keys(POForm?.subcarts?.alamat?.products).length < 1) ||
            loading
          }
          color="primary"
          variant="contained"
        >
          Berikutnya
        </Button>
      </Grid>
      <div className={classes.fab1}>
        <Fab
          color="secondary"
          onClick={() => {
            setOpenAddProduct(true);
          }}
        >
          <PostAddIcon />
        </Fab>
        <AddProductSmartPO
          open={openAddProduct}
          handleClose={() => {
            setOpenAddProduct(false);
          }}
        />
      </div>
    </>
  );
}
export function GetToko() {
  return (
    <>
      {dataResponse2}
      <Grid item xs={12}>
        <Button
          // className={classes.nextBtn}
          // onClick={handleSubmitPO}
          // disabled={!ongkir}

          color="primary"
          variant="contained"
        >
          Buat PO
        </Button>
      </Grid>
    </>
  );
}

export default ListProduct;
