import React, { useState } from "react";
import TabPanel from "../../../components/TabPanel";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Card from "@material-ui/core/Card";
import { firestore } from "../../../components/FirebaseProvider";
import { useCollectionData } from "react-firebase-hooks/firestore";
import AddBank from "./addBank";
import { Button } from "@material-ui/core";
import VerifikasiBank from "./verifikasiBank";
import { useSnackbar } from "notistack";

export default function RekTujuan({ value, dataKomisi, mitra_id }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const rekTujaunColl = firestore
    .collection(`mitra_bank`)
    .where("mitra_id", "==", mitra_id);
  const [dataRek, loadDataRek] = useCollectionData(rekTujaunColl, {
    idField: "id",
  });
  // add bank
  const [openAddBank, setOpenAddBank] = useState(false);
  const [openVerifikasi, setOpenVerifikasi] = useState({
    open: false,
    value: "",
    stat: "",
  });

  const handleDelete = async (val) => {
    if (
      window.confirm(
        `Apakah Anda Yakin Ingin menghapus Data Rekening ini dari akun anda ?`
      )
    ) {
      await firestore.doc(`mitra_bank/${val.id}`).delete();
      enqueueSnackbar("Data Rekening Berhasil Dihapus", { variant: "success" });
    }
  };
  return (
    <TabPanel value={value} index="rek_bank_tujuan">
      {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
      <Grid container spacing={3}>
        {dataKomisi?.length <= 0 && (
          <Typography className={classes.noOrderMsg} variant="h5">
            Belum Ada Pencairan
          </Typography>
        )}
        {dataRek?.map?.((rek) => {
          return (
            <Grid
              className={classes.liquidBlock}
              key={rek.id}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Card variant="outlined" className={classes.card}>
                <div className={classes.liquidSection}>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidTitle}>
                      Tujuan Bank
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      No Rek.
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {rek.no_rek || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Atas Nama
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {rek.nama_rek || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Nama Bank
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {rek.name || ""}
                    </Typography>
                  </div>

                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Mitra Id
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      : {rek.mitra_id || ""}
                    </Typography>
                  </div>
                  <div className={classes.liquidRow}>
                    <Typography className={classes.liquidLabel}>
                      Status Check
                    </Typography>
                    <Typography className={classes.liquidValue}>
                      :{" "}
                      {rek?.status_check || rek?.check
                        ? rek?.status_check
                        : !rek?.verifikasi
                        ? "Belum Verifikasi"
                        : "Sedang Diperiksa Oleh Admin"}
                    </Typography>
                  </div>
                  {rek?.status_check === "reject" && (
                    <div className={classes.liquidRow}>
                      <Typography className={classes.liquidLabel}>
                        Alasan
                      </Typography>
                      <Typography className={classes.liquidValue}>
                        : {rek?.message}
                      </Typography>
                    </div>
                  )}
                </div>
                {!rek?.verifikasi && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setOpenVerifikasi({
                        open: true,
                        value: rek,
                      });
                    }}
                  >
                    Verifikasi
                  </Button>
                )}
                {rek?.status_check === "reject" && (
                  <div
                    style={{ justifyContent: "space-between" }}
                    className={classes.liquidRow}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        handleDelete(rek);
                      }}
                    >
                      Hapus
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setOpenVerifikasi({
                          open: true,
                          value: rek,
                          title: "edit",
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <div className={classes.fab1}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            setOpenAddBank(true);
          }}
          tooltip
        >
          <PostAddIcon />+ Data Rekening
        </Fab>
        <AddBank
          open={openAddBank}
          handleClose={() => {
            setOpenAddBank(false);
          }}
        />
        <VerifikasiBank
          open={openVerifikasi.open}
          handleClose={() => {
            setOpenVerifikasi({ ...openVerifikasi, open: false });
          }}
          title={openVerifikasi?.title}
          data={openVerifikasi.value}
          stat={openVerifikasi.stat}
        />
      </div>
    </TabPanel>
  );
}
