import React, { useState, useEffect, forwardRef } from "react";
import Container from "@material-ui/core/Container";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Fab from "@material-ui/core/Fab";
// import MaterialTable from "material-table";
import useStyles from "./styles";
import AddProduct from "./add";
import {
  useCollection,
  useDocumentData,
  useDocument,
} from "react-firebase-hooks/firestore";
import PageLoading from "../../../components/loading/pageLoading";
import { unixToDate } from "../../../utils/dateFormatter";
import { useSnackbar } from "notistack";
// import tableIcons from "../../../config/table-icons";
import { firestore, FieldValue } from "../../../components/FirebaseProvider";
// import ExcelRead from './addFileExcel';
import { useData } from "../../../components/DataProvider";
import Edit from "@material-ui/icons/Edit";
import TabPanel from "../../../components/TabPanel";
import AddMitra from "./addMitra";
import { array, object } from "prop-types";
// import GetNibrasId from "./getNibrasId";
import MUIDataTable from "mui-datatables";
import { Alert } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

function SetMitra({ value }) {
  const { dataMitraCurrent, hideSetting, company_id } = useData();
  const classes = useStyles();
  const levelDoc = firestore.doc(`/settings/sellers_level`);
  const [level] = useDocument(levelDoc);

  const docSetting = firestore.doc(`settings/nibras_id_counter`);
  const [resultDocSetting] = useDocument(docSetting);
  const setNibrasIdDoc = firestore.doc(`mitra/${company_id}/settings/fitur`);
  const [dataNibrasCounter] = useDocumentData(setNibrasIdDoc);

  const { enqueueSnackbar } = useSnackbar();

  const [openAddProduct, setOpenAddProduct] = useState({
    mode: "Tambah",
    open: false,
  });

  const col = firestore.collection(`mitra`);

  const [snapshot, loading] = useCollection(col);

  const [table, setTable] = useState({
    columns: [
      {
        label: "Nama Mitra",
        name: "nama",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return (
                <p>{data.length > 30 ? `${data.substring(0, 30)}...` : data}</p>
              );
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Email",
        name: "email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return (
                <p>{data.length > 30 ? `${data.substring(0, 30)}...` : data}</p>
              );
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Mitra Level",
        name: "mitra_level",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (data === 2) {
              return "Nibras House";
            } else if (data === 3) {
              return "Agent";
            } else if (data === 4) {
              return "Reseller";
            } else {
              return "Tidak Ada Level";
            }
          },
        },
      },
      {
        label: "Upline",
        name: "upline",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value[0];
            if (value) {
              return (
                <p>
                  {data.nama.length > 25
                    ? `${data.nama.substring(0, 25)}...`
                    : data.nama}
                </p>
              );
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Nibras ID",
        name: "mitra_id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let data = value;
            if (value) {
              return data;
            } else {
              return <p>-</p>;
            }
          },
        },
      },
      {
        label: "Tanggal Dibuat",
        name: "created_at",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let rowData = value;
            return (
              <>
                {rowData && rowData !== null ? (
                  <span>{unixToDate(rowData.toMillis())}</span>
                ) : (
                  <span>-</span>
                )}
              </>
            );
          },
        },
      },
      // {
      //   label: "Tanggal Diubah",
      //   name: "updated_at",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       let rowData = value;
      //       return (
      //         <>
      //           {rowData && rowData !== null ? (
      //             <span>{unixToDate(rowData?.toMillis())}</span>
      //           ) : (
      //             <span>Belum Di Perbarui</span>
      //           )}
      //         </>
      //       );
      //     },
      //   },
      // },
    ],

    data: [],
  });
  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      const mitra_id = snapshot.docs.filter(
        (doc) => !!doc.data()[`ref_${dataMitraCurrent.mitra_level}`]
      );
      const upline = mitra_id
        ?.filter(
          (value) =>
            value?.data()[`ref_${dataMitraCurrent?.mitra_level}`]?.mitra_id ==
            company_id
        )
        ?.map((doc) => {
          const ref = doc.data().ref_3
            ? doc.data().ref_3?.mitra_id
            : doc.data().ref_2
            ? doc.data().ref_2?.mitra_id
            : doc.data().ref_1?.mitra_id;
          return {
            ref,
          };
        });
      let Up = [];
      for (let i = 0; i < upline.length; i++) {
        const element = upline[i].ref;
        Up.push(element);
      }
      // setlistupline([...new Set(Up)]);
      const listUpline = [...new Set(Up)];

      const upline_id = snapshot?.docs.filter((doc) =>
        listUpline.includes(doc.data().mitra_id)
      );
      let atasan = [];
      for (let i = 0; i < upline_id.length; i++) {
        const data = upline_id[i].data();
        atasan.push(data);
      }

      setTable((table) => {
        return {
          ...table,
          data: mitra_id
            ?.filter(
              (value) =>
                value?.data()[`ref_${dataMitraCurrent?.mitra_level}`]
                  ?.mitra_id == company_id
            )
            ?.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
                upline: doc.data().ref_3
                  ? atasan.filter(
                      (val) => val.mitra_id === doc.data().ref_3?.mitra_id
                    )
                  : doc.data().ref_2
                  ? atasan.filter(
                      (val) => val.mitra_id === doc.data().ref_2?.mitra_id
                    )
                  : atasan.filter(
                      (val) => val.mitra_id === doc.data().ref_1?.mitra_id
                    ),
              };
            }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  useEffect(() => {
    // set reset counter
    async function reloadMountCode() {
      const docMount = resultDocSetting?.data();
      const dataRes = docMount?.month_data;

      if (dataRes?.id !== dataNibrasCounter?.month_id) {
        await setNibrasIdDoc.set(
          {
            agen_db: 0,
            agen_nhs: 0,
            reseller_db: 0,
            reseller_nhs: 0,
            reseller_agen: 0,

            month_code: dataRes?.code,
            month_id: dataRes?.id,
            month_name: dataRes?.name,
          },
          { merge: true }
        );

        await docSetting.set(
          {
            reseller_agen: 0,
          },
          { merge: true }
        );
      }
      // else {
      //   console.log("TDK BERUBAH")
      // }
    }
    reloadMountCode();
  }, [dataNibrasCounter, dataMitraCurrent]);

  // options MUIDatatables
  const options = {
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 25, 35, 50],
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyHeight: "600px",
    download: false,
    print: false,
    empty: true,
    selectableRows: "none",
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: true,
    textLabels: {
      body: {
        noMatch: "Maaf Anda Belum Memiliki Downline Mitra",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Filter Berdasarkan ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Baris per Halaman:",
        displayRows: "Dari",
      },
      toolbar: {
        search: "Cari Produk",
        downloadCsv: "Download CSV",
        print: "Print Data Product",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      selectedRows: {
        text: "Baris Di Tandai",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  if (
    loading
    // || komisiLoading
  ) {
    return <PageLoading />;
  }

  return (
    <>
      <TabPanel value={value} index="mitra">
        <div className={classes.root}>
          {loading ? (
            <PageLoading />
          ) : (
            <Container className={classes.tableCon} maxWidth="lg">
              <div className={classes.tableWrap}>
                {dataMitraCurrent?.mitra_level > 3 ? (
                  <Grid item xs={12} lg={12}>
                    <Alert severity="error">
                      {" "}
                      Maaf Anda Berada Di Level Terendah Tidak Bisa Menampilkan
                      Downline
                    </Alert>
                  </Grid>
                ) : (
                  <MUIDataTable
                    title={"List Downline Mitra"}
                    columns={table?.columns}
                    data={table?.data}
                    options={options}
                  />
                )}
              </div>
            </Container>
          )}
        </div>
        {hideSetting.data().hideAddMitra ? null : (
          <>
            {dataMitraCurrent?.mitra_level <= 3 && (
              <div className={classes.fab1}>
                <Fab
                  color="secondary"
                  onClick={() => {
                    setOpenAddProduct({
                      mode: "Tambah",
                      open: true,
                      data: {},
                      id: "",
                    });
                  }}
                  tooltip
                >
                  <PostAddIcon />
                </Fab>
                <AddMitra
                  dialog={openAddProduct}
                  handleClose={() => {
                    setOpenAddProduct({ mode: "Tambah", open: false });
                  }}
                />
              </div>
            )}
          </>
        )}
      </TabPanel>
    </>
  );
}

export default SetMitra;
