import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory, useParams } from "react-router-dom";

// components
import PageLoading from "../../../components/loading/pageLoading";
import useStyles from "./style";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import {
  firestore,
  storage,
  FieldValue,
} from "../../../components/FirebaseProvider";
import { unixToDate } from "../../../utils/dateFormatter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useData } from "../../../components/DataProvider";
import TabPanel from "../../../components/TabPanel";

// material ui icons
import InputLabel from "@material-ui/core/InputLabel";
import UploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";


import { useSnackbar } from "notistack";

function EditProduct({ value }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();

  const { company_id } = useData();

  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);

  const prodDoc = firestore.doc(`mitra/${company_id}/products_reward/${params.productId}`);

  const [prodSnapshot, prodLoading] = useDocument(prodDoc);

  const [prodForm, setProdForm] = useState();
  const [errorProd, setErrorProd] = useState({});

  useEffect(() => {
    if (prodSnapshot) {
      const data = prodSnapshot.data();
      setProdForm({
        ...data,
      });
    }
  }, [prodSnapshot]);

  const handleChangeProd = (e) => {
    setProdForm({
      ...prodForm,
      [e.target.name]: e.target.value,
    });

    setErrorProd({
      ...errorProd,
      [e.target.name]: "",
    });
  };

  const validateProd = async () => {
    const newError = { ...errorProd };
    const {
      product_reward_id,
      nama,
      deskripsi_singkat,
      deskripsi_lengkap,
      variant1_label,
      variant2_label,
      thumbnail,
      default_variant,
    } = prodForm;

    if (!product_reward_id) {
      newError.product_reward_id = "Produk Id harus diisi";
    }

    if (!nama) {
      newError.nama = "Nama harus diisi";
    }

    if (!thumbnail) {
      newError.thumbnail = "thumbnail harus diisi";
    }

    if (!deskripsi_singkat) {
      newError.deskripsi_singkat = "Deskripsi harus diisi";
    }

    if (!deskripsi_lengkap) {
      newError.deskripsi_lengkap = "Deskripsi harus diisi";
    }

    if (!variant1_label) {
      newError.variant1_label = "Label variant 1 harus diisi";
    }

    if (!variant2_label) {
      newError.variant2_label = "Label variant 2 harus diisi";
    }

    if (!default_variant) {
      newError.default_variant = "Default variant harus diisi";
    }

    return newError;
  };

  const handleSubmitProd = async (e) => {
    e.preventDefault();
    const findError = await validateProd();

    if (Object.values(findError).some((m) => m !== "")) {
      setErrorProd(findError);
    } else {
      setSubmitting(true);
      try {
        const updProd = {
          ...prodForm,
          updated_at: FieldValue.serverTimestamp(),
          product_reward_id: prodForm.product_reward_id || prodDoc.id,
        };

        await prodDoc.set(updProd, { merge: true });
        history.replace("/reward");

        enqueueSnackbar("Produk Berhasil Diperbarui", { variant: "success" });
      } catch (e) {
        const newError = {};

        console.log(e.message);

        newError.title = e.message;

        setErrorProd(newError);
      }

      setSubmitting(false);
    }
  };

  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];

    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      setErrorProd((errorProd) => ({
        ...errorProd,
        thumbnail: `Tipe file tidak didukung: ${file.type}`,
      }));
    } else if (file.size >= 512000) {
      setErrorProd((errorProd) => ({
        ...errorProd,
        thumbnail: `Ukuran file terlalu besar > 500KB`,
      }));
    } else {
      const reader = new FileReader();

      reader.onabort = () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: `Proses pembacaan file dibatalkan`,
        }));
      };

      reader.onerror = () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: "File tidak bisa dibaca",
        }));
      };

      reader.onload = async () => {
        setErrorProd((errorProd) => ({
          ...errorProd,
          thumbnail: "",
        }));
        setSubmitting(true);
        try {
          const productsStorageRef = storage.ref(`products_reward`);

          const thumbnailExt = file.name.substring(file.name.lastIndexOf("."));

          const thumbnailRef = productsStorageRef.child(
            `${params.productId}/thumbnail_${Date.now()}${thumbnailExt}`
          );

          const thumbnailSnapshot = await thumbnailRef.putString(
            reader.result,
            "data_url"
          );

          const thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL();

          setProdForm((currentForm) => ({
            ...currentForm,
            thumbnail: [...(prodForm?.thumbnail ?? []), thumbnailUrl],
          }));

          // setSomethingChange(true);
        } catch (e) {
          setErrorProd((errorProd) => ({
            ...errorProd,
            thumbnail: e.message,
          }));
        }

        setSubmitting(false);
      };

      reader.readAsDataURL(file);
    }
  };

  // Variant

  const varCol = firestore.collection(
    `mitra/${company_id}/products_reward/${params.productId}/variants`
  );

  const [varSnapshot, varLoading] = useCollection(varCol);

  const [varCollection, setVarCollection] = useState();

  useEffect(() => {
    if (varSnapshot && !varSnapshot.empty) {
      setVarCollection(
        varSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    }
  }, [varSnapshot]);

  const handleDelete = async (e) => {
    if (window.confirm("Anda yakin ingin menghapus produk ini?")) {
      // const varThumbnail = varCollection?.thumbnail
      // if (varThumbnail) {
      // 	const varThumbnailUrl = varThumbnail.map?.(thumbna => {
      // 		return thumbna
      // 	});;
      // 	await Promise.all(storage.refFromURL(varThumbnailUrl).delete());
      // }
      await Promise.all(
        varCollection?.thumbnail?.map?.((thumbna) => {
          return storage.refFromURL(thumbna).delete();
        }) ?? []
      );

      // await varCol?.delete();

      await Promise.all(
        prodForm?.thumbnail?.map?.((thumbn) => {
          return storage.refFromURL(thumbn).delete();
        }) ?? []
      );

      await prodDoc.delete();

      // const sectionCol = firestore.collection(`kursus/${params.kursusId}/section`);
      history.replace("/reward");
    }
  };
  const handleDeleteThumb = (thumb) => async (e) => {
    if (window.confirm("Anda yakin ingin menghapus thumbnail ini?")) {
      const thumbnailURL = thumb;
      if (thumbnailURL) {
        await storage.refFromURL(thumbnailURL).delete();
        await prodDoc.update({
          thumbnail: FieldValue.arrayRemove(thumb),
        });
        setProdForm((prodForm) => ({
          ...prodForm,
          thumbnail: prodForm.thumbnail.filter((m) => m !== thumb),
        }));
      }
    }
  };
  // Page Loading
  if (prodLoading || varLoading) {
    return <PageLoading />;
  }
  return (
    <>
      <TabPanel value={value} index="editProduct">
        <div className={classes.root}>
          <Grid container className={classes.gridCon}>
            {/* Edit Produk */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.pageTitle}>
                    <Button
                      className={classes.backBtn}
                      variant="outlined"
                      component={Link}
                      to={`/reward`}
                    >
                      <ArrowBackIcon />
                    </Button>
                    <Typography variant="h6">
                      Edit Produk {prodForm.nama} -{" "}
                      {prodForm.product_reward_id || prodDoc.id}{" "}
                    </Typography>
                  </div>
                  <div className={classes.dateInfo}>
                    <Typography variant="subtitle2">
                      {prodForm.created_at !== null ? (
                        <span>
                          {" "}
                          Dibuat pada :{" "}
                          {unixToDate(prodForm.created_at?.toMillis())}{" "}
                        </span>
                      ) : (
                        <span> Dibuat pada : - </span>
                      )}
                    </Typography>
                    <Typography variant="subtitle2">
                      {prodForm.updated_at !== null ? (
                        <span>
                          {" "}
                          Perubahan terakhir :{" "}
                          {unixToDate(prodForm.updated_at?.toMillis())}{" "}
                        </span>
                      ) : (
                        <span> Perubahan terakhir : - </span>
                      )}
                    </Typography>
                  </div>
                </Grid>

                {/* <Grid item xs={2}>
							<img src={prodForm.thumbnail} alt="" width="100%" />
						</Grid> */}
                <Grid item xs={12}>
                  <InputLabel className={classes.thumbnailLabel}>
                    Thumbnail
                  </InputLabel>
                  <div className={classes.uploadFotoKursus}>
                    <div className={classes.previewFotoVar}>
                      {prodForm.thumbnail?.map((thumb) => {
                        return (
                          <>
                            <div className={classes.thumbWrap}>
                              <img
                                key={thumb}
                                src={thumb}
                                width="200"
                                height="auto"
                                alt=""
                              />
                              <IconButton className={classes.closeBtn}>
                                <HighlightOffIcon
                                  onClick={handleDeleteThumb(thumb)}
                                />
                              </IconButton>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <input
                      className={classes.hideInputFile}
                      type="file"
                      name="thumbnail"
                      id="upload-thumbnail-products"
                      accept="image/jpeg,image/png,image/jpg"
                      onChange={handleUploadProduct}
                    />
                    <label htmlFor="upload-thumbnail-products">
                      <Button
                        disabled={isSubmitting}
                        name="thumbnail"
                        className={classes.uploadBtn}
                        variant="outlined"
                        component="span"
                      >
                        Upload
                        <UploadIcon className={classes.iconRight} />
                      </Button>
                    </label>

                    {errorProd.thumbnail && (
                      <Typography color="error">
                        {errorProd.thumbnail}
                      </Typography>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        autoComplete="off"
                        disabled={isSubmitting}
                        id="product_reward_id"
                        name="product_reward_id"
                        label="Produk Id"
                        value={prodForm.product_reward_id || prodDoc.id}
                        onChange={handleChangeProd}
                        error={errorProd.product_reward_id ? true : false}
                        helperText={errorProd.product_reward_id}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <TextField
                        autoComplete="off"
                        disabled={isSubmitting}
                        id="nama"
                        name="nama"
                        label="Nama Produk"
                        value={prodForm.nama || ""}
                        onChange={handleChangeProd}
                        error={errorProd.nama ? true : false}
                        helperText={errorProd.nama}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        autoComplete="off"
                        disabled={isSubmitting}
                        id="deskripsi_singkat"
                        name="deskripsi_singkat"
                        label="Deskripsi singkat"
                        value={prodForm.deskripsi_singkat || ""}
                        onChange={handleChangeProd}
                        error={errorProd.deskripsi_singkat ? true : false}
                        helperText={errorProd.deskripsi_singkat}
                        fullWidth
                        multiline
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        disabled={isSubmitting}
                        id="deskripsi_lengkap"
                        name="deskripsi_lengkap"
                        label="Deskripsi Lengkap"
                        value={prodForm.deskripsi_lengkap || ""}
                        onChange={handleChangeProd}
                        error={errorProd.deskripsi_lengkap ? true : false}
                        helperText={errorProd.deskripsi_lengkap}
                        fullWidth
                        multiline
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} lg={4}>
                          <Autocomplete
                            id="default_variant"
                            name="default_variant"
                            margin="normal"
                            required
                            value={prodForm.default_variant}
                            onChange={(event, value) => {
                              handleChangeProd({
                                target: { name: "default_variant", value },
                              });
                            }}
                            disabled={isSubmitting}
                            options={
                              varCollection?.map?.((default_variant) => {
                                return {
                                  id: default_variant?.id,
                                  sku: default_variant?.sku,
                                };
                              }) ?? []
                            }
                            getOptionLabel={(option) => option.sku}
                            renderInput={(params) => (
                              <TextField
                                helperText={errorProd.default_variant}
                                error={errorProd.default_variant ? true : false}
                                {...params}
                                label="Default Variant"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} lg={4}>
                          <TextField
                            variant="outlined"
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="variant1_label"
                            name="variant1_label"
                            label="Label variant 1"
                            value={prodForm.variant1_label || ""}
                            onChange={handleChangeProd}
                            error={errorProd.variant1_label ? true : false}
                            helperText={errorProd.variant1_label}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                          <TextField
                            variant="outlined"
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="variant2_label"
                            name="variant2_label"
                            label="Label Variant 2"
                            value={prodForm.variant2_label || ""}
                            onChange={handleChangeProd}
                            error={errorProd.variant2_label ? true : false}
                            helperText={errorProd.variant2_label}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <Button
                        disabled={isSubmitting}
                        onClick={handleSubmitProd}
                        size="large"
                        color="primary"
                        variant="contained"
                        fullWidth
                      >
                        <SaveIcon className={classes.iconLeft} />
                      </Button>
                    </Grid>
                    <Grid item xs={6} lg={1}>
                      <Button
                        disabled={isSubmitting}
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={handleDelete}
                        fullWidth
                      >
                        <DeleteIcon className={classes.iconLeft} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
    </>
  );
}

export default EditProduct;
