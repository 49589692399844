import React, { useState, useEffect } from "react";
import {
    firestore,
    FieldValue,
} from "../../../components/FirebaseProvider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { useSnackbar } from "notistack";



function Resi({ resiDialog: { open, data, id }, handleClose }) {


    const [form, setForm] = useState({
        resi_pengiriman_reward: "",
    });

    const [error, setError] = useState({
        resi_pengiriman_reward: "",
    });

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {

        const defaultError = {
            resi_pengiriman_reward: "",
        };
        setForm(data);
        setError(defaultError);
    }, [data]);

    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number") {
            value = parseInt(value);
        }
        setForm({
            ...form,
            [e.target.name]: value,
        });

        setError({
            ...error,
            [e.target.name]: "",
        });
    };
    const validate = async () => {
        const newError = { ...error };

        if (!form.resi_pengiriman_reward) {
            newError.resi_pengiriman_reward = "Resi pengiriman harus di isi";
        }

        return newError;
    };

    const handleSubmit = async (e) => {
        // console.log(form);
        e.preventDefault();
        const findError = await validate();

        // console.log(findError);
        if (Object.values(findError).some((m) => m !== "")) {
            setError(findError);
        } else {
            setSubmitting(true);
            try {
                const penukaran_reward = firestore.collection(`penukaran_reward`);
                await penukaran_reward.doc(id).set({
                    ...form,
                    updated_at: FieldValue.serverTimestamp(),
                });

                handleClose()

                enqueueSnackbar("Resi Pengiriman Berhasil Diperbarui", { variant: "success" });
            } catch (e) {
                const newError = {};
                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
        >
            <DialogTitle>Resi Pengiriman</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="resi_pengiriman_reward"
                            name="resi_pengiriman_reward"
                            label="Resi Pengiriman"
                            value={form.resi_pengiriman_reward || ""}
                            onChange={handleChange}
                            error={error.resi_pengiriman_reward ? true : false}
                            helperText={error.resi_pengiriman_reward}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}> Batal</Button>
                <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Resi;
