import { useEffect, useState } from "react";
import {
  useCollection,
  useCollectionData,
  useDocument,
} from "react-firebase-hooks/firestore";
import {
  auth,
  FieldValue,
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../components/Logo";
import { Paper } from "@material-ui/core";
import PageLoading from "../../components/loading/pageLoading";
import { useSnackbar } from "notistack";

const queryParams = new URLSearchParams(window?.location?.search);

function VerifyEmail() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isCode] = useState(queryParams?.get("code"));
  const [isID] = useState(queryParams?.get("id"));
  const [isResultDate] = useState(queryParams?.get("date"));

  const listComp = firestore.collection(`mitra`).doc(isID);
  const [snapshot, loadingDataMitra] = useDocument(listComp);

  const [loadingCircle, setloadingCircle] = useState(false);

  const configEmail = firestore.collection(`settings`).doc("email");
  const [configEmailDoc, loadingDataConfigEmail] = useDocument(configEmail);

  const [mitraDoc, setmitraDoc] = useState({});
  const [emailDoc, setEmailDoc] = useState({});

  const [DateFormat, setDateFormat] = useState("");
  const [error, setError] = useState({
    date: "",
  });

  const GenerateDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    var hh = String(today.getHours());
    var mnt = String(today.getMinutes());
    var ss = String(today.getSeconds());

    today = yyyy + "-" + mm + "-" + dd + ":" + hh + ":" + mnt + ":" + ss;

    return setDateFormat(today);
  };

  useEffect(() => {
    if ((snapshot && !snapshot.exists) || configEmailDoc) {
      setmitraDoc(snapshot?.data());
      setEmailDoc(configEmailDoc?.data());
      GenerateDate();
    } else {
      setmitraDoc(snapshot?.data());
      GenerateDate();
    }
  }, [snapshot]);

  const validate = async () => {
    let isDate = isResultDate.substring(0, 10);
    let dateNow = DateFormat.substring(0, 10);
    let newError = { ...error };

    if (isDate < dateNow) {
      newError.date = "Kode Verifikasi Sudah Kadaluarsa Silahkan Hub CS";
    }
    if (isDate > dateNow) {
      newError.date = "Kode Verifikasi Terlalu Lebih Dari Hari Ini";
    }
    return newError;
  };

  const handleSimpan = async (e) => {
    try {
      const findError = await validate();
      if (Object.values(findError).some((m) => m !== "")) {
        setError(findError);
      } else {
        setloadingCircle(true);
        try {
          const mitraVerification = functions.httpsCallable(
            "mitra-verificationMitra"
          );
          await mitraVerification({
            code: isCode,
            mitra_id: isID,
          });
          setTimeout(() => {
            handleloginto();
          }, 1000);
          enqueueSnackbar("Terimakasih Verifikasi anda berhasil", {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar(`Error : ${error.message}`, {
            variant: "error",
          });
        }
        setloadingCircle(false);
      }
      // history.push(`/login`)
    } catch (e) {}
  };

  const handleloginto = async () => {
    setTimeout(() => {
      history.push(`/login`);
    }, 100);
  };

  if (loadingDataMitra || loadingDataConfigEmail) {
    return <PageLoading />;
  }
  return (
    <>
      <Container maxWidth="xs" className={classes.containerverify}>
        <div className={classes.logo}>
          <Logo />
        </div>
        {!mitraDoc?.verification ? (
          <Typography className={classes.pageTitle}>
            Silahkan klik tombol Verifikasi di Bawah
          </Typography>
        ) : (
          <Typography className={classes.pageTitle}>
            Selamat Akun anda sudah Terverifikasi silahkan klik tombol di bawah
            login
          </Typography>
        )}
        <Typography className={classes.pageEror}>
          {error ? error.date : null}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <img
                className={classes.img}
                alt="complex"
                src="https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/image-landing-page%2Fcara.png?alt=media&token=aea7c8a4-0a6e-4477-82a4-eb77b8d38fab"
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {!mitraDoc?.verification ? (
              <Button
                onClick={handleSimpan}
                fullWidth
                color="primary"
                variant="contained"
              >
                {loadingCircle ? (
                  <CircularProgress color="white" size={24} value={17} />
                ) : (
                  "verifikasi"
                )}
              </Button>
            ) : (
              <Button
                onClick={handleloginto}
                fullWidth
                color="primary"
                // disabled={mitraDoc?.verification}
                variant="contained"
              >
                Akun Anda Sudah Terverifikasi
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send/?phone=628118336243&text&app_absent=0`
                )
              }
              color="primary"
            >
              Hubungi CS
            </Button>
          </Grid>
        </Grid>
      </Container>
      {/* <PrivateRoute nibrasId={findNibrasId?.nibras_id} /> */}
    </>
  );
}

export default VerifyEmail;
