import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    tab: {
        marginTop: 7
    },
    // paper: {
    //     padding: theme.spacing(2),
    //     margin: theme.spacing(2),
    //     '@media (max-width: 1280px)': {
    //         padding: '16px 30px',
    //         margin: '12px 0 40px'
    //     }
    // },
    paper: {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: theme.spacing(2),
        flexWrap: 'wrap',
        borderRadius: 8,
        borderRadius: '0 !important',
        '&:first-child': {
            borderRadius: '8px 8px 0 0'
        },
        '&:nth-child(even)': {
            backgroundColor: '#eee',
        }
    },
    paperCart: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        '@media (max-width: 1280px)': {
            padding: '16px 30px',
            margin: '12px 0 40px'
        }
    },

    pageTitle: {
        paddingBottom: '20px',
        marginLeft: '10px',
    },
    pageTitleA: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    pageTitleB: {
        marginLeft: '20px',
    },
    margin: {
        marginTop: '30px',
    },
    sliderWrap: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        // marginTop: 20,
    },
    sliderBox: {
        width: '31%',
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        borderRadius: 8,
        padding: 20,
    },
    fab1: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6),
    },

    hideInputFile: {
        display: 'none'
    },
    uploadFotoKursus: {
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    previewFotoKursus: {
        width: '100%',
        height: 'auto'
    },
    iconRight: {
        marginLeft: theme.spacing(1)
    },
    sliderRow: {
        height: 45,
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    slideLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: 12,
    },
    slideValue: {
        color: theme.palette.textColor.primeTextColor,
    },
    padding: {
        marginTop: 20
    },
    // imgWrap: {
    //     width: '100%',
    //     height: 160,
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     backgroundColor: '#000',
    //     overflow: 'hidden',
    //     border: 'solid 1px ' + theme.palette.textColor.gray2,
    //     '& img': {
    //         width: '100%',
    //         height: 'auto',
    //     }
    // },
    margin2: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    margin3: {
        marginBottom: theme.spacing(2),
    },
    margin4: {
        marginBottom: theme.spacing(0.5),
    },
    cardProd: {
        margin: theme.spacing(1),
    },
    cardAct: {
        padding: theme.spacing(3)
    },
    image: {
        borderRadius: '5px',
    },
    pagination: {
        '& > *': {
            display: 'flex',
            marginTop: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    con: {
        padding: 30
    },
    carousel: {
        width: 480,
        borderRadius: '10px'
    },
    carousel2: {
        paddingRight: 30,
    },
    jp1: {
        border: '2px solid #cd3478',
        borderRadius: '5px',
        padding: '13px',
        cursor: 'pointer',
    },
    wrapOng: {
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        padding: '13px',
        cursor: 'pointer',
    },
    jp2: {
        border: '1px solid #bbb',
        borderRadius: '5px',
        padding: '13px',
        color: '#7d7d7d'
    },
    jp3: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    ltx: {
        color: theme.palette.primary.main,
        marginLeft: '10px',
    },
    jpt: {
        color: '#aaa',
        paddingBottom: '5px'
    },

    addIcon: {
        borderRadius: 0,
        margin: theme.spacing(1),
        marginTop: 6
    },
    tabsWrap: {
        position: 'relative',
    },
    tabLink: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    feedKosong: {
        width: 200,
        height: 40,
        marginTop: '14%',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paperWrap: {
        marginTop: 12,
        marginBottom: 40,
    },
    paperWrap2: {
        margin: 0,
        overflow: 'hidden'
    },

    pageWrap: {
        // padding: '0 30px',
        paddingBottom: 25,
        minHeight: '65vh'
    },
    // paper: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     // padding: theme.spacing(2),
    //     margin: '0 16px',
    //     flexWrap: 'wrap',
    //     borderRadius: 8,
    //     borderRadius: '0 !important',
    //     '&:first-child': {
    //         borderRadius: '8px 8px 0 0'
    //     },
    //     '&:nth-child(even)': {
    //         backgroundColor: '#eee',
    //     }
    // },
    // pageTitle: {
    //     marginBottom: 10,
    // },
    button: {
        padding: theme.spacing(1),
        right: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    grid: {
        marginBottom: theme.spacing(3),
        // flexDirection: 'column',
    },
    gridOther: {
        marginBottom: theme.spacing(3),
        wordBreak: 'break-all',
        padding: '0 10px'
    },
    textBreak: {
        wordBreak: 'break-all',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        // flexWrap: 'wrap'
    },
    card: {
        padding: theme.spacing(5),

    },
    gridTyp: {
        marginBottom: theme.spacing(3),

    },
    buttonBase: {
        width: '100%',

    },
    buttonReveral: {
        marginTop: 15,

    },
    gridFilter: {
        margin: theme.spacing(2),

    },
    fabPrint: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    filterBox: {
        margin: '20px 0',
        borderTop: 'solid 1px ' + theme.palette.textColor.gray2,
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        padding: 20,
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterForm: {
        width: '60%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '@media (max-width:1280px)': {
            width: '100%',
        },
    },
    filterBoxDate: {
        marginLeft: 10,
        width: '70%',
    },
    KeyboardDatePicker: {
        margin: '16px 20px'
    },
    searchBox: {
        width: '15%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center'
    },
    suborderContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    headerFilterContainer: {
        padding: '16px 24px'
    },
    infoCon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    info: {
        display: "flex",
        flexDirection: "row",
        fontSize: 17,
        alignItems: "center",
    },
    trackBox: {
        marginBottom: 10,
    },
    trackDot: {
        width: 25,
        height: 25,
    },
    trackCon: {
        display: "flex",
        flexDirection: "column",
        fontSize: 17,
        textAlign: "left",
        paddingLeft: 30,
    },
    trackDate: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    trackStatus: {
        color: theme.palette.textColor.gray8,
        fontSize: 13,
    },
    // tabsWrap: {
    //     position: 'relative',
    //     marginBottom: 20,
    // },
    // tabLink: {
    //     borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    // },
    orderLabel: {
        color: theme.palette.textColor.lessTextColor,
        fontSize: '0.8rem',
    },
    innerValue: {
        fontSize: 13,
        color: theme.palette.textColor.gray8,
    },
    orderValue: {
        color: theme.palette.textColor.primeTextColor,
    },
    detailTitle: {
        fontSize: 20,
        marginBottom: 10,
    },
    orderValueRed: {
        color: theme.palette.secondary.main,
        fontSize: 15,
        marginLeft: 10,
        fontWeight: 'bold'
    },
    orderValueBold: {
        color: theme.palette.textColor.primeTextColor,
        fontWeight: 'bold'
    },
    komisiBox: {
        backgroundColor: theme.palette.textColor.white,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        borderRadius: 8
    },
    komisiRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
        padding: 15,
    },
    komisiLevel: {
        width: '50%',
    },
    komisiLine: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        color: theme.palette.textColor.gray8,
    },
    subdomLine: {
        display: 'flex',
        alignItems: 'center',
        width: '25%',
        fontSize: 13,
        color: theme.palette.textColor.gray8,
    },
    orderBox: {
        display: 'flex',
        flexFlow: 'column wrap',
        width: '100%',
        borderRadius: 8,
        borderTop: 'dotted 1px ' + theme.palette.textColor.gray2
    },
    orderRowTop: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        padding: 24,
    },
    innerRow: {
        margin: '0 0 10px 0',
    },
    innerRowBot: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    orderRowBot: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        backgroundColor: '#EEF2FF',
        padding: 24,
    },
    orderCol: {
        width: '40%'
    },
    orderColTopLeft: {
        width: '45%'
    },
    orderColTopRight: {
        width: '50%'
    },
    orderColLast: {
        width: '20%'
    },
    noOrderMsg: {
        width: 200,
        height: 40,
        marginTop: '5%',
        marginBottom: '5%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconLeft: {
        marginRight: 5
    },
    timelineStatus: {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        zIndex: 2,
        margin: '0 0 30px 0'
    },
    timelineBox: {
        width: '25%',
        position: 'relative'
    },
    checkpoint: {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.textColor.white,
        border: 'solid 5px ' + theme.palette.textColor.gray2,
        borderRadius: '50%',
        color: theme.palette.textColor.primeTextColor,
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            backgroundColor: theme.palette.textColor.white,
            border: 'solid 5px ' + theme.palette.textColor.gray2,
            width: 120,
            height: 5,
            display: 'block',
            content: '""',
            position: 'absolute',
            top: 23,
            left: -90,
            zIndex: -2,
        }
    },
    active: {
        border: 'solid 5px ' + theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            border: 'solid 5px ' + theme.palette.primary.main,
            width: 120,
            height: 5,
            display: 'block',
            content: '""',
            position: 'absolute',
            top: 23,
            left: -90,
            zIndex: -1,
        }
    },
    // active2: {
    //     backgroundColor: green[500],
    //     color: '#fff',
    //     '&::before': {
    //         backgroundColor: theme.palette.primary.main,
    //         width: 120,
    //         height: 5,
    //         display: 'block',
    //         content: '""',
    //         position: 'absolute',
    //         top: 23,
    //         left: -90,
    //         zIndex: -1,
    //     }
    // },
    // active3: {
    //     backgroundColor: purple[500],
    //     color: '#fff',
    //     '&::before': {
    //         backgroundColor: theme.palette.primary.main,
    //         width: 120,
    //         height: 5,
    //         display: 'block',
    //         content: '""',
    //         position: 'absolute',
    //         top: 23,
    //         left: -90,
    //         zIndex: -1,
    //     }
    // },
    // active4: {
    //     backgroundColor: orange[500],
    //     color: '#fff',
    //     '&::before': {
    //         backgroundColor: theme.palette.primary.main,
    //         width: 120,
    //         height: 5,
    //         display: 'block',
    //         content: '""',
    //         position: 'absolute',
    //         top: 23,
    //         left: -90,
    //         zIndex: -1,
    //     }
    // },
    totalPricing: {
        margin: '20px 0',
    },
    // btnDetail: {
    //     width: '100%',
    //     borderRadius: '0 0 8px 8px'
    // },
    orderLabelBot: {
        color: theme.palette.primary.main,
        width: 160,
        fontSize: 14,
    },
    orderValueBot: {
        color: theme.palette.primary.main,
        fontSize: 14,
    },
    transactionRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    transactionLabel: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    transactionValue: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    transactionItems: {
        fontWeight: 'bold',
        fontSize: 14,
        color: theme.palette.primary.main,
        marginRight: 5,
        '& span': {
            marginRight: 5
        }
    },
    statusBar: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    statusBarItem: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    statusLabel: {
        color: theme.palette.textColor.gray8,
        textTransform: 'capitalize',
        marginLeft: 5,
    },
    sellerName: {
        fontWeight: 'bold'
    },
    suborderWrap: {
        backgroundColor: theme.palette.primary.dark,
        padding: '30px 20px',
        marginTop: -10,
        width: '100%',
    },
    suborderBox: {
        backgroundColor: theme.palette.textColor.gray2,
        borderRadius: 8,
        padding: 20,
    },
    imgWrap: {
        width: '100%',
        height: 100,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: 3,
        '& img': {
            width: 'auto',
            height: '100%',
        }
    },
    productCategory: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
    },
    categoryText: {
        color: theme.palette.textColor.gray8,
        marginRight: 5,
        fontWeight: 'bold',
        fontSize: 12,
    },
    detailCol: {
        position: 'relative',
        height: '100%',
    },
    detailRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 10,
    },
    detailRow2: {
        display: 'flex',
        flexFlow: 'column wrap',
        marginTop: 20,
    },
    detailProduct: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        marginTop: 10,
        marginBottom: 20,
    },
    detailProductThumb: {
        marginRight: 20,
    },
    detailProductDesc: {
    },
    rincianText: {
        fontSize: 15,
        color: theme.palette.primary.main,
    },
    productDesc: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
    },
    productDescItem: {
        color: theme.palette.textColor.gray8,
        marginLeft: 5,
        fontWeight: 'bold',
        fontSize: 12,
    },
    brandName: {
        color: theme.palette.textColor.gray8,
        fontWeight: 'bold',
        fontSize: 12,
    },
    addressDelivery: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
    },
    addressBox: {
        width: '65%'
    },
    addressRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
    },
    addressLabel: {
        color: theme.palette.textColor.gray8,
        width: 125,
        fontSize: 13,
    },
    addressValue: {
        color: theme.palette.textColor.gray8,
        fontSize: 13,
        width: '60%',
        wordBreak: 'break-word'
    },
    courierText: {
        color: theme.palette.textColor.gray8,
        fontSize: 13,
    },
    btnPrintWrap: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        '@media (max-width:960px)': {
            position: 'relative',
            marginLeft: 20,
        }
    },
    loading: {
        display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'
    },
    orderBlock: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 45,
        width: '100%',
        padding: '5px 0'
    },
    orderDateWrap: {
        marginLeft: 7,
        marginRight: 10,
    },
    orderDate: {
        color: theme.palette.textColor.gray6,
        fontSize: 12,
    },
    orderCode: {
        marginRight: 10,
        width: '25%'
    },
    orderStatus: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '40%',
        '& div': {
            margin: ' 0 15px'
        }
    },
    btnDetailWrap: {
        width: 150,
    },
    btnDetail: {
        color: theme.palette.primary.main,
        background: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            background: 'transparent',
            boxShadow: 'none',
        }
    },
    detailIcon: {
        color: theme.palette.primary.main,
    },
    errorValueRed: {
        color: theme.palette.secondary.main,
        fontSize: 13,
        width: '60%',
        wordBreak: 'break-word'
    },
    img: {
        width: "auto",
        height: 26,
        marginRight: 24,
    },

}));

export default useStyles;
