import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paperWrap: {
        marginTop: 10,
        marginBottom: 25,
    },
    tabPanel: {
        display: 'flex'
    },
    tabWrap: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    content: {
        padding: theme.spacing(1)
    },
    formWrap: {
        width: '100%',
    },
    autocomplete: {
        marginRight: theme.spacing(2),
        paddingRight: 15,
    },
    pickupRow: {
        margin: '20px 0',
        '@media(min-width:320px)': {
            width: 300
        },
        '@media(min-width:600px)': {
            width: 500
        },
    },
    btnSave: {
        marginTop: 50,
    },
    pickupTitle: {
        marginTop: 20,
        marginBottom: 10,
        color: theme.palette.textColor.lessTextColor,
        fontWeight: 'bold',
    },
    pickupDesc: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
    },
    descLabel: {
        color: theme.palette.textColor.gray8,
        width: 100,
        fontSize: 13,
    },
    descValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        width: '60%',
        wordBreak: 'break-word'
    },
    mapLabel: {
        color: theme.palette.textColor.gray8,
        fontSize: 13,
        marginBottom: 10,
    },
    mapBox: {
        height: '40vh',
        width: '100%',
    },
    rateid: {
        marginTop: theme.spacing(2)
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    grid: {
        // marginBottom: theme.spacing(1.5),
        // marginRight: theme.spacing(1)
        width: "100%"
    },
    copy: {
        display: 'flex',
        // alignItems: 'center',
        marginBottom: theme.spacing(1.5),
        marginRight: theme.spacing(1)
    },
    btnCopy: {
        alignSelf: 'center',
        marginLeft: 10,
        // height:45,
        // width: 70,
        textTransform: 'none'
    },
}))


export default useStyles;