import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    List,
    ListItem,
    // ListItemAvatar,
    ListItemText,
    // Typography,
} from "@material-ui/core";
import {
    firestore,
} from "../../../components/FirebaseProvider";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useData } from "../../../components/DataProvider";

function PilihAlamat({ dialogAlamat: { open, data }, handleClose, handleChangealamat, alamatData }) {
    // console.log(data)
    const { company_id } = useData();

    const alamatCol = firestore.collection(`mitra/${company_id}/alamat`)
    const [dataAlamat, loadAlamat] = useCollectionData(alamatCol, { idField: 'id' })

    // console.log(stokData);

    if (loadAlamat) {
        return <CircularProgress />;
    }

    return (
        <Dialog
            // disableBackdropClick={isSubmitting}
            // disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>Pilih Alamat</DialogTitle>
            <DialogContent dividers>
                {
                    dataAlamat?.map((alamat, i) => {
                        return <List
                            key={i}
                        >

                            <ListItem
                                key={i}
                                button
                                // disabled={isSubmitting}
                                // selected={selectedIndex === 0}
                                value={alamatData}
                                // disabled={isSubmitting}
                                // selected={selectedIndex === 0}
                                onClick={(event, value) => {
                                    handleChangealamat({ target: { name: "alamat", value: alamat } });
                                    handleClose()
                                }}                            >
                                <ListItemText
                                    primary={`${alamat?.[alamat?.provider]?.kelurahan?.name} - ${alamat?.[alamat?.provider]?.kecamatan?.name} - ${alamat?.[alamat?.provider]?.kota?.name}-${alamat?.[alamat?.provider]?.provinsi?.name}`}
                                    secondary={`${alamat?.[alamat?.provider]?.alamat_lengkap}`}
                                />
                            </ListItem>


                        </List>
                    })
                }
                {/* <Grid>
                    <Autocomplete
                        className={classes.margin}
                        id="target_type"
                        name="target_type"
                        margin="normal"
                        required
                        value={form?.target_type ?? []}
                        onChange={(event, value) => {
                            handleChange({ target: { name: "target_type", value } });
                        }}
                        disabled={isSubmitting}
                        options={target}
                        renderInput={(params) => (
                            <TextField
                                helperText={error?.target_type}
                                error={error?.target_type ? true : false}
                                {...params}
                                label="Tipe Target"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid> */}








            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Batal
                </Button>
                {/* <Button disabled onClick color="primary">
                    Simpan
                </Button> */}
            </DialogActions>
        </Dialog>
    );
}

PilihAlamat.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default PilihAlamat;
