import React, { useState } from "react";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { FieldPath, firestore, useFirebase } from "./FirebaseProvider";
import AppLoading from "./loading/appLoading";
import { arrayToTree } from "performant-array-to-tree";

const DataContext = React.createContext();

export function useData() {
  return React.useContext(DataContext);
}

export default function DataProvider(props) {
  const { user } = useFirebase();

  const [company_id] = useState(user?.uid.split("-")[0]);
  const mitraDoc = firestore.doc(`mitra/${company_id}`);
  const [dataMitraCurrent, loadMitra] = useDocumentData(mitraDoc);

  const queryKategori = firestore
    .collection(`categories`)
    .orderBy("created_at", "desc");
  const [kategoriList, loadingKategoriList] = useCollectionData(queryKategori, {
    idField: "id",
  });

  const queryBrand = firestore
    .collection(`brand`)
    .orderBy("created_at", "desc");
  const [brandList, loadingBrandList] = useCollectionData(queryBrand, {
    idField: "id",
  });

  const queryProduct = firestore
    .collection(`products`)
    .orderBy("created_at", "desc");
  const [peoductList, loadingPeoductList] = useCollectionData(queryProduct, {
    idField: "id",
  });

  const levelSettingDoc = firestore.doc(`settings/sellers_level`);
  const [levelSetting] = useDocument(levelSettingDoc);

  // pricing procedures
  const pricingProceduresCollection =
    firestore.collection("pricing_procedures");
  const [pricing_procedure, loadingPricingProcedure] = useDocumentData(
    pricingProceduresCollection.doc(`online_${dataMitraCurrent?.mitra_level}`),
    { idField: "id" }
  );

  // pricing conditions
  const pricingConditionsCollection =
    firestore.collection("pricing_conditions");
  const condition_type_names = pricing_procedure?.procedures?.map?.(
    (pro) => pro?.condition_type_name
  ) ?? ["none"];
  const [pricing_conditions, loadingPricingConditions] = useCollectionData(
    pricingConditionsCollection.where(
      FieldPath.documentId(),
      "in",
      condition_type_names
    ),
    { idField: "id" }
  );

  // const usersCol = firestore.collection('users');
  // const [dataUsers, loadingDataList] = useCollectionData(usersCol, { idField: 'id' });

  const querySubdomain = firestore
    .collection("subdomains")
    .where("mitra_id", "==", company_id);
  const [subdomaintList, loadingSubdomain] = useCollectionData(querySubdomain, {
    idField: "id",
  });

  const getBroadcasting = firestore.collection("broadcast");
  const [broadcastingList, loadingbroadcastingList] = useCollectionData(getBroadcasting, {
    idField: "id",
  });

  // const queryLevel = firestore.collection('levels').orderBy('created_at', 'desc');
  // const [levelList, loadingLevel] = useCollectionData(queryLevel, { idField: "id" });

  // load sellers_level
  const mitraLevelDoc = firestore.doc(`settings/sellers_level`);
  const [dataMitraLevel, loaddatamitralevel] = useDocumentData(mitraLevelDoc);

  const dropshipSettingDoc = firestore.doc(`settings/dropship`);
  const [dropshipSetting, loadingDropshipSetting] =
    useDocument(dropshipSettingDoc);

  const hideSettingDoc = firestore.doc(`settings/hide`);
  const [hideSetting, loadingHideSetting] = useDocument(hideSettingDoc);

  let treeDataKategori = [];
  if (kategoriList) {
    treeDataKategori = arrayToTree(kategoriList, {
      idField: "id",
      parentId: "parent",
      childrenField: "sub",
      dataField: null,
    });
  }
  if (
    loadingKategoriList ||
    loadingBrandList ||
    loadingPeoductList ||
    loadMitra ||
    loadingPricingProcedure ||
    loadingPricingConditions ||
    loadingSubdomain ||
    loadingDropshipSetting ||
    loadingHideSetting ||
    loaddatamitralevel ||
    loadingbroadcastingList
    // || loadingDataList || loadingSubdomain || loadingLevel
  ) {
    return <AppLoading />;
  }

  return (
    <DataContext.Provider
      value={{
        treeDataKategori,
        brandList,
        peoductList,
        levelSetting,
        company_id,
        pricing_procedure,
        pricing_conditions,
        dataMitraCurrent,
        subdomaintList,
        dropshipSetting,
        hideSetting,
        dataMitraLevel,
        // dataUsers,
        // subdomaintList,
        // levelList
        broadcastingList,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
