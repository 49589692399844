import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProdMitra from './prodMitra';
import DetailProdMitra from './detailProdMitra';
import Cart from './cart';

function CartIdx() {

    return <>
        <Switch>
            <Route path="/cart/detailProdMitra/:mitraId" component={DetailProdMitra} />
            <Route path="/cart/prodMitra/:mitraId" component={ProdMitra} />
            <Route path="/cart" component={Cart} />
            <Redirect to="/cart" />
        </Switch>
    </>
}

export default CartIdx;
