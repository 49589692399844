import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./home";
import clsx from "clsx";

// material-ui/core
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import {
  Checkbox,
  Collapse,
  DialogContentText,
  List,
  Radio,
} from "@material-ui/core";

// material ui icon
// import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import GroupIcon from "@material-ui/icons/Group";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import DnsIcon from "@material-ui/icons/Dns";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RedeemIcon from "@material-ui/icons/Redeem";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import HomeIcon from "@material-ui/icons/Home";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StorefrontIcon from "@material-ui/icons/Storefront";
import parse from "html-react-parser";
import FaceIcon from "@material-ui/icons/Face";
import useStyles from "./styles";
import {
  auth,
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import Orders from "./orders";
// import Komisi from "./komisi";
// import Promo from "./promo";
import Reward from "./reward";
import Penukaran from "./penukaran";
import Analytics from "./analytics";
// import Settings from "./settings";
import Pencairan from "./pencairan";
import CartIdx from "./cart";
import PencairanRekber from "./pencairanRekber";
// import LogoTokoRame from "../../images/logo";
// import PilihProduk from "./pilihProduk";
// import ListSeller from "./listSeller";
// import Feedback from "./feedback";
// import GudangRoute from "../../components/routing/GudangRoute";
// import AppLoading from "../../components/AppLoading";
// import Companies from "./companies";
import Seller from "./seller";
import Settings from "./settings";
import { useDashboardUI } from "../../components/DashboardUIProvider";
import Notification from "./notification";
import Admin from "./admin";
// import PromoOngkir from "./promoOngkir";
import PurchaseOrders from "./purchaseOrders";
import Stock from "./productStock";
import Logo from "../../components/Logo";
import { useData } from "../../components/DataProvider";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import AppLoading from "../../components/loading/appLoading";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import DetailProd from "./purchaseOrders/detailProduct";
import CartOrderManual from "./orders/cartOrderManual";
// import EditReward from "./reward/edit";
import { useSnackbar } from "notistack";
import * as DOMPurify from "dompurify";

// data month static
import dataMonth from "./admin/month.json";
import Broadcast from "./broadcast/Index";
// import SmartPO from "./smartPO";

function Private() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { appbar } = useDashboardUI();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // function LogOuth
  const handleLogout = (e) => {
    auth.signOut();
    window.location.replace("/login");
  };

  // const [openMasterData, setOpenMasterData] = React.useState(false);
  // const handleClickMasterData = () => {
  //   setOpenMasterData(!openMasterData);
  //   setOpenReward(false);
  //   setOpenSetting(false);
  // };
  const [openReward, setOpenReward] = React.useState(false);
  const handleClickReward = () => {
    setOpenReward(!openReward);
    // setOpenMasterData(false);
    // setOpenSetting(false);
  };
  // const [openPO, setOpenPO] = React.useState(false);
  // const handleClickPO = () => {
  //   setOpenPO(!openPO);
  //   // setOpenMasterData(false);
  //   setOpenReward(false);
  // };

  // route akses
  const { user } = useFirebase();
  const {
    company_id,
    dataMitraCurrent,
    dropshipSetting,
    hideSetting,
    subdomaintList,
    broadcastingList,
  } = useData();
  const roleDoc = firestore.doc(`mitra/${company_id}/admin/${user?.uid}`);
  const [role, roleLoading] = useDocument(roleDoc);
  const [form, setForm] = useState();
  const uidDoc = firestore.doc(`mitra/${company_id}`);
  const [uid] = useDocument(uidDoc);
  const mitraData = useDocumentData(uidDoc);
  const levelDoc = firestore.doc(`/settings/sellers_level`);
  const [level] = useDocument(levelDoc);
  const docSetting = firestore.doc(`settings/nibras_id_counter`);
  const [resultDocSetting] = useDocument(docSetting);
 
  useEffect(() => {
    if (role) {
      const data = role?.data();
      setForm({
        ...data,
      });
    }
    // else {
    //   history.push('/login')
    // }

    // // set Data Month Now
    async function SetMonthNow() {
      var today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const dd = String(today.getDate()).padStart(2, "0");
      const hh = String(today.getHours());
      const mnt = String(today.getMinutes());
      const ss = String(today.getSeconds());
      today = yyyy + "-" + mm + "-" + dd + "-" + hh + "-" + mnt + "-" + ss;

      setLoading(true);
      try {
        const getMonth = dataMonth
          ?.filter((val) => val.id === Number(mm))
          ?.map((val) => {
            return {
              id: val.id,
              name: val.name,
              code: val.code,
            };
          });
        await docSetting.set(
          {
            month_data: {
              id: getMonth[0].id,
              name: getMonth[0].name,
              code: getMonth[0].code,
            },
          },
          { merge: true }
        );
      } catch (error) {
        setLoading(false);
      }
    }
    SetMonthNow();
  }, [role]);

  var comp = {
    home: Home,
    iconHome: <HomeIcon />,
    // companies: Companies,
    // iconCompanies: <GroupIcon />,
    stock: Stock,
    iconStock: <AllInboxIcon />,

    orders: Orders,
    iconOrders: <LocalMallIcon />,

    purchaseOrders: PurchaseOrders,
    iconPurchaseOrders: <ShoppingCartIcon />,

    reward: Reward,
    iconReward: <LocalActivityIcon />,

    analytics: Analytics,
    iconAnalytics: <AssessmentIcon />,

    settings: Settings,
    iconSettings: <SettingsIcon />,

    pencairan: Pencairan,
    iconPencairan: <MonetizationOnIcon />,

    pencairanRekber: PencairanRekber,
    iconPencairanRekber: <AccountBalanceWalletIcon />,

    notification: Notification,
    iconNotification: <NotificationsActiveIcon />,

    penukaran: Penukaran,
    iconPenukaran: <RedeemIcon />,

    admin: Admin,
    iconAdmin: <SupervisorAccountIcon />,
    // feedback: Feedback,
    // iconFeedback: <ForumIcon />,
    seller: Seller,
    iconSeller: <DnsIcon />,
  };
  // console.log("UPDATE GIT")
  const akses = form?.akses?.map((role) => {
    if (role?.sub) {
      return {
        path: role?.path,
        name: role?.name,
        icon: comp?.[role?.icon],
        component: comp?.[role?.component],
        sub: role?.sub?.map((sub) => {
          return {
            path: sub?.path,
            name: sub?.name,
            icon: comp?.[sub?.icon],
            component: comp?.[sub?.component],
          };
        }),
      };
    }
    return {
      path: role?.path,
      name: role?.name,
      icon: comp?.[role?.icon],
      component: comp?.[role?.component],
    };
  });
  const { enqueueSnackbar } = useSnackbar();

  // doc fitur
  const docFitur = firestore.doc(`mitra/${company_id}/settings/fitur`);
  const [fitur, loadFitur] = useDocumentData(docFitur);

  ///term
  const docTerm = firestore.doc(`settings/term_and_condition`);
  const [dataTerm, loaddataTerm] = useDocumentData(docTerm);
  // console.log(dataTerm);
  const [checkTerm, setCheckTerm] = useState("");
  const termParagraf = DOMPurify.sanitize(dataTerm?.paragraf);
  const handleCheck = (e) => {
    if (checkTerm !== e.value) {
      setCheckTerm(e.value);
    } else {
      setCheckTerm("");
    }
  };
  const handleKirimTerm = async () => {
    if (checkTerm === "setuju") {
      const prodCol = firestore.doc(`mitra/${company_id}`);
      await prodCol.set(
        {
          active: true,
        },
        { merge: true }
      );

      enqueueSnackbar(
        "Anda telah menyetujui Syarat dan Ketentuan di nibras play",
        { variant: "success" }
      );
    } else {
      try {
        const prodCol = firestore.doc(`mitra/${company_id}`);
        await prodCol.set(
          {
            isBlock: true,
            alasan_block: "user tidak menyetujui Syarat dan Ketentuan",
          },
          { merge: true }
        );
        const blockMitra = functions.httpsCallable("mitra-disableMitra");
        const response = await blockMitra({
          uid: `${company_id}`,
          status: true,
        });

        enqueueSnackbar(`${response.data.message}`, { variant: "success" });
        handleLogout();
      } catch (e) {
        // const newError = {};
        enqueueSnackbar(`${e.message}`, { variant: "error" });
        // newError.title = e.message;
      }
    }
  };
  // console.log("route", fitur);
  // if (fitur) {
  //   if (fitur?.is_aktif === false || !fitur?.is_aktif) {
  //     console.log('test')

  //     return enqueueSnackbar('Anda Belum Memenuhi Minimum Belanja', {
  //       variant: "warning", autoHideDuration: null, anchorOrigin: {
  //         vertical: 'top',
  //         horizontal: 'center',
  //       }, preventDuplicate: true,
  //     })
  //   }

  // }
  // link toko env
  let domain = "nibrasplay.xyz";
  if (process.env.REACT_APP_ENV === "NIBRAS_PROD") {
    domain = "nibrasplay.com";
  }

  if (roleLoading || loadFitur || loaddataTerm) {
    return <AppLoading />;
  }
  return (
    <>
      {fitur !== undefined
        ? fitur?.is_aktif === false || !fitur?.is_aktif
          ? enqueueSnackbar("Anda Belum Memenuhi Minimum Belanja", {
              variant: "warning",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              preventDuplicate: true,
            })
          : null
        : null}
      <div className={classes.root}>
        {appbar ? (
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: openDrawer,
                })}
              >
                <MenuIcon className={classes.icw} />
              </IconButton>
              <Typography variant="h6" noWrap className={classes.title}>
                <Switch>
                  {akses?.map?.((role) => {
                    return (
                      <Route exact path={role?.path} children={role?.name} />
                    );
                  })}
                  <Route path="/cart" children="Keranjang" />
                  <Route
                    path="/purchaseOrders/detailProduct/:productId"
                    children="Detail Produk"
                  />
                </Switch>
              </Typography>
              {dataMitraCurrent?.isBlock === true && (
                <Typography variant="h4" className={classes.messageBlock}>
                  Akun anda di blok, Alasan : {dataMitraCurrent?.alasan_blcok}
                </Typography>
              )}
              {history?.location?.pathname === "/purchaseOrders" && (
                <IconButton
                  className={classes.icw}
                  onClick={() => {
                    history.push("/cart");
                  }}
                >
                  <ShoppingCartIcon className={classes.icw} />
                </IconButton>
              )}
              {history?.location?.pathname !== "/purchaseOrders" && (
                <Typography>
                  Email : {user?.email}
                  <br />
                  Level :{" "}
                  {dataMitraCurrent?.mitra_level > 2 &&
                  dataMitraCurrent?.extra_marker
                    ? dataMitraCurrent?.mitra_level === 3
                      ? `Nibras Patner`
                      : `Nibras Corner`
                    : level?.data()?.custom_name[uid?.data()?.mitra_level]}
                </Typography>
              )}
            </Toolbar>
          </AppBar>
        ) : null}
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer,
            }),
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.listNav}
          >
            <div>
              <div className={classes.toolbar}>
                <Logo />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              {/* {
              form?.role === 'administrator' &&
              <> */}
              {akses?.map?.((role) => {
                return <></>;
              })}
              {akses?.map?.((role) => {
                return role?.path === "/seller" &&
                  dropshipSetting.data().hideDropship ? (
                  <></>
                ) : role?.path === "/pencairan" &&
                  hideSetting.data().hidePencairan ? (
                  <></>
                ) : role?.path === "/pencairanRekber" &&
                  hideSetting.data().hideRekber ? (
                  <></>
                ) : role?.path === "/reward" &&
                  hideSetting.data().hideReward ? (
                  <></>
                ) : role?.path === "/notification" &&
                  hideSetting.data().hideNotification ? (
                  <></>
                ) : (
                  <>
                    {role?.sub ? (
                      <>
                        <>
                          <ListItem
                            button
                            onClick={handleClickReward}
                            className={classes.listItem}
                            classes={{ selected: classes.selectedList }}
                          >
                            <ListItemIcon>
                              <LocalActivityIcon />
                            </ListItemIcon>
                            <ListItemText primary="Reward" />
                            {openReward ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse
                            in={openReward}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {role?.sub?.map((sub) => {
                                return (
                                  <>
                                    <Route
                                      path={sub?.path}
                                      children={({ match, history }) => {
                                        return (
                                          <ListItem
                                            button
                                            selected={match ? true : false}
                                            onClick={() =>
                                              history.push(`${sub?.path}`)
                                            }
                                            className={classes.nested}
                                            classes={{
                                              selected: classes.selectedList,
                                            }}
                                          >
                                            <ListItemIcon>
                                              {sub?.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={sub?.name} />
                                          </ListItem>
                                        );
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </List>
                          </Collapse>
                          <Divider />
                        </>
                      </>
                    ) : (
                      <>
                        <Route
                          exact
                          path={role?.path}
                          children={({ match, history }) => {
                            return (
                              <ListItem
                                disabled={
                                  dataMitraCurrent?.isBlock === true &&
                                  role?.path === "/purchaseOrders"
                                }
                                button
                                selected={match ? true : false}
                                onClick={() => history.push(`${role?.path}`)}
                                className={classes.listItem}
                                classes={{ selected: classes.selectedList }}
                              >
                                <ListItemIcon>{role?.icon}</ListItemIcon>
                                <ListItemText primary={role?.name} />
                              </ListItem>
                            );
                          }}
                        />
                        <Divider />
                      </>
                    )}
                  </>
                );
              })}
              {subdomaintList?.[0]?.mitra_unique_id && (
                <Route
                  exact
                  path={role?.path}
                  children={({ match, history }) => {
                    return (
                      <ListItem
                        button
                        onClick={() =>
                          subdomaintList.length !== 0
                            ? window.open(
                                `https://${subdomaintList[0]?.id}.${domain}/mitra`
                              )
                            : window.location.replace("/settings?tab=subdomain")
                        }
                        className={classes.listItem}
                        // selected={openDialog}
                      >
                        <ListItemIcon>
                          <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listText}
                          primary="Lihat Toko Saya"
                        />
                      </ListItem>
                    );
                  }}
                />
              )}
              <Divider />
            </div>
            {/* bottom */}
            <div>
              <Divider />
              <ListItem
                button
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send/?phone=628118336243&text&app_absent=0`
                  )
                }
                className={classes.listItem}
                // selected={openDialog}
              >
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Kontak CS"
                />
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={handleClickOpen}
                className={classes.listItem}
                selected={openDialog}
              >
                <ListItemIcon>
                  <ExitToAppIcon color="error" />
                </ListItemIcon>
                <ListItemText className={classes.liText} primary="Logout" />
              </ListItem>
            </div>
          </List>
        </Drawer>
        <main
          className={clsx(classes.contentShrink, {
            // [classes.content]: appbar,
          })}
        >
          <Switch>
            {dataMitraCurrent?.isBlock !== true && (
              <Route path="/cart" component={CartIdx} />
            )}
            {/* <Route path="/purchaseOrders/detailProduct/:productId" component={DetailProd} /> */}
            {/* <Route path='/penukaran' component={Penukaran} /> */}
            <Route path="/orders/cartOrderManual" component={CartOrderManual} />
            {/* <Route path="/reward/edit/:productId" component={EditReward} /> */}
            {akses?.map?.((role) => {
              if (role?.path === "/") {
                return (
                  <Route exact path={role?.path} component={role?.component} />
                );
              }
            })}
            {akses?.map?.((role) => {
              if (role?.path !== "/" && !role?.sub) {
                return <Route path={role?.path} component={role?.component} />;
              }
            })}
            {akses?.map?.((role) => {
              if (role?.sub) {
                return (
                  <>
                    <Route path="/penukaran" component={Penukaran} />
                    <Route path="/reward" component={Reward} />
                    <Route path="/purchaseOrders" component={PurchaseOrders} />
                    {/* <Route path="/smartPO" component={SmartPO} /> */}
                  </>
                );
              }
            })}
          </Switch>
        </main>
      </div>
      {/* Dialog Term and condition */}
      {dataTerm?.tampilkan_ketentuan && (
        <Dialog
          open={!mitraData?.[0]?.active}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullScreen
          style={{ width: "80%", height: "100%", margin: "auto" }}
        >
          <DialogContent className={classes.termParagraf}>
            <DialogContentText>{parse(termParagraf)}</DialogContentText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checkTerm === "setuju"}
                onChange={() => handleCheck({ value: "setuju" })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <span style={{ display: "block" }}>
                Saya <span style={{ fontWeight: "bold" }}> Setuju </span>{" "}
                mengikuti Syarat dan ketentuan di Nibras Play
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checkTerm === "tidak setuju"}
                onChange={() => handleCheck({ value: "tidak setuju" })}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <span style={{ display: "block" }}>
                Saya <span style={{ fontWeight: "bold" }}> Tidak Setuju </span>{" "}
                mengikuti Syarat dan ketentuan di Nibras Play
              </span>
            </div>
            <DialogActions>
              <ButtonGroup fullWidth variant="text">
                <Button disabled={checkTerm === ""} onClick={handleKirimTerm}>
                  kirim
                </Button>
              </ButtonGroup>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {/* Broadcast */}
      {broadcastingList && <Broadcast />}
      {/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Apakah Anda Yakin?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Logout</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Private;
