import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineOppositeContent,
    TimelineDot,
} from "@material-ui/lab";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography } from '@material-ui/core';
import { functions } from '../../../components/FirebaseProvider';
import { LocalShippingOutlined } from '@material-ui/icons';
import { isoToDate, isoToDateTime, isoToTime, unixToDateStrip } from '../../../utils/dateFormatter';
import useStyles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';


function Tracking({ openAddDialog: { open, orderId, suborderId }, handleClose }) {
    const classes = useStyles();

    const [tracking, setTracking] = useState([]);
    const [loading, setLoading] = useState(false)

    const trackingTimeline = useMemo(() => {
        let data = tracking;
        if (data?.length > 0) {
            data[0].color = "primary";
        }
        return data
    }, [tracking]);

    // console.log('id', orderId,
    //     suborderId)

    useEffect(() => {
        const getTotalTransaksiSeller = async () => {
            setLoading(true)
            try {
                if (open) {
                    const transaksi = functions.httpsCallable("shipping-tracking");
                    const res = await transaksi({
                        order_id: orderId,
                        suborder_id: suborderId?.id
                    });
                    if (res?.data?.[0]?.date) {
                        const tracking = res.data?.sort?.((a, b) => {
                            // console?.log('time', isoToDateTime(b?.date) - isoToDateTime(a?.date))
                            return b?.date - a?.date;

                        });
                        setTracking(tracking);
                        // console.log('track', tracking)
                    }
                    if (res?.data?.[0]?.date_time) {
                        const tracking = res.data?.sort?.((a, b) => {

                            return isoToDateTime(b?.date_time) - isoToDateTime(a?.date_time);

                        });
                        setTracking(tracking);
                        // console.log('track', tracking)
                    }
                    if (res?.data?.[0]?.time) {
                        const tracking = res.data?.sort?.((a, b) => {

                            return unixToDateStrip(b?.date_time) - unixToDateStrip(a?.date_time);

                        });
                        setTracking(tracking.reverse());
                    }
                    

                    setLoading(false)
                }
            } catch (e) {
                setLoading(false)
                console.log(e.message)
            }
        };

        getTotalTransaksiSeller();
    }, [open, orderId, suborderId]);

    // console.log('track2', tracking)

    return <Dialog

        open={open}
        onClose={handleClose}
        maxWidth='md'
    >
        <DialogTitle>Detail Pengiriman</DialogTitle>
        <DialogContent dividers>
            {
                loading ?
                    <>
                        loading...<CircularProgress size={20} style={{ marginTop: 8 }} />

                    </> :
                    <React.Fragment>
                        <Typography className={classes.trackDate}>Kurir : {suborderId?.shipping_info?.kurir}</Typography>
                        <Typography className={classes.trackDate}>Resi : {suborderId?.shipping_info?.resi}</Typography>
                        <div className={classes.infoCon}>
                            <div className={classes.info}>
                                <LocalShippingOutlined />
                            </div>
                        </div>
                        <div className={classes.trackBox}>
                            <Timeline>
                                {trackingTimeline?.map?.((item, index) => {
                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot
                                                    color={item?.color ? item.color : "grey"}
                                                    className={classes.trackDot}
                                                />
                                                {index !== trackingTimeline.length - 1 ? (
                                                    <TimelineConnector />
                                                ) : null}
                                            </TimelineSeparator>
                                            <TimelineOppositeContent className={classes.trackCon}>
                                                <div style={{ color: item?.color ? item.color : "grey" }} className={classes.trackDate}>{item.date_time || item?.date || item?.time}</div>
                                                <div style={{ color: item?.color ? item.color : "grey" }} className={classes.trackDate}>{item.status || item?.code}</div>
                                                <div style={{ color: item?.color ? item.color : "grey" }} className={classes.trackStatus}>{item.city || item?.receiver_name || item.desc}</div>


                                            </TimelineOppositeContent>
                                        </TimelineItem>
                                    );
                                })}
                            </Timeline>
                        </div>
                    </React.Fragment>
            }

        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
            >Kembali</Button>
        </DialogActions>
    </Dialog>
}
Tracking.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default Tracking;