import React, { useEffect, useState } from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import TabPanel from "../../../components/TabPanel";

import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { FieldValue, firestore } from "../../../components/FirebaseProvider";
// import useStyles from "./styles";

// Icon
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useSnackbar } from "notistack";
import PageLoading from "../../../components/loading/pageLoading";
import SaveIcon from "@material-ui/icons/Save";
import { useData } from "../../../components/DataProvider";
import {
  IconButton,
  InputAdornment,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

function Company({ value }) {
  // const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { company_id } = useData();
  // console.log(company_id)
  const CompDoc = firestore.doc(`mitra/${company_id}`);
  const [compSnapshot, compLoading] = useDocument(CompDoc);
  const [mitraDoc, setmitraDoc] = useState({});
  const [ubah, setubah] = useState(false);
  const findNibrasid = firestore
    .collection("nibras_id")
    .where("mitra_id", "==", company_id);
  const [subdomaintList, loadingFindNibrasID] = useCollectionData(
    findNibrasid,
    {
      idField: "id",
    }
  );

  const [compForm, setCompForm] = useState({
    nama: "",
    alamat: "",
    telepon: "",
    mitraId: "",
  });
  const [errorComp, setErrorComp] = useState({
    nama: "",
    alamat: "",
    telepon: "",
    mitraId: "",
  });
  const [before, setBefore] = useState();

  useEffect(() => {
    if (compSnapshot) {
      const data = compSnapshot.data();
      setmitraDoc(data);
      let nomor_telepon = data?.telepon?.substring(2.14);
      setCompForm({
        ...data,
        telepon: nomor_telepon,
      });
      setBefore({
        ...data,
        telepon: nomor_telepon,
      });
    }
  }, [compSnapshot]);

  const handleChangeComp = (e) => {
    setCompForm({
      ...compForm,
      [e.target.name]: e.target.value,
    });

    setErrorComp({
      ...errorComp,
      [e.target.name]: "",
    });
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const handleCancel = () => {
    setubah(false);
    setCompForm({
      ...before,
    });
  };
  const validate = async () => {
    const newError = { ...errorComp };
    if (!compForm.nama) {
      newError.nama = "Nama harus diisi";
    }
    if (!compForm.alamat) {
      newError.alamat = "Alamat harus diisi";
    }
    if (!compForm.telepon) {
      newError.telepon = "Telepon harus diisi";
    }
    if (compForm.telepon?.length >= 13) {
      newError.telepon = "No Telepon tidak valid";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    // console.log(findError)
    if (Object.values(findError).some((m) => m !== "")) {
      setErrorComp(findError);
    } else {
      setSubmitting(true);
      try {
        compForm.nama = compForm.nama.toLowerCase();
        compForm.telepon = "62" + compForm.telepon;
        compForm.alamat !== before.alamat
          ? await CompDoc.set(
              {
                ...compForm,
                mitraId: company_id,
                updated_at: FieldValue.serverTimestamp(),
                count_change_address: FieldValue.increment(1),
              },
              { merge: true }
            )
          : await CompDoc.set(
              {
                ...compForm,
                mitraId: company_id,
                updated_at: FieldValue.serverTimestamp(),
              },
              { merge: true }
            );

        enqueueSnackbar("Berhasil diubah", { variant: "success" });
      } catch (e) {
        const newError = {};
        newError.title = e.message;
        enqueueSnackbar(`gagal diubah,${e.message}`, { variant: "error" });
        setErrorComp(newError);
      }
      setubah(false);
      setSubmitting(false);
    }
  };

  const CopyCliboard = async (param) => {
    let kode = param?.kode;
    let type = param?.type;
    navigator.clipboard.writeText(kode);
    enqueueSnackbar(`Kode ${type} Berhasil di Salin`, {
      variant: "success",
      autoHideDuration: 850,
    });
  };
  const uplineDoc = firestore.doc(
    `mitra/${
      mitraDoc?.ref_3
        ? mitraDoc?.ref_3?.mitra_id
        : mitraDoc?.ref_2
        ? mitraDoc?.ref_2?.mitra_id
        : mitraDoc?.ref_1?.mitra_id
    }`
  );
  const [uplineSnapshot, uplineLoading] = useDocument(uplineDoc);
  const [upDoc, setupDoc] = useState({});

  useEffect(() => {
    if (uplineSnapshot) {
      const data = uplineSnapshot.data();
      setupDoc(data);
    }
  }, [uplineSnapshot]);
  if (compLoading && loadingFindNibrasID) {
    return <PageLoading />;
  }
  return (
    <TabPanel value={value} index="profil">
      <div>
        {/* <Typography variant="h6" className={classes.judul}>Profil Company</Typography> */}
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              {upDoc && (
                <>
                  <Grid item xs={4} lg={4}>
                    <TextField
                      autoComplete="off"
                      disabled={true}
                      id="Upline_id"
                      name="Upline_id"
                      label="Email Upline"
                      value={upDoc?.email || ""}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                CopyCliboard({
                                  kode: upDoc?.email,
                                  type: "Mitra ID",
                                })
                              }
                            >
                              <FileCopy size={24} value={15} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <TextField
                      autoComplete="off"
                      disabled={true}
                      id="nama_upline"
                      name="nama_upline"
                      label="Nama Toko Upline"
                      value={upDoc?.nama || ""}
                      fullWidth
                      variant="outlined"
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         onClick={() =>
                      //           CopyCliboard({
                      //             kode: upDoc?.nama,
                      //             type: "Nibras ID",
                      //           })
                      //         }
                      //       >
                      //         <FileCopy size={24} value={15} />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <TextField
                      autoComplete="off"
                      disabled={true}
                      id="kontak_upline"
                      name="kontak_upline"
                      label="Kontak Upline"
                      value={`${upDoc?.telepon ? upDoc?.telepon : "-"}` || ""}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                CopyCliboard({
                                  kode: upDoc.telepon,
                                  type: "Referal",
                                })
                              }
                            >
                              <FileCopy size={24} value={15} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={4} lg={4}>
                <TextField
                  autoComplete="off"
                  disabled={true}
                  id="mitra_id"
                  name="mitra_id"
                  label="Kode Mitra ID"
                  value={mitraDoc?.mitra_id || ""}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            CopyCliboard({
                              kode: mitraDoc?.mitra_id,
                              type: "Mitra ID",
                            })
                          }
                        >
                          <FileCopy size={24} value={15} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={4}>
                <TextField
                  autoComplete="off"
                  disabled={true}
                  id="referal"
                  name="referal"
                  label="Kode Referal"
                  value={mitraDoc.mitra_id || ""}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            CopyCliboard({
                              kode: mitraDoc.mitra_id,
                              type: "Referal",
                            })
                          }
                        >
                          <FileCopy size={24} value={15} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={4}>
                <TextField
                  autoComplete="off"
                  disabled={true}
                  id="nibras_id"
                  name="nibras_id"
                  label="Kode Nibras ID"
                  value={subdomaintList?.[0]?.nibras_id || ""}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            CopyCliboard({
                              kode: subdomaintList?.[0]?.nibras_id,
                              type: "Nibras ID",
                            })
                          }
                        >
                          <FileCopy size={24} value={15} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  autoComplete="off"
                  disabled={true}
                  id="nama"
                  name="nama"
                  label="Nama Toko"
                  value={compForm.nama || ""}
                  onChange={handleChangeComp}
                  error={errorComp.nama ? true : false}
                  helperText={errorComp.nama}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  autoComplete="off"
                  disabled={!ubah}
                  id="alamat"
                  name="alamat"
                  label={`Alamat Toko`} // Berubah Sebanyak ${mitraDoc?.count_change_address ? mitraDoc?.count_change_address - 1 : 0}x
                  value={ubah ? compForm?.alamat : before?.alamat || ""}
                  onChange={handleChangeComp}
                  error={errorComp.alamat ? true : false}
                  helperText={errorComp.alamat}
                  fullWidth
                  variant="outlined"
                  // InputProps={{
                  //     endAdornment: <InputAdornment position="end">Perubahan Sebanyak {mitraDoc?.count_change_address ? mitraDoc?.count_change_address - 1 : 0}x</InputAdornment>,
                  // }}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  autoComplete="off"
                  disabled={!ubah}
                  id="telepon"
                  name="telepon"
                  label="Telepon"
                  value={compForm.telepon || ""}
                  onChange={handleChangeComp}
                  error={errorComp.telepon ? true : false}
                  helperText={errorComp.telepon}
                  fullWidth
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+62</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
              {/* {mitraDoc.count_change_address && (
                <Grid item xs={12} lg={12}>
                  <Alert severity="warning">{`Anda memiliki 5x Kesempatan untuk melakukan perubahan`}</Alert>
                </Grid>
              )} */}
              {mitraDoc.count_change_address === 6 ? null : (
                <>
                  {mitraDoc.count_change_address < 3 ? (
                    <Grid item xs={12} lg={12}>
                      <Alert severity="warning">{`Anda memiliki ${
                        6 - mitraDoc?.count_change_address
                      }x Kesempatan untuk melakukan perubahan`}</Alert>
                    </Grid>
                  ) : (
                    <>
                      {mitraDoc.count_change_address >= 3 ? (
                        <Grid item xs={12} lg={12}>
                          <Alert severity="error">{`Anda memiliki ${
                            6 - mitraDoc?.count_change_address
                          }x Kesempatan untuk melakukan perubahan`}</Alert>
                        </Grid>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {mitraDoc.count_change_address >= 6 ? (
                <Grid item xs={12} lg={12}>
                  <Alert severity="error">
                    {" "}
                    Maaf Anda Sudah Melebihi Batas Maksimal Perubahan Profil !
                  </Alert>
                </Grid>
              ) : !ubah ? (
                <Grid item xs={12} lg={12}>
                  <Button
                    onClick={() => setubah(true)}
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                    fullWidth
                    InputProps={{
                      endAdornment: <SaveIcon />,
                    }}
                  >
                    ubah
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item xs={6} lg={6}>
                    <Button
                      onClick={handleCancel}
                      disabled={isSubmitting}
                      color="primary"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: <SaveIcon />,
                      }}
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Button
                      onClick={handleSubmit}
                      disabled={
                        isSubmitting ||
                        (compForm.telepon === before.telepon &&
                          compForm.alamat === before.alamat)
                      }
                      color="primary"
                      variant="contained"
                      fullWidth
                      InputProps={{
                        endAdornment: <SaveIcon />,
                      }}
                    >
                      Simpan
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* <Grid item sm={12} md={4}>
                    <AccountCircleIcon color="disabled" style={{ display: 'block', fontSize: 220, marginLeft: 'auto', marginRight: 'auto' }} />
                </Grid> */}
        </Grid>
      </div>
    </TabPanel>
  );
}

export default Company;
