import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(theme => ({

    paper: {
        padding: theme.spacing(2),
    },
    judul: {
        margin: 'auto',
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    tableCon: {
        marginTop: 30,
        marginBottom: 30,
    },
    fab1: {
        display: 'flex',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(6)
    },
    tableWrap: {
        border: 'solid 1px ' + theme.palette.textColor.gray2,
        borderRadius: 8,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        marginBottom: 20,
    },
    tabWrap: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },

}))

export default useStyles;