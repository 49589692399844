import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paperWrap: {
        marginTop: 35,
        marginBottom: 25,
    },
    pageWrap: {
        // padding: '0 30px',
        paddingBottom: 20,
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        borderRadius: 8,
    },
    tabPanel: {
        display: 'flex'
    },
    pageTitle: {
        paddingTop: 15,
        marginLeft: 20,
    },
    tabWrap: {
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray2,
    },
    content: {
        padding: theme.spacing(2)
    },
    formWrap: {
        width: '100%',
    },
    alamatRow: {
        width: '100%',
        height: 60,
        marginBottom: 30,
    },
    autocomplete: {
        marginRight: theme.spacing(2),
        paddingRight: 15,
    },
    pickupRow: {
        margin: '20px 0',
        '@media(min-width:320px)': {
            width: 300
        },
        '@media(min-width:600px)': {
            width: 500
        },
    },
    btnSave: {
        marginTop: 15,
        marginLeft: 5,
        marginRight: 5
    },
    pickupTitle: {
        marginTop: 20,
        marginBottom: 10,
        color: theme.palette.textColor.lessTextColor,
        fontWeight: 'bold',
    },
    pickupDesc: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
    },
    descLabel: {
        color: theme.palette.textColor.gray8,
        width: 100,
        fontSize: 13,
    },
    liquidRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: 5,
        marginBottom: 5
    },
    liquidLabel: {
        color: theme.palette.textColor.lessTextColor,
        width: 140,
        marginRight: 10,
        fontSize: 14,
    },
    liquidValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 13,
        width: '75%',
        wordBreak: 'break-all'
    },
    descValue: {
        color: theme.palette.textColor.primeTextColor,
        fontSize: 15,
        width: '60%',
        wordBreak: 'break-word'
    },
    mapLabel: {
        color: theme.palette.textColor.gray8,
        fontSize: 13,
    },
    mapBox: {
        height: '40vh',
        width: '100%',
    },
    rateid: {
        marginTop: theme.spacing(2)
    },
    imgWrap: {
        width: '100%',
        height: 300,
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#000',
        overflow: 'hidden',
        // border: 'solid 1px ' + theme.palette.textColor.gray2,
        '& img': {
            width: '100%',
            height: 'auto',
        }
    },

}))


export default useStyles;