import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import Nibrasplay from "../../images/nibrasplay-logo.svg";
import Kulaku from "../../images/kulaku-logo.svg";
import Reform from "../../images/reform-logo.svg";

const Logo = (props) => {
  const { classes } = props;

  let logo = Nibrasplay;

  if (
    process.env.REACT_APP_ENV === "KULAKU_PROD" ||
    process.env.REACT_APP_ENV === "KULAKU_DEV"
  ) {
    logo = Kulaku;
  } else if (
    process.env.REACT_APP_ENV === "REFORM_PROD" ||
    process.env.REACT_APP_ENV === "REFORM_DEV"
  ) {
    logo = Reform;
  }

  return <img className={classes.mainLogo} src={logo} alt="" />;
};

export default withStyles(styles)(Logo);
