import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { firestore } from '../../../components/FirebaseProvider';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PageLoading from '../../../components/loading/pageLoading';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './styles';
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from '@material-ui/lab/Pagination';
import DataProdMitra from "./dataProdMitra";

export default function ProdMitra({ prodId }) {
    const params = useParams();
    const classes = useStyles();
    // const { company_id } = useData();
    // const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const prodCol = firestore.collection(`products`);

    const [dataProd, loadingDataProd] = useCollectionData(prodCol, { idField: 'id' })

    // mitra
    const prodStokQuery = firestore.collection(`mitra/${params?.mitraId}/products_stock`);

    const [stokData, loadingStok] = useCollectionData(prodStokQuery)
    const mitra = stokData?.map((stok) => {
        return stok
    })
    // const productId = mitra?.map((stok) => {
    //     return stok?.productId
    // })
    const prodIdSet = [...new Map(mitra?.map?.(item => [item?.productId, item]))?.values()];
    // pagination
    const itemsPerPage = 12;
    const [page, setPage] = React.useState(1);
    const noOfPages = Math.ceil(prodIdSet?.length / itemsPerPage);

    const handleChangePage = (event, value) => {
        setPage(value);
    };
    // const unique2 = mitra?.reduce?.((result, element) => {

    //     return result.includes(element?.productId) ? result : [...result, element?.productId];

    // }, []);
    // console.log('mitraaa', unique2)

    // console.log('mitra', [...new Map(mitra?.map?.(item => [item?.productId, item]))?.values()])
    if (loadingDataProd || loadingStok) {
        return <PageLoading />
    }
    return <>
        <Box p={3}>
            <div className={classes.pageTitleA}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography className={classes.pageTitleB} component="h1" variant="h6">Tambah Product</Typography>
            </div>
            <Grid className={classes.sliderWrap} container>
                {
                    prodIdSet?.slice((page - 1) * itemsPerPage, page * itemsPerPage)?.map((prod) => {
                        // console.log(prod)
                        return <Grid key={prod?.id} item xs={12} md={6} lg={3} xl={3}>
                            <DataProdMitra
                                prodId={prod?.productId}
                                varId={prod?.variantId}
                            // varId={prod?.variantId}
                            />
                        </Grid>
                    })
                }
            </Grid>
            <Pagination
                className={classes.pagination}
                count={noOfPages}
                page={page}
                onChange={handleChangePage}
                defaultPage={1}
                showFirstButton
                showLastButton
                color="primary"
                variant="outlined"
            />
        </Box>
    </>
}