// Material
import { Dialog } from "@material-ui/core";
import SearchKelurahan from "./seacrhKelurahan";

//
// import SearchKelurahan from "./SearchKelurahan";

// Styles
// import useStyles from "./styles/AlamatDialog";

function DialogKelurahan({ open, value, onClose }) {
//   const classes = useStyles();

  return (
    <Dialog
      fullWidth
    //   maxWidth="md"
      open={open}
      aria-labelledby="max-width-dialog-title"
    
    >
      <SearchKelurahan value={value} onClose={onClose} />
    </Dialog>
  );
}

export default DialogKelurahan;
