import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { firestore, FieldValue, functions } from '../../../components/FirebaseProvider';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';
import Typography from '@material-ui/core/Typography';
import Autocomplete from "@material-ui/lab/Autocomplete";

import Virtualize from '../../../components/Virtualization';
import { useCollectionData, } from 'react-firebase-hooks/firestore';
import AddAlamatOrder from './addAlamat';
import uniqBy from 'lodash/uniqBy';
import { InputAdornment, Switch } from '@material-ui/core';

function AddOrder({ open, handleClose }) {
    const history = useHistory();

    const { company_id, dataMitraCurrent, pricing_procedure, pricing_conditions, dataMitraLevel, hideSetting } = useData();
    const [HideEkspedisi, setHideEkspedisi] = useState(hideSetting.data().hideEkspedisi)
    // console.log('==>' , HideEkspedisi)

    const [form, setForm] = useState({
        product: '',
        variant: '',
        qty: '',
        nama : '',
        no_hp : '',
        kurir : ''
    });

    const [error, setError] = useState({
        product: '',
        variant: '',
        qty: '',
        nama : '',
        no_hp : '',
        alamat: ''
    });

    const subdomainCol = firestore.collection(`subdomains`).where('mitra_id', '==', company_id || '');

    // resi marketplace
    const [state, setState] = useState(false);

    const pilihKurir = ['JNE', 'JNT', 'SICEPAT', 'SHOPE XPRESS' , 'HEMAT' , 'LAZADA EXPRESS' , 'WAHANA', 'PICKUP' , 'Lain nya']

    const handleChangeSwicth = async (event) => {
        setState(event.target.checked);
    };

    const [alamat, setalamat] = useState()
    const [pengiriman, setPengiriman] = React.useState();
    const [subdomainData,] = useCollectionData(subdomainCol, {
        idField: "id",
    });
    const [pembayaran, setPembayaran] = React.useState();

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        let value = e.target.value
        if (e.target.type === 'number') {
            value = parseInt(value)
        }
        // console.log(e.target.type)
        setForm({
            ...form,
            [e.target.name]: value
        })
        // console.log(form)

        setError({
            ...error,
            [e.target.name]: ''
        })
    }
    // product_stock
    // mitra
    const prodStokQuery = firestore.collection(`mitra/${company_id}/products_stock`);

    const [stokData,] = useCollectionData(prodStokQuery)
    const mitra = stokData?.map((stok) => {
        return stok
    })
    const prodIdSet = [...new Map(mitra?.map?.(item => [item?.productId, item]))?.values()];
    const prodIdSetLength = prodIdSet?.length
    let prodFilter = []
    for (var i = 0; i < prodIdSetLength; i++) {
        var filterProd = firestore.doc(`products/${prodIdSet?.[i]?.productId ? prodIdSet?.[i]?.productId : 'prod' || 'prod'}`);
        filterProd.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data()
                if (!data?.sembunyikan_produk) {
                    prodFilter.push(doc.data())
                }
                // console.log("Document data:", doc.data());
                // setLoadData(doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

    }

    // console.log(prodFilter)

    // collvariant
    let uniques = uniqBy?.(stokData, 'variantId')
    const varId = uniques?.map((stok) => {
        return stok?.variantId
    })

    // const [variantsSelect, setVariants] = useState()
    let variantRes = []
    let variantFilter = [...new Set(variantRes)];
    
    if (varId && form?.product?.product_id) {
        const prod = stokData?.filter(a=> a.productId === form?.product?.product_id)
        for (var j = 0; j < prod?.length; j++) {
            var filterVar = firestore.collectionGroup(`variants`).where('product_id', '==', form?.product?.product_id || '').where('variant_id', '==', prod?.[j].variantId || '');
            filterVar.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    variantFilter.push(doc.data())
                });
                // setVariants(variantFilter)
            });
            
        }
    }

    // const variantsQuery = firestore.collectionGroup(`variants`).where('product_id', '==', form?.product?.product_id || 'BAA258').where('variant_id', 'in', varId || ['02302']);

    // const [variants, loadingVariants, errVar] = useCollectionData(variantsQuery, { idField: 'id' })

    // const varColl = firestore.collection(`products/${form?.product?.id || 'prodId'}/variants`);
    // const [dataVar, loadDataVar] = useCollectionData(varColl, { idField: "id" })
    // console.log(form?.variant?.harga_normal)

    const validate = async () => {
        let noHp = String(form.no_hp)
        const newError = { ...error };

        if (!form.product) {
            newError.product = 'Product harus diisi';
        }
        if (!form.variant) {
            newError.variant = 'Variant harus diisi';
        }
        if (!form.qty) {
            newError.qty = 'Total jumlah qty harus diisi';
        }
        if (!alamat && pengiriman == 'ekspedisi'){
            newError.alamat = 'Alamat Tidak Di temukan!!'
            enqueueSnackbar(`Alamat Tidak Di temukan!!`, { variant: "error" })

        }
        if (!form.nama){
            newError.nama = 'Nama tidak Boleh Kosong'
        }
        if (!form.no_hp){
            newError.no_hp = 'No Telepon tidak Boleh Kosong'
        }
        if (noHp.length > 11){
            newError.no_hp = 'No Telepon tidak valid'
        }
        // if (!totalOmset) {
        //     newError.qty = 'Total pencairan harus diisi';
        // }
        return newError;
    }
    // subdomain
    
    const subdomain = subdomainData?.map((mit) => {
        return mit?.mitra_unique_id
    })

    // console.log("EX",typeof pengiriman)
    // change alamat
    // const handleChangealamat = (event) => {
    //     setalamat({
    //         ...alamat,
    //         [event.target.name]: event.target.value
    //     });
    // };
    // console.log(alamat)
    // radio metode pembayaran

    const handleChangePembayaran = (event) => {
        setPembayaran(event.target.value);
    };
    // radio metode pengiriman
    

    const handleChangePengiriman = (event) => {
        setPengiriman(event.target.value);
    };
    // radio metode pembeli
    const [pembeli, setPembeli] = React.useState();
    // level_buyer
    const levelAvailable = dataMitraCurrent?.mitra_level

    let deptLevel = [];
    // let test = []
    for (var k = levelAvailable; k <= dataMitraLevel?.depth; k++) {
        // deptLevel.push(i.toString());
        deptLevel.push({ level: dataMitraLevel?.custom_name?.[k], no: k })
        // setLevelMitra(i)
    }
    // const [level_mitra, setLevelMitra] = useState();
    // useEffect(() => {
    //     if (form?.level === 'distributor') {
    //         setLevelMitra(1)
    //     } else if (form?.level === 'nibras house') {
    //         setLevelMitra(2)
    //     } else if (form?.level === 'agen') {
    //         setLevelMitra(3)
    //     } else if (form?.level === 'reseller') {
    //         setLevelMitra(4)
    //     }
    // }, [form?.level])
    const handleChangePembeli = (event) => {
        setPembeli(event.target.value);
    };
    // console.log(form?.product)
    // load pricing
    const [hargaPricing, setHargaPricing] = useState()
    useEffect(() => {
        try {
            setSubmitting(true)
            async function CekPricing() {
                if (form?.variant && form?.level?.no) {
                    const loadRecords = functions.httpsCallable('pricing-loadRecords');

                    const body = JSON.stringify({
                        item: { products: form?.product, variants: [form?.variant], id: form?.product?.id },
                        pricing_procedure,
                        pricing_conditions,
                        level: { level: dataMitraCurrent?.mitra_level, buyer_level: form?.level?.no },
                        profil: { id: company_id },
                        location: 'manual'
                    })


                    const result = await loadRecords({
                        body
                    });
                    // console.log(result)
                    setHargaPricing(result)
                    return result?.data ?? {};
                }
            };
            CekPricing()
            setSubmitting(false)
        } catch (e) {

        }
    }, [form?.variant, form?.level?.no])

    // cek stok
    const [stok, setStok] = useState()

    useEffect(() => {
        async function getStok() {
            if (form?.variant) {
                const stokDoc = await prodStokQuery.doc(`${form?.variant?.product_id}_${form?.variant?.variant_id}`).get()
                if (stokDoc.exists) {
                    const dataStok = stokDoc.data()
                    setStok(dataStok?.stok_available)
                }
            }
        }
        getStok()
    }, [form?.variant])
    // console.log(stok)
    const handleSubmit = async e => {
        e.preventDefault();
        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const prdStocDoc = firestore.doc(`mitra/${company_id}/carts/${company_id}`);
                const pricing = hargaPricing?.data?.priceslocal?.[0] ? hargaPricing?.data?.priceslocal?.[0] : form?.variant?.harga_normal
                const hargaTagihan = pricing * form?.qty
                const potongan = form?.variant?.harga_normal - hargaPricing?.data?.priceslocal?.[0]
                // console.log('SUBDOMAIN', subdomain)
                if (state === false) {
                    await prdStocDoc.set({
                        subcarts:
                        {
                            [alamat?.id ? alamat?.id : 'alamat']:
                            {
                                berat_total: (form?.variant?.berat_paket * form?.qty),
                                height: form?.variant?.tinggi_paket,
                                length: form?.variant?.panjang_paket,
                                width: form?.variant?.lebar_paket,
                                total_pembayaran: hargaTagihan,
                                total_tagihan: hargaTagihan,
                                harga_normal : form?.variant?.harga_normal,
                                products:
                                {
                                    [`${form?.product?.product_id}_${form?.variant?.variant_id}`]: {
                                        created_at: FieldValue.serverTimestamp(),
                                        jumlah: form?.qty, nama: form?.product?.nama,
                                        product: form?.product?.product_id,
                                        promo: false,
                                        harga: hargaTagihan,
                                        berat: (form?.variant?.berat_paket * form?.qty),
                                        harga_potongan : potongan ? potongan : 0,
                                    }
                                },
                            }
                        },
                        kode_order: `m_${subdomain?.[0]}_${pembeli}_${Date.now()}`,
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp(),
                        metode_pembayaran: pembayaran,
                        metode_pengiriman: pengiriman,
                        buyer_level: pembeli === 'mitra' ? form?.level?.no : pembeli,
                        detail_buyer: { 
                            nama: form?.nama ? form?.nama : 'mitra',
                            no_hp: form?.no_hp ? `62${form?.no_hp}` : '-' 
                        }
                    })
                } else if (state === true){
                    await prdStocDoc.set({
                        subcarts:
                        {
                            [alamat?.id ? alamat?.id : 'alamat']:
                            {
                                berat_total: (form?.variant?.berat_paket * form?.qty),
                                height: form?.variant?.tinggi_paket,
                                length: form?.variant?.panjang_paket,
                                width: form?.variant?.lebar_paket,
                                total_pembayaran: hargaTagihan,
                                total_tagihan: hargaTagihan,
                                harga_normal : form?.variant?.harga_normal,
                                harga_potongan : potongan,
                                products:
                                {
                                    [`${form?.product?.product_id}_${form?.variant?.variant_id}`]: { 
                                        created_at: FieldValue.serverTimestamp(),
                                        jumlah: form?.qty,
                                        nama: form?.product?.nama,
                                        product: form?.product?.product_id,
                                        promo: false,
                                        harga: hargaTagihan,
                                        berat: (form?.variant?.berat_paket * form?.qty) 
                                    }
                                },
                                biaya_pengiriman: 0,
                                biaya_pengiriman_before: 0,
                                resi_otomatis_mp: true,
                                resi_otomatis_mp_kurir: form?.kurir,
                                resi_otomatis_mp_resi: form?.resi
                            }
                        },
                        kode_order: `m_${subdomain?.[0]}_${pembeli}_${Date.now()}`,
                        created_at: FieldValue.serverTimestamp(),
                        updated_at: FieldValue.serverTimestamp(),
                        buyer_level: pembeli === 'mitra' ? form?.level?.no : pembeli,
                        detail_buyer: { 
                            nama: form?.nama ? form?.nama : 'mitra',
                            no_hp: form?.no_hp ? `62${form?.no_hp}` : '-' 
                        }
                    })
                }

                handleClose()
                enqueueSnackbar('Order ditambahkan', { variant: "success" })
                history.replace("/orders/cartOrderManual");

            } catch (e) {
                const newError = {};
                enqueueSnackbar(`Order gagal ditambahkan,${e.message}`, { variant: "error" })
                // console.log(e.message)
                newError.title = e.message;
                setError(newError);
            }
            setSubmitting(false)
        }
    }

    // addAlamat
    const [dialog, setDialog] = useState({
        open: false,
        data: {},
    });

    // 
    // console.log(variantFilter)

    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullScreen
        >
            <DialogTitle>Tambah Order</DialogTitle>
            <DialogContent dividers>
                {/* {
                    prodIdSet?.map?.((prod) => {
                        return <GetIdProd prodId={prod?.productId} setLoadData={setLoadData} />
                    })
                } */}
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Virtualize
                            value={prodFilter}
                            data={form?.product}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="variant"
                            name="variant"
                            margin="normal"
                            required
                            value={form?.variant || ''}
                            onChange={(event, value) => {
                                handleChange({ target: { name: "variant", value } });
                            }}
                            disabled={isSubmitting}
                            options={variantFilter || ''}
                            getOptionLabel={(option) => `${option?.variant1?.nilai ?
                                `${option?.variant1?.nilai}_${option?.variant2?.nilai}`
                                : ''}`}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option?.variant1?.label}:{option?.variant1?.nilai} {option?.variant2?.label}:{option?.variant2?.nilai}
                                </React.Fragment> || ''
                            )}
                            renderInput={(params) => (
                                <TextField
                                    helperText={error?.variant}
                                    error={error?.variant ? true : false}
                                    {...params}
                                    label="Variant"
                                    variant="outlined"

                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="qty"
                            name="qty"
                            type='number'
                            label="QTY"
                            onChange={handleChange}
                            error={error.qty ? true : false}
                            helperText={error.qty}
                            fullWidth
                            variant="outlined"
                        />

                    </Grid>
                    <Grid item xs={12}>
                        {
                            form?.qty > stok &&
                            <Typography color='error'>*Stok Tidak Mencukupi</Typography>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel component="legend">Metode Pembayaran</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={pembayaran} onChange={handleChangePembayaran}>
                            <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                            <FormControlLabel value="doku" control={<Radio />} label="Doku" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel component="legend">Metode Pengiriman</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={pengiriman} onChange={handleChangePengiriman}>
                            {pembayaran === 'cash' ? <FormControlLabel value="pickup" control={<Radio />} label="Pickup" /> : null}
                            {!HideEkspedisi && pembayaran !== 'cash' ?
                                <FormControlLabel value="ekspedisi" control={<Radio />} label="Ekspedisi" />
                                : null}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel component="legend">Detail Pembeli</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={pembeli} onChange={handleChangePembeli}>
                            <FormControlLabel value="mitra" control={<Radio />} label="Mitra" />
                            {
                                pembeli === 'mitra' && <>
                                    <Autocomplete
                                        id="level"
                                        name="level"
                                        margin="normal"
                                        required
                                        value={form?.level || ''}
                                        onChange={(event, value) => {
                                            handleChange({ target: { name: "level", value } });
                                        }}
                                        // disabled={loadingStok || loadingVariants || cekLoad}
                                        options={deptLevel || ''}
                                        getOptionLabel={(option) => option?.level || ''}
                                        renderInput={(params) => (
                                            <TextField
                                                helperText={error?.level}
                                                error={error?.level ? true : false}
                                                {...params}
                                                label='Level'
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    {form?.level &&  
                                    <TextField
                                        margin='normal'
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="nama"
                                        name="nama"
                                        label="Nama Penerima"
                                        value={form?.nama}
                                        onChange={handleChange}
                                        error={error.nama ? true : false}
                                        helperText={error.nama}
                                        fullWidth
                                        variant="outlined"
                                    />}
                                    <TextField
                                        // margin='normal'
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="no_hp"
                                        name="no_hp"
                                        label="No Hp"
                                        type='number'
                                        value={form?.no_hp}
                                        onChange={handleChange}
                                        error={error.no_hp ? true : false}
                                        helperText={error.no_hp}
                                        fullWidth
                                        variant="outlined"
                                        style={{marginTop:10}}
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                +62
                                                </InputAdornment>,
                                        }}
                                        // inputProps={{ maxLength: 12}}
                                    />
                                </>
                            }
                            <FormControlLabel value="consumer" control={<Radio />} label="Consumer" />
                            {
                                pembeli === 'consumer' &&
                                <>
                                    <TextField
                                        margin='normal'
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="nama"
                                        name="nama"
                                        label="Nama"
                                        value={form?.nama}
                                        onChange={handleChange}
                                        error={error.nama ? true : false}
                                        helperText={error.nama}
                                        fullWidth
                                        variant="outlined"
                                    />
                                    <TextField
                                        // margin='normal'
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                        id="no_hp"
                                        name="no_hp"
                                        label="No Hp"
                                        type='number'
                                        value={form?.no_hp}
                                        onChange={handleChange}
                                        error={error.no_hp ? true : false}
                                        helperText={error.no_hp}
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                +62
                                                </InputAdornment>,
                                        }}
                                        // inputProps={{ maxLength: 10}}
                                    />
                                </>
                            }
                        </RadioGroup>
                    </Grid>
                    {
                        pengiriman !== 'pickup' &&
                        <Grid>
                            <Button
                                onClick={(event) => {
                                    setDialog((dialog) => ({
                                        ...dialog,
                                        // dataProd: form?.variant2,
                                        open: true,
                                        // mitra_id: company_id,
                                        // mitra_asal: mitraAsal,
                                        // jumlah: jumlah,
                                        // formKurir: ongkir,
                                        // isSubmitting: { isSubmitting }
                                    }));
                                }}
                                variant="outlined"
                                color="secondary"
                            >{alamat ? 'Ubah Alamat' : 'Tambah Alamat'}

                            </Button>
                            <AddAlamatOrder
                                dialog={dialog}
                                handleClose={() => {
                                    setDialog({ open: false, data: {} });
                                }}
                                setalamat={setalamat}
                            />
                        </Grid>
                    }
                </Grid>
                {alamat &&
                    <Grid style={{ marginTop: 24 }}>
                        <Typography color='secondary'>{alamat?.[alamat?.provider]?.alamat_lengkap},{alamat?.[alamat?.provider]?.kelurahan?.name},{alamat?.[alamat?.provider]?.kecamatan?.name},{alamat?.[alamat?.provider]?.kota?.name},{alamat?.[alamat?.provider]?.provinsi?.name}</Typography>
                    </Grid>
                }
                {/* <Grid item xs={12} style={{marginTop : "20px"}}>
                    <Typography>Resi Otomatis Marketplace</Typography>
                    <Switch
                        checked={state}
                        onChange={handleChangeSwicth}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {
                        state === true &&
                        <Grid container>
                            <Grid item xs={2}>
                                <Autocomplete
                                    style={{ marginRight: 16 }}
                                    id="kurir"
                                    name="kurir"
                                    margin="normal"
                                    required
                                    value={form?.kurir || ''}
                                    onChange={(event, value) => {
                                        handleChange({ target: { name: "kurir", value } });
                                    }}
                                    // disabled={loadingVar || loadingVar2}
                                    options={pilihKurir || ''}
                                    // getOptionLabel={(option) => option?.kurir?.nilai || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            helperText={error?.kurir}
                                            error={error?.kurir ? true : false}
                                            {...params}
                                            label='Kurir'
                                            variant="outlined"
                                        />
                                    )}
                                /></Grid>
                            <Grid item xs={2}>
                                <TextField
                                    // style={{ marginLeft: 16 }}
                                    // margin="normal"
                                    variant='outlined'
                                    label='No Resi'
                                    id='resi'
                                    name='resi'
                                    value={form?.resi}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid> */}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button

                    onClick={handleSubmit}
                    disabled={isSubmitting || form?.qty > stok}
                    color="primary"
                >
                    Tambah ke cart
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddOrder.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddOrder;
