import React, { useEffect, useRef, useState } from "react";
import { unixToDate } from "../../../utils/dateFormatter";
import { currency } from "../../../utils/formatter";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
// import { Print } from "./print";
import { useReactToPrint } from 'react-to-print';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrintIcon from '@material-ui/icons/Print';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDocument } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";
// import Barcode from 'react-barcode';
// import BarcodeORC from './barcodeORC';
import html2canvas from "html2canvas";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import Logo from "../../../components/Logo";

export default function PrintLabelPO({ printLabel: { order, subOrder, open, compId }, handleClose, }) {
    // console.log(order, subOrder)
    // ref
    const componentRef = useRef();

    const compDoc = firestore.doc(`mitra/${compId}`);
    const [compSnapshot] = useDocument(compDoc);
    const [compForm, setProdForm] = useState();

    const compAlamatDoc = firestore.doc(`mitra/${compId}/settings/alamat`);
    const [compAlamatSnapshot] = useDocument(compAlamatDoc);
    const [compAlamatForm, setCompAlamatForm] = useState();

    const kodeOrderSuborder = `${order?.kode_order}-${subOrder?.kode_suborder?.split("-")[2]}`

    useEffect(() => {
        if (compSnapshot) {
            const data = compSnapshot.data();
            setProdForm({
                ...data,
            });
        }
    }, [compSnapshot]);

    useEffect(() => {
        if (compAlamatSnapshot) {
            const data = compAlamatSnapshot.data();
            setCompAlamatForm({
                ...data,
            });
        }
    }, [compAlamatSnapshot]);
    // upload png
    const saveAs = (uri, filename) => {
        var link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = uri;
            link.download = filename;

            //Firefox requires the link to be in the body
            document.body.appendChild(link);

            //simulate click
            link.click();

            //remove the link when done
            document.body.removeChild(link);
        } else {
            window.open(uri);
        }
    };

    const printDocument = domElement => {
        let option = {
            height: window.outerHeight + window.innerHeight,
            windowHeight: window.outerHeight + window.innerHeight,
            scrollY: -window.scrollY
        };
        html2canvas(domElement, option).then(canvas => {
            saveAs(canvas.toDataURL(), `${value}_${order?.kode_order}.png`);
        });

        // console.log(html2canvas);
    };
    const handleDownloadImage = async () => {
        const element = componentRef.current;
        let option = {
            height: window.outerHeight + window.innerHeight,
            windowHeight: window.outerHeight + window.innerHeight,
            scrollY: -window.scrollY
        };
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.png';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };



    // print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // class Label extends React.Component {
    //     render() {
    //         return (
    //             <> <div ref={componentRef}>
    //                 <table border="1" width="370px" cellPadding="5" style={{
    //                     borderCollapse: 'collapse',
    //                     margin: '10px',
    //                     fontSize: '10px',
    //                     borderColor: 'black'
    //                 }}>
    //                     <tr>
    //                         <th align="left" width="50%" style={{ borderRight: 'none' }}>
    //                             <Logo />
    //                         </th>
    //                         <th align="right" width="50%" style={{ borderLeft: 'none' }}>
    //                             {
    //                                 subOrder?.resi_otomatis_mp === true ?
    //                                     subOrder?.resi_otomatis_mp_kurir === 'SiCepat' ?
    //                                         <img height="40px" src='https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/kurir%2Fsicepat-01.png?alt=media&token=063780a5-51c8-4972-995d-d409434ac8e5' alt='' />
    //                                         :
    //                                         <img height="40px" src='https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/kurir%2Fjne-01.png?alt=media&token=045077e5-27cc-4efd-a275-8b675e3d298c' alt='' /> :
    //                                     subOrder?.kurir?.nama === 'SiCepat' ?
    //                                         <img height="40px" src='https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/kurir%2Fsicepat-01.png?alt=media&token=063780a5-51c8-4972-995d-d409434ac8e5' alt='' />
    //                                         :
    //                                         <img height="40px" src='https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/kurir%2Fjne-01.png?alt=media&token=045077e5-27cc-4efd-a275-8b675e3d298c' alt='' />
    //                             }
    //                         </th>
    //                     </tr>

    //                     <tr>
    //                         <td colSpan="2" align="center">
    //                             {
    //                                 subOrder?.resi_otomatis_mp === true ?
    //                                     <Barcode height="50" fontSize="11" margin={3} value={subOrder?.resi_otomatis_mp_resi ? subOrder?.resi_otomatis_mp_resi : ''} format="CODE128" /> :
    //                                     subOrder?.shipping_info?.resi ? <Barcode height="50" fontSize="11" margin={3} value={subOrder?.shipping_info?.resi ? subOrder?.shipping_info?.resi : ''} format="CODE128" /> : "Belum Ada Nomor Resi"
    //                             }
    //                         </td>
    //                     </tr>
    //                     {
    //                         subOrder?.resi_otomatis_mp === true ?
    //                             null :
    //                             subOrder?.status_cod === true ? <tr>
    //                                 <td colSpan="2" align="center" style={{ fontSize: '15px', fontWeight: 'bold' }}>
    //                                     COD :  {currency(subOrder?.total_pembayaran)}
    //                                 </td>
    //                             </tr> : <tr>
    //                                 <td colSpan="2" align="center" style={{ fontSize: '15px', fontWeight: 'bold' }}>
    //                                     NON-COD
    //                                 </td>
    //                             </tr>
    //                     }
    //                     {
    //                         subOrder?.resi_otomatis_mp === true ?
    //                             null :
    //                             <tr>

    //                                 <td colSpan="2" align="center">
    //                                     Jenis Layanan : {subOrder?.kurir?.layanan ? `${subOrder?.kurir?.layanan}` : ''}
    //                                 </td>
    //                             </tr>
    //                     }

    //                     {/* <tr>
    //                         <td rowSpan="4">
    //                             {
    //                                 subOrder?.shipping_info?.kurir === 'JNE' ?
    //                                     <BarcodeORC value={subOrder?.shipping_info?.jne_orderId} /> :
    //                                     <BarcodeORC value={kodeOrderSuborder} />
    //                             }

    //                         </td>
    //                     </tr> */}
    //                     <tr>
    //                         <td colspan="2">
    //                             Asuransi : {subOrder?.asuransi_pengiriman ? `${currency(subOrder?.asuransi_pengiriman)}` : 0}
    //                         </td>
    //                     </tr>
    //                     <tr>
    //                         <td colspan="2">
    //                             Berat : {subOrder?.dimensi_paket?.wt / 1000} kg
    //                         </td>
    //                     </tr>
    //                     <tr>
    //                         <td colspan="2">
    //                             Quantity : {subOrder?.products_qty?.reduce((a, b) => a + b, 0)} pcs
    //                         </td>
    //                     </tr>
    //                     <tr>
    //                         <td>
    //                             <h4>Penerima</h4>
    //                             <p>
    //                                 <strong>{subOrder?.alamat_tujuan?.nama_penerima}</strong>
    //                                 <br />
    //                                 {subOrder?.alamat_tujuan?.alamat_lengkap ? subOrder?.alamat_tujuan?.alamat_lengkap : subOrder?.alamat_tujuan?.directapi?.alamat_lengkap},
    //                                 <br />
    //                                 {subOrder?.alamat_tujuan?.directapi?.kelurahan?.name || ''},
    //                                 {subOrder?.alamat_tujuan?.directapi?.kecamatan?.name || ''},
    //                                 {subOrder?.alamat_tujuan?.directapi?.kota?.name || ''},
    //                                 {subOrder?.alamat_tujuan?.directapi?.provinsi?.name || ''}  - {subOrder?.alamat_tujuan?.directapi?.kelurahan?.postal_code}<br />
    //                                 {
    //                                     subOrder?.shipping_info?.kurir === 'JNE' ?
    //                                         subOrder?.alamat_tujuan?.directapi?.kelurahan?.kode_destination_jne : null
    //                                 }
    //                                 <br /> <br />
    //                                 {subOrder?.alamat_tujuan?.no_telepon || ''}
    //                             </p>
    //                         </td>
    //                         <td>
    //                             <h4>Pengirim</h4>
    //                             <p>
    //                                 <strong>{compForm?.nama}</strong>
    //                                 <br />
    //                                 {compAlamatForm?.directapi?.alamat_lengkap},
    //                                 <br />
    //                                 {compAlamatForm?.directapi?.kelurahan?.name},
    //                                 {compAlamatForm?.directapi?.kecamatan?.name},
    //                                 {compAlamatForm?.directapi?.kota?.name},


    //                                 {compAlamatForm?.directapi?.provinsi?.name} - {compAlamatForm?.directapi?.kelurahan?.postal_code}<br />
    //                                 {
    //                                     subOrder?.shipping_info?.kurir === 'JNE' ?
    //                                         compAlamatForm?.directapi?.kelurahan?.kode_destination_jne : null
    //                                 }
    //                                 <br /> <br />
    //                                 {compForm?.telepon || 'No Hp'}
    //                             </p>
    //                         </td>
    //                     </tr>
    //                     <tr>
    //                         <td colSpan="2">
    //                             {
    //                                 subOrder?.shipping_info?.kurir === 'JNE' ?
    //                                     "Deskripsi barang :" : "Catatan :"
    //                             }

    //                             {subOrder?.shipping_info?.kurir === 'JNE' && subOrder.products ? subOrder.products?.map?.((val, i) => {

    //                                 return <><p key={val}>{i + 1}. {val?.kategori2.nama} {val?.kategori3.nama} {val?.nama} - {val?.product_id}
    //                                 </p></>;
    //                             }) : ''}
    //                         </td>
    //                     </tr>
    //                     {/* {
    //                     options.fragile &&
    //                     <tr>
    //                         <td colSpan="2" align="center">
    //                             <img height="100px" alt="" src="https://firebasestorage.googleapis.com/v0/b/nibrasplay-dev.appspot.com/o/kurir%2Ffragile-01.png?alt=media&token=3cbf69a0-8301-489b-a73d-8026e9fa1c59" />
    //                         </td>
    //                     </tr>
    //                 } */}
    //                 </table>
    //             </div>
    //             </>
    //         );
    //     }
    // }

    class PrintInvoice extends React.PureComponent {
        render() {
            return (
                <div style={{ border: "1px solid black", padding: 10, marginTop: 15, marginBottom: 10 }} ref={componentRef}>
                    <table width="100%" border="0" cellSpacing="0" fontSize="12px;" >
                        <tbody>
                            <tr style={{ margin: 0, }}>
                                <td colSpan="6" style={{ margin: 0, }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
                                        {
                                            compForm?.logo &&
                                            <div style={{ width: 64, marginRight: 10, }}>
                                                <img alt='logo' style={{ marginRight: 10, width: 64 }} src={compForm?.logo} />
                                            </div>
                                        }
                                        <div>
                                            <h3 style={{ padding: 0, margin: '0.5em 0 0' }}>{compForm?.nama}</h3>
                                            <p style={{ marginTop: 0, fontSize: 12, wordWrap: 'break-word' }}>{compForm?.deskripsi}</p>
                                        </div>
                                    </div>
                                </td>

                            </tr>

                            <tr style={{ margin: 0, padding: 20 }}>
                                <td colSpan="2">
                                    <p style={{ lineHeight: '18px', margin: 0, padding: '20px 0 0' }}>
                                        <strong>
                                            <span style={{ margin: '0 0 9px', display: 'block', }}>Kepada</span>
                                            <span style={{ textTransform: 'capitalize', width: 300, wordWrap: 'break-word', display: 'block', marginBottom: 7, }}>{subOrder?.alamat_tujuan?.nama_penerima}</span>
                                        </strong>
                                    </p>
                                    <p style={{ fontSize: 12, lineHeight: '1.25em', wordWrap: 'break-word', marginTop: 0, }}>
                                        {subOrder?.alamat_tujuan?.alamat_lengkap ? subOrder?.alamat_tujuan?.alamat_lengkap : subOrder?.alamat_tujuan?.directapi?.alamat_lengkap},
                                        {subOrder?.alamat_tujuan?.directapi?.kota?.name || ''}, {subOrder?.alamat_tujuan?.directapi?.kecamatan?.name || ''},
                                        <br />
                                        {subOrder?.alamat_tujuan?.directapi?.provinsi?.name || ''}
                                        <br />
                                        {subOrder?.alamat_tujuan?.no_telepon || ''}
                                    </p>
                                </td>
                                <td colSpan="3">
                                    <h5 style={{ padding: 0, margin: '0.5em 0' }}>
                                        <strong style={{ fontSize: 15 }}>Tanggal:</strong>
                                        <span style={{ clear: 'both', display: 'block', fontWeight: 'normal' }}>{unixToDate(order.created_at?.toMillis())}</span>
                                    </h5>
                                    <h5 style={{ padding: 0, margin: '0.5em 0' }}>
                                        <strong style={{ fontSize: 15 }}>Nomor Invoice:</strong>
                                        <span style={{ clear: 'both', display: 'block', fontWeight: 'normal' }}>{order?.kode_order}</span>
                                    </h5>
                                </td>

                                <td colSpan="3" style={{ fontSize: '0.85rem', textAlign: 'center', }}>
                                    {
                                        order.status_pembayaran === 'paid' ?
                                            <strong><span style={{ color: '#00C853' }}>PAID</span> {order.paid_at ? `(${unixToDate(order.paid_at?.toMillis())})` : ''}</strong>

                                            :
                                            <strong><span style={{ color: '#E45864' }}>UNPAID</span> </strong>
                                    }

                                </td>

                            </tr>

                            <tr>
                                <td colSpan="12">
                                    <div style={{ display: 'flex', alignItems: 'center', borderTop: 'solid 1px #ddd', borderBottom: 'solid 1px #ddd', padding: '5px 15px', justifyContent: 'space-between', marginBottom: 15, }}>
                                        <h2 style={{ margin: '13px 0' }}>Total Invoice</h2>
                                        <p style={{ fontSize: '28px' }}>
                                            {currency(subOrder?.total_pembayaran)}
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr style={{ margin: 0, background: '#555', lineHeight: '1em', fontSize: 12, color: '#fff' }}>
                                <td colSpan="2" style={{ padding: '10px 20px', width: '20%' }}>Produk</td>
                                <td colSpan="2" style={{ padding: '10px 20px', width: '25%' }}>variant</td>
                                <td align="center" style={{ padding: '10px 20px', width: '15%', }}></td>
                                <td align="center" style={{ padding: '10px 20px', width: '10%', }}> <span style={{}}>Qty</span> </td>
                                <td align="center" style={{ padding: '10px 20px', width: '15%', }}></td>
                                <td style={{ padding: '10px 20px', width: '15%' }}>Subtotal</td>
                            </tr>

                            {/* {
                            order.items.map((item) => { */}
                            {/* // let hargaDiskon = 0;
                                // let hargaNoDiskon = 0;
                                // const itemDiskon = countItemDiskon(item, item.created_at, pelanggan, diskon.items);
                                // const { hargaDiskon, hargaNoDiskon } = itemDiskon;
                                //console.log(item, itemDiskon)
                                // if (item.varian) { */}
                            {/* //     if (pelanggan.tipe === 'reseller') { */}
                            {/* //         hargaDiskon = item.varian.harga_reseller - (item.varian.harga_reseller * item.varian.diskon / 100);
                                //         hargaNoDiskon = item.varian.harga_reseller;
                                //     }
                                //     else if (pelanggan.tipe === 'distributor') { */}
                            {/* //         hargaDiskon = item.varian.harga_distributor - (item.varian.harga_distributor * item.varian.diskon / 100);
                                //         hargaNoDiskon = item.varian.harga_distributor;
                                //     }
                                //     else if (pelanggan.tipe === 'agen') {
                                //         hargaDiskon = item.varian.harga_agen - (item.varian.harga_agen * item.varian.diskon / 100);
                                //         hargaNoDiskon = item.varian.harga_agen;
                                //     }
                                //     else {
                                //         hargaDiskon = item.varian.harga_normal - (item.varian.harga_normal * item.varian.diskon / 100);
                                //         hargaNoDiskon = item.varian.harga_normal;
                                //     }
    
                                // }
     */}


                            {/* // return ( */}
                            <tr key={subOrder.id} style={{ lineHeight: '1.25em', fontSize: 12, verticalAlign: 'top' }}>
                                <td colSpan="2" style={{ paddingLeft: 10, }}>

                                    {subOrder.products_name ? subOrder.products_name?.map?.((val, i) => {

                                        return <><p key={val}>{val}
                                        </p></>;
                                    }) : 'Produk Deleted'}

                                </td>
                                <td style={{ textAlign: 'center', }}>
                                    {
                                        subOrder?.products_variant1?.map?.((varsatu) => {
                                            return <><p key={varsatu}>{varsatu},</p></>
                                        })
                                    }

                                </td>
                                <td>
                                    {
                                        subOrder?.products_variant2?.map?.((vardua) => {
                                            return <><p key={vardua}>{vardua}</p></>
                                        })
                                    }
                                </td>
                                <td style={{ textAlign: 'center', }}>
                                    {/* <p> {subOrder.dimensi_paket?.wt} gr</p> */}
                                </td>
                                <td style={{ textAlign: 'center', }}>
                                    {subOrder?.products_qty?.map?.((val, i) => {
                                        return <><p key={val}>{val}</p></>;
                                    })}
                                </td>

                                <td style={{ textAlign: 'center', }}>
                                    {/* {hargaNoDiskon != hargaDiskon &&
                                                <p style={{ marginTop: 12, marginBottom: 0, }}>
                                                    <strike>{subOrder.varian ? <AppNumberFormat displayType="text" value={hargaNoDiskon} /> : 'Deleted'}</strike>
                                                </p>} */}
                                    {/* {
                                        subOrder?.products_qty?.map?.((prod, idx) => {
                                            const hargaSatu = subOrder.products.filter(
                                                // eslint-disable-next-line array-callback-return
                                                (currVal, i) => {
                                                    if (idx === i) {
                                                        return currVal?.variant?.harga_normal;
                                                    }
                                                }
                                            )
                                            const harga = hargaSatu?.map?.((satu) => {
                                                return satu?.variant?.harga_normal
                                            })
                                            // console.log(harga)
                                            return <><p>{prod} x {currency(harga)}</p></>

                                        })
                                    } */}
                                    {/* <p>{currency(subOrder?.total_tagihan)}</p> */}
                                </td>

                            </tr>
                            {/* //         );
                        //     })
                        // } */}

                            <tr style={{ lineHeight: '1.25em', fontSize: 12 }}>
                                <td colSpan="2" style={{ paddingLeft: 10, }}>
                                    <p style={{ marginTop: 0, }}>
                                        <strong>
                                            {subOrder?.kurir?.nama?.replace('custom-', '').toUpperCase()}{subOrder?.kurir?.layanan ? `-${subOrder?.kurir?.layanan}` : ''}
                                        </strong>
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: 'center', }}>
                                    <p style={{ marginTop: 0, }}>{subOrder?.dimensi_paket?.wt} gr</p>
                                </td>
                                <td style={{ textAlign: 'center', }}>
                                    <p style={{ marginTop: 0, }}>
                                        {currency(subOrder?.biaya_pengiriman)}
                                    </p>
                                </td>
                                <td></td>

                            </tr>
                            {/* <tr style={{ lineHeight: '1.25em', fontSize: 12 }}>
                            <td colSpan="5" style={{ paddingLeft: 10, }}>
                                <p style={{ marginTop: 0, }}>Asuransi</p>
                            </td>
                            <td style={{ textAlign: 'center', }}>
                                <p style={{ marginTop: 0, }}>
                                    <AppNumberFormat value={order.biaya_asuransi ? order.biaya_asuransi : '0'} displayType="text" />
                                </p>
                            </td>
                        </tr> */}
                            <tr style={{ lineHeight: '1.25em', fontSize: 12 }}>
                                <td colSpan="5" style={{ paddingLeft: 10, }}>
                                    <p style={{ marginTop: 0, }}>Biaya Tambahan</p>
                                </td>
                                <td></td>
                                <td style={{ textAlign: 'center', }}>
                                    <p style={{ marginTop: 0, }}>
                                        {subOrder.biaya_tambahan ? currency(subOrder.biaya_tambahan) : 'Rp.0'}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ lineHeight: '1.25em', fontSize: 12 }}>
                                <td colSpan="5" style={{ paddingLeft: 10, }}>
                                    <p style={{ marginTop: 0, }}>Total Tagihan</p>
                                </td>
                                <td></td>
                                <td style={{ textAlign: 'center', }}>
                                    <p style={{ marginTop: 0, }}>
                                        {currency(subOrder.total_tagihan)}
                                    </p>
                                </td>
                            </tr>
                            {/* {extraDiskon > 0 && <tr style={{ lineHeight: '1.25em', fontSize: 12 }}>
                            <td colSpan="5" style={{ paddingLeft: 10, }}>
                                <p style={{ marginTop: 0, }}>Extra Diskon</p>
                            </td>
                            <td style={{ textAlign: 'center', }}>
                                <p style={{ marginTop: 0, }}>
                                    - <AppNumberFormat value={extraDiskon} displayType="text" />
                                </p>
                            </td>
                        </tr>} */}
                            <tr style={{ lineHeight: '2em', fontSize: 12 }}>
                                <td colSpan="5" style={{ paddingLeft: 10, }}>
                                    <span style={{ fontWeight: 700, fontSize: '0.9rem' }}>TOTAL</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: 'center', }}>
                                    <span style={{ fontWeight: 700, fontSize: '0.9rem' }}>
                                        {currency(subOrder?.total_pembayaran)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table >
                </div>
            );
        }
    }

    // // buat print Picking List yg perlu ditampilkan info produk + variant, qty, sama alamat rak (ada di variant)
    // class PickingList extends React.PureComponent {
    //     render() {
    //         return (

    //             <div style={{ border: "1px solid #ddd", margin: '0 0 1px', overflow: 'hidden' }} ref={componentRef}>
    //                 <div style={{ width: '100%', position: 'relative', minHeight: 150 }}>

    //                     <tr style={{ margin: 0, background: '#555', lineHeight: '1em', fontSize: 12, color: '#fff' }}>
    //                         <td align="center" style={{ padding: '10px 20px', width: '15%' }}>Tgl.Order</td>
    //                         <td align="center" style={{ padding: '10px 20px', width: '10%' }}>Kode Order</td>
    //                         <td align="center" style={{ padding: '10px 20px', width: '10%' }}>Produk</td>
    //                         {/* <td align="center" style={{ padding: '10px 20px', width: '15%', }}>  </td> */}
    //                         <td align="center" style={{ padding: '10px 20px', width: '10%' }}>variant</td>
    //                         <td align="center" style={{ padding: '10px 20px', width: '10%' }}></td>
    //                         <td align="center" style={{ padding: '10px 20px', width: '15%', }}> Qty </td>
    //                         <td align="center" style={{ padding: '10px 20px', width: '15%', }}>Alamat Rak</td>
    //                     </tr>


    //                     <tr key={subOrder.id} style={{ lineHeight: '1.25em', fontSize: 12, verticalAlign: 'top' }}>
    //                         <td style={{ textAlign: 'center', }}>
    //                             <p style={{ marginBottom: 0, height: 80, margin: 5 }}>{unixToDate(order.created_at?.toMillis())}</p>
    //                         </td>
    //                         {/* <td style={{ textAlign: 'center', }}>
    //                             <p style={{ marginBottom: 0, height: 80, margin: 5 }}>{order?.kode_order}</p>
    //                             <BarcodeORC value={order?.kode_order} />

    //                         </td> */}
    //                         <td style={{ paddingLeft: 10, }}>

    //                             {subOrder.products_name ? subOrder.products_name?.map?.((val, i) => {

    //                                 return <>
    //                                     <Barcode value={val} width='1' format="CODE128" fontSize='10' />
    //                                 </>;
    //                             }) : 'Produk Deleted'}

    //                         </td>


    //                         <td style={{ textAlign: 'center', }}>
    //                             {
    //                                 subOrder?.products_variant1?.map?.((varsatu) => {
    //                                     return <><p key={varsatu} style={{
    //                                         height: 80, margin: 5, marginBottom: 60
    //                                     }}>{varsatu},</p></>
    //                                 })
    //                             }

    //                         </td>
    //                         <td>
    //                             {
    //                                 subOrder?.products_variant2?.map?.((vardua) => {
    //                                     return <><p key={vardua} style={{
    //                                         height: 80, margin: 5, marginBottom: 60
    //                                     }}>{vardua}</p></>
    //                                 })
    //                             }
    //                         </td>

    //                         <td style={{ textAlign: 'center', }}>
    //                             {subOrder?.products_qty?.map?.((val, i) => {
    //                                 return <><p key={val} style={{
    //                                     height: 80, margin: 5, marginBottom: 60
    //                                 }}>{val}</p></>;
    //                             })}
    //                         </td>

    //                         <td style={{ textAlign: 'center', }}>

    //                             {
    //                                 subOrder?.products?.map?.((prod) => {

    //                                     return <><p key={prod} style={{
    //                                         height: 80, margin: 5, marginBottom: 60
    //                                     }}>{prod?.variant?.alamat_rak}</p></>

    //                                 })
    //                             }
    //                         </td>

    //                     </tr>


    //                 </div>
    //             </div>

    //         );
    //     }
    // }

    const [value, setValue] = React.useState('invoice');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    // // INV ORDERS
    const PrintInvoices = () => {
        // console.log(componentRef?.current)
        return (
            <div>
                <PrintInvoice />
            </div>
        );
    };

    // const Print = () => {
    //     return (
    //         <Label />
    //     );
    // };

    // const PrintPicking = () => {
    //     return (
    //         <div>
    //             <PickingList />
    //         </div>
    //     );
    // };
    // console.log(subOrder)

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='lg'
        >
            <DialogTitle>Print Label
                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange} style={{ display: 'inline', marginLeft: 20 }}>
                    <FormControlLabel value="invoice" control={<Radio color='primary' />} label="Invoice" />
                    
                    
                    {/* <FormControlLabel value="label" control={<Radio color='primary' />} label="Label" />
                    <FormControlLabel value="pickingList" control={<Radio color='primary' />} label="Picking List" /> */}
                </RadioGroup>
            </DialogTitle>
            <DialogContent dividers >
                {/* <Label /> */}
                {
                    value === 'invoice' &&
                    <> <PrintInvoices />


                    </>
                }

                {/* {
                    value === 'label' &&
                    <Container>
                        <Print />
                    </Container>
                } */}
                {/* {
                    value === 'pickingList' &&
                    <PrintPicking />
                } */}
                {/* <App /> */}
            </DialogContent>
            <DialogActions style={{ width: '100%', float: 'right', padding: '30px 10px', }}>

                <Button
                    fullWidth
                    onClick={handlePrint}
                    variant="contained"
                    color='primary'
                    startIcon={<PrintIcon />}
                >Cetak</Button>

                {/* <Button
                    fullWidth
                    onClick={handleDownloadImage}
                    variant="contained"
                    color='primary'
                    startIcon={<PrintIcon />}
                >Download</Button> */}
                <Button
                    onClick={handleClose}
                    fullWidth
                    variant='outlined'
                    startIcon={<CancelIcon />}
                >Batal</Button>

            </DialogActions>
        </Dialog>
    );
}