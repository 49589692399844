import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore, } from "../../../components/FirebaseProvider";
import PageLoading from "../../../components/loading/pageLoading";
import Typography from '@material-ui/core/Typography';
import useStyles from "./styles";
import { useData } from "../../../components/DataProvider";

export default function PhonesSeller({ users_id }) {
    const classes = useStyles();
    const { company_id } = useData();

    const phonesCol = firestore.collection(`mitra/${company_id}/sellers_login`).where('user_id', '==', users_id);

    const [dataPhones, loadingDataPhones] = useCollectionData(phonesCol, { idField: 'id' })
    const wa = dataPhones?.map?.((phones) => {
        return phones?.id
    })
    // console.log(wa)
    if (loadingDataPhones) {
        return <PageLoading />
    }
    return <Typography className={classes.rowValue}>: {wa}</Typography>
}

