import { useState } from "react";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import TextField from '@material-ui/core/TextField';

// typesense
import {
    InstantSearch,
    connectSearchBox,
    connectHits,
    connectHighlight,
    Configure,
    connectInfiniteHits
} from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { host, indexName, searchKey } from '../../../../config/typesense';


function SearchBox({ currentRefinement, refine }) {
    // const { uid } = useData();

    // const history = useHistory();

    // const viewCart = () => {
    //   analytics.logEvent("view_cart", { user_id: uid });

    //   history.push("/cart");
    // };

    return (
        <TextField
            variant='outlined'
            // type="with-back"
            label="Cari Produk"
            // onClick={viewCart}
            value={currentRefinement}
            onChange={(e) => refine(e.target.value)}
        />
    );
}
const ConnectSearchBox = connectSearchBox(SearchBox);



function Highlight({ hit, highlight }) {
    const highlightsProductName = highlight({
        highlightProperty: "_highlightResult",
        attribute: "nama",
        hit,
    });

    const highlightsDeskripsiSingkat = highlight({
        highlightProperty: "_highlightResult",
        attribute: "deskripsi_singkat",
        hit,
    });
    console.log(hit)
    // const history = useHistory();

    // const { store } = useParams();

    // const toDetailProduct = (id, title) => {
    //   analytics.logEvent("view_product", { name: title, id: id });

    //   history.push(`/${store}/product/${slugify(title.toLowerCase())}/${id}`);
    // };

    return (
        <>
            <ListItem
                alignItems="flex-start"
                button
            //   onClick={() => toDetailProduct(hit.produkId, hit.nama)}
            >
                <ListItemAvatar>
                    <Avatar
                        alt={hit.nama}
                        src={
                            hit.thumbnail ? hit.thumbnail : "https://via.placeholder.com/40"
                        }
                        variant="rounded"
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            {highlightsProductName.map(({ value, isHighlighted }, index) => {
                                const style = {
                                    fontWeight: isHighlighted ? "bold" : "normal",
                                };

                                return (
                                    <span key={index} style={style}>
                                        {value}
                                    </span>
                                );
                            })}
                            <span> - </span>
                            <span>{hit.brand_nama}</span>
                        </>
                    }
                    secondary={
                        <>
                            {highlightsDeskripsiSingkat.map(
                                ({ value, isHighlighted }, index) => {
                                    const style = {
                                        fontWeight: isHighlighted ? "bold" : "normal",
                                    };

                                    return (
                                        <span key={index} style={style}>
                                            {value}
                                        </span>
                                    );
                                }
                            )}
                        </>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
        </>
    );
}

const ConnectedHighlight = connectHighlight(Highlight);


function Hits({ hits }) {
    return (
        <List style={{ width: "100%" }}>
            {hits.length > 0 ? (
                hits.map((item, index) => {
                    return <ConnectedHighlight key={index} attribute="nama" hit={item} />;
                })
            ) : (
                <p>Produk tidak ditemukan</p>
            )}
        </List>
    );
}

const ConnectedHits = connectInfiniteHits(Hits);

export default function SeacrhProduk() {
    const [searchState, setSearchState] = useState({});

    // typesenes
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: searchKey,
            nodes: [
                {
                    host: host,
                    port: "443",
                    protocol: "https",
                },
            ],
        },
        additionalSearchParameters: {
            queryBy:
                "nama, deskripsi_singkat, brand_nama, kategori1_nama, kategori2_nama, kategori3_nama, variant1_label, variant1_nilai, variant2_label, variant2_nilai, sku",
            groupBy: "produkId",
            groupLimit: 1,
            typo_tokens_threshold: 3,
            //   filterBy: `produkId:[${filter
            //     .toString()
            //     .replaceAll(",", " ")
            //     .replaceAll("|", ",")}]`,
        },
    });


    const searchClient = typesenseInstantsearchAdapter.searchClient;


    return <InstantSearch
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={setSearchState}
        indexName={indexName}
    >
        <Configure />
        <ConnectSearchBox />
        {/* <Gap height={60} /> */}
        <div >
            <ConnectedHits />
        </div>
    </InstantSearch>
}

