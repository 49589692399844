import React, { useEffect, useState } from 'react';
import TabPanel from '../../../components/TabPanel';
import { useDocument } from 'react-firebase-hooks/firestore';
import { firestore, } from '../../../components/FirebaseProvider';
import PageLoading from '../../../components/loading/pageLoading';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useData } from '../../../components/DataProvider';
import TextField from "@material-ui/core/TextField";


export default function Fitur({ value }) {
    const { company_id, dataMitraCurrent , hideSetting } = useData();

    const [hidePoint, setHidePoint] = useState(hideSetting.data().hidePicingpoint)

    const settDoc = firestore.doc(`mitra/${company_id}/settings/fitur`);
    const [settSnapshot, settLoading] = useDocument(settDoc);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [form, setForm] = useState({
        disable_order_creation: false,
        aktivasi_perhitungan_point: false,
        aktivasi_pembayaran_iris: false,
        metode_perhitungan_komisi: 'order',
        // jeda: 0,
        omset_per_1kredit: 10000
    });

    const [error, setError] = useState({})

    useEffect(() => {
        if (settSnapshot) {
            const data = settSnapshot.data()
            setForm({
                ...data,
            });
        }
    }, [settSnapshot]);

    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = async e => {
        setSubmitting(true);
        try {
            await settDoc.set({
                ...form,
            }, { merge: true });
            enqueueSnackbar('Perubahan berhasil disimpan', { variant: "success" })

        } catch (e) {
            setError(e.message)
            enqueueSnackbar(`Perubahan gagal disimpan,${e.message}`, { variant: "error" })
        }
        setSubmitting(false);
    }

    const handleChangeCheck = (event) => {
        setForm({ ...form, [event.target.name]: event.target.checked });
    };

    // const handleChangeMetodeKomisi = (event) => {
    //     setForm({ ...form, metode_perhitungan_komisi: event.target.value });
    // };

    // const handleChangeJeda = (event) => {
    //     setForm({ ...form, jeda: event.target.value });
    // };

    const handleChangeOmsetKredit = (event) => {
        setForm({ ...form, omset_per_1kredit: event.target.value });
    };

    if (settLoading) {
        return <PageLoading />
    }

    // console.log(formKomisi)
    return <TabPanel value={value} index="fitur">
        {/* <Typography className={classes.pageSubTitle} component="h1" variant="h5">Komisi</Typography> */}
        <Grid container spacing={4}>
            <Grid item lg={6}>
                <FormLabel component="legend">Non-aktifkan pesanan baru</FormLabel>
                <FormControlLabel
                    disabled={dataMitraCurrent?.isBlock === true}
                    control={<Checkbox checked={form.disable_order_creation} onChange={handleChangeCheck} name="disable_order_creation" />}
                    label="Non-aktifkan Orderan"
                />
            </Grid>
            {!hidePoint ? (
                <>
                    <Grid item lg={6}>
                        <FormLabel component="legend">Aktivasi Perhitungan Point</FormLabel>
                        <FormControlLabel
                            control={<Checkbox checked={form.aktivasi_perhitungan_point} onChange={handleChangeCheck} name="aktivasi_perhitungan_point" />}
                            label="Aktivasi Perhitungan Point"
                        />
                        <TextField
                            disabled={!form.aktivasi_perhitungan_point}
                            value={form.omset_per_1kredit}
                            className={classes.grid}
                            fullWidth
                            label="Omset belanja/point"
                            name="omset_per_1kredit"
                            variant="outlined"
                            type="number"
                            onChange={handleChangeOmsetKredit}
                        />
                    </Grid>
                </>
            ) : null}
            
            {/* <Grid item lg={4}>
                <FormLabel component="legend">Aktivasi Pembayaran Iris</FormLabel>
                <FormControlLabel
                    control={<Checkbox checked={form.aktivasi_pembayaran_iris} onChange={handleChangeCheck} name="aktivasi_pembayaran_iris" />}
                    label="Aktivasi Pembayaran Iris"
                />
            </Grid> */}
            {/* <Grid item lg={4}>
                <FormLabel component="legend">Basis Perhitungan Komisi</FormLabel>
                <RadioGroup aria-label="komisi" name="metodekomisi" value={form.metode_perhitungan_komisi} onChange={handleChangeMetodeKomisi}>
                    <FormControlLabel value="order" control={<Radio />} label="Order Dibayar" />
                    <FormControlLabel value="completed" control={<Radio />} label="Delivery Selesai" />
                </RadioGroup>

                <TextField
                    disabled={form.metode_perhitungan_komisi === 'order' ? true : false}
                    value={form.jeda}
                    className={classes.grid}
                    fullWidth
                    label="Jeda Delivery Diterima > Selesai (hari)"
                    name="jeda"
                    variant="outlined"
                    type="number"
                    onChange={handleChangeJeda}
                />
                *Note: Order dengan status_pengiriman 'diterima' akan otomatis berubah menjadi 'selesai' setelah jeda hari dan tidak ada complain.
            </Grid> */}
        </Grid>
        <Grid item xs={12} lg={1}>
            <Button
                className={classes.btnSave}
                disabled={isSubmitting}
                onClick={handleSubmit}
                size="large"
                color="primary"
                variant="contained"
                fullWidth
            > Simpan </Button>
        </Grid>

    </TabPanel>
}