import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { firestore, FieldValue } from '../../../components/FirebaseProvider';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useData } from '../../../components/DataProvider';

function AddProduct({ open, handleClose }) {
    const history = useHistory();

    const { company_id } = useData();

    const [form, setForm] = useState({
        product_reward_id: '',
        nama: '',
        deskripsi_singkat: '',
        deskripsi_lengkap: '',
        variant1_label: '',
        variant2_label: '',
    });

    // const [checked, setChecked] = useState(false);

    const [error, setError] = useState({
        produk_reward_id: '',
        nama: '',
        deskripsi_singkat: '',
        deskripsi_lengkap: '',
        variant1_label: '',
        variant2_label: ''
    });

    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        // console.log(form)

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    // const togglecheckbox = e => {
    //     setChecked(e.target.checked)
    // }
    // console.log(checked)

    const validate = async () => {
        const newError = { ...error };

        if (!form.product_reward_id) {
            newError.product_reward_id = 'Id Produk harus diisi';
        }

        if (!form.nama) {
            newError.nama = 'Nama harus diisi';
        }

        if (!form.deskripsi_singkat) {
            newError.deskripsi_singkat = 'Deskripsi harus diisi';
        }

        if (!form.deskripsi_lengkap) {
            newError.deskripsi_lengkap = 'Deskripsi harus diisi';
        }
        if (!form.variant1_label) {
            newError.variant1_label = 'variant1 label harus diisi';
        }
        if (!form.variant2_label) {
            newError.variant2_label = 'variant2 label harus diisi';
        }

        return newError;
    }

    const handleSubmit = async e => {
        e.preventDefault();

        // console.log(form);
        // history.push(`reward/edit/${form.product_reward_id}`)

        const findError = await validate();

        // console.log(findError)
        if (Object.values(findError).some(m => m !== "")) {
            setError(findError)
        } else {
            setSubmitting(true)
            try {
                const prodCol = firestore.collection(`mitra/${company_id}/products_reward`);

                await prodCol.doc(form.product_reward_id).set({
                    ...form,
                    // sembunyikan_produk: checked,
                    created_at: FieldValue.serverTimestamp(),
                    updated_at: FieldValue.serverTimestamp(),
                });

                enqueueSnackbar('Produk ditambahkan', { variant: "success" })

                history.push(`reward/edit/${form.product_reward_id}`)

            } catch (e) {
                const newError = {};

                console.log(e.message);

                newError.title = e.message;

                setError(newError);
            }

            setSubmitting(false)
        }
    }


    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Tambah Produk Reward</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="product_reward_id"
                            name="product_reward_id"
                            label="ID Produk"
                            onChange={handleChange}
                            error={error.product_reward_id ? true : false}
                            helperText={error.product_reward_id}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="nama"
                            name="nama"
                            label="Nama Produk"
                            onChange={handleChange}
                            error={error.nama ? true : false}
                            helperText={error.nama}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="deskripsi_singkat"
                            name="deskripsi_singkat"
                            label="Deskripsi Singkat"
                            onChange={handleChange}
                            error={error.deskripsi_singkat ? true : false}
                            helperText={error.deskripsi_singkat}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="deskripsi_lengkap"
                            name="deskripsi_lengkap"
                            label="Deskripsi Lengkap"
                            onChange={handleChange}
                            error={error.deskripsi_lengkap ? true : false}
                            helperText={error.deskripsi_lengkap}
                            fullWidth
                            multiline
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="variant1_label"
                            name="variant1_label"
                            label="Label Variant 1"
                            value={form.variant1_label || ''}
                            onChange={handleChange}
                            error={error.variant1_label ? true : false}
                            helperText={error.variant1_label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            autoComplete="off"
                            disabled={isSubmitting}
                            id="variant2_label"
                            name="variant2_label"
                            label="Label Variant 2"
                            value={form.variant2_label || ''}
                            onChange={handleChange}
                            error={error.variant2_label ? true : false}
                            helperText={error.variant2_label}
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item xs={6} lg={6}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            // disabled={isSubmitting}
                            onChange={togglecheckbox}
                            checked = {checked}
                            name="sembunyikan_produk"
                            color="default"
                        />
                        }
                        label="Jangan Tampilkan Ke Publik"
                    />
                    </Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                >Batal</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    color="primary"
                >
                    Buat
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddProduct.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default AddProduct;
