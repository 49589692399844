import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import {
    firestore,
} from "../../../components/FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import Approved from "./approved";
import useStyles from "./styles";
import Pending from "./pending";
import Reject from "./reject";
import Detail from "./detail";
import Resi from "./resi";
import PageLoading from "../../../components/loading/pageLoading";
import { useData } from "../../../components/DataProvider";

function Penukaran() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { company_id } = useData();

    const [tabValue, setTabValue] = useState(
        qs.parse(location.search)?.tab ?? "approved"
    );
    const handleChangeTab = (event, value) => {
        setTabValue(value);
    };

    useEffect(() => {
        history.push(`?tab=${tabValue}`);
    }, [tabValue, history]);

    const [penukaran, setPenukaran] = useState({});
    const col = firestore.collection(`mitra/${company_id}/penukaran_reward`);
    const [snapshot, loading] = useCollection(col, { idField: "id" });

    useEffect(() => {
        if (snapshot && !snapshot.empty) {
            setPenukaran((penukaran) => {
                return {
                    ...penukaran,
                    data: snapshot.docs.map((doc) => {
                        return {
                            uid: doc.id,
                            ...doc.data(),
                        };
                    }),
                };
            });
        } else {
            setPenukaran((penukaran) => {
                return {
                    ...penukaran,
                    data: [],
                };
            });
        }
    }, [snapshot]);

    //   console.log(penukaran.data.map((isi)=>{
    //       return isi.status
    //   }));

    const [dialog, setDialog] = useState({
        open: false,
        data: {},
    });

    const [resiDialog, setResiDialog] = useState({
        open: false,
        data: {},
    });

    const [tabs] = useState([
        {
            label: "Disetujui",
            value: "approved",
        },
        {
            label: "Ditunda",
            value: "pending",
        },
        {
            label: "Ditolak",
            value: "reject",
        },
    ]);

    return (
        <>
            {loading ? <PageLoading size={50} color="inherit" /> : ""}
            <Container maxWidth="lg">
                <Paper className={classes.paperWrap}>
                    <div className={classes.pageWrap}>
                        <Typography
                            className={classes.pageTitle}
                            component="h1"
                            variant="h6"
                        >
                            Data Penukaran
                        </Typography>
                        <Tabs
                            indicatorColor="primary"
                            variant="fullWidth"
                            className={classes.tabWrap}
                            textColor="primary"
                            value={tabValue || "approved"}
                            onChange={handleChangeTab}
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </Tabs>
                        {/* <div className={classes.content}> */}
                        {
                            penukaran?.length <= 0 &&
                            <Typography className={classes.noOrderMsg} variant="h5">Belum Ada Notifikasi</Typography>
                        }
                        <Approved
                            value={tabValue}
                            penukaran={penukaran}
                            dialog={dialog}
                            setDialog={setDialog}
                            resiDialog={resiDialog}
                            setResiDialog={setResiDialog}
                        />
                        <Pending
                            value={tabValue}
                            penukaran={penukaran}
                            dialog={dialog}
                            setDialog={setDialog}
                        />
                        <Reject
                            value={tabValue}
                            penukaran={penukaran}
                            dialog={dialog}
                            setDialog={setDialog}
                        />
                        {/* </div> */}
                    </div>
                </Paper>
            </Container>
            <Detail
                dialog={dialog}
                handleClose={() => {
                    setDialog({ open: false, data: {} });
                }}
            />
            <Resi
                resiDialog={resiDialog}
                handleClose={() => {
                    setResiDialog({ open: false, data: {} });
                }}
            />
        </>
    );
}

export default Penukaran;
