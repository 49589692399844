import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    firestore,
    functions,
} from "../../../components/FirebaseProvider";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import { currency } from "../../../utils/formatter";
import { useDocument } from "react-firebase-hooks/firestore";
function Edit({ editDialog: { open, dataSub, id, company_id }, handleClose, handleEditKurir }) {
    const classes = useStyles();
    // console.log(dataSub)
    // const location = useLocation();
    // const { enqueueSnackbar } = useSnackbar();
    // mitra_asal
    const mitraAsalDoc = firestore.doc(`mitra/${company_id}/settings/alamat`);
    const [mitraSnapshot] = useDocument(mitraAsalDoc);



    const [mitraAsal, setMitraAsal] = useState();


    useEffect(() => {
        if (mitraSnapshot) {

            const data = mitraSnapshot.data()
            setMitraAsal({
                ...data,
            });
        }

    }, [mitraSnapshot]);



    // console.log(dataSub?.alamat_tujuan?.[dataSub?.alamat_tujuan?.provider] ?? {})
    const [isSubmitting, setSubmitting] = useState(false);
    const [kurir_regular, setKurir_regular] = useState({});

    useEffect(() => {
        setSubmitting(true);
        const cekOngkir = async () => {
            if (open === true && mitraAsal && dataSub?.alamat_tujuan?.[dataSub?.alamat_tujuan?.provider] && mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id) {
                const getOngkir = functions.httpsCallable("shipping-ongkir");
                let origin = mitraAsal?.[mitraAsal?.provider]?.kecamatan?.id;
                const provider_detail = dataSub?.alamat_tujuan?.[dataSub?.alamat_tujuan?.provider] ?? {};

                let destination = provider_detail?.kecamatan?.id;

                if (mitraAsal?.provider === 'directapi') {
                    origin = {}
                    destination = {}
                    let origin_sicepat = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_sicepat?.substring?.(0, 3);
                    let destination_sicepat = provider_detail?.kelurahan?.kode_sicepat;

                    if (origin_sicepat && destination_sicepat && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_sicepat) {
                        origin.sicepat = origin_sicepat;
                        destination.sicepat = destination_sicepat;
                    }
                    let origin_jne = mitraAsal?.[mitraAsal?.provider]?.kelurahan?.kode_origin_jne;
                    let destination_jne = provider_detail?.kelurahan?.kode_destination_jne;
                    if (origin_jne && destination_jne && mitraAsal?.[mitraAsal?.provider]?.coverage_pickup_jne) {
                        origin.jne = origin_jne;
                        destination.jne = destination_jne;
                    }
                }
                let sub = {
                    id_asal: origin,
                    id_tujuan: destination,
                    berat: dataSub?.dimensi_paket?.wt,
                    panjang: dataSub?.dimensi_paket?.l,
                    lebar: dataSub?.dimensi_paket?.w,
                    tinggi: dataSub?.dimensi_paket?.h,
                    harga: dataSub?.total_pembayaran,
                    cod: 0,
                    type: 2

                };
                const response = await getOngkir(sub);



                let data = {}
                switch (response.data.provider) {

                    case 'directapi':
                        data = Object.entries(response.data.items).map(([key, value]) => {
                            return {
                                title: key,
                                data:  value?.filter((item , index) => item?.service !== "KEPO" && item?.service !== "REG").map?.((item) => {

                                    if (key === "sicepat") {
                                        return {
        
                                            provider: "sicepat",
                                            ...item,
                                            name: "SiCepat",
                                            rate_name: item.service,
                                            rate_id: item.service,
                                            finalRate: item.tariff,
                                            kurir: 'sicepat',
                                            tipe: item.service
                                        }
                                    } else if (key === "jne") {
                                        return {
                                            provider: "jne",
                                            ...item,
                                            name: "JNE",
                                            service: item.service_display,
                                            rate_id: item.service_code,
                                            rate_name: item.service_display,
                                            finalRate: parseInt(item.price),
                                            kurir: 'jne',
                                            tipe: item.service_code
                                        }
                                    }
                                }),
                            };
                        }).filter((item) => item.data.length > 0);
                        // data = response.data.items.map(item => {

                        //   return {
                        //     title: item.description,
                        //     data: [
                        //       {
                        //         ...item,
                        //         name: item.description,
                        //         rate_name: item.service,
                        //         rate_id: item.service,
                        //         finalRate: item.tariff
                        //       }
                        //     ]
                        //   }
                        // })
                        break;
                    case 'rajaongkir':
                        data = response.data.items.map(item => {
                            return {
                                title: item.name,
                                data: item.costs.map(cost => {
                                    return {
                                        ...cost,
                                        rate_id: `${item.code}-${cost.service}`,
                                        finalRate: cost?.cost?.[0]?.value ?? 0,
                                        name: item.name,
                                        rate_name: cost.service
                                    }
                                })
                            }
                        }).filter((item) => item.data.length > 0);

                        break;
                    case 'shipper':
                        if (kurir_regular) {
                            data = Object.entries(kurir_regular)
                                .map(([key, value]) => {
                                    return {
                                        title: key,
                                        data: value,
                                    };
                                })
                                .filter((item) => item.data.length > 0);
                        }
                        break;
                    default:
                }
                // console.log(data);
                setKurir_regular(data);
            }
            setSubmitting(false);
        };
        cekOngkir();
    }, [mitraAsal, open]);
    // console.log(kurir_regular)
    const lengthKurir = kurir_regular?.length
    var fieldsArray = [];

    for (var i = 0; i < lengthKurir; i++) {
        fieldsArray.push(
            kurir_regular?.[i]?.data ?
                kurir_regular?.[i]?.data?.map?.((item, i) => {
                    // console.log('K=>',item)
                    return (
                        <List
                            key={i}
                        >
                            <ListItem
                                key={i}
                                button
                                // disabled={isSubmitting}
                                // selected={selectedIndex === 0}
                                onClick={() => handleEditKurir(item, dataSub)}
                            >
                                <ListItemText
                                    primary={`${item?.provider?.toUpperCase()} ${item?.provider === 'jne' ? "" : item?.description} - ${item?.service}`} 
                                    secondary={
                                        `${currency(item?.finalRate)} 
                                            - ( ${item?.provider === 'jne' ? 
                                                    item?.etd_from !== null ? 
                                                        `${item?.etd_from } - ${item.etd_thru} hari` : `0 hari` 
                                                        : 
                                                        `${item.etd}`
                                                    } 
                                            )
                                        `}
                                />
                            </ListItem>


                        </List>
                    );
                })
            :
            <ListItemText primary="Belum Tersedia" />
        )
    }
    return (
        <Dialog
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogContent dividers>
                <Grid>
                    <DialogTitle>Pilih Kurir</DialogTitle>
                    {isSubmitting ? (
                        <ListItemText primary="Mohon Tunggu Sebentar..." />
                    ) : (
                        <>
                            {fieldsArray}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose}>
                    Batal
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Edit.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default Edit;
