import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "../../../../components/FirebaseProvider";
import Avatar from "@material-ui/core/Avatar";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { currency } from "../../../../utils/formatter";
export default function ThumbProd(props) {
    const prodDoc = firestore.doc(`products/${props?.productId}`);

    const [prod] = useDocumentData(prodDoc);
    // console.log(prod)

    const variantsDoc = firestore.doc(`products/${props?.productId}/variants/${props?.variantId}`)
    const [variant] = useDocumentData(variantsDoc)

    const thumb = prod?.thumbnail?.[0];
    const position = thumb?.lastIndexOf?.(".");
    const thumbnail300 =
        thumb?.substring(0, position) +
        "_300x300" +
        thumb?.substring(position, position?.length);
    return <>
        <ListItemAvatar>
            <Avatar
                alt={prod?.nama}
                src={
                    prod?.thumbnail ? thumbnail300 : "https://via.placeholder.com/40"
                }
                variant="rounded"
            /></ListItemAvatar>
        <ListItemText
            primary={`${prod?.nama} (${variant?.variant1?.nilai}) (${variant?.variant2?.nilai})`}
            secondary={`QTY:${props?.jumlah},${props?.harga ? `Harga = ${currency(props?.harga)} x ${props?.jumlah} = ${currency(props?.harga * props?.jumlah)}` : ''}`}
        />


    </>

}